import type {DataGridColumn, DataGridDensities, DataGridSortBy} from "../../../type-declerations";
import {useContext, useMemo} from "react";
import {DataGridSavedStateContext} from "../../../index";

export type UseDataGridSavedStateArgs = {
    columns: DataGridColumn[],
    name?: string,
    version?: number,
    sortByObjectMap?: {
        property: string,
        descending: string,
    },
}

export type UseDataGridSavedStateReturnValue<sortByObjectMap> = [
    DataGridColumn[],
        (sortByObjectMap extends undefined ? DataGridSortBy : Record<string, any>) | undefined,
        number | undefined,
    DataGridDensities?,
];

const useDataGridSavedState = ({
                                   columns: columnsProps,
                                   name,
                                   version,
                                   sortByObjectMap,
                               }: UseDataGridSavedStateArgs)
    : UseDataGridSavedStateReturnValue<typeof sortByObjectMap> => {
    const contextValue = useContext(DataGridSavedStateContext);
    const savedStateEntry = useMemo(() => contextValue.find(e => e.name === name && e.version === version), [name, version, contextValue]);

    const columns = useMemo<DataGridColumn[]>(() => {
        if (!savedStateEntry)
            return columnsProps;
        return [
            ...columnsProps,
            ...(savedStateEntry.data?.columns?.map(e => ({...e, saved: true, savable: true})) ?? [])
        ].reduce((array, column,) => {
            if (!(column as any).saved) {
                array.push(column);
            } else {
                const findIndex = array.findIndex(e => e.name === column.name);
                if (findIndex !== -1) {
                    array[findIndex] = {
                        ...array[findIndex],
                        ...column,
                    }
                    delete (array[findIndex] as any).saved;
                }
            }
            return array;
        }, [] as DataGridColumn[])
    }, [columnsProps, savedStateEntry])

    const sortBy = useMemo(() => {
        if (!savedStateEntry?.data?.sortBy)
            return undefined;
        if (!sortByObjectMap)
            return savedStateEntry.data.sortBy;
        return {
            [sortByObjectMap.property]: savedStateEntry.data.sortBy.field,
            [sortByObjectMap.descending]: savedStateEntry.data.sortBy.descending,
        };
    }, [sortByObjectMap, savedStateEntry?.data?.sortBy])

    return useMemo(() => [
        columns,
        sortBy,
        savedStateEntry?.data?.pageSize,
        savedStateEntry?.data?.density,
    ], [columns, savedStateEntry?.data?.density, savedStateEntry?.data?.pageSize, sortBy]);
}

export default useDataGridSavedState;

// --------------------------------------------------------- Pagination ---------------------------------------------------------

/**
 * The default page sizes of the table pagination
 */
export const DefaultPageSizes = [
    10 as const,
    20 as const,
    50 as const,
    100 as const,
];


/**
 * The default pagination info of the application
 */
export const DefaultPaginationInfo = {
    pageSize: DefaultPageSizes[0],
    length: 0 as const,
    currentPage: 1 as const,
    sizes: DefaultPageSizes,
}


// --------------------------------------------------------- Code Formats ---------------------------------------------------------

/**
 * The existing formats of the codes in this application.
 */
export enum CodeFormats {
    qrCode = 'qrCode',
    barCode = 'barCode'
}

/**
 * The existing content type ids for the codes in this application.
 */
export enum CodeFormatIds {
    qrCode = 1,
    barCode = 2,
}

/**
 * The map of the content type to the content type id.
 */
export const CodeFormatFormatIdMap = {
    [CodeFormats.qrCode]: CodeFormatIds.qrCode as const,
    [CodeFormats.barCode]: CodeFormatIds.barCode as const,
}
/**
 * The map of the content type id to the content type.
 */
export const CodeFormatIdFormatMap = {
    [CodeFormatIds.qrCode]: CodeFormats.qrCode as const,
    [CodeFormatIds.barCode]: CodeFormats.barCode as const,
}

export const CodeFormatIdTitleMap = {
    [CodeFormatIds.qrCode]: "QR Code" as const,
    [CodeFormatIds.barCode]: "Barcode" as const,
}

/**
 * The map of the format to the title.
 */
export const CodeFormatTitleMap = {
    [CodeFormats.qrCode]: 'QR Code' as const,
    [CodeFormats.barCode]: 'Barcode' as const,
}


// --------------------------------------------------------- Code Content Type ---------------------------------------------------------

/**
 * The existing content types for the codes in this application.
 */
export enum CodeContentTypes {
    externalLink = "external-link",
}

/**
 * The existing content type ids for the codes in this application.
 */
export enum CodeContentTypeIds {
    externalLink = 1,
}

/**
 * The map of the content type to the content type id.
 */
export const CodeContentTypeTypeIdMap = {
    [CodeContentTypes.externalLink]: CodeContentTypeIds.externalLink as const,
}
/**
 * The map of the content type id to the content type.
 */
export const CodeContentTypeIdTypeMap = {
    [CodeContentTypeIds.externalLink]: CodeContentTypes.externalLink as const,
}
/**
 * The map of the content type id to the title.
 */
export const CodeContentTypeIdTitleMap = {
    [CodeContentTypeIds.externalLink]: "External Link" as const,
}


/**
 * The visibility of the charts.
 */
export enum ChartVisibilities {
    yearly = 'yearly',
    monthly = 'monthly',
    weekly = 'weekly',
    daily = 'daily',
    hourly = 'hourly',
}

import React, {useCallback, useContext, useMemo} from "react";
import {DataGridMiscContext, DataGridStateContext} from "../../../index";
import classnames from "classnames";
import {DataGridColumnPinnedTypes, DataGridExoticColumnFields, DefaultDataGridCell} from "../../../type-declerations";
import DataGridBodyRow from "./row";
import DataGridUtils from "../../../core/services/utils";

/**
 * @param {DataGridRow[]} rows
 * @param {number | undefined} layoutRectWidth
 * @param {Function} startScrolling
 * @return {JSX.Element}
 * @constructor
 */
const DataGridBody = ({
                          rows,
                          layoutRectWidth,
                          startScrolling,
                      }) => {
    const {selectedRows, allRowsSelected, excludedRows, columns, density, group} = useContext(DataGridStateContext);
    const {classNames} = useContext(DataGridMiscContext);

    /**
     * @type {(visibleColumns:  DataGridInternalColumn[], row: DataGridRow) => DataGridInternalCell[]}
     */
    const createCells = useCallback((visibleColumns, row) => {
        /**
         * @type {DataGridInternalCell[]}
         */
        return visibleColumns?.map((column, index, array) => {
            const firstRightPinned =
                (index === 0 && column.pinnedType === DataGridColumnPinnedTypes.right) ||
                (index > 0 && !array[index - 1].pinned && column.pinnedType === DataGridColumnPinnedTypes.right);
            const lastLeftPinned =
                ((index + 1 === array.length) && column.pinnedType === DataGridColumnPinnedTypes.left) ||
                (
                    (array.length > index + 1 && array[index + 1]?.pinned === false)
                    && column.pinnedType === DataGridColumnPinnedTypes.left
                );

            // imputing content object
            let content = row.cells[column.name];
            if (React.isValidElement(content) || typeof content !== 'object') {
                content = {
                    value: content,
                    editableValue: null,
                    editable: false
                }
            } else {
                content = {
                    ...DefaultDataGridCell,
                    ...(content ?? {}),
                }
            }

            return ({
                column: column,
                firstRightPinned: firstRightPinned,
                lastLeftPinned: lastLeftPinned,
                content: content,
                showGroupToggler: false,
            })
        }) ?? []
    }, [])

    const _visibleColumns = useMemo(() =>
        columns.filter(e =>
            e.visible &&
            e.name !== DataGridExoticColumnFields.detailedPanel
        ), [columns])

    const visibleColumns = useMemo(() => {
        return DataGridUtils.getVisibleColumnsCalculatedWidths(_visibleColumns, layoutRectWidth);
    }, [_visibleColumns, layoutRectWidth])

    /**@type {Array<DataGridInternalRow>}*/
    const visibleRows = useMemo(() => {
        const colCount = visibleColumns?.length;

        /**@type {Array<DataGridInternalRow>}*/
        let result = rows?.map(row => ({
            ...row,
            selected: (allRowsSelected && !excludedRows.find(e => e.key === row.key)) ||
                (!allRowsSelected && !!selectedRows.find(e => e.key === row.key)),
            detailedPanel: row.cells[DataGridExoticColumnFields.detailedPanel],
            colCount: colCount,
            cells: createCells(visibleColumns, row),
        })) ?? []

        if (!group) {
            // no group
            return result
        }
        /**@type {Record<string, Array<DataGridRow>>}*/
        const rowGroups = result.reduce((obj, row, index) => {
            const groupValue = rows?.[index].cells[group.columnName]?.groupValue;
            if (groupValue === null || typeof groupValue === "undefined") {
                // group value does not exist
                return obj;
            }
            obj[groupValue] = [
                ...(obj[groupValue] ?? []),
                row,
            ]
            return obj;
        }, {}) ?? {};

        const rowGroupIncluded = Object.fromEntries(Object.keys(rowGroups).map(e => [e, false]))
        /**@type {DataGridInternalRow[]}*/
        const initReducedResult = [];
        result = result.reduce((array, row, index) => {
            const groupValue = rows?.[index].cells[group.columnName]?.groupValue;
            if (groupValue === null || typeof groupValue === "undefined") {
                // group value does not exist
                array.push(row)
                return array;
            }
            if (!rowGroupIncluded[groupValue]) {
                const groupedRows = rowGroups[groupValue].filter(e => e.key !== row.key) ?? [];
                array.push({
                    ...row,
                    cells: row.cells.map(e => ({
                        ...e,
                        showGroupToggler: e.column.name === group.showOnColumn,
                        groupedRowsLength: groupedRows.length,
                    })),
                    groupedRows: groupedRows,
                })
                rowGroupIncluded[groupValue] = true;
                return array;
            }
            return array;
        }, initReducedResult)

        return result;
    }, [visibleColumns, rows, allRowsSelected, selectedRows, excludedRows, group, createCells])


    return (
        <>
            <tbody className={classnames(
                'data-grid-body',
                classNames.body,
            )}>
            {
                visibleRows?.map(row => (
                    <DataGridBodyRow
                        density={density}
                        key={row.key}
                        row={row}
                        startScrolling={startScrolling}
                    />
                ))
            }
            </tbody>
        </>
    )
}

export default DataGridBody;

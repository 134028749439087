import DialogsReduxSlice from "../slices/dialogs";

/**
 * The Actions of the Redux Store state.
 */
const ReduxActions = {
    [DialogsReduxSlice.name]: DialogsReduxSlice.actions,
}

export default ReduxActions;

import './index.scss';
import {FunctionComponent, useEffect} from "react";
import Splt from 'react-spltjs';
import anime from 'animejs';
import {Typography} from "@mui/material";

export interface LoadingCirclesProps {
    text?: string;
}

const LoadingCircles: FunctionComponent<LoadingCirclesProps> = (props) => {

    /**
     * As soon as the component mounts:
     * - animates the circles
     */
    useEffect(() => {
        anime({
            targets: '.loading-circles-container',
            translateY: ['20%', 0],
            elasticity: 1,
            duration: 600,
            easing: 'cubicBezier(.71,-0.77,.43,1.67)',
        })
        anime({
            targets: '.loading-circle',
            elasticity: 1,
            opacity: [0, 1],
            duration: 1000,
            delay: anime.stagger(25),
            easing: 'cubicBezier(.71,-0.77,.43,1.67)',
        })
    }, [])

    /**
     * When the text changes:
     * - animates the text
     * - animates the dots
     */
    useEffect(() => {
        if (!props.text?.length)
            return;
        anime({
            targets: '.reveal',
            translateY: ['-50%', 0],
            opacity: [0, 1],
            elasticity: 1,
            duration: 1000,
            delay: anime.stagger(25),
            easing: 'cubicBezier(.71,-0.77,.43,1.67)',
        })
        const timerId = setTimeout(() => {
            anime({
                targets: '.reveal-dots',
                translateY: ['-50%', 0, 0],
                opacity: [0, 1, 0],
                elasticity: 1,
                duration: 2000,
                loop: true,
                delay: anime.stagger(200),
                easing: 'cubicBezier(.71,-0.77,.43,1.67)',
            })
        }, (props.text.length * 25) + 1000);
        return () => {
            window.clearTimeout(timerId);
        }
    }, [props.text])

    return (
        <>
            <div className={'loading-circles-wrapper'}>
                <div className="loading-circles-container">
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                    <div className="loading-circle"/>
                </div>
                <Typography component={'p'} variant={'h6'} className={'loading-circles-text'}>
                    {
                        props.text &&
                        <Splt text={props.text} className={'reveal'}/>
                    }
                    {
                        props.text &&
                        <Splt text={'...'} className={'reveal-dots'}/>
                    }
                </Typography>
            </div>
        </>
    );
}

export default LoadingCircles;

import {TinyColor} from "@ctrl/tinycolor/dist";

/**
 * Represents a point-entity on the canvas.
 */
class Point {
    public x: number;
    public y: number;
    public z: number;
    public scale: number;
    public color: string;
    public vel: [number, number, number];
    public ttl: number;
    public sttl: number;

    private readonly alpha: number;
    private readonly controller: BallFountainCanvasController;

    /**
     * Constructs a new point with the provided values.
     *
     * @param controller        the controller that manages the rendering of this point.
     * @param x                 the horizontal position of the point in the canvas
     * @param y                 the vertical position of the point in the canvas
     * @param z                 the diagonal position of the point in the canvas
     * @param scale             the scale of the point in the canvas
     * @param color             the color of the point in the canvas
     * @param alpha             the opacity of the point in the canvas
     * @param vel               the velocity of the point in the canvas
     */
    public constructor(
        controller: BallFountainCanvasController,
        x?: number,
        y?: number,
        z?: number,
        scale?: number,
        color?: string,
        alpha?: number,
        vel?: [number, number, number],
    ) {
        this.controller = controller;
        this.x = x || 1;
        this.y = y || 0;
        this.z = z || 1.001;
        this.scale = scale || 1;
        this.alpha = alpha || 1.0;
        this.color = color || "#fff";
        this.vel = vel || [0, 0, 0];
        this.ttl = Math.random() * this.controller.ttl + 1;
        this.sttl = this.ttl;
    }

    /**
     * Creates a rotated and projected point from this entity.
     */
    public process(): Point {
        return this.project(this.rotate(this));
    }

    /**
     * Rotates the provided point.
     * @param point    the point to rotate.
     */
    private rotate(point: Point = this): Point {
        const p = new Point(this.controller, point.x, point.y, point.z, point.scale, point.color, point.alpha);
        p.x = (point.x) * Math.cos(this.controller.ry) - (point.z) * Math.sin(this.controller.ry);
        p.z = (point.x) * Math.sin(this.controller.ry) + (point.z) * Math.cos(this.controller.ry) + this.controller.z;
        p.scale = point.scale;
        return p;
    }

    /**
     * Projects the provided point
     * @param point    the point to project.
     * @private
     */
    private project(point: Point = this): Point {
        const p = new Point(this.controller, point.x, point.y, point.z, point.scale, point.color, point.alpha);
        p.x = this.controller.canvasContext.canvas.width / 2 + (point.x * (this.controller.dist / point.z));
        p.y = this.controller.canvasContext.canvas.height / 2 + (point.y * (this.controller.dist / point.z));
        p.scale = (this.controller.dist / point.z);
        return p;
    }
}

/**
 * Houses the logic for rendering the Plain and balls for the animation.
 */
class Quad {
    public readonly cy: number;
    private readonly cx: number;
    private readonly cz: number;
    private readonly length: number;
    private readonly width: number;
    private readonly color: string;
    private readonly verts: Array<Point>;
    private readonly controller: BallFountainCanvasController;

    /**
     * Constructs a new Quad with the provided values.
     *
     * @param controller        the controller that manages the rendering of this quad.
     * @param cx                the horizontal position of the point in the canvas
     * @param cy                the vertical position of the point in the canvas
     * @param cz                the diagonal position of the point in the canvas
     * @param l                 the length of the quad's lines that depict the plain on which the points fall
     * @param w                 the width of the quad's plain (diagonal axis)
     * @param color             the color of the plain lines.
     */
    public constructor(
        controller: BallFountainCanvasController,
        cx?: number,
        cy?: number,
        cz?: number,
        l?: number,
        w?: number,
        color?: string,
    ) {
        this.controller = controller;
        this.cx = cx || 0;
        this.cy = cy || 0;
        this.cz = cz || 0;
        this.length = l || 1;
        this.width = w || 1;
        this.color = color || "#fff";
        this.verts = [
            new Point(this.controller, this.cx + this.width / 2, cy, this.cz + this.length / 2),
            new Point(this.controller, this.cx - this.width / 2, cy, this.cz + this.length / 2),
            new Point(this.controller, this.cx - this.width / 2, cy, this.cz - this.length / 2),
            new Point(this.controller, this.cx + this.width / 2, cy, this.cz - this.length / 2),
        ];
    }

    /**
     * Draws the quad's lines in the canvas that this quad's controller manages.
     */
    public draw(): void {
        let pt1: Point;
        let pt2: Point;
        for (let i = 0; i < this.verts.length - 1; i++) {
            pt1 = this.verts[i].process();
            pt2 = this.verts[i + 1].process();
            this.drawLine(
                pt1.x,
                pt1.y,
                pt2.x,
                pt2.y,
                this.color,
            );
        }
        pt1 = this.verts[0].process();
        pt2 = this.verts[this.verts.length - 1].process();
        this.drawLine(
            pt2.x,
            pt2.y,
            pt1.x,
            pt1.y,
            this.color,
        );
    }

    /**
     * Draws a single line for this quad's plain surface.
     *
     * @param sx            starting horizontal position of the line
     * @param sy            starting vertical position of the line
     * @param x             ending horizontal position of the line
     * @param y             ending vertical position of the line
     * @param color         the color of the line.
     */
    private drawLine(sx: number, sy: number, x: number, y: number, color: string): void {
        this.controller.canvasContext.strokeStyle = color;
        this.controller.canvasContext.beginPath();
        this.controller.canvasContext.moveTo(sx, sy);
        this.controller.canvasContext.lineTo(x, y);
        this.controller.canvasContext.stroke();
    }

}

/**
 * Houses the logic for rendering and performing the animations of the ball-fountain-canvas
 */
class BallFountainCanvasController {
    public canvasContext!: CanvasRenderingContext2D;
    public blur: boolean;
    public ttl: number;
    // noinspection JSUnusedGlobalSymbols
    public pt!: Point;
    public z: number;
    public dist: number;
    public ry: number;

    private readonly numpts: number;
    private readonly blurf: number;
    private readonly gravity: number;
    private readonly color: number;
    private readonly bounce: number;
    private readonly xvel: number;
    private readonly yvel: number;
    private readonly zvel: number;
    private readonly ballColor: string;
    private readonly canvasColor: string;
    private plane: Quad;
    private points: Array<Point>;
    private mouse: boolean = false;
    private lastx!: number;
    private lasty!: number;
    private last: number;
    private canvas: HTMLCanvasElement;

    /**
     * Constructs the controller that manages the provided canvas.
     *
     * @param canvas            the canvas element that the controller will manage.
     * @param ballColor         the color of the balls
     * @param canvasColor       the background color of the canvas
     * @param plainColor        the color of the canvas plain lines
     */
    constructor(
        canvas: HTMLCanvasElement,
        ballColor?: string,
        canvasColor?: string,
        plainColor?: string,
    ) {
        this.canvas = canvas;
        this.canvasContext = canvas.getContext("2d")!;
        this.blur = false;
        this.ttl = 10;
        this.z = 200;
        this.dist = 1000;
        this.ry = 0;
        this.numpts = 300;
        this.blurf = 0.3;
        this.gravity = 9.8;
        this.color = new TinyColor(ballColor).toHsl().h;
        this.bounce = 0.7;
        this.points = [];
        this.mouse = false;
        this.xvel = 25;
        this.yvel = 50;
        this.zvel = 25;
        this.last = new Date().getTime();
        this.ballColor = ballColor || '#ffffff00';
        this.canvasColor = canvasColor || '0 0 0';
        this.plane = new Quad(this, 0, 25, 0, 40, 40, plainColor);
    }

    /**
     * Creates the balls that this controller will use to animate.
     */
    private createBalls() {
        this.points = [];
        for (let i = 0; i < this.numpts; i++) {
            this.points.push(this.createPoint());
            this.points[i].ttl = 0;
        }
    }

    /**
     * Starts the controller's animation.
     */
    public start() {
        this.createBalls();
        const cleanupFunction = this.attachObservers();
        this.render();
        return cleanupFunction;
    }

    /**
     * Attaches observers to the canvas to control canvases camera position.
     */
    private attachObservers(): () => void {
        const onMousedown = (e: MouseEvent) => {
            const evt: MouseEvent & { layerX: number, layerY: number } = e as any;
            this.mouse = true;
            this.lastx = ((evt.layerX || evt.offsetX || evt.clientX) - this.canvasContext.canvas.width / 2) / this.canvasContext.canvas.width * Math.PI * 2;
            this.lasty = (-evt.layerY || evt.offsetY || evt.clientY);
        }
        const onMouseup = () => {
            this.mouse = false;
        }
        const onMousemove = (e: MouseEvent) => {
            const evt: MouseEvent & { layerX: number, layerY: number } = e as any;
            if (!this.mouse)
                return;

            let ex = (evt.layerX || evt.offsetX || evt.clientX);
            let ey = (-evt.layerY || evt.offsetY || evt.clientY);

            let r = ((ex - this.canvasContext.canvas.width / 2) / this.canvasContext.canvas.width) * Math.PI * 2;
            this.ry += r - this.lastx;
            this.lastx = r;

            this.z += ey - this.lasty;
            this.z = Math.min(Math.max(this.z, 30), 1000);
            this.lasty = ey;
        }
        this.canvas.addEventListener('mousedown', onMousedown);
        this.canvas.addEventListener('mouseup', onMouseup);
        this.canvas.addEventListener('mousemove', onMousemove);
        return () => {
            this.canvas.removeEventListener('mousedown', onMousedown)
            this.canvas.removeEventListener('mouseup', onMouseup)
            this.canvas.removeEventListener('mousemove', onMousemove)
        };
    }

    /**
     * Renders the plain and points of the current controller.
     * @private
     */
    private render() {
        if (!this.blur) {
            this.canvasContext.clearRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);
        } else {
            this.canvasContext.fillStyle = this.canvasColor;
            this.canvasContext.fillRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);
        }

        this.plane.draw();

        for (let i = 0; i < this.points.length; i++) {
            const pt = this.points[i].process();
            this.drawCircle(pt.x, pt.y, this.points[i].scale * pt.scale, 1, this.points[i].color);
        }

        this.animate();
        requestAnimationFrame(() => this.render());
    }

    /**
     * Draws a circle (The ball) with the provided values.
     *
     * @param x         the horizontal position of the circle
     * @param y         the vertical position of the circle
     * @param r         the radius of the circle
     * @param w         the line width of the circle
     * @param color     the color of the circle
     */
    private drawCircle(x: number, y: number, r: number, w: number, color: string) {
        this.canvasContext.fillStyle = color;
        this.canvasContext.lineWidth = w;
        this.canvasContext.beginPath();
        this.canvasContext.arc(x, y, (r > 0 ? r : 0), 0, Math.PI * 2);
        this.canvasContext.fill();
    }

    /**
     * Animates the position and scale of the balls in this controller.
     */
    private animate() {
        let time = new Date().getTime();
        let t = (time - this.last) / 1000;
        // HACK - clamp time, so it doesn't go haywire when last grows large cus render stops
        t = t > 0.3 ? 0.3 : t;

        for (let i = 0; i < this.points.length; i++) {
            this.points[i].ttl -= t;
            this.points[i].x += this.points[i].vel[0] * t;
            this.points[i].y += this.points[i].vel[1] * t;
            this.points[i].z += this.points[i].vel[2] * t;
            this.points[i].vel[1] += this.gravity;
            this.points[i].scale = (this.points[i].ttl / this.points[i].sttl);
            this.points[i].color = "hsla(" + (this.color + (this.points[i].ttl / this.points[i].sttl) * 30) + ",100%, " + (this.points[i].ttl / this.points[i].sttl) * 100 + "%,1.0)";

            if (this.points[i].ttl < 0) {
                this.points.splice(i, 1);
                this.points.push(this.createPoint());
            }

            if ((this.points[i].y + this.points[i].scale / 2) >= this.plane.cy) {
                // applying (+this.gravity) keeps them from bouncing forever
                this.points[i].vel[1] = -this.points[i].vel[1] * this.bounce + this.gravity * 1.25;
                this.points[i].y = this.plane.cy - (this.points[i].scale / 2);
            }
        }

        this.last = time;
    }

    /**
     * Creates an instance of the Point for the ball to be rendered.
     * @private
     */
    private createPoint(): Point {
        return new Point(
            this,
            Math.random() * 2 - 1,
            Math.random() * 2 - 1,
            Math.random() * 2 - 1,
            undefined, // Math.random() + 0.1,
            this.ballColor,
            0.5,
            [
                Math.random() * this.xvel - this.xvel / 2,
                Math.random() * -this.yvel,
                Math.random() * this.zvel - this.zvel / 2
            ]
        );
    }
}

export default BallFountainCanvasController;

import React, {createContext, ForwardedRef, forwardRef, useContext} from "react";
import * as ReactFinalForm from 'react-final-form';
import YupExtensions from "./yup-extensions";
import {FormApi} from "final-form";

declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
    ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

interface FormProps<FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>> extends Omit<ReactFinalForm.FormProps<FormValues, InitialFormValues>, 'render' | 'component'> {
    children: ReactFinalForm.FormProps<FormValues, InitialFormValues>['children'];
    formProps?: Partial<Omit<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit' | 'children' | 'ref'>>,
}

YupExtensions.injectExtensions();

/**
 * The context for providing the form inside the form-related components.
 */
const FormContext = createContext<FormApi | null>(null);

const FormInner = <FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>(
    props: FormProps<FormValues, InitialFormValues>,
    ref: ForwardedRef<HTMLFormElement>
) => {

    return (
        <ReactFinalForm.Form<FormValues, InitialFormValues>
            {...props}
            onSubmit={props.onSubmit}
        >
            {(renderProps) => {
                return (
                    <FormContext.Provider value={renderProps.form as FormApi}>
                        <form
                            noValidate
                            {...props.formProps}
                            onSubmit={renderProps.handleSubmit}
                            ref={ref}
                        >
                            {
                                typeof props.children !== 'function'
                                    ? props.children
                                    // @ts-ignore
                                    : props.children(renderProps)
                            }
                        </form>
                    </FormContext.Provider>
                );
            }}
        </ReactFinalForm.Form>
    );
}

const Form = forwardRef(FormInner);

/**
 * A hook that exposes the FormContext's value to the using component.
 */
export const useForm = () => useContext(FormContext);

export * as Yup from 'yup';
export * as MuiRFF from 'mui-rff';
export * as ReactFinalForm from 'react-final-form';
export * as FinalForm from 'final-form';
export default Form;

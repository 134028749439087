import * as Bizkeytech from "../../../@bizkeytech";


/**
 * @inheritDoc
 */
class EnvService extends Bizkeytech.EnvService {

    /**
     * The current version of the application.
     */
    public static version: string = process.env.REACT_APP_VERSION ?? "0.0.0";

    /**
     * Whether to bypass authentication or not.
     */
    public static bypassAuthentication: boolean = process.env.REACT_APP_BYPASS_AUTHENTICATION === 'true';

    /**
     * The name of the application for QueryBuilder and DataGrid services.
     */
    public static servicesApplicationName: string = process.env.REACT_APP_SERVICES_APPLICATION_NAME ?? "";
}

export default EnvService;

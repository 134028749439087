import type {FunctionComponent, MouseEventHandler, ReactNode} from "react";
import {useCallback, useMemo, useState} from "react";
import {
    Box,
    Breakpoint,
    Button,
    ButtonProps,
    Divider,
    IconButton,
    IconButtonProps,
    InputAdornment,
    inputBaseClasses,
    ListItemIcon,
    listItemIconClasses,
    ListItemText,
    listItemTextClasses,
    Menu,
    MenuItem,
    MenuItemProps,
    TextField,
    TextFieldProps,
    Theme,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {MoreVertRounded, Search} from "@mui/icons-material";
import useDebounced from "../../../hooks/use-debounced";
import {useUpdateEffect} from "react-use";
import UIUtils from "../../../utils";


/**
 * The default breakpoint for the appbar.
 */
const defaultBreakpoints: Required<Required<AppbarProps>['breakpoints']> = {
    mobileView: 'sm',
    tabletView: 'md',
};

export interface AppbarProps {
    breakpoints?: {
        mobileView?: Breakpoint | number,
        tabletView?: Breakpoint | number,
    },
    startActions?: Array<Omit<AppbarStartActionProps, 'breakpoints'> & { key?: string }>,
    endActions?: Array<Omit<AppbarEndActionProps, 'breakpoints'> & { key?: string }>,
    SearchbarProps?: Omit<AppbarSearchbarProps, 'variant'>,
}

export const PanelAppbar: FunctionComponent<AppbarProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const breakpoints = useMemo(() => ({
        tabletView: props.breakpoints?.tabletView ?? defaultBreakpoints.tabletView,
        mobileView: props.breakpoints?.mobileView ?? defaultBreakpoints.mobileView,
    }), [props.breakpoints?.mobileView, props.breakpoints?.tabletView]);

    const theme = useTheme();
    const useMenuForStartActions = useMediaQuery(theme.breakpoints.down(breakpoints.mobileView))
    const useMenuForEndActions = useMediaQuery(theme.breakpoints.down(breakpoints.tabletView))

    const startActions = useMemo<Required<AppbarProps>['startActions']>(() => props.startActions?.map(e => ({
        ...e,
        ...(useMenuForStartActions && {
            sx: (theme) => ({
                '&:hover': {
                    backgroundColor: `rgba(${UIUtils.themeVar(`palette-${e.color ?? 'primary'}-mainChannel`)} / ${UIUtils.themeVar(`palette-action-selectedOpacity`)})`,
                    [`& .${listItemIconClasses.root}`]: {
                        color: theme.palette[e.color ?? 'primary'].main,
                    },
                    [`& .${listItemTextClasses.primary}`]: {
                        color: theme.palette[e.color ?? 'primary'].main,
                    },
                }
            }),
        })
    })) ?? [], [props.startActions, useMenuForStartActions]);

    const endActions = useMemo<Required<AppbarProps>['endActions']>(() => props.endActions?.map(e => ({
        ...e,
        ...(useMenuForEndActions && {
            sx: (theme) => ({
                '&:hover': {
                    backgroundColor: `rgba(${UIUtils.themeVar(`palette-${e.color ?? 'primary'}-mainChannel`)} / ${UIUtils.themeVar(`palette-action-selectedOpacity`)})`,
                    [`& .${listItemIconClasses.root}`]: {
                        color: theme.palette[e.color ?? 'primary'].main,
                    },
                    [`& .${listItemTextClasses.primary}`]: {
                        color: theme.palette[e.color ?? 'primary'].main,
                    },
                }
            }),
        })
    })) ?? [], [props.endActions, useMenuForEndActions]);

    /**
     * Opens the menu.
     * @param {MouseEvent<HTMLElement>} event    The event.
     */
    const openMenu = useCallback<MouseEventHandler<HTMLElement>>((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    /**
     * Closes the menu.
     */
    const closeMenu = useCallback<VoidCallback>(() => {
        setAnchorEl(null);
    }, []);

    /**
     * Closes the menu and calls the [action] callback.
     *
     * @param {MouseEventHandler<HTMLElement>} action    The action callback.
     * @return {MouseEventHandler<HTMLElement>}          The event handler.
     */
    const closeMenuOnActionSelection = useCallback<(action?: MouseEventHandler<HTMLElement>) => MouseEventHandler<HTMLElement>>(
        (action) => (event) => {
            closeMenu();
            action?.(event);
        }, [closeMenu])

    return (
        <>
            <Box display={'flex'} flexGrow={1} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
                    {
                        !useMenuForStartActions &&
                        startActions?.map((action) => (
                            <AppbarStartAction
                                {...action}
                                key={action.key}
                                breakpoints={breakpoints}
                            />
                        ))
                    }
                </Box>
                <Box flexGrow={1}/>
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gap={1}>
                    <AppbarSearchbar
                        placeholder={'search...'}
                        {...props.SearchbarProps}
                    />
                    {
                        !useMenuForEndActions && (
                            endActions?.map((action, index, array) => (
                                <AppbarEndAction
                                    {...action}
                                    {...(index === array.length - 1 && {edge: 'end'})}
                                    key={action.key}
                                    breakpoints={breakpoints}
                                />
                            ))
                        )
                    }
                    {
                        (useMenuForEndActions || useMenuForStartActions) && (
                            <>
                                <Tooltip title={"More Actions"}>
                                    <IconButton onClick={openMenu} edge={'end'}>
                                        <MoreVertRounded/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={closeMenu}
                                    onClick={closeMenu}
                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                    slotProps={{
                                        paper: {sx: {backgroundImage: 'none'}}
                                    }}
                                >
                                    {
                                        useMenuForStartActions &&
                                        startActions?.map((action) => (
                                            <AppbarStartAction
                                                {...action}
                                                key={action.key}
                                                breakpoints={breakpoints}
                                                onClick={closeMenuOnActionSelection(action.onClick)}
                                            />
                                        ))
                                    }
                                    {
                                        useMenuForEndActions && useMenuForStartActions &&
                                        <Divider/>
                                    }
                                    {
                                        useMenuForEndActions &&
                                        endActions?.map((action) => (
                                            <AppbarEndAction
                                                {...action}
                                                key={action.key}
                                                breakpoints={breakpoints}
                                                onClick={closeMenuOnActionSelection(action.onClick)}
                                            />
                                        ))
                                    }
                                </Menu>
                            </>
                        )
                    }
                </Box>
            </Box>
        </>
    );
}

export interface AppbarSearchbarProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
    value?: string,
    onChange?: (value: string) => void,
    timout?: number,
}

export const AppbarSearchbar: FunctionComponent<AppbarSearchbarProps> = (props) => {
    const [value, setValue, debouncedValue, clearDebounceTimeout] = useDebounced(props.value ?? '', props.timout ?? 300);

    /**
     * With each change in the [value]:
     * - calls the [onChange] callback of the [props].
     */
    useUpdateEffect(() => {
        props.onChange?.(debouncedValue);
    }, [props.onChange, debouncedValue]);

    /**
     * Handle the change in the value by updating the [value].
     * @param {ChangeEvent<HTMLInputElement>} event   The event.
     */
    const onValueChanged = useCallback<Required<TextFieldProps>['onChange']>(
        (event) => setValue(event.target.value), [setValue]);

    /**
     * Search for the [value] by calling the [onChange] callback of the [props].
     */
    const search = useCallback<VoidCallback>(() => {
        props.onChange?.(value);
        clearDebounceTimeout();
    }, [props, value, clearDebounceTimeout]);

    /**
     * Handle the key down event by calling the [onChange] callback of the [props] when the [Enter] key is pressed.
     * @param {KeyboardEvent<HTMLDivElement>} event   The event.
     */
    const onKeyDown = useCallback<Required<TextFieldProps>['onKeyDown']>(
        (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                search();
            }
        }, [search]);

    /**
     * The styles applied to the search bar.
     *
     * @param {Theme} theme         The theme.
     * @return {ThemeMixin<Theme>}  The styles.
     */
    const mixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.vars?.shape.borderRadius,
        '& fieldset': {
            border: 'none',
        },
        [`& .${inputBaseClasses.root}`]: {
            paddingInlineEnd: theme.spacing(1),
        },
        [`& .${inputBaseClasses.input}`]: {
            fontSize: theme.typography.subtitle2.fontSize,
        }
    }), [])

    return (
        <>
            <TextField
                {...props}
                variant={'outlined'}
                onChange={onValueChanged}
                onKeyDown={onKeyDown}
                value={value}
                sx={mixin}
                size={'small'}
                InputProps={{
                    ...(props.InputProps ?? {}),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton size={'small'} disableRipple color={'primary'} onClick={search}>
                                <Search fontSize={'small'}/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </>
    );
}

export interface AppbarStartActionProps extends Omit<IconButtonProps, 'color' | 'classes'>, Omit<ButtonProps, 'color' | 'classes'>, Omit<MenuItemProps<'button'>, 'color' | 'classes'> {
    title: string,
    icon: ReactNode,
    breakpoints?: AppbarProps['breakpoints'],
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    classes?: IconButtonProps['classes'] & ButtonProps['classes'] & MenuItemProps['classes'],
}

export const AppbarStartAction: FunctionComponent<AppbarStartActionProps> = (props) => {
    const breakpoints = useMemo(() => ({
        tabletView: props.breakpoints?.tabletView ?? defaultBreakpoints.tabletView,
        mobileView: props.breakpoints?.mobileView ?? defaultBreakpoints.mobileView,
    }), [props.breakpoints?.mobileView, props.breakpoints?.tabletView]);

    const theme = useTheme();

    const useIconButton = useMediaQuery(theme.breakpoints.up(breakpoints.mobileView))
    const useMenuItem = useMediaQuery(theme.breakpoints.down(breakpoints.mobileView))

    return (
        <>
            {
                !useIconButton && !useMenuItem && (
                    <Button {...props}>
                        {props.title}
                    </Button>
                )
            }
            {
                useIconButton && (
                    <Tooltip title={props.title}>
                        <span>
                            <IconButton {...props} color={'paper.'.concat(props.color ?? 'primary') as 'primary'}>
                                {props.icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                )
            }
            {
                useMenuItem && (
                    <MenuItem {...props as MenuItemProps}>
                        <ListItemIcon>
                            {props.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={props.title}
                            primaryTypographyProps={{
                                fontSize: theme.typography.subtitle2.fontSize,
                            }}
                        />
                    </MenuItem>
                )
            }
        </>
    );
}


export interface AppbarEndActionProps extends Omit<IconButtonProps, 'color' | 'classes'>, Omit<MenuItemProps<'button'>, 'color' | 'classes'> {
    title: string,
    icon: ReactNode,
    breakpoints?: AppbarProps['breakpoints'],
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    classes?: IconButtonProps['classes'] & MenuItemProps['classes'],
}

export const AppbarEndAction: FunctionComponent<AppbarEndActionProps> = (props) => {
    const breakpoints = useMemo(() => ({
        tabletView: props.breakpoints?.tabletView ?? defaultBreakpoints.tabletView,
        mobileView: props.breakpoints?.mobileView ?? defaultBreakpoints.mobileView,
    }), [props.breakpoints?.mobileView, props.breakpoints?.tabletView]);

    const theme = useTheme();
    const useMenuItem = useMediaQuery(theme.breakpoints.down(breakpoints.tabletView))

    return (
        <>
            {
                !useMenuItem && (
                    <Tooltip title={props.title}>
                        <span>
                            <IconButton {...props} color={'paper.'.concat(props.color ?? 'primary') as 'primary'}>
                                {props.icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                )
            }
            {
                useMenuItem && (
                    <MenuItem {...props as MenuItemProps}>
                        <ListItemIcon>
                            {props.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={props.title}
                            primaryTypographyProps={{
                                fontSize: theme.typography.subtitle2.fontSize,
                            }}
                        />
                    </MenuItem>
                )
            }
        </>
    );
}

export default PanelAppbar;

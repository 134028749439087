import type {ApiExecutorEffect, ApiInterceptors, ApiRequestDs, ApiResponseDs, IApiExecutor} from "./type-declarations";
import ApiResponseCodes from "../../models/api-response-codes";
import axios, {AxiosError, AxiosResponse} from "axios";

/**
 * The Api executor of this application.
 */
class ApiExecutor implements IApiExecutor {

    /**
     * Creates a placeholder response data-structure.
     *
     * * this method is primarily used for creating the response object when an error happens in the api call.
     * @param statusCode        The status code of the response.
     */
    private static createPlaceholderResponseDs(statusCode: number): ApiResponseDs {
        return {
            code: statusCode,
            resultFlag: false,
            data: null,
            message: undefined,
            aborted: statusCode === ApiResponseCodes.aborted,
        };
    }

    /**
     * Executes the logic for when an error is thrown while the api call is being made or after the response of the
     * api call is received.
     * @param error     The error that was thrown.
     */
    private static onErrorReceived(error: AxiosError & {}): ApiResponseDs {
        if (error.response) {
            // Request made and server responded
            return this.createPlaceholderResponseDs(
                error.response?.status ?? ApiResponseCodes.serverNotResponded
            );
        }
        if (error.request) {
            if (axios.isCancel(error)) {
                // cancelled / aborted
                return this.createPlaceholderResponseDs(ApiResponseCodes.aborted);
            }
            // The request was made but no response was received
            return this.createPlaceholderResponseDs(ApiResponseCodes.serverNotResponded);
        }

        // Something happened in setting up the request that triggered an Error
        return this.createPlaceholderResponseDs(ApiResponseCodes.requestFailed);
    }

    /**
     * Creates a response data-structure from the given response object and the statusCode.
     *
     * @param response      The response object.
     * @param statusCode    The status code of the response.
     */
    private static createResponseDs(response: ApiResponseDs, statusCode: number): ApiResponseDs {
        const code = response.code ?? statusCode;
        return {
            ...response,
            resultFlag: response.resultFlag ?? false,
            data: response?.data,
            code: code,
            aborted: code === ApiResponseCodes.aborted,
        };
    }

    /**
     * Executes the logic for when the response of the api call is received without any axios-specific or http
     * specific errors.
     *
     * @param request       The request object.
     * @param response      The response object.
     */
    private static onResponseReceived(request: ApiRequestDs, response: AxiosResponse): ApiResponseDs {
        if (!request.externalResponseDS) {
            const internalResponse: ApiResponseDs = response.data;
            return this.createResponseDs(
                internalResponse,
                internalResponse.code ?? response.status,
            );
        }
        return this.createResponseDs(
            {
                code: response.status,
                data: response.data,
                resultFlag: true,
            },
            response.status,
        );
    }

    /**
     * The effects that will be run after the execution.
     */
    private readonly effects: Array<ApiExecutorEffect> = [];
    /**
     * The instance with which all the api calls are handled.
     */
    private readonly instance = axios.create();

    /**
     * Injects api interceptors to the axios instance used in this interface.
     * @param interceptors  the interceptors to be injected.
     */
    public injectInterceptors(interceptors: Partial<ApiInterceptors>): void {
        this.instance.interceptors.request.clear();
        this.instance.interceptors.response.clear();
        for (const [onFulfilled, onRejected] of interceptors.request ?? [])
            this.instance.interceptors.request.use(onFulfilled, onRejected);
        for (const [onFulfilled, onRejected] of interceptors.response ?? [])
            this.instance.interceptors.response.use(onFulfilled, onRejected);
    }

    /**
     * Injects the given effects to the executor.
     * @param effects    The effects to be injected.
     */
    public injectEffects(effects: Array<ApiExecutorEffect>): void {
        for (const effect of effects) {
            this.removeEffect(effect.id);
            this.injectEffect(effect.id, effect.effect);
        }
    }

    /**
     * Executes the api call with the given args and then parses the response.
     * @param request       The request that will be executed.
     */
    public async execute(request: ApiRequestDs): Promise<ApiResponseDs> {
        let response: ApiResponseDs;
        try {
            response = ApiExecutor.onResponseReceived(request, await this.instance.request(request));
        } catch (e: any) {
            response = ApiExecutor.onErrorReceived(e);
        }
        this.runExecutionEffects(request, response);
        return response;
    }

    /**
     * Runs the effects registered in this executor after the execution of the api call.
     * @param request       The request that was executed.
     * @param response      The response that was received.
     */
    private runExecutionEffects(request: ApiRequestDs, response: ApiResponseDs): void {
        for (const effect of this.effects ?? []) {
            effect.effect(request, response);
        }
    }

    /**
     * Injects an effect to the executor.
     * @param id        The id of the effect.
     * @param effect    The effect to be injected.
     */
    private injectEffect(id: string, effect: Function): void {
        this.effects.push({id, effect});
    }

    /**
     * Removes the effect with the given id.
     * @param id        The id of the effect to be removed.
     */
    private removeEffect(id: string): void {
        const index = this.effects.findIndex(effect => effect.id === id);
        if (index === -1)
            return;
        this.effects.splice(index, 1);
    }
}


export default ApiExecutor;

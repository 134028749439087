import React, {FunctionComponent, useMemo} from "react";
import {ReactComponent as CheckBoxIcon} from "../../../assets/images/checkbox/checkbox.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/images/checkbox/checkbox-active.svg";
import {ReactComponent as RadioIcon} from "../../../assets/images/radio/radio.svg";
import {ReactComponent as RadioCheckedIcon} from "../../../assets/images/radio/radio-active.svg";
import classnames from "classnames";
import type {CheckboxProps} from '@mui/material';
import {Checkbox} from "@mui/material";

export type DataGridCheckboxProps = CheckboxProps & {
    radio?: boolean;
}

const DataGridCheckbox: FunctionComponent<DataGridCheckboxProps> = ({
                                                                        className,
                                                                        radio,
                                                                        ...props
                                                                    }) => {

    const icons = useMemo(() => {
        if (!radio)
            return {
                icon: <CheckBoxIcon/>,
                checkedIcon: <CheckBoxCheckedIcon/>,
            }
        return {
            icon: <RadioIcon/>,
            checkedIcon: <RadioCheckedIcon/>,
        }
    }, [radio])

    return (
        <>
            <Checkbox
                {...props}
                {...icons}
                className={classnames('data-grid-checkbox', className)}
            />
        </>
    )
}

export default DataGridCheckbox;

export enum DataGridCachingServiceLocalStorageKeys {
    savedState = 'data-grid-saved-state',
}

export type DataGridCachingServiceLocalStorageKeysType = ReverseMap<typeof DataGridCachingServiceLocalStorageKeys>;


export enum DataGridCachingServiceCookiesKeys {
    savedState = 'data-grid-saved-state',
}

export type DataGridCachingServiceCookiesKeysType = ReverseMap<typeof DataGridCachingServiceCookiesKeys>;



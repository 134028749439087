import React, {useContext, useMemo} from "react";
import {DataGridControllerContext, DataGridStateContext} from "../../../../../index";
import DataGridCheckbox from "../../../../components/checkbox";
import DataGridUtils from "../../../../../core/services/utils";

const DataGridHeaderCellSelectionContent = () => {
    const {allRowsSelected, selectedRows, excludedRows, pagination} = useContext(DataGridStateContext);
    const dataGridApi = useContext(DataGridControllerContext);

    const checked = useMemo(() =>
            DataGridUtils.areAllRowsSelected(allRowsSelected, selectedRows.length, excludedRows.length, pagination.length),
        [selectedRows.length, excludedRows.length, pagination.length, allRowsSelected])

    /**
     * Toggles the selection of all rows.
     *
     * @param {Event} e - The event object.
     */
    const toggleSelection = (e) => {
        e.stopPropagation();
        e.preventDefault();
        dataGridApi.toggleAllRowsSelection(!checked, true);
    }

    return (
        <>
            <DataGridCheckbox
                checked={checked}
                onClick={toggleSelection}
            />
        </>
    )
}

export default DataGridHeaderCellSelectionContent;

import React, {forwardRef, useCallback, useEffect, useState} from "react";
import * as DG from "../../../../data-grid";
import LottieAnimation, {LoadingAnimation} from "../lottie-animation";
import useIsMounted from "../../hooks/use-is-mounted";
import classNames from "classnames";
import BizkeytechReduxStore, {BizkeytechReduxActions} from "../../redux";
import dayjs from "dayjs";

export type DataGridProps = Omit<DG.DataGridProps, 'getExportInfo'> & {
    getExportInfo?: () => Promise<DG.DataGridExportInfo | DG.DataGridExportInfo['body'] | undefined>,
}

const DataGrid = forwardRef<DG.DataGridController, DataGridProps>(({
                                                                       LoadingComponent =
                                                                           <LottieAnimation width={'400px'} src={LoadingAnimation}/>,
                                                                       getExportInfo: getExportInfoProps,
                                                                       ...props
                                                                   }, ref) => {
    const [exporting, setExporting] = useState(false);
    const isMounted = useIsMounted();

    /**
     * With each change in the [exporting] condition of the state:
     * - closes or opens the exporting dialog.
     */
    useEffect(() => {
        BizkeytechReduxStore.dispatch(BizkeytechReduxActions.dialogs.exporting({open: exporting}))
    }, [exporting]);

    /**
     * Fetches the exported information of the data-grid.
     *
     * * sets the [exporting] state value to open or close the exporting dialog.
     */
    const getExportInfo = useCallback(async (): Promise<DG.DataGridExportInfo | undefined> => {
        if (!getExportInfoProps)
            return;
        setExporting(true);
        const data = await getExportInfoProps();
        if (!isMounted())
            return;
        setExporting(false);
        if (Array.isArray(data)) {
            return {
                title: document.title.concat(' - Exported Data').concat(` - ${dayjs().format()}`),
                body: data,
            };
        }
        return data;
    }, [getExportInfoProps, isMounted])

    return (
        <DG.DataGrid
            LoadingComponent={LoadingComponent}
            getExportInfo={!getExportInfoProps ? undefined : getExportInfo}
            {...props}
            classNames={{
                ...props?.classNames ?? {},
                container: classNames('app-data-grid-container', props.classNames?.container),
            }}
            ref={ref}
        />
    );
})

export * from '../../../../data-grid';
export default DataGrid;

import React, {useCallback, useContext, useLayoutEffect, useMemo, useRef, useState} from "react";
import {DataGridControllerContext, DataGridMiscContext, DataGridStateContext} from "../../../index";
import classnames from "classnames";
import DataGridHeaderCell from "./cell";
import {DataGridExoticColumnFields} from "../../../type-declerations";
import {ReactSortable} from "react-sortablejs";
import DataGridUtils from "../../../core/services/utils";

const DataGridHeader = ({layoutRectWidth, onTableWidthChanged}) => {
    const {columns} = useContext(DataGridStateContext);
    const {classNames} = useContext(DataGridMiscContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const [dragging, setDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [sortedColumns, setSortedColumns] = useState(
        columns.map(e => ({
            id: e.name,
            filtered: e.pinned || !e.reorderable,
        }))
    );
    /**@type {MutableRefObject<HTMLTableSectionElement>}*/
    const containerRef = useRef();
    const initialRender = useRef(true);

    const _visibleColumns = useMemo(() =>
        columns.filter(e =>
            e.visible &&
            e.name !== DataGridExoticColumnFields.detailedPanel
        ), [columns])

    const visibleColumns = useMemo(() => {
        return DataGridUtils.getVisibleColumnsCalculatedWidths(_visibleColumns, layoutRectWidth);
    }, [_visibleColumns, layoutRectWidth])

    const availableWidth = useMemo(() => {
        return visibleColumns?.reduce((p, c) => p + Math.max(c.width.size, c.width.minWidth), 0) ?? 0;
    }, [visibleColumns])

    /**
     * With each change in the [totalColumnWidths] memo value:
     * - sets the width of the table according to the total width of the columns.
     */
    useLayoutEffect(() => {
        if (!containerRef.current.parentElement)
            return;
        containerRef.current.parentElement.width = `${availableWidth}px`;
        onTableWidthChanged?.(availableWidth);
    }, [availableWidth])

    /**
     * With each change in the visibleColumns memo value:
     * - sets the column orders only if the order of the columns have changed.
     */
    useLayoutEffect(() => {
        setSortedColumns(prevState => {
            const newSList = visibleColumns.map(e => ({
                id: e.name,
                filtered: e.pinned || !e.reorderable,
            }));
            const prevList = prevState.map(e => ({
                id: e.id,
                filtered: e.filtered,
            }))
            if (DataGridUtils.deepEqual(prevList, newSList))
                return prevState;
            return newSList;
        })
    }, [visibleColumns])

    /**
     * Updates the columnsOffset of the width resizer.
     * @param {DataGridInternalColumn} column
     * @param {number} offset
     */
    const resizeColumnsByOffset = (column, offset) => {
        dataGridApi.resizeColumnsBy({
            [column.name]: offset,
        }, true);
    }

    /**
     * Sets the order of the columns.
     * @param {any[]} newList
     */
    const setColumnsOrders = (newList) => {
        if (initialRender.current) {
            return (initialRender.current = false) || void 0;
        }
        setSortedColumns((prevState) => {
            // local state
            const newSortedColumns = DataGridUtils.sortColumnsByOrder(
                newList.map((column) => ({
                    ...column,
                    name: column.id,
                    // order here is undefined so that the sortColumnsByOrder method imputes them
                    order: undefined,
                }))
            ).map(e => ({id: e.id, filtered: e.filtered}))

            const prevSortedColumns = prevState.map(e => ({
                id: e.id,
                filtered: e.filtered
            }))

            if (DataGridUtils.deepEqual(newSortedColumns, prevSortedColumns)) {
                return prevState;
            }
            return newSortedColumns;
        });
        dataGridApi.setAllColumnsOrder(newList.map(e => e.id), true);
    }

    const content = useMemo(() =>
        visibleColumns?.map((column) => (
            <DataGridHeaderCell
                key={column.name}
                column={column}
                onIsResizingChanged={setIsResizing}
                resizeColumn={resizeColumnsByOffset}
            />
        )), [setIsResizing, visibleColumns, containerRef])

    /**
     * This function is called when the user drags the header cell to the left or right.
     * @type {(function(number, number, Event, TouchEvent, HTMLElement): void)|*}
     */
    const scrollFn = useCallback((offsetX, offsetY, originalEvent,) => {
        const scrollThreshold = 100;
        const scrollSpeed = 15;

        const layout = containerRef.current.parentElement.parentElement;
        let {left, right} = layout.getBoundingClientRect();

        const leftPinnedColumns = containerRef.current.parentElement.querySelectorAll('.data-grid-header-cell.left-pinned');
        if (leftPinnedColumns.length) {
            const lastLeftPinnedColumn = leftPinnedColumns[leftPinnedColumns.length - 1];
            left = lastLeftPinnedColumn.getBoundingClientRect().right;
        }

        const rightPinnedColumns = containerRef.current.parentElement.querySelectorAll('.data-grid-header-cell.right-pinned');
        if (rightPinnedColumns.length) {
            const firstRightPinnedColumn = rightPinnedColumns[0];
            right = firstRightPinnedColumn.getBoundingClientRect().left;
        }

        if (originalEvent.clientX < left + scrollThreshold) {
            layout.scrollBy({
                left: -scrollSpeed,
                behavior: 'instant',
            })
        }
        if (originalEvent.clientX > right - scrollThreshold) {
            layout.scrollBy({
                left: scrollSpeed,
                behavior: 'instant',
            })
        }
    }, []);

    const sortableContainer = useMemo(() =>
        <ReactSortable
            tag={'tr'}
            list={sortedColumns}
            draggable={'.reorderable'}
            ignore={'.not-reorderable'}
            setList={setColumnsOrders}
            forceAutoScrollFallback
            scrollSensitivity={Number.MAX_SAFE_INTEGER}
            scrollFn={scrollFn}
            onStart={() => setDragging(true)}
            onEnd={() => setDragging(false)}
            dragClass={'data-grid-dragged-header-cell'}
        >
            {content}
        </ReactSortable>, [sortedColumns, content, scrollFn])

    return (
        <>
            <thead ref={containerRef} className={classnames(
                'data-grid-header',
                classNames.header,
                {'dragging-cell': !!dragging},
                {'resizing-cell': isResizing},
            )}>
            {sortableContainer}
            </thead>
        </>
    )
}

export default DataGridHeader;

import type BaseCachingServiceEntity from "./base";

/**
 * The Local Storage Entity of the CachingService.
 */
export class CachingServiceLocalStorageEntity<T extends string> implements BaseCachingServiceEntity<T> {

    /**@inheritDoc */
    keys: Record<T | string, string>;

    /**@inheritDoc */
    get(key: T | string): string | null {
        return localStorage.getItem(key);
    }

    /**@inheritDoc */
    getAll(): Record<T | string, string | null> {
        const res: Record<string, string | null> = {};
        for (const [resKey, key] of Object.entries<string>(this.keys)) {
            res[resKey] = localStorage.getItem(key);
        }
        return res;
    }

    /**@inheritDoc */
    set(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    /**@inheritDoc */
    remove(key: string): void {
        localStorage.removeItem(key);
    }

    /**@inheritDoc */
    clear(): void {
        for (const key of Object.values<string>(this.keys)) {
            localStorage.removeItem(key);
        }
    }

    /**
     * Constructs a new LocalStorageEntity for the caching service based on the provided keys.
     * @param keys
     */
    constructor(keys: Record<T | string, string>) {
        this.keys = keys;
    }
}

export default CachingServiceLocalStorageEntity;

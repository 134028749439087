export interface ImageNaturalSize {
    width: number,
    height: number,
}

export interface ModifyEventConfig {
    preventDefault?: boolean,
    stopPropagation?: boolean,
    stopImmediatePropagation?: boolean,
}

/**
 * Different possible entries found in the [window.navigator.platform] value.
 */
export enum OperatingSystems {
    mac = 'Mac',
    ios = 'IOS',
    windows = 'Windows',
    android = 'Android',
    linux = 'Linux',
}

/**
 * Different types of file types the file-fetcher of browser accepts
 */
export enum InputFileAcceptTypes {
    png = '.png',
    jpg = '.jpg',
    jpeg = '.jpeg',
    mp4 = '.mp4',
    mov = '.mov',
    webm = '.webm',
    flv = '.flv',
    wmv = '.wmv',
    pdf = '.pdf',
    json = '.json',
}

/**
 * Options for reading a file.
 */
export enum FileReadingOptions {
    binaryString = 'binaryString',
    dataUrl = 'dataUrl',
    text = 'text',
    arrayBuffer = 'arrayBuffer',
    json = 'json',
}

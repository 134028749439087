import type {FunctionComponent} from "react";
import type {TextFieldProps} from '@mui/material';
import {TextField} from "@mui/material";
import classnames from "classnames";


const DataGridInput: FunctionComponent<TextFieldProps> = ({className, ...props}) => {

    return (
        <TextField
            className={classnames(className, 'data-grid-input')}
            {...props}
        />
    )
}

export default DataGridInput;

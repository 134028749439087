//    ***** CSS IMPORTS *******
import "react-toastify/dist/ReactToastify.css";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import "./assets/css/index.scss";
import './index.scss';

// noinspection ES6UnusedImports
import {} from './@bizkeytech/base/type-declerations';
import ReactDOM from 'react-dom/client';
import Application from './ui';
import reportWebVitals from './report-web-vitals';
import {ApiExecutor, DataGridApiService, MapboxApiService, QueryBuilderApiService} from "./@bizkeytech";
import ApiService from "./core/services/api";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import UIUtils from "./ui/utils";
import {registerPlugin} from "react-filepond";
import {ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from "chart.js";
import dayjs from "dayjs/esm";
import weekOfYear from 'dayjs/esm/plugin/weekOfYear';
import isoWeek from 'dayjs/esm/plugin/isoWeek';
import advancedFormat from 'dayjs/esm/plugin/advancedFormat';
import relativeTime from 'dayjs/esm/plugin/relativeTime';

export const RootElement = document.getElementById('root') as HTMLElement;
export const ReactRoot = ReactDOM.createRoot(RootElement);

/**
 * The driver of the application.
 */
class Driver {

    /**
     * Runs the application.
     */
    public static run(): void {
        this.initializeScrollbar().then();
        this.initializeAPIServices();
        this.registerFilePondPlugins();
        this.registerChartJS();
        this.registerDayJSPlugins();
        this.render();
        this.reportWebVitals();
    }

    /**
     * Renders the application.
     * @private
     */
    private static render(): void {
        // STRICT MODE WILL INTENTIONALLY CALL USE_REDUCER AND USE_STATE's INITIAL METHODS MULTIPLE TIMES TO TEST THE PUTRIDITY OF SUCH
        //  FUNCTIONS. IT ALSO CHECKS FOR THE PROBLEMS IN CONCURRENT MODE SO THE PERFORMANCE IS INTENTIONALLY WORSE. PLEASE BE AWARE!!!
        // IF A DISPATCH OF A USE REDUCER THROWS, THE DISPATCHER AUTOMATICALLY RECALLS THE DISPATCH IN HOPE THAT THE ERROR IS RESOLVED
        ReactRoot.render(
            // <StrictMode>
            <Application/>
            // </StrictMode>
        );
    }

    /**
     * Initializes whether we need to force the webkit scrollbar or not.
     * @private
     */
    private static async initializeScrollbar() {
        await new Promise((resolve) => document.addEventListener('DOMContentLoaded', resolve));
        // Exclude Edge which also includes 'Chrome' in UA
        const isChrome: boolean = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);
        const chromeVersionArray: RegExpExecArray | null = /Chrome\/([0-9]+)/.exec(navigator.userAgent);
        const chromeVersion: number = chromeVersionArray ? parseInt(chromeVersionArray[1], 10) : 0;
        const isModernChrome: boolean = isChrome && chromeVersion >= 121;

        document.body.classList.toggle('force-webkit-scrollbar', isModernChrome);
    }

    /**
     * Initializes the api services and inject the effects that are used by the ApiService.
     */
    private static initializeAPIServices(): void {
        ApiService.executor = new ApiExecutor();
        DataGridApiService.executor = new ApiExecutor();
        QueryBuilderApiService.executor = new ApiExecutor();
        MapboxApiService.executor = new ApiExecutor();

        const showToastsEffect = {id: 'showToastApiCallEffect', effect: UIUtils.showToastApiCallEffect.bind(UIUtils)}

        ApiService.injectEffects([showToastsEffect]);
        DataGridApiService.injectEffects([showToastsEffect]);
        QueryBuilderApiService.injectEffects([showToastsEffect]);
        MapboxApiService.injectEffects([showToastsEffect]);
    }

    /**
     * Registers the file pond plugins.
     */
    private static registerFilePondPlugins(): void {
        registerPlugin(
            FilePondPluginImageExifOrientation,
            FilePondPluginImagePreview,
            FilePondPluginFileValidateType,
        )
    }

    /**
     * Registers the chart js components.
     * @private
     */
    private static registerChartJS(): void {
        Chart.register(
            CategoryScale,
            LinearScale,
            LineElement,
            BarElement,
            ArcElement,
            PointElement,
            Title,
            Tooltip,
            Legend,
        );
    }

    /**
     * Registers the dayjs plugins.
     * @private
     */
    private static registerDayJSPlugins(): void {
        dayjs.extend(advancedFormat as any);
        dayjs.extend(isoWeek as any);
        dayjs.extend(weekOfYear as any);
        dayjs.extend(relativeTime as any);
    }

    /**
     * Reports the web vitals.
     * @private
     */
    private static reportWebVitals(): void {
        reportWebVitals();
    }

}

Driver.run();


import MapboxEnvService from "../../../services/env";

/**
 * This interface houses all the available endpoints of this application specific for the 'mapbox' server.
 */
class MapBoxApiEndpoints {

    /**
     * The base url of the api endpoints of this application.
     * @private
     */
    public static baseUrl: string = MapboxEnvService.apiEndpointUrl + '/geocoding/v5';

    /**
     * The controllers of the mapbox api endpoints.
     * @private
     */
    public static controllers = {
        places: this.baseUrl.concat('/mapbox.places'),
    }

    /**
     * The endpoints specific for the places controller of the mapbox api.
     */
    public static places = {
        baseUrl: this.controllers.places,
        getMarkerAddress: (lng: number, lat: number, accessToken: string) => this.controllers.places.concat(`/${lng},${lat}.json?access_token=${accessToken}`),
        getAddressCoordinates: (address: string, accessToken: string, country?: string) => this.controllers.places.concat(`/${encodeURIComponent(address)}.json?access_token=${accessToken}${country?.length ? `&country=${country}` : ''}`),
    }
}

export default MapBoxApiEndpoints;

import DataGridEnvService from "../../../services/env";


/**
 * This interface houses all the available endpoints of this package.
 */
class DataGridApiEndpoints {

    /**
     * The base url of the api endpoints of this package.
     * @private
     */
    public static baseUrl: string = DataGridEnvService.apiEndpointUrl + '/api';

    /**
     * The controllers of the api endpoints.
     * @private
     */
    public static controllers = {
        dataGrid: this.baseUrl.concat("/UiComponent"),
    }

    /**
     * The endpoints specific for the data-grid controller of the server api.
     */
    public static dataGrid = {
        baseUrl: this.controllers.dataGrid,
        getData: this.controllers.dataGrid.concat('/GetApplicationComponents'),
        saveData: this.controllers.dataGrid.concat('/Save'),
    }
}


export default DataGridApiEndpoints;

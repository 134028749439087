import * as Yup from "yup";
import {Message} from "yup/lib/types";

declare module "yup" {
    interface StringSchema {
        phoneNo(message?: Message, validLength?: number): StringSchema;
    }
}

/**
 * Houses all the customized test functions added to different types of the Yup package.
 *
 * - NOTE: be sure to add each of the necessary extensions in the files that you intend to use them.
 */
class YupExtensions {

    /**
     * Adds the phone number method to Yup String types.
     * @return {Yup.Schema}
     */
    public static phoneNo(): void {
        Yup.addMethod(Yup.string, 'phoneNo', function (...args) {
            const [message, validLength = 10] = args;
            return this.test('test-phone-no', message, function (val) {
                let value = val?.trim() ?? "";
                if (!val) {
                    return true;
                }
                value = value
                    .replace(/[()_-]/g, '')
                    .replace(/\+1/g, '')
                    .replaceAll(' ', '');
                if (!val) {
                    return true;
                }
                return value?.length === validLength;
            });
        });
    }

    /**
     * Injects all the extensions of this interface to yup.
     */
    public static injectExtensions(): void {
        this.phoneNo();
    }
}

export default YupExtensions;

import debounce from "lodash/debounce";
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useIsMounted from "../use-is-mounted";
import {useDeepCompareEffect} from "react-use";
import queryString from "query-string";
import {Utils} from "../../../core";

export interface SearchDataSettingsPaginationInfo {
    pageSize: number;
    currentPage: number;
    length: number;
    sizes: number[];
}

export interface SearchDataSettingsOrderBy {
    field: string;
    descending: boolean;
}

export interface SearchDataSettingsFilters {
    [key: string]: any;
}


export type SearchDataSettingsTab = string;

export interface SearchDataSettings<Filters = SearchDataSettingsFilters> {
    paginationInfo?: SearchDataSettingsPaginationInfo;
    orderBy?: SearchDataSettingsOrderBy;
    filters?: Filters;
    tab?: SearchDataSettingsTab;
}

export interface UseSearchResult<Filters = SearchDataSettingsFilters> {
    paginationInfo?: SearchDataSettingsPaginationInfo;
    setPaginationInfo: Dispatch<SetStateAction<SearchDataSettingsPaginationInfo | undefined>>;
    orderBy?: SearchDataSettingsOrderBy;
    setOrderBy?: Dispatch<SetStateAction<SearchDataSettingsOrderBy | undefined>>;
    filters?: Filters;
    setFilters?: Dispatch<SetStateAction<Filters>>;
    tab?: SearchDataSettingsTab;
    setTab?: Dispatch<SetStateAction<SearchDataSettingsTab | undefined>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    error: any;
}


export type UseSearchFunctionArg<Filters = SearchDataSettingsFilters> =
    Omit<UseSearchResult<Filters>, 'paginationInfo' | 'loading' | 'error'>
    & {
    paginationInfo?: {
        currentPage: number,
        pageSize: number
    };
    abortController?: AbortController;
};


export type UseSearchFunction<Filters = SearchDataSettingsFilters, ReturnType = any> = (result: UseSearchFunctionArg<Filters>) => Promise<ReturnType>

/**
 * Throttles an async function in a way that can be awaited.
 * By default, throttle doesn't return a promise for async functions unless it's invoking them immediately. See CUR-4769 for details.
 * @param func async function to throttle calls for.
 * @param wait same function as "lodash.debounce"'s wait parameter.
 *             Call this function at most this often.
 * @returns a promise which will be resolved/ rejected only if the function is executed, with the result of the underlying call.
 */
function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
    const debounced = debounce((resolve, reject, args: Parameters<F>) => {
        func(...args).then(resolve).catch(reject);
    }, wait);
    const result = (...args: Parameters<F>): ReturnType<F> =>
        new Promise((resolve, reject) => {
            debounced(resolve, reject, args);
        }) as ReturnType<F>;
    result.cancel = debounced.cancel;
    result.flush = debounced.flush;
    return result;
}

/**
 * Custom hook to manage search-related states and synchronize them with the URL.
 *
 * @param searchFunction - The function to execute for performing the search.
 * @param initialSettings - Initial settings for pagination, orderBy, filters, etc.
 * @param useUrlParams  - Whether to synchronize the state with the URL.
 */
const useSearch = <Filters, >(
    searchFunction: UseSearchFunction<Filters>,
    initialSettings: SearchDataSettings<Filters> = {},
    useUrlParams: boolean = true,
) => {
    const [paginationInfo, setPaginationInfo] = useState(initialSettings.paginationInfo);
    const [orderBy, setOrderBy] = useState(initialSettings.orderBy);
    const [filters, _setFilters] = useState<Filters>(initialSettings.filters ?? {} as Filters);
    const [tab, setTab] = useState(initialSettings.tab);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isInitializedFromUrl, setIsInitializedFromUrl] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const isMounted = useIsMounted();

    const initializedFromUrlRef = useRef(false);
    const userTriggeredChangeRef = useRef(0);

    const setFilters = useCallback<Dispatch<SetStateAction<Filters>>>((state) => {
        _setFilters((prevState) => {
            let newState: Filters;
            if (typeof state === 'function') {
                newState = (state as Function)(prevState)
            } else {
                newState = state
            }
            if (Utils.deepEqual(prevState, newState)) {
                return prevState;
            }
            return newState;
        });
    }, [])

    /**
     * Checks if the state is non-null, non-undefined, and not an empty array.
     */
    const isValidState = useCallback((state: any) => {
        return state !== null && state !== undefined && !(Array.isArray(state) && state.length === 0);
    }, []);

    /**
     * Serializes state for URL synchronization
     */
    const serializeState = useCallback((state: any) => {
        if (typeof state === 'string')
            return state;
        return JSON.stringify(state);
    }, []);

    /**
     * Deserializes state from URL parameters
     */
    const deserializeState = useCallback((param: string[] | string | null, defaultValue: any): any => {
        if (Array.isArray(param)) {
            return param.map(p => deserializeState(p, defaultValue));
        }
        try {
            const parsed = JSON.parse(param as any);
            return isValidState(parsed) ? parsed : defaultValue;
        } catch (e) {
            return defaultValue;
        }
    }, [isValidState]);


    /**
     * Synchronize state with URL.
     */
    const syncWithUrl = useCallback(() => {
        if (!useUrlParams || !isInitializedFromUrl) return;

        const newQuery: any = {};
        if (isValidState(tab)) newQuery.tab = serializeState(tab);
        if (isValidState(paginationInfo)) newQuery.paginationInfo = serializeState(paginationInfo);
        if (isValidState(orderBy)) newQuery.orderBy = serializeState(orderBy);
        if (isValidState(filters)) newQuery.filters = serializeState(filters);

        const queryStringified = queryString.stringify(newQuery, {arrayFormat: 'bracket'});
        if (location.search !== `?${queryStringified}`) {
            navigate({pathname: location.pathname, search: queryStringified, hash: location.hash}, {replace: true})
        }
    }, [tab, paginationInfo, orderBy, filters, isInitializedFromUrl, navigate, location, useUrlParams, isValidState, serializeState]);

    /**
     * This is the debounced version of the syncWithUrl function.
     */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    const syncWithUrlDebounced = useCallback(debounce(syncWithUrl, 300), [syncWithUrl]);

    /**
     * Synchronize state with URL when the state changes.
     */
    useEffect(() => {
        if (useUrlParams) {
            syncWithUrlDebounced();
            return () => syncWithUrlDebounced.cancel();
        }
    }, [syncWithUrlDebounced, useUrlParams]);

    /**
     * Initializes the state from URL parameters or from provided initial settings
     */
    useDeepCompareEffect(() => {
        if (useUrlParams && !initializedFromUrlRef.current) {
            // Deserialize URL parameters and set state
            const params = queryString.parse(window.location.search, {arrayFormat: 'bracket',});
            setPaginationInfo(deserializeState(params.paginationInfo as any, initialSettings.paginationInfo));
            setOrderBy(deserializeState(params.orderBy as any, initialSettings.orderBy));
            setFilters(deserializeState(params.filters as any, initialSettings.filters ?? {}));
            setTab(deserializeState(params.tab as any, initialSettings.tab));

            // Mark initialization complete and reset user-triggered change flag
            initializedFromUrlRef.current = true;
            userTriggeredChangeRef.current = 0;
        } else if (!useUrlParams) {
            initializedFromUrlRef.current = true;
            userTriggeredChangeRef.current = 2;
        }
        setIsInitializedFromUrl(true);
    }, [deserializeState, initialSettings.filters, initialSettings.orderBy, initialSettings.paginationInfo, initialSettings.tab, useUrlParams]);

    /**
     * Tracks user-triggered changes
     */
    useDeepCompareEffect(() => {
        if (isInitializedFromUrl && initializedFromUrlRef.current) {
            userTriggeredChangeRef.current = Math.min(userTriggeredChangeRef.current + 1, 2);
        }
    }, [orderBy, filters, tab, isInitializedFromUrl]);

    /**
     * Resets pagination when the filters or other search parameters change
     */
    useDeepCompareEffect(() => {
        if (isInitializedFromUrl && initializedFromUrlRef.current && userTriggeredChangeRef.current === 2) {
            setPaginationInfo(prevState => ({...prevState!, currentPage: initialSettings.paginationInfo?.currentPage ?? 1}));
        }
    }, [orderBy, filters, tab, initialSettings.paginationInfo?.currentPage, isInitializedFromUrl]);

    const hasPaginationInfo = useMemo<boolean>(() => !!paginationInfo, [paginationInfo]);
    const result = useMemo<Omit<UseSearchFunctionArg<Filters>, 'abortController'>>(() => ({
        paginationInfo: hasPaginationInfo ? {
            currentPage: paginationInfo?.currentPage as number,
            pageSize: paginationInfo?.pageSize as number
        } : undefined,
        setPaginationInfo,
        orderBy, setOrderBy,
        filters, setFilters,
        tab, setTab,
        setLoading,
    }), [hasPaginationInfo, paginationInfo?.currentPage, paginationInfo?.pageSize, orderBy, filters, setFilters, tab]);

    /**
     * The debounced version of the search function.
     */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchFunction = useCallback(asyncDebounce(searchFunction, 1000), [searchFunction]);

    /**
     * Performs the search and updates the state accordingly.
     */
    const performSearch = useCallback(async (abortController: AbortController) => {
        setLoading(true);
        try {
            await debouncedSearchFunction({...result, abortController})
        } catch (err) {
            console.error('use search error:\n', err);
            setError(err as any);
        }
        if (!isMounted() || abortController.signal.aborted)
            return;
        setLoading(false);
    }, [debouncedSearchFunction, isMounted, result]);

    /**
     * Triggers the search when relevant states change.
     */
    useEffect(() => {
        if (isInitializedFromUrl) {
            const abortController = new AbortController();
            performSearch(abortController).then();
            return () => {
                abortController.abort();
                debouncedSearchFunction.cancel()
            };
        }
    }, [debouncedSearchFunction, isInitializedFromUrl, performSearch]);

    /**
     * Cancels the debounced search function when the component is unmounted.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => debouncedSearchFunction.cancel(), []);


    return useMemo(() => ({
        ...result,
        loading,
        error,
        paginationInfo: hasPaginationInfo
            ? {...result.paginationInfo, length: paginationInfo?.length, sizes: paginationInfo?.sizes} as SearchDataSettingsPaginationInfo
            : undefined,
    }), [result, loading, error, hasPaginationInfo, paginationInfo?.length, paginationInfo?.sizes]);
};

export default useSearch;

import {
    autocompleteClasses,
    Color,
    CssVarsTheme,
    experimental_extendTheme,
    menuItemClasses,
    switchClasses,
    SwitchProps,
    tabClasses
} from "@mui/material";
import type {ComponentsPropsList, CssVarsThemeOptions} from "@mui/material/styles";
import UIUtils from "../utils";
import {deepmerge} from "@mui/utils";
import {EnvService} from "../../core";
import type * as XDatePickersTA from '@mui/x-date-pickers/themeAugmentation';
import type * as LabTA from '@mui/lab/themeAugmentation';
import type * as TD from './type-declarations';
import {TinyColor} from '@ctrl/tinycolor';
import {ReactComponent as TimelineDot} from "../../../base/assets/svgs/timeline/dot.svg";
import {timelineContentClasses, timelineDotClasses, timelineItemClasses, timelineOppositeContentClasses} from "@mui/lab";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _ = typeof XDatePickersTA;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type __ = typeof LabTA;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ___ = typeof TD;

/**
 * The utilities for overriding the default MUI switch component.
 */
export class SwitchOverrides {

    /**
     * The size map for the switch component.
     */
    static sizeMap: Record<Required<SwitchProps>['size'], number> = {
        small: 5,
        medium: 6,
    }

    /**
     * The size modifiers for the switch component.
     */
    static sizeModifiers = {
        thumbSide: 3.5,
        switchHeight: 5,
        switchWidth: 9,
        thumbPadding: 0.5,
    }

    /**
     * Gets the size of the switch component.
     * @param modifier  The modifier to apply.
     * @param size      The size of the switch component.
     */
    static getSize(modifier: keyof typeof this.sizeModifiers, size: ComponentsPropsList["MuiSwitch"]['size']) {
        return this.sizeMap[size!] * this.sizeModifiers[modifier];
    }

    /**
     * Gets the transform of the switch component.
     * @param ownerState    The owner state of the switch component.
     */
    static getThumbTransformY(ownerState: ComponentsPropsList["MuiSwitch"] & Record<string, unknown>) {
        return (
            this.getSize('switchHeight', ownerState.size)
            - this.getSize('thumbSide', ownerState.size)
            - (this.getSize('thumbPadding', ownerState.size) * 2)
        ) / 2;
    }

    /**
     * Gets the transform of the switch component.
     * @param ownerState    The owner state of the switch component.
     */
    static getThumbTransformX(ownerState: ComponentsPropsList["MuiSwitch"] & Record<string, unknown>) {
        if (!ownerState.checked)
            return (this.getSize('thumbPadding', ownerState.size) * 0.5);
        return this.getSize('switchWidth', ownerState.size)
            - this.getSize('thumbSide', ownerState.size)
            - (this.getSize('thumbPadding', ownerState.size) * 2.5)
    }

    /**
     * Gets the transform of the switch component.
     * @param ownerState    The owner state of the switch component.
     */
    static getTransform(ownerState: ComponentsPropsList["MuiSwitch"] & Record<string, unknown>) {
        return `translate(${this.getThumbTransformX(ownerState)}px, ${this.getThumbTransformY(ownerState)}px)`;
    }
}

export class BizkeyTechTheme {

    /**
     * The classes for the Bizkey Tech theme.
     */
    public static readonly classes = {
        inputMenuPaper: 'bizkey-tech-input-menu-paper',
    };

    /**
     * The font family of the application.
     */
    public static readonly fontFamily = [
        EnvService.defaultFontName,
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(',');

    /**
     * The primary color of the application.
     */
    public static readonly primaryColor = this.generateColorPalette(EnvService.primaryColor);

    /**
     * The primary color of the application.
     */
    public static readonly secondaryColor = this.generateColorPalette(EnvService.secondaryColor);

    /**
     * Combines the provided theme with the default theme of the application.
     * @param theme             The theme to inject.
     * @returns {CssVarsTheme}  The injected theme.
     */
    public static combineTheme(theme: CssVarsThemeOptions): CssVarsTheme {
        return experimental_extendTheme(deepmerge(this.getDefaultTheme(), theme, {clone: true}));
    }

    /**
     * Generates the color palette of the application.
     * @param base400ShadeOfLight   The base 400 shade of the light theme.
     * @private
     */
    private static generateColorPalette(base400ShadeOfLight: string): {
        lightPalette: Color,
        darkPalette: Color
    } {
        const lightPalette = {
            50: new TinyColor(base400ShadeOfLight).lighten(60).toRgbString(),
            100: new TinyColor(base400ShadeOfLight).lighten(48).toRgbString(),
            200: new TinyColor(base400ShadeOfLight).lighten(36).toRgbString(),
            300: new TinyColor(base400ShadeOfLight).lighten(24).toRgbString(),
            400: base400ShadeOfLight,
            500: new TinyColor(base400ShadeOfLight).darken(8).toRgbString(),
            600: new TinyColor(base400ShadeOfLight).darken(16).toRgbString(),
            700: new TinyColor(base400ShadeOfLight).darken(24).toRgbString(),
            800: new TinyColor(base400ShadeOfLight).darken(32).toRgbString(),
            900: new TinyColor(base400ShadeOfLight).darken(40).toRgbString(),
            A100: new TinyColor(base400ShadeOfLight).lighten(40).saturate(30).toRgbString(),
            A200: new TinyColor(base400ShadeOfLight).lighten(20).saturate(30).toRgbString(),
            A400: new TinyColor(base400ShadeOfLight).saturate(40).toRgbString(),
            A700: new TinyColor(base400ShadeOfLight).darken(10).saturate(40).toRgbString(),
        };

        // Dark Theme Palette (using the 500 shade from light theme for base)
        const darkBase = lightPalette[400];
        const darkPalette = {
            50: new TinyColor(darkBase).lighten(55).desaturate(5).toRgbString(),
            100: new TinyColor(darkBase).lighten(45).desaturate(5).toRgbString(),
            200: new TinyColor(darkBase).lighten(35).desaturate(5).toRgbString(),
            300: new TinyColor(darkBase).lighten(25).desaturate(5).toRgbString(),
            400: new TinyColor(darkBase).lighten(15).desaturate(5).toRgbString(),
            500: darkBase,
            600: new TinyColor(darkBase).darken(5).desaturate(5).toRgbString(),
            700: new TinyColor(darkBase).darken(10).desaturate(5).toRgbString(),
            800: new TinyColor(darkBase).darken(20).desaturate(5).toRgbString(),
            900: new TinyColor(darkBase).darken(25).desaturate(10).toRgbString(),
            A100: new TinyColor(darkBase).lighten(25).saturate(20).toRgbString(),
            A200: new TinyColor(darkBase).lighten(10).saturate(20).toRgbString(),
            A400: new TinyColor(darkBase).saturate(30).toRgbString(),
            A700: new TinyColor(darkBase).darken(3).saturate(30).toRgbString(),
        };

        return {lightPalette, darkPalette};
    }

    /**
     * The default theme of the application.
     * @private
     */
    private static getDefaultTheme(): CssVarsThemeOptions {
        return {
            cssVarPrefix: UIUtils.ThemePrefix,
            colorSchemes: {
                light: {
                    palette: {
                        primary: this.primaryColor.lightPalette,
                        secondary: this.secondaryColor.lightPalette,
                        background: {
                            paper: '#FFFFFF',
                            default: '#F6F7F8',
                            tertiary: "#EBEBEB"
                        },
                        warning: {
                            light: "#FDEDD3",
                            main: "#F8B858"
                        },
                        error: {
                            light: "#FDEBEB",
                            main: "#EB5757"
                        },
                        success: {
                            light: "#E6F3ED",
                            main: "#219653",
                            dark: "#2C9765"
                        },
                        text: {
                            primary: "#1E1E1E",
                            secondary: "#4F4F4F",
                            disabled: "#BDBDBD"
                        },
                        'app-specific': {
                            scrollbar: {
                                'track-color': 'transparent',
                                'thumb-color': '#a4a3a2',
                            },
                            'ball-fountain': {
                                'background-color': '#FFFFFF00',
                                'line-color': '#000000',
                                'ball-color': this.primaryColor.lightPalette[400],
                            },
                            'toast': {
                                "text-color": UIUtils.themeVar('palette-text-primary'),
                                "color": UIUtils.themeVar('palette-background-paper'),
                            },
                            "loading-shimmer": '#f6f7f8',
                            'loading-shimmer-gradient': 'linear-gradient(to right, #f6f7f8 0%, #f2f4f7 10%, #f0f0f2 20%, #f2f4f7 30%, #f6f7f8 40%, #f6f7f8 100%)',
                            "error-view": {
                                "flashlight-opacity": 0.19,
                            },
                            'data-grid': {
                                'loading-overlay-background': "#0000001a",
                                'cell-border-color': '#F8F8F8',
                                'selected-cell-background-color': UIUtils.themeVar('palette-primary-light'),
                                'hovered-cell-background-color': `#EDEDED`,
                                'grouped-row-cell-background-color': UIUtils.themeVar('palette-primary-light'),
                                'last-left-pinned-box-shadow-color': '#0000000d',
                                'first-right-pinned-box-shadow-color': '#0000000d',
                                'detailed-panel-box-shadow': '#ffffff00',
                                'switch-wrapper-box-shadow': '#00000038',
                                'popover-shadow-color': "#0000001c",
                            },
                            "video-player": {
                                "button-color": UIUtils.themeVar('palette-common-white'),
                                "button-background-color": `rgba(${UIUtils.themeVar('palette-primary-mainChannel')} / 1)`,
                                "button-hover-color": UIUtils.themeVar('palette-primary-main'),
                                "button-hover-background-color": UIUtils.themeVar('palette-common-white'),
                                "controls-container-background-color": `rgba(${UIUtils.themeVar('palette-primary-mainChannel')} / 0.1)`,
                            },
                        },
                    },
                },
                dark: {
                    palette: {
                        primary: {
                            main: this.primaryColor.darkPalette["500"],
                            light: "#121212",
                        },
                        secondary: {
                            main: this.secondaryColor.darkPalette['500'],
                            light: "#1b1b1b",
                        },
                        background: {
                            paper: "#252525",
                            default: "#121212",
                            tertiary: "#1e1e1e"
                        },
                        warning: {
                            light: "#484528",
                            main: "#FFEB3B"
                        },
                        error: {
                            light: "#4D2829",
                            main: "#ED323B"
                        },
                        success: {
                            light: "#233530",
                            main: "#1DE9B6",
                            dark: "#2C9765"
                        },
                        text: {
                            primary: "#DEDEDE",
                            secondary: "#A0A0A0",
                            disabled: "#414141"
                        },
                        'app-specific': {
                            scrollbar: {
                                'track-color': 'transparent',
                                'thumb-color': '#656565FF',
                            },
                            'ball-fountain': {
                                'background-color': '#ffffff00',
                                'line-color': '#FFFFFF',
                                'ball-color': this.primaryColor.darkPalette[400],
                            },
                            'toast': {
                                "text-color": UIUtils.themeVar('palette-text-primary'),
                                "color": UIUtils.themeVar('palette-background-paper'),
                            },
                            "loading-shimmer": '#3a3a3a',
                            "loading-shimmer-gradient": 'linear-gradient(to right, #3a3a3a 0%, #3f3f3f 10%, #4a4a4a 20%, #3f3f3f 30%, #3a3a3a 50%, #3a3a3a 100%)',
                            "error-view": {
                                "flashlight-opacity": 0.225,
                            },
                            'data-grid': {
                                'loading-overlay-background': "#ffffff1a",
                                'cell-border-color': '#2c2c2c',
                                'selected-cell-background-color': "#454545",
                                'hovered-cell-background-color': '#2c2c2c',
                                'grouped-row-cell-background-color': '#454545',
                                'last-left-pinned-box-shadow-color': '#00000052',
                                'first-right-pinned-box-shadow-color': '#00000052',
                                'detailed-panel-box-shadow': '#ffffff4d',
                                'switch-wrapper-box-shadow': '#ffffff40',
                                'popover-shadow-color': "#00000052",
                            },
                            "video-player": {
                                "button-color": UIUtils.themeVar('palette-background-paper'),
                                "button-background-color": `rgba(${UIUtils.themeVar('palette-primary-mainChannel')} / 1)`,
                                "button-hover-color": UIUtils.themeVar('palette-primary-main'),
                                "button-hover-background-color": UIUtils.themeVar('palette-common-white'),
                                "controls-container-background-color": `rgba(${UIUtils.themeVar('palette-primary-mainChannel')} / 0.1)`,
                            },
                        },
                    },
                },
            },
            'app-specific': {
                toast: {
                    "min-height": 32,
                    "width": 300,
                    'max-width': 400,
                    'font-family': this.fontFamily,
                },
            },
            typography: {
                fontFamily: this.fontFamily,
            },
            shape: {
                borderRadius: 8,
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                        size: 'small',
                        type: 'button',
                    },
                    styleOverrides: {
                        root: {
                            textTransform: 'none',
                            fontWeight: 600,
                        }
                    },
                },

                MuiFormControl: {
                    defaultProps: {
                        size: 'small',
                    },
                },
                MuiInputBase: {
                    defaultProps: {
                        size: 'small',
                    },
                },
                MuiFilledInput: {
                    defaultProps: {
                        disableUnderline: true,
                    },
                    styleOverrides: ({
                        underline: () => ({
                            '&:after, &:before': {
                                display: 'none',
                            }
                        }),
                        root: ({theme}) => ({
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: theme.vars.shape.borderRadius,
                        })
                    }),
                },
                MuiMenu: {
                    defaultProps: {
                        classes: {
                            paper: this.classes.inputMenuPaper,
                        },
                    }
                },
                MuiSelect: {
                    defaultProps: {
                        size: 'small',
                        MenuProps: {
                            classes: {
                                paper: this.classes.inputMenuPaper
                            },
                        }
                    },
                },
                MuiAutocomplete: {
                    defaultProps: {
                        classes: {
                            paper: this.classes.inputMenuPaper,
                        },
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: ({theme, ownerState}) => ({
                            ...(!ownerState.className?.includes(this.classes.inputMenuPaper) && {
                                boxShadow: 'none',
                            }),
                            backgroundImage: 'none',
                            ...(ownerState.className?.includes(this.classes.inputMenuPaper) && {
                                [`& .${menuItemClasses.root}, & .${autocompleteClasses.option}`]: {
                                    marginBlock: theme.spacing(0.5),
                                    marginInline: theme.spacing(0.5),
                                    borderRadius: theme.shape.borderRadius,
                                },
                            })
                        })
                    }
                },
                MuiSwitch: {
                    defaultProps: {
                        size: 'small',
                    },
                    styleOverrides: {
                        root: ({ownerState, theme}) => ({
                            padding: 0,
                            margin: 0,
                            marginInline: theme.spacing(0.5),
                            width: SwitchOverrides.getSize('switchWidth', ownerState.size),
                            height: SwitchOverrides.getSize('switchHeight', ownerState.size),
                            [`& .${switchClasses.switchBase}`]: {
                                padding: SwitchOverrides.getSize('thumbPadding', ownerState.size),
                                transform: SwitchOverrides.getTransform(ownerState),
                                [`&.${switchClasses.checked}`]: {
                                    transform: SwitchOverrides.getTransform({...ownerState, checked: true}),
                                },
                                [`&.${switchClasses.disabled}`]: {
                                    transform: SwitchOverrides.getTransform({...ownerState, disabled: true}),
                                    [`&.${switchClasses.checked}`]: {
                                        transform: SwitchOverrides.getTransform({...ownerState, checked: true, disabled: true}),
                                    }
                                },
                            },
                        }),
                        switchBase: ({ownerState, theme}) => ({
                            color: theme.vars.palette.background.paper,
                            "&:hover": {
                                backgroundColor: 'unset',
                            },
                            ...(ownerState.color === 'primary' && {
                                [`&.${switchClasses.checked}:not(.${switchClasses.disabled}) + .${switchClasses.track}`]: {
                                    opacity: 1,
                                    backgroundColor: 'transparent',
                                    color: theme.vars.palette.primary.main,
                                },
                            }),
                            ...(ownerState.color === 'secondary' && {
                                [`&.${switchClasses.checked}:not(.${switchClasses.disabled}) + .${switchClasses.track}`]: {
                                    opacity: 1,
                                    backgroundColor: 'transparent',
                                    color: theme.vars.palette.secondary.main,
                                },
                            }),
                            [`&.${switchClasses.disabled}`]: {
                                color: theme.vars.palette.action.disabled,
                                [`& + .${switchClasses.track}`]: {
                                    backgroundColor: theme.vars.palette.action.disabledBackground,
                                },
                            },
                            [`& .${switchClasses.thumb}`]: {
                                width: SwitchOverrides.getSize('thumbSide', ownerState.size),
                                height: SwitchOverrides.getSize('thumbSide', ownerState.size),
                            },
                            [`&.${switchClasses.checked} .${switchClasses.thumb}`]: {
                                boxShadow: `inset 0 0 5px ${theme.vars.palette.background.paper} !important`,
                            },
                        }),
                        track: ({ownerState, theme}) => ({
                            border: `1px solid transparent`,
                            backgroundColor: theme.vars.palette.background.paper,
                            opacity: 1,
                            borderRadius: SwitchOverrides.getSize('thumbSide', ownerState.size),
                            transition: theme.transitions.create(['background-color', 'border']),
                            ...(ownerState.color === 'primary' && {
                                borderColor: theme.vars.palette.primary.main,
                            }),
                            ...(ownerState.color === 'secondary' && {
                                borderColor: theme.vars.palette.secondary.main,
                            }),
                        }),
                        thumb: ({
                            boxShadow: `inset 0 0 5px ${UIUtils.themeVar('palette-app-specific-data-grid-switch-wrapper-box-shadow')}`,
                        }),
                    },
                },
                MuiTabs: {
                    defaultProps: {
                        value: false,
                        scrollButtons: 'auto',
                        allowScrollButtonsMobile: true,
                        variant: 'scrollable',
                        indicatorColor: 'primary',
                        textColor: 'primary',
                    },
                    styleOverrides: {
                        root: ({theme}) => ({
                            borderBottom: `1px solid ${theme.palette.divider}`,
                        }),
                        indicator: ({theme}) => ({
                            backgroundColor: theme.palette.primary.main,
                        }),
                    },
                },
                MuiTab: {
                    defaultProps: {
                        disableRipple: true,
                        disableFocusRipple: true,
                        disableTouchRipple: true,
                    },
                    styleOverrides: {
                        root: ({theme}) => ({
                            textTransform: 'none',
                            transition: theme.transitions.create(['color', 'opacity', 'background-color']),
                            [`&.${tabClasses.selected}`]: {
                                fontWeight: 700,
                            },
                        }),
                    },
                },
                MuiIconButton: {
                    defaultProps: {
                        size: 'small',
                    },
                    styleOverrides: {
                        root: ({theme, ownerState}) => ({
                            '.fill': {
                                fill: 'currentColor',
                            },
                            '.stroke': {
                                stroke: 'currentColor',
                            },
                            '&:hover': {
                                '.fill': {
                                    fill: 'currentColor',
                                },
                                '.stroke': {
                                    stroke: 'currentColor',
                                },
                            },
                            ...(ownerState.color?.startsWith('paper.') && {
                                backgroundColor: theme.palette.background.paper,
                                color: theme.palette[ownerState.color?.replace('paper.', '') as 'primary'].main,
                                '&:hover': {
                                    color: theme.palette[ownerState.color?.replace('paper.', '') as 'primary'].main,
                                    backgroundColor: `rgba(${UIUtils.themeVar(`palette-${ownerState.color?.replace('paper.', '')}-mainChannel`)} / ${UIUtils.themeVar('palette-action-hoverOpacity')})`,
                                    '.fill': {
                                        fill: 'currentColor',
                                    },
                                    '.stroke': {
                                        stroke: 'currentColor',
                                    },
                                },
                            }),
                        }),
                    },
                    variants: []
                },
                MuiDialog: {
                    defaultProps: {
                        scroll: 'paper',
                    },
                    styleOverrides: {
                        paper: {
                            backgroundImage: 'none',
                        },
                    },
                },
                MuiDatePicker: {
                    defaultProps: {
                        timezone: 'system',
                    },
                },
                MuiTimePicker: {
                    defaultProps: {
                        timezone: 'system',
                    },
                },
                MuiDateTimePicker: {
                    defaultProps: {
                        timezone: 'system',
                    },
                },
                MuiTimelineConnector: {
                    styleOverrides: {
                        root: {
                            width: 2,
                            background: 'none',
                            boxShadow: 'none',
                            borderLeft: `1px dashed rgb(189, 189, 189)`,
                        },
                    },
                },
                MuiTimelineDot: {
                    defaultProps: {
                        variant: "app-specific",
                        children: <TimelineDot/>,
                    },
                    styleOverrides: {
                        root: ({theme}) => ({
                            padding: 0,
                            marginBlock: theme.spacing(1),
                        }),
                    },
                    variants: [
                        {
                            props: {variant: "app-specific"},
                            style: ({theme}: { theme: any }) => ({
                                border: 'none',
                                boxShadow: 'none',
                                'svg': {
                                    transition: theme.transitions.create(['transform', 'color']),
                                    ...(theme.palette.mode === 'light' && {
                                        color: theme.palette.primary.light,
                                    }),
                                    ...(theme.palette.mode === 'dark' && {
                                        color: theme.palette.text.secondary,
                                    }),
                                    width: 20,
                                    height: 20,
                                }
                            }),
                        },
                        {
                            props: {variant: "app-specific-end"},
                            style: ({theme}: { theme: any }) => ({
                                border: 'none',
                                boxShadow: 'none',
                                background: theme.palette.primary.main,
                                borderRadius: '10%',
                                marginBlock: '11.5px',
                                width: 12,
                                height: 12,
                                marginInline: '4px',
                                '*': {
                                    display: 'none'
                                },
                            }),
                        }
                    ],
                },
                MuiTimeline: {
                    styleOverrides: {
                        root: ({theme, ownerState}) => ({
                            [`.${timelineItemClasses.root}.selected`]: {
                                color: theme.palette[ownerState.color as 'primary']?.main ?? "currentColor",
                                [`.${timelineDotClasses.root} svg`]: {
                                    transform: 'rotate(-40deg) scale(1.2)',
                                    color: theme.palette[ownerState.color as 'primary']?.main ?? "currentColor",
                                }
                            },
                            [`.${timelineItemClasses.root}:not(.disabled):hover`]: {
                                [`.${timelineDotClasses.root} svg`]: {
                                    transform: 'rotate(-40deg) scale(1.2)',
                                    color: theme.palette.info.main,
                                }
                            },
                            [`.${timelineItemClasses.root}.clickable`]: {
                                [`.${timelineContentClasses.root}, .${timelineOppositeContentClasses.root}, .${timelineDotClasses.root}`]: {
                                    cursor: 'pointer',
                                },
                            },
                        }),
                    },
                },
                MuiTimelineItem: {
                    styleOverrides: {
                        root: {
                            '&.selected': {},
                            '&.hovered': {},
                        },
                    },
                },
                MuiTimelineContent: {
                    styleOverrides: {
                        root: ({theme}) => ({
                            transition: theme.transitions.create(['color', 'font-weight']),
                        }),
                    },
                },
                MuiTimelineOppositeContent: {
                    styleOverrides: {
                        root: ({theme}) => ({
                            transition: theme.transitions.create(['color', 'font-weight']),
                        }),
                    },
                },
                MuiSkeleton: {
                    defaultProps: {
                        variant: 'rounded',
                    },
                },
            },
        };
    }
}


export type * from './type-declarations';
export default BizkeyTechTheme;

import Texts from "../texts";

class ApiResponseCodes {

    // ########################### HTTP SPECIFIC ###################################

    /**
     * When the given request is incomplete or wrong in the context of the server call
     */
    static readonly badRequest: number = 400;
    /**
     * When the user should log in to access the data from the server.
     */
    static readonly unauthorized: number = 401;
    /**
     * When the user does not have access to the requested data.
     */
    static readonly forbiddenAccess: number = 403;
    /**
     * When server was not found to communicate with
     */
    static readonly notFound: number = 404;
    /**
     * When server instance for the api call crashed or was terminated before any response could be given
     */
    static readonly serverError: number = 500;

    // ########################### CUSTOM ###################################

    /**
     * When the server call successfully was made but response was never returned.
     */
    static readonly aborted: number = 597;
    /**
     * When the server call failed due to a problem in request preparation.
     */
    static readonly requestFailed: number = 599;
    /**
     * When the server call successfully was made but response was never returned.
     */
    static readonly serverNotResponded: number = 598;


    /**
     * Fetches the default Text message from the code of the api call response.
     * @param {number} code
     * @private
     */
    static getMessageFromCode(code?: number) {
        switch (code) {
            //    HTTP Codes
            case ApiResponseCodes.badRequest:
                return Texts.badRequest;
            case ApiResponseCodes.unauthorized:
                return Texts.unauthorized;
            case ApiResponseCodes.forbiddenAccess:
                return Texts.forbiddenAccess;
            case ApiResponseCodes.notFound:
                return Texts.notFound;
            case ApiResponseCodes.serverError:
                return Texts.serverError;

            //    Custom Codes
            case ApiResponseCodes.requestFailed:
                return Texts.requestFailed;
            case ApiResponseCodes.serverNotResponded:
                return Texts.requestFailed;
            default:
                return;
        }
    }


}

export default ApiResponseCodes;

import {NavigateOptions, useSearchParams} from "react-router-dom";
import {useCallback, useMemo} from "react";

export namespace Query {

    export type SchemaParser<T> = (value?: Array<string>) => (T | undefined);

    export type ObjectSchema<T extends Record<string, any>> = {
        [P in keyof T]: SchemaParser<T[P]>;
    };

    export type RawSchemaEntry<T = any, U = any> = { type: string, value: T, defaultValue?: U };

    export type RawSchema<T extends Record<string, any>> = {
        [P in keyof T]: RawSchemaEntry
    };

    type ArrayValueType = ReturnType<SchemaCreatorMap['String'] | SchemaCreatorMap['Number'] | SchemaCreatorMap['Boolean'] | SchemaCreatorMap['Object']>;

    export type SchemaCreatorMap = {
        String: (defaultValue?: string) => RawSchemaEntry<string, string>,
        Number: (defaultValue?: number) => RawSchemaEntry<string, number>,
        Boolean: (defaultValue?: boolean) => RawSchemaEntry<string, boolean>,
        Array: (value: ArrayValueType, defaultValue?: Array<any>) => RawSchemaEntry<any, Array<any>>,
        Object: <T extends Record<string, any>>(value: T, defaultValue?: T) => RawSchemaEntry<T, T>,
    };

    export type SchemaCreator<T extends Record<string, any>> = (types: SchemaCreatorMap) => Query.RawSchema<T>;

    export type SetQuery<Type extends Record<string, any>> = (query: Type | ((prev: Type) => Type), navigateOpts?: NavigateOptions) => void;

}

/**
 * This is a helper class to create a schema for the useQuery hook.
 */
export class QuerySchema {

    private static String = "QuerySchema_String";
    private static Number = "QuerySchema_Number";
    private static Boolean = "QuerySchema_Boolean";
    private static Object = "QuerySchema_Object";
    private static Array = "QuerySchema_Array";

    /**
     * This is a map of the raw schema creator functions.
     * @private
     */
    private static RawSchemaCreatorMap: Query.SchemaCreatorMap = {
        String: (defaultValue) => ({type: QuerySchema.String, value: QuerySchema.String, defaultValue}),
        Number: (defaultValue) => ({type: QuerySchema.Number, value: QuerySchema.Number, defaultValue}),
        Boolean: (defaultValue) => ({type: QuerySchema.Boolean, value: QuerySchema.Boolean, defaultValue}),
        Object: (value, defaultValue) => ({type: QuerySchema.Object, value: value, defaultValue}),
        Array: (value, defaultValue) => ({type: QuerySchema.Array, value: value, defaultValue}),
    }

    /**
     * Creates a schema parser for an object.
     * @param schema                The schema.
     * @param defaultValue          The default value.
     */
    static object<T extends Record<string, any>>(schema: Query.ObjectSchema<T>, defaultValue?: T): Query.SchemaParser<Query.ObjectSchema<T>> {
        return ([value] = (defaultValue !== undefined ? [JSON.stringify(defaultValue)] : [])) => {
            try {
                const obj = JSON.parse(value);
                return Object.keys(schema).reduce((parsedObj, key) => {
                    const itemSchema = schema[key];
                    const itemValue = Array.isArray(obj[key]) ? obj[key] : [obj[key]];
                    parsedObj[key as keyof T] = itemSchema(itemValue) as T[typeof key];
                    return parsedObj;
                }, {} as T);
            } catch (e) {
                return undefined;
            }
        };
    }

    /**
     * Creates a schema parser for an array.
     * @param itemSchema        The schema for the items.
     * @param defaultValue      The default value.
     */
    static array<T>(itemSchema: Query.SchemaParser<T>, defaultValue?: Array<string>): Query.SchemaParser<Array<T>> {
        return (value = (defaultValue !== undefined ? defaultValue : [])) => value
            .map(val => {
                const parsed = itemSchema([val]);
                return parsed !== undefined ? parsed : null;
            })
            .filter(v => v !== null) as Array<T>;
    }

    /**
     * Creates a schema parser for a string.
     * @param defaultValue      The default value.
     */
    static string(defaultValue?: string): Query.SchemaParser<string> {
        return ([value] = (defaultValue !== undefined ? [defaultValue] : [])) => value;
    }

    /**
     * Creates a schema parser for a number.
     * @param defaultValue    The default value.
     */
    static number(defaultValue?: number): Query.SchemaParser<number> {
        return ([value] = (defaultValue !== undefined ? [defaultValue.toString()] : [])) => {
            const parsed = parseFloat(value);
            if (isNaN(parsed))
                return undefined;
            return parsed;
        };
    }

    /**
     * Creates a schema parser for a boolean.
     * @param defaultValue    The default value.
     */
    static boolean(defaultValue?: boolean): Query.SchemaParser<boolean> {
        return ([value] = (defaultValue !== undefined ? [defaultValue.toString()] : [])) => {
            if (value !== 'true' && value !== 'false')
                return undefined;
            return value === 'true';
        };
    }

    /**
     * Fetches the parser for the given type.
     * @param type              The type of the value.
     * @param value             The value to parse.
     * @param defaultValue      The default value.
     */
    private static getParserFromValueType(type: string, value?: string | Record<string, any>, defaultValue?: any): Query.SchemaParser<any> {
        switch (type) {
            case QuerySchema.String:
                return QuerySchema.string(defaultValue);
            case QuerySchema.Number:
                return QuerySchema.number(defaultValue);
            case QuerySchema.Boolean:
                return QuerySchema.boolean(defaultValue);
            case QuerySchema.Object: {
                const _value = value as Record<string, any>;
                return QuerySchema.object(QuerySchema.createSchema(() => _value), defaultValue);
            }
            case QuerySchema.Array: {
                const _value = value as Record<string, any>;
                const itemSchema = QuerySchema.getParserFromValueType(_value.type, _value.value, _value.defaultValue);
                return QuerySchema.array(itemSchema, defaultValue);
            }
            default:
                throw new Error(`Unknown type: ${type}`);
        }
    }

    /**
     * Creates a schema from the given creator function.
     * @param creator       The creator function.
     */
    public static createSchema<T extends Record<string, any>>(creator: Query.SchemaCreator<T>): Query.ObjectSchema<T> {
        const rawSchema = creator(QuerySchema.RawSchemaCreatorMap);
        return Object.keys(rawSchema).reduce((schema, key) => {
            schema[key] = QuerySchema.getParserFromValueType(
                rawSchema[key].type,
                rawSchema[key].value,
                rawSchema[key].defaultValue,
            );
            return schema;
        }, {} as Record<string, any>) as Query.ObjectSchema<T>;
    }
}

/**
 * Parses the search params into the query object.
 * @param searchParams      The search params to parse.
 * @param schema            The schema to use for parsing.
 */
export const parseSearchParams = <Type extends Record<string, any>>(searchParams: URLSearchParams, schema: Query.ObjectSchema<Type>) => {
    const result: Record<string, any> = {};
    // Loop through each key in the schema
    for (const key in schema) {
        const parser = schema[key];
        const value = searchParams.getAll(key);
        if (value.length) {
            result[key] = parser(value);
        } else {
            result[key] = parser();
        }
    }
    return result as Type;
};

/**
 * Creates the search params from the query object.
 * @param query    The query object.
 */
export const createSearchParams = <Type extends Record<string, any>>(query: Type) => {
    const newSearchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
        if (value === undefined || value === null) continue;

        if (Array.isArray(value)) {
            for (const element of value) {
                if (element !== undefined && element !== null) {
                    newSearchParams.append(key, JSON.stringify(element));
                }
            }
        } else if (typeof value === 'object') {
            newSearchParams.set(key, JSON.stringify(value));
        } else {
            newSearchParams.set(key, value.toString());
        }
    }
    return newSearchParams;
}

/**
 * This hook is used to parse the query parameters from the URL.
 * @param schema        The schema to use for parsing.
 */
export const useQuery = <Type extends Record<string, any>>(schema: Query.ObjectSchema<Type>): [Type, Query.SetQuery<Type>] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const query = useMemo(() => parseSearchParams(searchParams, schema), [schema, searchParams]);

    /**
     * Sets the query parameters.
     * @param queryUpdate       The query update.
     * @param navigateOpts      The navigate options.
     */
    const setQuery = useCallback<Query.SetQuery<Type>>((queryUpdate, navigateOpts) => {
        setSearchParams((searchParams) => {
            const newQuery = typeof queryUpdate === 'function' ? queryUpdate(parseSearchParams(searchParams, schema)) : queryUpdate;
            return createSearchParams(newQuery);
        }, navigateOpts);
    }, [setSearchParams, schema]);

    return useMemo(() => ([query, setQuery]), [query, setQuery])
}

export default useQuery;

import {Helmet} from "react-helmet-async";
import {EnvService} from "../../../core";
import {FunctionComponent, PropsWithChildren, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";

export interface SEOProps {
    facebookMetaData?: boolean;
    twitterMetaData?: boolean;
    title?: string;
    description?: string;
    creator?: string;
    type?: "website" | "article" | "video.movie";
    card?: "summary" | "summary_large_image" | "app" | "player";
    image?: string;
    video?: string;
    canonicalTag?: string;
    useSelfReferencingCanonicalTag?: boolean;
    useReverseTitleFromPath?: boolean;
}

const SEO: FunctionComponent<PropsWithChildren<SEOProps>> = (props) => {
    const location = useLocation();
    const [imageInfo, setImageInfo] = useState({width: 500, height: 129});

    const facebookMetaData = useMemo(() => props.facebookMetaData ?? true, [props.facebookMetaData])
    const twitterMetaData = useMemo(() => props.twitterMetaData ?? true, [props.twitterMetaData])

    const useSelfReferencingCanonicalTag = useMemo(() => props.useSelfReferencingCanonicalTag ?? true, [props.useSelfReferencingCanonicalTag])
    const canonicalTag = useMemo(() =>
            useSelfReferencingCanonicalTag
                ? window.location.origin + location.pathname.concat(location.hash)
                : props.canonicalTag
        , [location.hash, location.pathname, props.canonicalTag, useSelfReferencingCanonicalTag]);

    const useReverseTitleFromPath = useMemo(() => props.useReverseTitleFromPath ?? true, [props.useReverseTitleFromPath])
    const title = useMemo(() => {
        if (props.title)
            return props.title;
        const appName = EnvService.websiteName;
        const extension = location.pathname
            .replaceAll(/\/[a-zA-Z]/g, (string) => '/'.concat(string.substring(1, 2).toUpperCase()))
            .replaceAll('/', ' - ')
            .replaceAll(/-[a-zA-Z]/g, (string) => ' '.concat(string.substring(1, 2).toUpperCase()));
        if (' - ' === extension) {
            return appName;
        }
        const title = appName + extension;
        if (!useReverseTitleFromPath)
            return title;
        // now we want to reverse the title with " - " as separator
        return title.split(" - ").reverse().join(" - ");
    }, [props.title, location.pathname, useReverseTitleFromPath])

    const description = useMemo(() => {
        if (props.description)
            return props.description;
        return EnvService.websiteDescription;
    }, [props.description])

    const richPreviewInfo = useMemo(() => ({
        title: title,
        description: description,
        creator: props.creator ?? EnvService.websiteTwitterCreator,
        type: props.type ?? 'website',
        card: props.card ?? 'summary',
        image: props.image ?? (window.location.origin.concat('/assets/images/logo.png')),
        video: props.video,
        url: canonicalTag,
    }), [description, props.card, props.creator, props.image, props.type, props.video, title, canonicalTag])

    /**
     * This effect is used to get the image width and height
     */
    useEffect(() => {
        const img = new Image();
        img.src = richPreviewInfo.image;
        img.onload = () => {
            setImageInfo({width: img.width, height: img.height});
        }
    }, [richPreviewInfo.image]);

    return (
        <Helmet prioritizeSeoTags defaultTitle={EnvService.websiteName}>

            <title>{title}</title>

            <meta name='description' content={description}/>

            {
                canonicalTag &&
                <link rel="canonical" href={canonicalTag}/>
            }
            {
                facebookMetaData && richPreviewInfo.type &&
                <meta property="og:type" content={richPreviewInfo.type}/>
            }
            {
                facebookMetaData && richPreviewInfo.title &&
                <meta property="og:title" content={richPreviewInfo.title}/>
            }
            {
                facebookMetaData && richPreviewInfo.description &&
                <meta property="og:description" content={richPreviewInfo.description}/>
            }
            {
                facebookMetaData && richPreviewInfo.url &&
                <meta property="og:url" content={richPreviewInfo.url}/>
            }
            {
                facebookMetaData && richPreviewInfo.image &&
                <meta property="og:image" content={richPreviewInfo.image}/>
            }
            {
                facebookMetaData && richPreviewInfo.image &&
                <meta property="og:image:width" content={imageInfo.width.toString()}/>
            }
            {
                facebookMetaData && richPreviewInfo.image &&
                <meta property="og:image:height" content={imageInfo.height.toString()}/>
            }
            {
                facebookMetaData && richPreviewInfo.video &&
                <meta property="og:video" content={richPreviewInfo.video}/>
            }
            {
                twitterMetaData && richPreviewInfo.creator &&
                <meta name="twitter:creator" content={richPreviewInfo.creator}/>
            }
            {
                twitterMetaData && richPreviewInfo.creator &&
                <meta name="twitter:site" content={richPreviewInfo.creator}/>
            }
            {
                twitterMetaData && richPreviewInfo.card &&
                <meta name="twitter:card" content={richPreviewInfo.card}/>
            }
            {
                twitterMetaData && richPreviewInfo.title &&
                <meta name="twitter:title" content={richPreviewInfo.title}/>
            }
            {
                twitterMetaData && richPreviewInfo.description &&
                <meta name="twitter:description" content={richPreviewInfo.description}/>
            }
            {
                twitterMetaData && richPreviewInfo.url &&
                <meta property="twitter:url" content={richPreviewInfo.url}/>
            }
            {
                twitterMetaData && richPreviewInfo.image &&
                <meta property="twitter:image" content={richPreviewInfo.image}/>
            }
            {
                twitterMetaData && richPreviewInfo.image &&
                <meta property="twitter:image:width" content={imageInfo.width.toString()}/>
            }
            {
                twitterMetaData && richPreviewInfo.image &&
                <meta property="twitter:image:height" content={imageInfo.height.toString()}/>
            }
            {
                twitterMetaData && richPreviewInfo.video &&
                <meta property="twitter:player" content={richPreviewInfo.video}/>
            }
            {props.children}
        </Helmet>
    )
}

export default SEO;

import {ObserversService} from "../../../../base/core";

/**
 * @inheritDoc
 */
class DataGridObserversService extends ObserversService {

}

export default DataGridObserversService;

import React, {useContext, useMemo} from "react";
import DataGridIconButton from "../../../../components/icon-button/inedx";
import classnames from "classnames";
import {ReactComponent as SortIcon} from "../../../../../assets/images/header/sort.svg";
import {DataGridControllerContext, DataGridMiscContext, DataGridStateContext} from "../../../../../index";
import DataGridAHeaderActionsPopover from "../../actions-popover";

/**
 * @param {DataGridInternalColumn} column
 * @return {JSX.Element}
 * @constructor
 */
const DataGridHeaderCellDefaultContent = ({column}) => {
    const {classNames, resetSelectionOnSortByChange} = useContext(DataGridMiscContext);
    const {sortBy} = useContext(DataGridStateContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const sortedByThisColumn = useMemo(() => sortBy?.field === column.name, [sortBy, column.name])

    /**
     * Sorts the data-grid entries with the current column.
     *
     * * if this column is already sorted and its sort type is descending, then removes the sortBy of the table
     */
    const sortDataGrid = () => {
        if (resetSelectionOnSortByChange) {
            dataGridApi.resetSelection();
        }
        if (sortedByThisColumn && sortBy?.descending) {
            return dataGridApi.setSortBy(undefined, true);
        }
        const newSortBy = {
            field: column.name,
            descending: sortedByThisColumn,
        }
        dataGridApi.setSortBy(newSortBy, true);
    }

    const actionsPopover = useMemo(() =>
            <DataGridAHeaderActionsPopover column={column}/>,
        [
            column.name,
            column.alignment,
            column.sortable,
            column.pinnedToggleable,
            column.visibilityToggleable,
            column.pinned,
            column.pinnedType,
        ])

    return (
        <>
            {
                column.title &&
                <>
                    {
                        typeof (column.title) === "function"
                            ? <div className={'data-grid-header-title'}>
                                {column.title() ?? ""}
                            </div>
                            : <p className={'data-grid-header-title'}>
                                {column.title ?? ""}
                            </p>
                    }
                </>
            }
            {
                column.sortable &&
                <DataGridIconButton
                    className={classnames(
                        'data-grid-header-sort-icon-button',
                        {'active': sortedByThisColumn},
                        {'descending': sortedByThisColumn && !!sortBy?.descending},
                        classNames.headerSortIconButton,
                    )}
                    onClick={sortDataGrid}
                >
                    <SortIcon/>
                </DataGridIconButton>
            }
            {actionsPopover}
        </>
    )
}

export default DataGridHeaderCellDefaultContent;

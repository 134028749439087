import React, {useContext, useMemo} from "react";
import {DataGridStateContext} from "../../../../index";
import DataGridBodyRow from "../row";

/**
 * @param {DataGridInternalRow} row
 * @param {boolean} visible
 * @param {Function} startScrolling
 * @return {JSX.Element}
 * @constructor
 */
const DataGridBodyGroupedRows = ({row, visible, startScrolling}) => {
    const {density} = useContext(DataGridStateContext);
    const rows = useMemo(() => row.groupedRows, [row?.groupedRows])

    return (
        <>
            {
                visible &&
                rows.map(row => (
                    <DataGridBodyRow
                        key={row.key}
                        density={density}
                        row={row}
                        className={'data-grid-grouped-row'}
                        startScrolling={startScrolling}
                    />
                ))
            }
        </>
    )
}

export default DataGridBodyGroupedRows;

import {RefObject, useEffect, useRef} from 'react';
import JsBarcode from 'jsbarcode';


export interface UseBarcodeArg {
    value: string;
    options?: BarcodeOptions;
}

export interface BarcodeOptions extends JsBarcode.BaseOptions {
    format?: string;
    width?: number;
    height?: number;
    displayValue?: boolean;
    text?: string;
    fontOptions?: string;
    font?: string;
    textAlign?: string;
    textPosition?: string;
    textMargin?: number;
    fontSize?: number;
    background?: string;
    lineColor?: string;
    margin?: number;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    flat?: boolean;
    valid?: (valid: boolean) => void;
}

/**
 * A wrapper hook for creating a Barcode element via the [js-barcode] library.
 * @param value     the value for which the barcode is to be created
 * @param options   the options of the barcode element.
 */
const useBarcode = <T extends SVGElement | HTMLCanvasElement | HTMLImageElement>
({
     value,
     options
 }: UseBarcodeArg): RefObject<T> => {
    const inputRef = useRef<T>(null);

    /**
     * With each change in the [value] or [options] props:
     * - re-renders the element associated with the input ref
     */
    useEffect(() => {
        if (!inputRef.current)
            return;
        JsBarcode(inputRef.current, value, options);
    }, [value, options, inputRef]);

    return inputRef;
}

export default useBarcode;

import React, {FunctionComponent, useCallback, useLayoutEffect, useMemo, useRef, useState} from "react";
import {ReduxActions, useAppDispatch, useAppSelector} from "../../redux";
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel} from "@mui/material";
import {ToastTypes, useIsMounted} from "../../../@bizkeytech";
import Utils from "../../../core/services/utils";
import UIUtils from "../../utils";
import dayjs from "dayjs";
import {CodeFormatIds} from "../../../core/constants/enums";
import PreviewQrcode, {PreviewQrcodeProps} from "../../components/preview-qrcode";
import PreviewBarcode, {PreviewBarcodeProps} from "../../components/preview-barcode";

const MemoPreviewQrcode = React.memo(PreviewQrcode);
const MemoPreviewBarcode = React.memo(PreviewBarcode);

type Config = Required<PreviewBarcodeProps>['downloadElementConfig'] & Required<PreviewQrcodeProps>['downloadElementConfig'];

const configInitialValue: Config = {
    includeMargin: false,
    transparentBG: false,
}

interface QRCodeConfig {
    logoUrl?: string;
}

const qrCodeConfigInitialValue = {
    logoUrl: undefined,
}

interface BarCodeConfig {
    showLabel?: boolean;
}

const barCodeConfigInitialValue = {
    showLabel: false,
}

const DownloadCodeDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.dialogs?.downloadCode);
    const [downloading, setDownloading] = useState(false);
    const [config, setConfig] = useState<Config>(configInitialValue);
    const [qrCodeConfig, setQrCodeConfig] = useState<QRCodeConfig>(qrCodeConfigInitialValue);
    const [barCodeConfig, setBarCodeConfig] = useState<BarCodeConfig>(barCodeConfigInitialValue);
    const downloadId = useMemo(() => `ID-${state.data?.id ?? Utils.createUUId()}`, [state.data?.id]);
    const isMounted = useIsMounted();
    const uploadInputRef = useRef<HTMLInputElement>(null);

    const {hideCloseButton, disableBackdropClick, ...dialogProps} = state;

    /**
     * As soon as the dialog opens, resets the state values.
     */
    useLayoutEffect(() => {
        if (state?.open) {
            setDownloading(false);
            setConfig(configInitialValue)
            setQrCodeConfig(qrCodeConfigInitialValue)
            setBarCodeConfig(barCodeConfigInitialValue)
        }
    }, [state?.open])

    /**
     * Closes the dialog by dispatching its relevant action to the redux store.
     */
    const closeDialog = useCallback(async (successful = false) => {
        if (state?.onClose) {
            setDownloading(true);
            const resultFlag = await state.onClose(successful ? "successful" : "leave");
            if (!isMounted())
                return;
            setDownloading(false);
            if (successful && !resultFlag)
                return;
        }
        dispatch(ReduxActions.dialogs.downloadCode({open: false}));
    }, [state, dispatch, isMounted])

    /**
     * Closes the dialog if the values from the state do not prevent the closing of the dialog.
     */
    const onDialogClosed = useCallback((event: Object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        switch (reason) {
            case "backdropClick":
                if (!state.disableBackdropClick && !downloading)
                    closeDialog().then();
                break;
            case "closeClick":
                if (!state.hideCloseButton && !downloading)
                    closeDialog().then();
                break;
            case "escapeKeyDown":
                if (!state.disableEscapeKeyDown && !downloading)
                    closeDialog().then();
                break;
            default:
                break;
        }
    }, [downloading, closeDialog, state.disableBackdropClick, state.disableEscapeKeyDown, state.hideCloseButton])


    /**
     * Downloads the code in client's system.
     */
    const download = useCallback<React.MouseEventHandler<HTMLElement>>(() => {
        const element = document.getElementById(downloadId);
        if (!element)
            return UIUtils.toast(ToastTypes.error, {message: "Did not find the element to download"});
        setDownloading(true);
        const canvasElement = element as HTMLCanvasElement;
        const dataURL = canvasElement.toDataURL('image/png', 1);
        Utils.downloadUrl(dataURL, `${state.data?.title ?? ""}_${dayjs().format('YYYY MM DD @ HH:mm:ss')}`);
        setDownloading(false);
        closeDialog(true).then();
    }, [closeDialog, downloadId, state.data?.title]);

    /**
     * Sets the logo value in the parent components state by calling hte uploadQRCodeLogo with the target value of the received event
     */
    const handleQECodeLogoImageSelection = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
        if (!event.target?.files?.length)
            return;
        setQrCodeConfig(prevState => ({...prevState, logoUrl: URL.createObjectURL(event.target.files![0])}));
        event.target.value = "";
    }, [])

    /**
     * Opens the file selection dialog when the user clicks on the upload button.
     */
    const onUploadButtonClicked = useCallback<VoidCallback>(() => {
        uploadInputRef.current?.click()
    }, [])


    return (
        <>
            <Dialog
                {...dialogProps}
                onClose={onDialogClosed}
            >
                <DialogTitle fontWeight={600}>
                    Download {state.data?.title ?? "Desired"} Code
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant={'body1'}>
                        You can configure how your downloaded code will look like in here. Please select the download button when you are
                        ready.
                    </DialogContentText>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            marginBlock: 2,
                        }}
                    >
                        {
                            state.data?.formatId === CodeFormatIds.qrCode &&
                            <MemoPreviewQrcode
                                size={150}
                                value={state.data?.value}
                                downloadId={downloadId}
                                syncPreviewWithDownloadElement
                                downloadElementConfig={config}
                                logoUrl={qrCodeConfig.logoUrl}
                            />
                        }
                        {
                            state.data?.formatId === CodeFormatIds.barCode &&
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 150}}>
                                <MemoPreviewBarcode
                                    width={'100%'}
                                    value={state.data?.value}
                                    downloadId={downloadId}
                                    syncPreviewWithDownloadElement
                                    downloadElementConfig={config}
                                    showLabel={barCodeConfig.showLabel}
                                />
                            </Box>
                        }
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <FormControlLabel
                                label="Include Margin"
                                slotProps={{
                                    typography: {
                                        variant: 'body2',
                                    },
                                }}
                                sx={{marginBlock: 0, marginInline: 0}}
                                control={
                                    <Checkbox
                                        disabled={downloading}
                                        sx={{paddingBlock: 0.75}}
                                        disableRipple
                                        checked={config.includeMargin}
                                        onChange={(e) => setConfig({...config, includeMargin: e.target.checked})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Transparent Background"
                                slotProps={{
                                    typography: {
                                        variant: 'body2',
                                    },
                                }}
                                sx={{marginBlock: 0, marginInline: 0}}
                                control={
                                    <Checkbox
                                        disabled={downloading}
                                        sx={{paddingBlock: 0.75}}
                                        disableRipple
                                        checked={config.transparentBG}
                                        onChange={(e) => setConfig({...config, transparentBG: e.target.checked})}
                                    />
                                }
                            />
                            {
                                state.data?.formatId === CodeFormatIds.qrCode &&
                                <>
                                    <Button
                                        variant={"outlined"}
                                        size={'medium'}
                                        color={'primary'}
                                        sx={{marginBlockStart: 1, marginInline: 'auto', paddingInline: 6}}
                                        onClick={onUploadButtonClicked}
                                    >
                                        Upload Logo
                                        <input
                                            hidden
                                            ref={uploadInputRef}
                                            type={"file"}
                                            accept={'image/*'}
                                            onChange={handleQECodeLogoImageSelection}
                                        />
                                    </Button>
                                </>
                            }
                            {
                                state.data?.formatId === CodeFormatIds.barCode &&
                                <>
                                    <FormControlLabel
                                        label="Show Label"
                                        slotProps={{
                                            typography: {
                                                variant: 'body2',
                                            },
                                        }}
                                        sx={{marginBlock: 0, marginInline: 0}}
                                        control={
                                            <Checkbox
                                                disabled={downloading}
                                                sx={{paddingBlock: 0.75}}
                                                disableRipple
                                                checked={barCodeConfig.showLabel}
                                                onChange={(e) => setBarCodeConfig({...config, showLabel: e.target.checked})}
                                            />
                                        }
                                    />
                                </>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        variant={'text'}
                        type={'button'}
                        color={'primary'}
                        disabled={downloading}
                        onClick={() => onDialogClosed({}, 'closeClick')}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        disabled={downloading}
                        onClick={download}
                    >
                        {
                            downloading
                                ? "Downloading..."
                                : "Download"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DownloadCodeDialog;

import {EnvService} from "../../../../base/core";

/**
 * @inheritDoc
 */
class QueryBuilderEnvService extends EnvService {

    /**
     *  The base url of our api
     */
    static apiEndpointUrl: string = process.env.REACT_APP_QUERY_BUILDER_API_URL ?? "";

}

export default QueryBuilderEnvService;

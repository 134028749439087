import React, {createContext, FunctionComponent, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import useIsMounted from "../use-is-mounted";
import {QueryBuilderColumn, QueryBuilderColumnType, QueryBuilderColumnTypeEnum} from "../../../../query-builder";
import {ApiResponseDs} from "../../../core";
import {DataGridColumn, DataGridColumnAlignments, DataGridColumnTypes} from "../../../../data-grid";

export type DynamicColumn = QueryBuilderColumn & {
    filterable: boolean;
    bindingColumn?: string;
    hideColumn?: boolean;
}

export interface UseDynamicColumnsContext {
    fetchColumns: (applicationName: DynamicColumnApplications, sectionName: string, abortController: AbortController) => Promise<ApiResponseDs<{
        columnsInfos: Array<DynamicColumn>
    }> | null | undefined>;
    getFilterOptionsMap: Record<DynamicColumnApplications, string>,
}

const DynamicColumnsContext = createContext<UseDynamicColumnsContext>({
    fetchColumns: async () => (void 0),
    getFilterOptionsMap: {
        default: ""
    },
});

export interface DynamicColumnsProviderProps {
    fetchColumnsMap: Record<DynamicColumnApplications, (sectionName: string, abortController: AbortController) => Promise<ApiResponseDs<{
        columnsInfos: Array<DynamicColumn>
    }> | null | undefined>>;
    getFilterOptionsMap: Record<DynamicColumnApplications, string>
}


export enum DynamicColumnApplications {
    default = 'default',
}

/**
 * A provider to provide the [fetchColumns] function to the [useDynamicColumns] hook.
 */
export const DynamicColumnsProvider: FunctionComponent<PropsWithChildren<DynamicColumnsProviderProps>> = (props) => {
    const [savedState, setSavedState] = useState<Record<DynamicColumnApplications, Record<string, Array<DynamicColumn>>>>({
        default: {},
    });
    const isMounted = useIsMounted();

    /**
     * Fetches the columns from the server.
     * If the columns are already fetched, it returns the saved columns.
     * @param sectionName   The section name.
     * @param cancelToken   The cancel token.
     */
    const fetchColumns = useCallback<UseDynamicColumnsContext['fetchColumns']>(async (applicationName, sectionName, cancelToken) => {
        applicationName = applicationName ?? DynamicColumnApplications.default;
        if (savedState[applicationName][sectionName]) {
            return {
                resultFlag: true,
                data: {columnsInfos: savedState[applicationName][sectionName]},
            } as ApiResponseDs;
        }
        const response = await props.fetchColumnsMap[applicationName](sectionName, cancelToken);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setSavedState(prevState => ({
                ...prevState,
                [applicationName]: {
                    ...prevState[applicationName] ?? {},
                    [sectionName]: response.data?.columnsInfos ?? [],
                }
            }));
        }
        return response;
    }, [props.fetchColumnsMap, isMounted, savedState])

    return (
        <>
            <DynamicColumnsContext.Provider value={{
                fetchColumns: fetchColumns,
                getFilterOptionsMap: props.getFilterOptionsMap,
            }}>
                {props.children}
            </DynamicColumnsContext.Provider>
        </>
    );
};

export interface UseDynamicColumnsArg {
    applicationName?: DynamicColumnApplications,
    sectionName: string;
    staticDataGridColumns: Array<Partial<DataGridColumn>>;
    dataGridColumnsConfig: Record<string, Partial<DataGridColumn>>;
}

export type GetDataGridColumns = (
    dataGridColumnsConfig?: Record<string, Partial<DataGridColumn>>,
    staticColumns?: Array<Partial<DataGridColumn>>
) => Array<Partial<DataGridColumn>>;


export type UseDynamicColumnsReturnValue = [
    boolean,
    Array<QueryBuilderColumn>,
    Array<Partial<DataGridColumn>>,
    Array<Partial<DynamicColumn>>,
]

export interface UseDynamicColumns {
    (arg: UseDynamicColumnsArg): UseDynamicColumnsReturnValue;
}

/**
 * A hook to fetch the columns from the server.
 * @param arg
 */
const useDynamicColumns: UseDynamicColumns = (arg) => {
    const {fetchColumns, getFilterOptionsMap} = useContext(DynamicColumnsContext);
    const [columns, setColumns] = useState<Array<DynamicColumn>>([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();
    const applicationName = useMemo(() => arg.applicationName ?? DynamicColumnApplications.default, [arg.applicationName]);

    const queryBuilderColumns = useMemo<Array<QueryBuilderColumn>>(() => {
        const boundColumns: string[] = [];
        const res = columns
            .map(e => ({
                    ...e,
                    multiSelect: e.type === QueryBuilderColumnTypeEnum.List
                }) as DynamicColumn
            )
            .map((column, index, array) => {
                if (column.bindingColumn) {
                    const bound = array.find((c) => c.field === column.bindingColumn);
                    if (!bound)
                        return column;
                    // column.column is used here since the field itself is being replaced
                    boundColumns.push(bound.column);
                    return {
                        ...column,
                        type: bound.type,
                        field: bound.field,
                        isNullable: bound.isNullable,
                        operators: bound.operators,
                        multiSelect: bound.multiSelect,
                        options: bound.options,
                        apiUrl: bound.apiUrl,
                    } as DynamicColumn;
                }
                return column;
            })
            .map(column => {
                if (column.type === QueryBuilderColumnTypeEnum.List) {
                    column.baseUrl = getFilterOptionsMap[applicationName];
                }
                return column;
            })
            .filter((column) => {
                return column.filterable && !boundColumns.includes(column.column)
            })
        // only return unique columns based on their filter field
        const uniqueColumns: Record<string, QueryBuilderColumn> = {};
        for (const column of res) {
            if (!uniqueColumns[column.field])
                uniqueColumns[column.field] = column;
        }
        return Object.values(uniqueColumns);
    }, [applicationName, columns, getFilterOptionsMap])

    /**
     * Fetches the columns from the server.
     */
    const getColumns = useCallback(async (abortController: AbortController) => {
        setLoading(true);
        const response = await fetchColumns(applicationName, arg.sectionName, abortController);
        if (!isMounted())
            return;
        setLoading(false);
        if (response?.resultFlag) {
            setColumns(response.data?.columnsInfos?.map(e => ({
                ...e,
                field: e.field?.charAt(0)?.toLowerCase() + e.field?.slice(1),
                bindingColumn: e.bindingColumn ? (e.bindingColumn?.charAt(0)?.toLowerCase() + e.bindingColumn?.slice(1)) : e.bindingColumn,
            })) ?? [])
        }
    }, [applicationName, arg.sectionName, fetchColumns, isMounted]);

    /**
     * With each change in the [sectionName]:
     * - fetches the columns from the server.
     */
    useEffect(() => {
        const abortController = new AbortController();
        getColumns(abortController).then();
        return () => abortController.abort();
    }, [getColumns]);

    /**
     * Creates data grid columns from the given query builder columns.
     * @param dynamicColumns                the dynamic columns
     * @param dataGridColumnsConfig         the data builder columns config
     */
    const createDataGridColumnsFromDynamicColumns = (
        dynamicColumns: DynamicColumn[],
        dataGridColumnsConfig: Record<string, Partial<DataGridColumn>>,
    ): Partial<DataGridColumn>[] => {

        const alignmentMap: Record<QueryBuilderColumnType, DataGridColumnAlignments> = {
            [QueryBuilderColumnTypeEnum.String]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.Int]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.Decimal]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.Bool]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.List]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.Date]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.Time]: DataGridColumnAlignments.left,
            [QueryBuilderColumnTypeEnum.DateTime]: DataGridColumnAlignments.left,
        }
        const typeMap: Record<QueryBuilderColumnType, DataGridColumnTypes> = {
            [QueryBuilderColumnTypeEnum.String]: DataGridColumnTypes.string,
            [QueryBuilderColumnTypeEnum.Int]: DataGridColumnTypes.number,
            [QueryBuilderColumnTypeEnum.Decimal]: DataGridColumnTypes.number,
            [QueryBuilderColumnTypeEnum.Bool]: DataGridColumnTypes.element,
            [QueryBuilderColumnTypeEnum.List]: DataGridColumnTypes.element,
            [QueryBuilderColumnTypeEnum.Date]: DataGridColumnTypes.date,
            [QueryBuilderColumnTypeEnum.Time]: DataGridColumnTypes.string,
            [QueryBuilderColumnTypeEnum.DateTime]: DataGridColumnTypes.dateTime,
        }
        return dynamicColumns
            .filter(column => column.hideColumn === false)
            .map(column => {
                const columnConfig = dataGridColumnsConfig[column.field!];
                return ({
                    title: column.column,
                    name: column.field,
                    alignment: alignmentMap[column.type],
                    type: typeMap[column.type],
                    sortable: column.sortable,
                    savable: true,
                    ...(columnConfig ?? {}),
                }) as DataGridColumn;
            })
    }

    /**
     * Returns the data grid columns from the dynamic columns and the static columns.
     */
    const getDataGridColumns = useCallback<GetDataGridColumns>((dataGridColumnsConfig, staticColumns) => {
        return [
            ...(staticColumns ?? []),
            ...createDataGridColumnsFromDynamicColumns(columns, dataGridColumnsConfig ?? {}),
        ]
    }, [columns]);

    const dataGridColumns = useMemo(() => getDataGridColumns(arg.dataGridColumnsConfig, arg.staticDataGridColumns), [arg.dataGridColumnsConfig, arg.staticDataGridColumns, getDataGridColumns]);

    return useMemo<UseDynamicColumnsReturnValue>(() => ([
            loading,
            queryBuilderColumns,
            dataGridColumns,
            columns,
        ]),
        [columns, loading, queryBuilderColumns, dataGridColumns]);
}

export default useDynamicColumns;

import React, {useContext, useMemo} from "react";
import classnames from "classnames";
import {DataGridMiscContext} from "../../../../index";
import {Collapse} from "@mui/material";

/**
 * @param {DataGridInternalRow} row
 * @param {boolean} visible
 * @return {JSX.Element}
 * @constructor
 */
const DataGridBodyRowDetailedPanel = ({row, visible}) => {
    const {classNames} = useContext(DataGridMiscContext);

    const content = useMemo(() => row.detailedPanel, [row?.detailedPanel])

    return (
        <>
            <tr className={classnames(
                classNames.detailedPanelContainer,
                {[classNames.detailedPanelVisible]: visible},
                classNames.row,
                'data-grid-detailed-panel-container',
                'data-grid-body-row',
                {'visible': visible},
            )}>
                <td colSpan={row.colCount}>
                    <Collapse in={visible}>
                        <div className={classnames(
                            'data-grid-detailed-panel',
                            classNames.detailedPanel,
                        )}>
                            {content}
                        </div>
                    </Collapse>
                </td>
            </tr>
        </>
    )
}

export default DataGridBodyRowDetailedPanel;

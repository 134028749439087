import {configureStore as toolkitConfigureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import ReduxRootReducer from "./root-reducer";
import DialogsReduxSlice from "./slices/dialogs";

/**
 * Configures the Redux store of the application and returns the configured store.
 * * enables hot module reloading (HMR) for the redux reducers as well.
 */
const configureStore = () => {
    const store = toolkitConfigureStore({
        reducer: ReduxRootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    ...Object.values(DialogsReduxSlice.actions).map(e => e.type),
                ],
                ignoredPaths: [
                    DialogsReduxSlice.name,
                ],
            }
        }),
    })

    if (process.env.NODE_ENV === 'development') {
        module.hot?.accept('./root-reducer', () => store.replaceReducer(ReduxRootReducer))
    }
    return store;
}

const ReduxStore = configureStore();

export type RootReduxState = ReturnType<typeof ReduxStore.getState>;
export type AppDispatch = typeof ReduxStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootReduxState> = useSelector;


export {default as ReduxActions} from './actions';
export default ReduxStore

import {QueryBuilderQuery} from "../../../../query-builder";
import {SearchDataSettingsPaginationInfo, UseSearchResult} from "../use-search";
import {DataGridColumn, DataGridColumnTypes, DataGridRow, GetExportInfoFunc} from "../../../../data-grid";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {ReportingApiResponse, Utils} from "../../../core";
import useIsMounted from "../use-is-mounted";
import BizkeytechReduxStore, {BizkeytechReduxActions} from "../../redux";
import UIUtils, {ToastTypes} from "../../utils";
import dayjs from "dayjs";


// unique symbol named continue marker
const ContinueMarker = Symbol('continueMarker');

export interface UseReportingSearchUtilsDataType {
    id: string | number;

    [p: string]: any,
}

export interface UseReportingSearchUtilsArg<DataType extends UseReportingSearchUtilsDataType, Filters> {
    savedDataGridColumns: Array<DataGridColumn>,
    fetchEntries: (args: UseSearchResult<Filters>, isExporting?: boolean) => ReportingApiResponse<DataType>,
    searchData: UseSearchResult<Filters>,
    exportingChunkSize?: number,
    formatExportedCell?: (cell: DataType[keyof DataType], column: DataGridColumn, continueMarker: typeof ContinueMarker) => null | undefined | string | typeof continueMarker,
    exportingTitle?: string,
    getDataGridCell?: (row: DataType, column: DataGridColumn, continueMarker: typeof ContinueMarker) => ReactNode | typeof continueMarker,
    data: Array<DataType>,
}

export type UseReportingSearchUtilsResult<DataType extends UseReportingSearchUtilsDataType = UseReportingSearchUtilsDataType> = {
    exporting: boolean,
    getExportInfo: GetExportInfoFunc,
    getAllData: () => Promise<Array<DataType>>,
    exportData: () => Promise<void>,
    dataGridRows: Array<DataGridRow>,
}

/**
 * A hook that provides common functions for reporting search.
 *
 * @param searchData                    The search data.
 * @param fetchEntries                  The function that fetches the entries.
 * @param savedDataGridColumns          The data grid columns.
 * @param exportingChunkSize            The exporting chunk size.
 * @param formatExportedCell            The function that formats the exported cell.
 * @param exportingTitle                the title of the exported data
 * @param getDataGridCell               The function that formats the data grid cell.
 * @param data                          The data grid rows.
 */
const useReportingSearchUtils = <DataType extends UseReportingSearchUtilsDataType = UseReportingSearchUtilsDataType, Filters = QueryBuilderQuery>(
    {
        searchData,
        fetchEntries,
        savedDataGridColumns,
        getDataGridCell,
        exportingChunkSize,
        formatExportedCell,
        exportingTitle,
        data,
    }: UseReportingSearchUtilsArg<DataType, Filters>): UseReportingSearchUtilsResult<DataType> => {
    const [exporting, setExporting] = useState(false);
    const isMounted = useIsMounted();

    /**
     * With each change in the [exporting] condition of the state:
     * - closes or opens the exporting dialog.
     */
    useEffect(() => {
        BizkeytechReduxStore.dispatch(BizkeytechReduxActions.dialogs.exporting({open: exporting}))
    }, [exporting]);

    /**
     * Fetches all the entries from the server based on the user's filters.
     */
    const getAllData = useCallback<UseReportingSearchUtilsResult<DataType>['getAllData']>(async () => {
        const _paginationInfo = {
            length: searchData.paginationInfo!.length,
            pageSize: exportingChunkSize ?? 100,
            currentPage: 1,
        } as SearchDataSettingsPaginationInfo;
        let entries: Array<DataType> = [];
        while (searchData.paginationInfo!.length > entries.length) {
            const res = await fetchEntries({...searchData, paginationInfo: _paginationInfo}, true);
            if (!res?.resultFlag)
                return [];

            if (!res?.data?.items?.length)
                break;

            entries.push(...res.data.items);
            if (!isMounted())
                return [];
            _paginationInfo.currentPage++;
        }
        return entries;
    }, [exportingChunkSize, fetchEntries, isMounted, searchData])

    /**
     * Fetches the entries for exporting purposes from the server based on the user's filters.
     */
    const getExportInfo = useCallback<UseReportingSearchUtilsResult<DataType>['getExportInfo']>(async () => {
        const entries = await getAllData();
        if (!entries.length)
            return;

        const body = entries
            .map(entry => {
                const res: Record<string, string> = {};
                const keys = Object.keys(entry);
                for (const key of keys) {
                    const column = savedDataGridColumns.find(e => e.name === key);
                    if (!column)
                        continue;
                    let title: string | undefined | null = typeof column.title === 'function' ? column.title()?.toString() : column.title;
                    if (typeof title !== 'string' || !title) {
                        title = column.name;
                    }
                    const cell = formatExportedCell?.(entry[key], column, ContinueMarker);
                    if (!formatExportedCell || cell === ContinueMarker) {
                        switch (column.type) {
                            case DataGridColumnTypes.date:
                                res[title] = dayjs(entry[key]).format('YYYY-MM-DD');
                                break;
                            case DataGridColumnTypes.dateTime:
                                res[title] = dayjs(entry[key]).format('YYYY-MM-DD HH:mm:ss');
                                break;
                            case DataGridColumnTypes.money:
                                let parsed = parseFloat(entry[key]);
                                if (Number.isNaN(parsed)) {
                                    res[title] = "--";
                                } else {
                                    res[title] = Utils.CurrencyFormatter.format(parsed);
                                }
                                break;
                        }
                        res[title] = entry[key] ?? "--";
                    } else {
                        res[title] = cell ?? '--';
                    }
                }
                if (Object.keys(res).length === 0) {
                    return undefined;
                }
                return res;
            })
            .filter(e => e !== undefined) as Array<Record<string, string>>;
        return {
            title: exportingTitle ?? document.title.concat(' - Exported Data').concat(` - ${dayjs().format()}`),
            body: body,
        }
    }, [getAllData, exportingTitle, savedDataGridColumns, formatExportedCell])

    /**
     * Exports the data.
     */
    const exportData = useCallback<UseReportingSearchUtilsResult<DataType>['exportData']>(async () => {
        setExporting(true);
        const exportInfo = await getExportInfo();
        if (!isMounted())
            return;
        setExporting(false);
        if (!exportInfo?.body?.length) {
            UIUtils.toast(ToastTypes.warning, {message: "No export data found"});
            return
        }
        Utils.exportCsvFile(exportInfo.body, exportInfo.title);
    }, [getExportInfo, isMounted])

    /**
     * The data grid rows to be displayed.
     */
    const dataGridRows = useMemo<UseReportingSearchUtilsResult<DataType>['dataGridRows']>(() => {
        return data.map(dataEntry => {
            const res: Record<string, any> = {};
            for (const column of savedDataGridColumns) {
                const cell = getDataGridCell?.(dataEntry, column, ContinueMarker);
                if (!getDataGridCell || cell === ContinueMarker) {
                    res[column.name] = dataEntry[column.name as keyof DataType];
                } else {
                    res[column.name] = cell;
                }
            }
            return {
                key: dataEntry.id,
                data: dataEntry,
                cells: res,
            }
        });
    }, [data, getDataGridCell, savedDataGridColumns])


    return useMemo(() => ({
        exporting,
        exportData,
        getAllData,
        getExportInfo,
        dataGridRows
    }), [
        exporting,
        exportData,
        getAllData,
        getExportInfo,
        dataGridRows
    ])
}

export default useReportingSearchUtils;

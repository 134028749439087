import QueryBuilderEnvService from "../../../services/env";

/**
 * This interface houses all the available endpoints of this package.
 */
class QueryBuilderApiEndpoints {

    /**
     * The base url of the api endpoints of this package.
     * @private
     */
    public static baseUrl: string = QueryBuilderEnvService.apiEndpointUrl + '/api';

    /**
     * The controllers of the api endpoints.
     * @private
     */
    public static controllers = {
        queryBuilder: this.baseUrl.concat("UiComponent"),
    }

    /**
     * The endpoints specific for the data-grid controller of the server api.
     */
    public static queryBuilder = {
        baseUrl: this.controllers.queryBuilder,
        getData: this.controllers.queryBuilder.concat('/GetApplicationComponents'),
        saveData: this.controllers.queryBuilder.concat('/Save'),
    }
}


export default QueryBuilderApiEndpoints;

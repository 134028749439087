import {CanvasHTMLAttributes, DetailedHTMLProps, FunctionComponent, useLayoutEffect, useRef} from "react";
import BallFountainCanvasController from "./controller";
import {useTheme} from "@mui/material";


export type BallFountainCanvasProps = DetailedHTMLProps<CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>;


const BallFountainCanvas: FunctionComponent<BallFountainCanvasProps> = (props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const theme = useTheme();

    /**
     * As soon as the component mounts:
     * - creates and starts the animation of the ball fountain in the canvas.
     */
    useLayoutEffect(() => {
        if (!canvasRef.current)
            return;
        return new BallFountainCanvasController(
            canvasRef.current,
            theme.palette['app-specific']['ball-fountain']['ball-color'],
            theme.palette['app-specific']['ball-fountain']['background-color'],
            theme.palette['app-specific']['ball-fountain']['line-color'],
        ).start();
    }, [theme.palette])

    return (
        <>
            <canvas {...props} ref={canvasRef}/>
        </>
    );
}

export default BallFountainCanvas;

import React, {createContext, forwardRef} from 'react';
import _DataGrid from './ui';
import {
    DataGridEvents,
    DataGridInternalState,
    DataGridLayout,
    DataGridMisc,
    DataGridProps,
    DataGridSavedState,
    DefaultDataGridEvents,
    DefaultDataGridLayout,
    DefaultDataGridMisc,
    DefaultDataGridState
} from "./type-declerations";
import InternalDataGridController from "./core/models/in-app/controller/internal";
import type DataGridController from "./core/models/in-app/controller";

export const DataGridStateContext = createContext<DataGridInternalState>(DefaultDataGridState);
export const DataGridControllerContext = createContext<InternalDataGridController>(new InternalDataGridController());
export const DataGridLayoutContext = createContext<DataGridLayout>(DefaultDataGridLayout);
export const DataGridEventHandlersContext = createContext<DataGridEvents>(DefaultDataGridEvents);
export const DataGridMiscContext = createContext<DataGridMisc>(DefaultDataGridMisc);
export const DataGridSavedStateContext = createContext<DataGridSavedState>([]);
export const DataGridApplicationNameContext = createContext<string>('');

// @ts-ignore
export const DataGrid = forwardRef(_DataGrid as React.ForwardRefRenderFunction<DataGridController, DataGridProps>);


export * from './type-declerations';

export * from './ui/components/provider';
export {default as DataGridProvider} from './ui/components/provider';
export * from './ui/hooks/use-data-grid-saved-state';
export {default as useDataGridSavedState} from './ui/hooks/use-data-grid-saved-state';

export * from './core/models/in-app/controller';
export {default as DataGridController} from './core/models/in-app/controller';

export * from './core/services/api';
export {default as DataGridApiService} from './core/services/api';

export default DataGrid;

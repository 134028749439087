import type {ToastTypes} from "../../../@bizkeytech";

/**
 * Types of the application toasts.
 */
export enum AppToastTypes {
    test = 'test',
}

export type AllToastTypes = ToastTypes | AppToastTypes;

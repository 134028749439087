import {useLocation} from "react-router-dom";
import React, {FunctionComponent, useMemo} from "react";
import {HTMLMotionProps, motion, Transition, Variants} from "framer-motion";

interface IFadeAnimationLayoutProps extends HTMLMotionProps<'div'> {
    motionKey?: string,
}

const FadeAnimationLayout: FunctionComponent<IFadeAnimationLayoutProps> = ({
                                                                               motionKey: motionKeyProp,
                                                                               ...props
                                                                           }) => {
    const location = useLocation();
    const pageVariants = useMemo<Variants>(() => ({
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1
        },
        exit: {
            opacity: 0
        }
    }), []);
    const pageTransition = useMemo<Transition>(() => ({
        type: "tween",
        ease: "linear",
        duration: 0.5
    }), []);

    const motionKey = useMemo<string>(() =>
            motionKeyProp ?? location.pathname,
        [motionKeyProp, location.pathname])

    return (
        <>
            <motion.div
                {...props}
                key={motionKey}
                initial={"initial"}
                animate={"animate"}
                exit={'exit'}
                variants={pageVariants}
                transition={pageTransition}
            >
                {props.children}
            </motion.div>
        </>
    );
}

export default FadeAnimationLayout;

import {GetMapboxCoordinatesResponse, GetMapboxMarkerAddressResponse} from "./type-declarations";
import {ApiMethods, ApiService} from "../../../../base/core";
import MapBoxApiEndpoints from "../../models/static/api-endpoints";

/**
 * @inheritDoc
 */
class MapboxApiService extends ApiService {

    /**
     * Fetches the Address geolocation based on the provided lng & lat of a particular marker
     *
     * @param lng               the longitude of the marker
     * @param lat               the latitude of the marker
     * @param accessToken       the access token of mapboxGl
     */
    public static async getMapboxMarkerAddress(lng: number, lat: number, accessToken: string): GetMapboxMarkerAddressResponse {
        return await this._executor.execute({
            url: MapBoxApiEndpoints.places.getMarkerAddress(lng, lat, accessToken),
            method: ApiMethods.get,
            externalResponseDS: true,
        });
    }

    /**
     * Fetches the general report of a specific spire counting report
     *
     * @param address               the address of the given location
     * @param accessToken           the access token of mapboxGl
     * @param country               the country in which the address should be looked up.
     */
    public static async getMapboxCoordinates(address: string, accessToken: string, country?: string): GetMapboxCoordinatesResponse {
        return await this._executor.execute({
            url: MapBoxApiEndpoints.places.getAddressCoordinates(address, accessToken, country),
            method: ApiMethods.get,
            externalResponseDS: true,
        });
    }

}


export * from './type-declarations';
export default MapboxApiService;

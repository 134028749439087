import type {CssVarsThemeOptions} from "@mui/material/styles";
import UIUtils from "../utils";

// noinspection ES6UnusedImports

declare interface IAppSpecificPalette {
    'chart': {
        "1": string;
        "2": string;
        "3": string;
        "4": string;
        "5": string;
        "6": string;
        "7": string;
        "8": string;
    },
}


declare module '@mui/material/styles' {

    interface Palette {
        'app-specific-new': IAppSpecificPalette,
    }

    interface PaletteOptions {
        'app-specific-new'?: Partial<IAppSpecificPalette>,
    }
}


/**
 * The default theme of the application.
 */
const theme: CssVarsThemeOptions = {
    cssVarPrefix: UIUtils.ThemePrefix,
    colorSchemes: {
        light: {
            palette: {
                "app-specific-new": {
                    'chart': {
                        "1": "#8442f5",
                        "2": "#ff6347",
                        "3": "#32cd32",
                        "4": "#1e90ff",
                        "5": "#ff1493",
                        "6": "#ffd700",
                        "7": "#8a2be2",
                        "8": "#00ced1",
                    },
                },
            },
        },
        dark: {
            palette: {
                "app-specific-new": {
                    'chart': {
                        "1": "#8442f5",
                        "2": "#ff4500",
                        "3": "#228b22",
                        "4": "#4682b4",
                        "5": "#c71585",
                        "6": "#daa520",
                        "7": "#9932cc",
                        "8": "#20b2aa",
                    },
                },
            },
        },
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: 'xl',
            },
            styleOverrides: {},
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {},
        },
    },
};


export default theme;


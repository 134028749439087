import * as Bizkeytech from "../../@bizkeytech";
import type {AllToastTypes} from "./type-declerations";
import {AppToastTypes} from "./type-declerations";
import {type Id as ToastId, toast, type ToastOptions} from "react-toastify";

/**
 * @inheritDoc
 */
class UIUtils extends Bizkeytech.UIUtils {

    /**
     * @inheritDoc
     */
    public static get ToastContainerIDs() {
        return {
            ...super.ToastContainerIDs,
        }
    }

    /**
     * Shows the toast for test toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: AppToastTypes.test, data?: any, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for duplicateCode toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: Bizkeytech.ToastTypes.apiResponseError, data: Bizkeytech.IApiResponseErrorToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for apiResponseSuccess toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: Bizkeytech.ToastTypes.apiResponseSuccess, data: Bizkeytech.IApiResponseSuccessToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for error toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: Bizkeytech.ToastTypes.error, data: Bizkeytech.IErrorToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for info toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: Bizkeytech.ToastTypes.info, data: Bizkeytech.IInfoToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for success toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: Bizkeytech.ToastTypes.success, data: Bizkeytech.ISuccessToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for warning toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: Bizkeytech.ToastTypes.warning, data: Bizkeytech.IWarningToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows a toast specific for the given [type].
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: AllToastTypes, data?: any, options?: ToastOptions): ToastId {
        options = options ?? {};
        let content;
        switch (type) {
            case AppToastTypes.test:
                break;
            default:
                return super.toast(type as any, data, options);
        }
        return toast<any>(content, {
            ...options,
            containerId: options?.containerId ?? this.ToastContainerIDs.default,
            data: data,
        });
    }
}

export * from './type-declerations';
export default UIUtils;

import {EnvService} from "../../../../base/core";

/**
 * @inheritDoc
 */
class MapboxEnvService extends EnvService {

    /**
     *  The base url of our api
     */
    static apiEndpointUrl: string = process.env.REACT_APP_MAPBOX_API_ENDPOINT_URL ?? "";

    /**
     * The base url of our api for the get filter options endpoint.
     */
    static accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";
}

export default MapboxEnvService;

import {
    CachingServiceCookiesKeys,
    CachingServiceCookiesKeysType,
    CachingServiceLocalStorageKeys,
    CachingServiceLocalStorageKeysType
} from "./type-declarations";
import * as Bizkeytech from "../../../@bizkeytech";

/**
 * @inheritDoc
 */
class CachingService extends Bizkeytech.CachingService {

    /**
     * @inheritDoc
     */
    protected static LocalStorageEntity = new Bizkeytech.CachingServiceLocalStorageEntity<CachingServiceLocalStorageKeysType>({
        [CachingServiceLocalStorageKeys.dataGridSavedState]: "data-grid-saved-state",
    });

    /**
     * @inheritDoc
     */
    protected static CookiesEntity = new Bizkeytech.CachingServiceCookiesEntity<CachingServiceCookiesKeysType>({
        [CachingServiceCookiesKeys.dataGridSavedState]: 'data-grid-saved-state',
    });

    /**
     * Fetches the cookies entity of this Caching service.
     * @param entityType
     */
    public static of(entityType: Bizkeytech.CachingServiceEntities.cookies): Bizkeytech.CachingServiceCookiesEntity<CachingServiceCookiesKeys>;
    /**
     * Fetches the local-storage entity of this Caching service.
     * @param entityType
     */
    public static of(entityType: Bizkeytech.CachingServiceEntities.localStorage): Bizkeytech.CachingServiceLocalStorageEntity<CachingServiceLocalStorageKeys>;
    /**
     * Fetches the requested entity of this Caching service based on the provided entityType.
     * @param entityType
     */
    public static of(entityType: Bizkeytech.CachingServiceEntities) {
        switch (entityType) {
            default:
                return super.of(entityType as any);
        }
    }
}

export * from "./type-declarations";
export {CachingServiceEntities} from "../../../@bizkeytech";
export default CachingService;

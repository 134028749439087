import React, {useContext} from "react";
import classnames from "classnames";
import {DataGridLayoutContext, DataGridMiscContext} from "../../../index";
import DataGridFooterActions from "./actions";
import DataGridFooterPagination from "./pagination";

const DataGridFooter = ({footerActions, rows}) => {
    const dataGridLayout = useContext(DataGridLayoutContext);
    const {classNames} = useContext(DataGridMiscContext);

    return (
        <>
            {
                !dataGridLayout.hideFooter &&
                <div className={classnames(
                    'data-grid-footer',
                    classNames.footer,
                )}>
                    {
                        !dataGridLayout.hideFooterActions &&
                        <DataGridFooterActions actions={footerActions} rows={rows}/>
                    }
                    {
                        (!dataGridLayout.hidePagination || !dataGridLayout.hidePageSize) &&
                        <DataGridFooterPagination/>
                    }
                </div>
            }
        </>
    )
}

export default DataGridFooter;

import ResizeObserver from 'resize-observer-polyfill';

require('intersection-observer');


/**
 * Houses all the functionality of observers in the DOM.
 * Generally uses the poly-filled version for some observers to provide a broader support of browsers.
 */
class ObserversService {

    //    ################## RESIZE OBSERVER ###############

    /**
     * Creates a new ResizeObserver with the given init callback.
     * @param {ResizeObserverCallback} callback
     * @return {ResizeObserver}
     */
    static newResizeObserver(callback) {
        return new ResizeObserver(callback);
    }

    /**
     * Makes the given observer to observer the given element for resize changes.
     * callback.
     * @param {ResizeObserver} observer
     * @param {Element} element
     * @return {void}
     */
    static observeResizeObserver(observer, element) {
        return observer.observe(element);
    }

    /**
     * Disconnects the given observer by cancelling all of its event listeners.
     *
     * @param {ResizeObserver} observer
     * @return {void}
     */
    static disconnectResizeObserver(observer) {
        return observer.disconnect();
    }

    /**
     * Disconnects the given observer's observer for the specified element.
     *
     * @param {ResizeObserver} observer
     * @param {Element} element
     * @return {void}
     */
    static unobserveResizeObserver(observer, element) {
        return observer.unobserve(element);
    }

    //    ################## INTERSECTION OBSERVER ###############

    /**
     * Creates a new intersectionObserver with the given init callback.
     * @param {IntersectionObserverCallback} callback
     * @param {IntersectionObserverInit?} options
     * @return {IntersectionObserver}
     */
    static newIntersectionObserver(callback, options) {
        return new IntersectionObserver(callback, options);
    }

    /**
     * Starts observing a target element for intersection changes based on the thresholds values.
     *
     * @param {IntersectionObserver} observer
     * @param {Element} element
     * @return {void}
     */
    static observeIntersectionObserver(observer, element) {
        return observer.observe(element);
    }

    /**
     * Stops observing a target element for intersection changes based on the thresholds values.
     *
     * @param {IntersectionObserver} observer
     * @param {Element} element
     * @return {void}
     */
    static unobserveIntersectionObserver(observer, element) {
        return observer.unobserve(element);
    }

    /**
     * Stops observing all target elements for intersection changes.
     *
     * @param {IntersectionObserver} observer
     * @return {void}
     */
    static disconnectIntersectionObserver(observer) {
        return observer.disconnect();
    }
}

export default ObserversService;

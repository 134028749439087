import {RefCallback, useCallback, useEffect, useRef} from "react";

/**
 * Runs the given effect with each change of the ref property returned by this hook.
 *
 * @param {effect: (elm: T) => void | (() => void)} effect the effect that returns a cleanup function.
 * @return {RefCallback<T>}
 */
const useRefEffect = <T, >(effect: (elm: T) => void | (() => void)): RefCallback<T> => {
    const cleanupRef = useRef<null | (() => void)>(null);
    const effectRef = useRef(effect);
    effectRef.current = effect;

    /**
     * The ref callback to be used to retrieve a reference of a React element.
     */
    const ref = useCallback((elm: T) => {
        cleanupRef.current?.();
        if (!elm)
            return;
        const effectCleanup = effectRef.current(elm);
        if (!effectCleanup)
            return;
        cleanupRef.current = () => {
            effectCleanup();
            cleanupRef.current = null;
        };
    }, []);

    /**
     * As soon as the component unmounts:
     * - invokes the cleanup function of this hook.
     */
    useEffect(() => () => cleanupRef.current?.(), []);

    return ref;
}

export default useRefEffect;

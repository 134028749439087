import {AuthenticationService} from "../../../core";
import {useEffect, useMemo, useState} from "react";

/**
 * A custom hook that returns the authorization token of the authenticated user.
 */
const useAuthorizationToken = () => {
    const [authToken, setAuthToken] = useState(AuthenticationService.authenticatedUser?.token);

    /**
     * As soon as the component is mounted:
     * - attaches an event listener that when the authentication token changes, it updates the [authToken] state.
     */
    useEffect(() => AuthenticationService.onAuthenticationTokenChanged(setAuthToken), [])

    return useMemo(() => authToken, [authToken]);
}

export default useAuthorizationToken;

/**
 * The interface that is used to keep the variables used in the .env file or any env related functionality.
 */
class EnvService {

    // ########################### EXISTING ENVIRONMENT VARIABLES ###################################

    /**
     * The Public Url of the application.
     */
    static publicUrl: string = process.env.PUBLIC_URL ?? "";

    /**
     * Determines if the current environment is Development.
     */
    static isDevelopment: boolean = process.env.NODE_ENV === 'development';

    /**
     * Determines if the current environment is Test.
     */
    static isTest: boolean = process.env.NODE_ENV === 'test';

    /**
     * Determines if the current environment is production.
     */
    static isProduction: boolean = process.env.NODE_ENV === 'production';


    // ########################### GENERAL / META-DATA INFO ###################################

    /**
     * Title of the application
     */
    static websiteName: string = process.env.REACT_APP_WEBSITE_NAME ?? ''

    /**
     * Description of the application
     */
    static websiteDescription: string = process.env.REACT_APP_WEBSITE_DESCRIPTION ?? ''

    /**
     * The Twitter's account tag for the creator of this website
     */
    static websiteTwitterCreator: string = process.env.REACT_APP_WEBSITE_TWITTER_CREATOR ?? ''

    /**
     * The primary theme color of the application
     */
    static primaryColor: string = process.env.REACT_APP_PRIMARY_COLOR ?? ''

    /**
     * The secondary theme color of the application
     */
    static secondaryColor: string = process.env.REACT_APP_SECONDARY_COLOR ?? ''

    /**
     * The list of fonts used in the application for styling in index.html
     */
    static fonts: string = process.env.REACT_APP_FONTS ?? ''

    /**
     * The default font name of the application
     */
    static defaultFontName: string = process.env.REACT_APP_DEFAULT_FONT_NAME ?? ''

    // ########################### APIS ###################################

    /**
     *  The base url of our api
     */
    static apiEndpointUrl: string = process.env.REACT_APP_API_ENDPOINT_URL ?? "";

}

export default EnvService;

import {
    DataGridColumnPinnedTypes,
    DataGridColumnWidthTypes,
    DataGridDensities,
    DataGridDispatcherAction,
    DataGridEvents,
    DataGridInternalColumn,
    DataGridInternalState,
    DataGridRow,
    DataGridSelectionEntry,
    DataGridStateActions,
    DataGridStateReducerInitializer,
    DefaultDataGridDensity,
    DefaultDataGridPageSize,
    InitialDataGridCurrentPage
} from "../../../../type-declerations";
import DataGridUtils from "../../../services/utils";
import type DataGridEventEmitter from "../event-emitter";
import type DataGridStateSaver from "../state-saver";

/**
 * The Reducer container for the data grid.
 *
 * * This wrapper is responsible for the logic of manipulating and managing the state of a data grid, and to emit
 * events if necessary.
 */
class DataGridReducer {
    /**
     * Makes the action not to emit any events anymore.
     *
     * * This function is used in places where the reducer itself is returning the same state without any change, and
     * thus we should not emit any event as well.
     * @param {DataGridDispatcherAction} action
     * @private
     */
    private static stopEventPropagation(action: DataGridDispatcherAction) {
        action.emitEvent = false;
    }

    /**
     * Makes the action not to save the state.
     *
     * * This function is used in places where the reducer itself is returning the same state without any change, and
     * thus we should not emit any event as well.
     * @param {DataGridDispatcherAction} action
     * @private
     */
    private static stopSavingTheState(action: DataGridDispatcherAction) {
        action.saveState = false;
    }

    /**
     * Fills the initialState object of the data grid.
     *
     * * replaces the default values for the properties that have them. (i.e. density, pageSizes, etc.)
     * * injects the initializer to the object itself, so it can be used in the reducer if the need arises.
     * * transforms the columns from DataGridColumn to DataGridInternalColumn
     *
     * @param  {Partial<DataGridState>} initialState
     * @param  {DataGridColumn[]} columns
     * @param  {DataGridColumn[]} preliminaryColumns
     * @return {DataGridInternalState}
     */
    private static initializeState({
                                       initialState: _initialState,
                                       columns,
                                       preliminaryColumns,
                                       preliminaryDensity,
                                   }: DataGridStateReducerInitializer): DataGridInternalState {
        const initialState = DataGridUtils.deepCopy(_initialState ?? {});

        const _columns = DataGridUtils.sortColumnsByOrder(
            DataGridUtils.fillColumns(columns, true)
        );

        return {
            ...initialState,
            initialState: DataGridUtils.deepCopy(_initialState ?? {}),

            // state
            density: initialState.density ?? DefaultDataGridDensity,
            preliminaryDensity: preliminaryDensity ?? DefaultDataGridDensity,
            pagination: DataGridUtils.fillPagination(initialState.pagination),
            sortBy: DataGridUtils.fillSortBy(initialState.sortBy),
            loading: DataGridUtils.fillLoading(initialState.loading),

            // columns
            initialColumns: DataGridUtils.deepCopy(columns),
            group: DataGridUtils.fillGroup(_columns, initialState.group),
            columns: _columns,
            preliminaryColumns: preliminaryColumns ?? [],

            // selection
            selectedRows: [],
            excludedRows: [],
            allRowsSelected: false,
        };
    }

    private readonly _eventEmitter: DataGridEventEmitter;
    private readonly _stateSaver: DataGridStateSaver;

    /**
     * Constructs a new DataGridReducer with the provided arguments.
     *
     * @param {DataGridEventEmitter} eventEmitter
     * @param {DataGridStateSaver} stateSaver
     */
    constructor(eventEmitter: DataGridEventEmitter, stateSaver: DataGridStateSaver) {
        this._eventEmitter = eventEmitter;
        this._stateSaver = stateSaver;
    }

    /**
     * The initializer used in the reducer of the ui.
     * @return {(DataGridStateReducerInitializer) => DataGridInternalState}
     */
    get initializer(): (args: DataGridStateReducerInitializer) => DataGridInternalState {
        return DataGridReducer.initializeState;
    }

    /**
     * Sets the events for the dispatcher of this reducer.
     * @param {DataGridEvents} events
     */
    set events(events: DataGridEvents) {
        this._eventEmitter.events = events;
    }

    /**
     * Sets the state saver's information of this reducer.
     * @param name      the name of the data-grid.
     * @param version   the current version of the data-grid's name.
     * @param appName
     */
    set stateSaverInfo({name, version, appName}: { appName?: string, name?: string, version?: number }) {
        this._stateSaver.name = name;
        this._stateSaver.version = version;
        this._stateSaver.appName = appName;
    }

    /**
     * The logic that is to be passed into the React reducer.
     */
    get reducer() {
        return this._reducer.bind(this);
    }

    /**
     * Reducer for the Data Grid state.
     *
     * This reducer manages the state of a data grid and is used in either of the two ways:
     *  * dispatches from the ui components within data-grid (internal usage)
     *  * dispatches from the api model of the data grid (internal usage)
     *
     * @param {DataGridInternalState} state
     * @param {{type: DataGridStateActions, payload: any}} action
     * @return {DataGridInternalState}
     * @private
     */
    _reducer(state: DataGridInternalState, action?: DataGridDispatcherAction): DataGridInternalState {
        if (!action)
            // to conform to React's reducer. In reality, this reducer will never be called without an action.
            return state;

        // ensure that we are automatically saving the state unless in reduction the flag is switched.
        action.saveState = true;

        const prevState = DataGridUtils.deepCopy(state);
        // obtain the new state
        const newState = this.reduce(state, action);
        // Emit an event if needed in another thread
        this._eventEmitter.emitEvent(action, prevState, newState).then();
        // save the state if necessary
        this._stateSaver.onStateChanged(action, prevState, newState).then();

        return newState;
    }


    /**
     * Performs the logic of this reducer, and returns the new state.
     *
     * @param {DataGridInternalState} state
     * @param {{type: DataGridStateActions, payload: any}} action
     * @return {DataGridInternalState}
     * @private
     */
    private reduce(state: DataGridInternalState, action: DataGridDispatcherAction): DataGridInternalState {
        switch (action.type) {
            /**
             * Checks to see if any new columns have been added or any of the existing ones have been removed through
             * their name.
             *
             * if false --> merge the existing columns, and then add the newly added columns
             * if true  --> reinitialize the whole state and refresh the layout
             */
            case DataGridStateActions.columnsPropsChanged: {
                if (DataGridUtils.deepEqual(state.initialColumns, action.payload)) {
                    // if the same, do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }

                const newFilledColumns = DataGridUtils.sortColumnsByOrder(
                    DataGridUtils.fillColumns(action.payload ?? [], true)
                );
                const prevInitFilledColumns = DataGridUtils.sortColumnsByOrder(
                    DataGridUtils.fillColumns(state.initialColumns ?? [], true)
                );


                const currColumnNames = state.columns.map(e => e.name);
                const newColumns: DataGridInternalColumn[] = [];

                let i = 0;
                // we use iterate over state.columns so the current order gets preserved.
                for (const currColumn of state.columns) {
                    const currInitialColumn = prevInitFilledColumns.find(e => e.name === currColumn.name) as DataGridInternalColumn;
                    const newColumn = newFilledColumns.find(e => e.name === currColumn.name) as DataGridInternalColumn;

                    // column was removed
                    if (!newColumn) {
                        continue;
                    }

                    newColumns[i] = currColumn;

                    if (DataGridUtils.deepEqual(newColumn, currInitialColumn)) {
                        // since the column prop has not changed since its last update, we just use the current
                        // column values
                        i++;
                        continue;
                    }

                    const discrepantProperties = Object.keys(newColumn)
                        // @ts-ignore
                        .filter(colProperty => !DataGridUtils.deepEqual(newColumn[colProperty], currInitialColumn[colProperty]))

                    // the properties that have changed can now be replaced.
                    for (const colProperty of discrepantProperties) {
                        // @ts-ignore
                        newColumns[i][colProperty] = newColumn[colProperty];
                    }
                    i++;
                }

                // newly added columns
                const remainingColumns = newFilledColumns.filter(e => !currColumnNames.includes(e.name));
                for (const remainingColumn of remainingColumns) {
                    newColumns.forEach((column) => {
                        if (column.order === remainingColumn.order) {
                            // if the order is the same, then increase the order of the remaining column. then again if next is the
                            // same, same thing automatically happens in this if statement.
                            remainingColumn.order += 1;
                        }
                        if (column.pinnedType === remainingColumn.pinnedType &&
                            column.pinned &&
                            column.pinnedOrder === remainingColumn.pinnedOrder) {
                            remainingColumn.pinnedOrder += 1;
                        }
                    })
                    newColumns.push(remainingColumn);
                }

                return {
                    ...state,
                    columns: DataGridUtils.sortColumnsByOrder(newColumns),
                    initialColumns: DataGridUtils.deepCopy(action.payload)
                };
            }
            /**
             * Changes the value of the preliminary columns of the state.
             */
            case DataGridStateActions.preliminaryColumnsChanged: {
                if (DataGridUtils.deepEqual(state.preliminaryColumns, action.payload ?? [])) {
                    // if the same, do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }
                return {
                    ...state,
                    preliminaryColumns: action.payload ?? [],
                }
            }
            /**
             * Changes the value of the preliminary density of the state.
             */
            case DataGridStateActions.preliminaryDensityChanged: {
                if (DataGridUtils.deepEqual(state.preliminaryDensity, action.payload ?? [])) {
                    // if the same, do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }
                return {
                    ...state,
                    preliminaryDensity: action.payload ?? DefaultDataGridDensity,
                }
            }
            /**
             * Reevaluates the state of the data from the properties of the new state.
             * * the initialState property shall be the exact given object.
             */
            case DataGridStateActions.statePropsChanged:
                if (DataGridUtils.deepEqual(state.initialState, action.payload)) {
                    // if the same, do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }
                // pagination, sortBy, loading, density, so just merge the new values
                return {
                    ...state,
                    initialState: DataGridUtils.deepCopy(action.payload ?? {}),
                    density: action.payload?.density ?? DefaultDataGridDensity,
                    pagination: DataGridUtils.fillPagination(action.payload?.pagination),
                    group: DataGridUtils.fillGroup(state.columns, action.payload?.group),
                    sortBy: DataGridUtils.fillSortBy(action.payload?.sortBy),
                    loading: DataGridUtils.fillLoading(action.payload?.loading),
                }
            /**
             * Sets the page size of the state's pagination.
             * * if the given value is not a number, then replaces it with the DefaultDataGridPageSize
             */
            case DataGridStateActions.setPageSize:
                if (!Number.isInteger(action.payload)) {
                    action.payload = DefaultDataGridPageSize;
                }
                action.payload = Math.max(action.payload, 0);
                let currentPage = state.pagination.currentPage;
                while (currentPage * action.payload > state.pagination.length && currentPage > 0) {
                    currentPage--;
                }
                return {
                    ...state,
                    pagination: DataGridUtils.fillPagination({
                        ...state.pagination,
                        pageSize: action.payload,
                        currentPage: currentPage,
                    })
                }
            /**
             * Sets the length of the state's pagination.
             * * if the given value is not a number, then replaces it with the 0
             */
            case DataGridStateActions.setPageLength:
                if (!Number.isInteger(action.payload)) {
                    action.payload = 0;
                }
                action.payload = Math.max(action.payload, 0);
                return {
                    ...state,
                    pagination: DataGridUtils.fillPagination({...state.pagination, length: action.payload})
                }
            /**
             * Sets the current page of the state's pagination.
             * * if the given value is not a number, then replaces it with 1, since the first page is 1.
             */
            case DataGridStateActions.setCurrentPage:
                if (!Number.isInteger(action.payload)) {
                    action.payload = InitialDataGridCurrentPage;
                }
                action.payload = Math.max(action.payload, InitialDataGridCurrentPage);
                return {
                    ...state,
                    pagination: DataGridUtils.fillPagination({...state.pagination, currentPage: action.payload})
                }
            /**
             * Sets the sort by of the state.
             */
            case DataGridStateActions.setSortBy:
                return {
                    ...state,
                    sortBy: DataGridUtils.fillSortBy(action.payload)
                }
            /**
             * Sets the sort by of the state.
             * * if the given value is not of type DataGridDensities, DefaultDataGridDensity is replaced with it.
             */
            case DataGridStateActions.setDensity:
                if (!Object.values(DataGridDensities).includes(action.payload)) {
                    action.payload = DefaultDataGridDensity;
                }
                return {
                    ...state,
                    density: action.payload,
                }
            /**
             * Toggles the visibilities of the given columns.
             *
             * * only the columns that allow toggling visibility shall be changed.
             * * only the column names included in the given payload shall be changed.
             * * if the column with which the data-grid is ordered by is not visible anymore, remove the sortBy
             *      property as well.
             */
            case DataGridStateActions.toggleColumnsVisibility: {
                action.payload = action.payload ?? {};
                const names = Object.keys(action.payload).filter(e => state.columns.find(c => c.name === e)?.visibilityToggleable);
                if (!names.length) {
                    // if no column is allowed to change, then do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }
                // if the column with which the data-grid is ordered by is not visible anymore, remove the sortBy
                // property as well.
                if (state.sortBy?.field &&
                    names.includes(state.sortBy?.field) &&
                    !action.payload[state.sortBy?.field]) {
                    state = {
                        ...state,
                        sortBy: undefined,
                    }
                }
                const newColumns = state.columns?.map(e => names.includes(e.name)
                    ? {...e, visible: !!action.payload[e.name]}
                    : e
                ) ?? []
                return {
                    ...state,
                    columns: newColumns,
                }
            }
            /**
             * Toggles the Pinned state of the given columns.
             *
             * * only the columns that allow toggling pinned state shall be changed.
             * * only the column names included in the given payload shall be changed.
             * * in case of adding a new pinned column, they will receive the highest pinnedOrder and in case of
             * removing an existing one, their pinnedColumn shall be undefined.
             */
            case DataGridStateActions.togglePinnedColumns: {
                action.payload = action.payload ?? {};
                const names = Object.keys(action.payload).filter(e => {
                    const found = state.columns.find(c => c.name === e);
                    return found?.pinnedToggleable?.right || found?.pinnedToggleable?.left;
                });
                if (!names.length) {
                    // if no column is allowed to change, then do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }

                let maxPinnedOrderLeft = Math.max(
                    ...(state.columns
                        ?.filter(e => !isNaN(e.pinnedOrder) && e.pinnedType === DataGridColumnPinnedTypes.left)
                        ?.map(e => e.pinnedOrder) ?? [])
                )
                let maxPinnedOrderRight = Math.max(
                    ...(state.columns
                        ?.filter(e => !isNaN(e.pinnedOrder) && e.pinnedType === DataGridColumnPinnedTypes.right)
                        ?.map(e => e.pinnedOrder) ?? [])
                )

                const newColumns = DataGridUtils.sortColumnsByOrder(state.columns?.map((e) => {
                    if (!names.includes(e.name))
                        return e;
                    const isPinned = !!action.payload[e.name];
                    let pinnedOrder;
                    switch (action.payload[e.name]) {
                        case  DataGridColumnPinnedTypes.left:
                            pinnedOrder = ++maxPinnedOrderLeft;
                            break;
                        case  DataGridColumnPinnedTypes.right:
                            pinnedOrder = ++maxPinnedOrderRight;
                            break;
                        default:
                            pinnedOrder = undefined;
                            break;
                    }
                    const pinnedToggleable = e.pinnedToggleable?.left || e.pinnedToggleable?.right;
                    // make the pinned columns not to be reorderable.
                    const reorderable = isPinned ? false : pinnedToggleable;
                    return {
                        ...e,
                        pinned: isPinned,
                        pinnedType: action.payload[e.name],
                        reorderable: reorderable,
                        pinnedOrder: pinnedOrder,
                    }
                }) ?? []);
                return {
                    ...state,
                    columns: newColumns,
                }
            }
            /**
             * Refreshes the layout of the data grid.
             *
             *  the layout to be refreshed are:
             *  - density
             *  - column ordering, pinned state, visibility
             */
            case DataGridStateActions.refreshLayout:
                // layout is density, column ordering, pinned columns, column visibility,
                return {
                    ...state,
                    density: state.preliminaryDensity,
                    columns: DataGridUtils.sortColumnsByOrder(DataGridUtils.fillColumns(state.preliminaryColumns ?? [], true)),
                }
            /**
             * Reorders the columns based on the given column names and their new orders (payload).
             * * only the order of the un-pinned columns may be changed
             */
            case DataGridStateActions.reorderColumns: {

                const orders: Record<string, number> = action.payload ?? {};
                const reorderableColumnNames = Object.keys(orders).filter(e => state.columns.find(c => c.name === e)?.reorderable);
                if (!reorderableColumnNames.length) {
                    // if no column is allowed to change, then do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }

                for (const column of state.columns.filter(e => reorderableColumnNames.includes(e.name))) {
                    state.columns = state.columns.map((e, _, array) => {
                        if (e.name === column.name)
                            return {...e, order: orders[e.name]}
                        if (e.pinnedType === DataGridColumnPinnedTypes.right)
                            return {...e, order: e.order + array.length};
                        if (e.pinnedType === DataGridColumnPinnedTypes.left)
                            return {...e, order: e.order - array.length};
                        if (e.order <= orders[column.name])
                            return {...e, order: e.order - 1}
                        if (e.order > orders[column.name])
                            return {...e, order: e.order + 1}
                        return e;
                    });
                }

                return {
                    ...state,
                    columns: DataGridUtils.sortColumnsByOrder(state.columns),
                }
            }
            /**
             * Sets the order of the columns based on the provided column names list.
             *
             * * only the order of the un-pinned columns may be changed
             */
            case DataGridStateActions.setAllColumnsOrder: {
                const columnNames: Array<string> = action.payload ?? [];
                const existingColumnNames = state.columns.map(e => e.name);

                if (DataGridUtils.deepEqual(existingColumnNames, columnNames)) {
                    // if no column is allowed to change, then do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }

                for (const column of state.columns.filter(e => !columnNames.includes(e.name))) {
                    columnNames.splice(column.order - 1, 0, column.name);
                }

                return {
                    ...state,
                    columns: DataGridUtils.sortColumnsByOrder(
                        columnNames
                            .filter(e => existingColumnNames.includes(e))
                            .map((colName) => ({
                                ...state.columns.find(e => e.name === colName),
                                // order here is undefined so that the DataGridUtils.sortColumnsByOrder method
                                // imputes them
                                order: undefined,
                            }))
                    ),
                }
            }
            /**
             * Toggles the selected rows of this data grid.
             *
             * * if the count of selected rows in between the range 0 to total - 1, then toggles the allRowsSelected to false
             * * if the count of te selected rows is equal to the total and not 0, then toggles the allRowsSelected to true
             */
            case DataGridStateActions.toggleSelectedRows: {
                let selectedRows: DataGridSelectionEntry[] = state.selectedRows ?? [];
                let excludedRows: DataGridSelectionEntry[] = state.excludedRows ?? [];

                Object.entries(action.payload.keySelection).forEach(([key, selected]) => {
                    const row = action.payload.rows?.find((e: DataGridRow) => e.key.toString() === key);
                    if (!selected) {
                        const index = selectedRows.findIndex(e =>
                            typeof e.key !== 'undefined' &&
                            e.key !== null &&
                            e.key.toString() === key
                        );
                        if (index !== -1) {
                            selectedRows.splice(index, 1);
                        }
                        if (row && state.allRowsSelected) {
                            excludedRows.push(row)
                        }
                    } else {
                        const index = excludedRows.findIndex(e =>
                            typeof e.key !== 'undefined' &&
                            e.key !== null &&
                            e.key.toString() === key
                        );
                        if (index !== -1) {
                            excludedRows.splice(index, 1);
                        }
                        if (row && !state.allRowsSelected) {
                            selectedRows.push(row)
                        }
                    }
                })

                selectedRows = Array.from(new Set(selectedRows.map(e => e.key)).values()).map(e => (selectedRows.find(r => r.key === e) as DataGridRow));
                excludedRows = Array.from(new Set(excludedRows.map(e => e.key)).values()).map(e => (excludedRows.find(r => r.key === e) as DataGridRow));

                let allRowsSelected = state.allRowsSelected;
                if (!allRowsSelected) {
                    if (state.pagination.length !== 0 && state.pagination.length === selectedRows.length) {
                        allRowsSelected = true;
                        selectedRows = [];
                        excludedRows = [];
                    }
                } else {
                    if (state.pagination.length === excludedRows.length) {
                        allRowsSelected = false;
                        selectedRows = [];
                        excludedRows = [];
                    }
                }
                return {
                    ...state,
                    excludedRows,
                    selectedRows,
                    allRowsSelected
                }
            }
            /**
             * Toggles the AllRowsSelected of the data grid.
             *
             * * removes the list of selectedRows and excludedRows as well since all-selection features are api-related
             * and there is no point in keeping the selected state.
             */
            case DataGridStateActions.toggleAllRowsSelection: {
                const checked = DataGridUtils.areAllRowsSelected(
                    state.allRowsSelected,
                    state.selectedRows.length,
                    state.excludedRows.length,
                    state.pagination.length
                );
                if (action.payload === checked) {
                    // we do not want to flush the lists if the value is the same.
                    return state;
                }

                return {
                    ...state,
                    selectedRows: [],
                    excludedRows: [],
                    allRowsSelected: action.payload
                }
            }
            /**
             * Resets the selection state of the data-grid.
             */
            case DataGridStateActions.resetSelection: {
                return {
                    ...state,
                    selectedRows: [],
                    excludedRows: [],
                    allRowsSelected: false,
                }
            }
            /**
             * Resizes the columns of this data grid.
             *
             * * only the columns that allow resizing shall be changed.
             * * only the column names included in the given payload shall be changed.
             */
            case DataGridStateActions.resizeColumns: {
                action.payload = action.payload ?? {};
                const names = Object.keys(action.payload).filter(e => state.columns.find(c => c.name === e)?.resizable);
                if (!names.length) {
                    // if no column is allowed to change, then do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }
                return {
                    ...state,
                    columns: state.columns?.map(e => names.includes(e.name)
                        ? {...e, width: DataGridUtils.fillColumnWidth(e, action.payload[e.name])}
                        : e
                    ) ?? [],
                }
            }
            /**
             * Resizes the columns of this data grid by the specified width.
             *
             * * only the columns that allow resizing shall be changed.
             * * only the column names included in the given payload shall be changed.
             */
            case DataGridStateActions.resizeColumnsBy: {
                action.payload = action.payload ?? {};
                const names = Object.keys(action.payload).filter(e => {
                    const column = state.columns.find(c => c.name === e)
                    return column?.resizable && column.width.type !== DataGridColumnWidthTypes.flex
                });
                if (!names.length) {
                    // if no column is allowed to change, then do nothing.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }

                const columns = state.columns?.map(e => names.includes(e.name)
                    ? {
                        ...e,
                        width: DataGridUtils.fillColumnWidth(e, {
                            ...e.width,
                            size: e.width.size + action.payload[e.name]
                        })
                    }
                    : e
                ) ?? [];

                if (DataGridUtils.deepEqual(columns.map(e => e.width), state.columns.map(e => e.width))) {
                    // no change in the width.
                    DataGridReducer.stopEventPropagation(action);
                    DataGridReducer.stopSavingTheState(action);
                    return state;
                }
                return {
                    ...state,
                    columns: columns,
                }
            }
            /**
             * Loads a data grid configuration for this data grid.
             */
            case DataGridStateActions.loadConfiguration:
                return {
                    ...state,
                    ...action.payload,
                };

            default:
                return state;
        }
    }

}

export default DataGridReducer;

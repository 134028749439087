import {EnvService} from "../../../../base/core";


/**
 * @inheritDoc
 */
class DataGridEnvService extends EnvService {

    /**
     *  @inheritDoc
     */
    static apiEndpointUrl: string = process.env.REACT_APP_DATA_GRID_API_ENDPOINT_URL ?? "";
}

export default DataGridEnvService;

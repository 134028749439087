import React, {useEffect, useMemo} from 'react';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import theme from "./theme";
import type {History} from '@remix-run/router';
import ApplicationViews from "./views";
import {
    ApplicationToast,
    BizkeytechDialogs,
    BizkeytechReduxStore,
    BizkeyTechTheme,
    DataGridProvider,
    DynamicColumnApplications,
    DynamicColumnsProvider,
    DynamicColumnsProviderProps,
    QueryBuilderProvider,
    SEO as ApplicationSEO,
    useAuthorizationToken
} from "../@bizkeytech";
import {Experimental_CssVarsProvider as CssVarsProvider,} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import ApplicationMiddlewares from "./middlewares";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import {Provider as ReduxProvider} from 'react-redux';
import ApiService from '../core/services/api';
import UIUtils from "./utils";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import ApplicationDialogs from "./dialogs";
import ReduxStore from "./redux";
import {HelmetProvider} from "react-helmet-async";
import EnvService from "../core/services/env";
import ApiEndpoints from "../core/models/api-endpoints";
import CachingService, {CachingServiceEntities, CachingServiceLocalStorageKeys} from "../core/services/caching";


const Application = () => {
    const history = useMemo(() => createBrowserHistory() as unknown as History, [])
    const combinedTheme = useMemo(() => BizkeyTechTheme.combineTheme(theme), []);
    const authorizationToken = useAuthorizationToken();

    /**
     * Injects the authentication token in the request headers of the API service.
     */
    useEffect(() => {
        ApiService.injectInterceptors({
            request: [[
                (config) => {
                    config.headers.Authorization = config.headers.Authorization ?? authorizationToken;
                    return config;
                }
            ]],
        })
    }, [authorizationToken]);

    const dynamicColumnsFetchColumnsMap = useMemo<DynamicColumnsProviderProps['fetchColumnsMap']>(() => ({
        [DynamicColumnApplications.default]: ApiService.fetchDynamicColumns.bind(ApiService),
    }), [])

    const dynamicColumnsGetFilterOptionsMap = useMemo<DynamicColumnsProviderProps['getFilterOptionsMap']>(() => ({
        [DynamicColumnApplications.default]: ApiEndpoints.reportingTools.baseUrl,
    }), [])


    return (
        <HelmetProvider>
            <HistoryRouter basename={EnvService.publicUrl} history={history}>
                <ApplicationSEO/>
                <CssVarsProvider theme={combinedTheme} defaultMode={'system'}>
                    <CssBaseline enableColorScheme/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DynamicColumnsProvider
                            fetchColumnsMap={dynamicColumnsFetchColumnsMap}
                            getFilterOptionsMap={dynamicColumnsGetFilterOptionsMap}
                        >
                            <QueryBuilderProvider
                                //authorizationToken={authorizationToken} // TODO: uncomment when Save Component APIs are available.
                                applicationName={EnvService.servicesApplicationName}
                            >
                                <DataGridProvider
                                    //authorizationToken={authorizationToken} // TODO: uncomment when Save Component APIs are available.
                                    applicationName={EnvService.servicesApplicationName}
                                    storageKey={CachingService.of(CachingServiceEntities.localStorage).get(CachingServiceLocalStorageKeys.dataGridSavedState)}
                                >
                                    <ReduxProvider store={BizkeytechReduxStore}>
                                        <BizkeytechDialogs/>
                                    </ReduxProvider>
                                    <ReduxProvider store={ReduxStore}>
                                        <ApplicationViews/>
                                        <ApplicationDialogs/>
                                    </ReduxProvider>
                                    <ApplicationMiddlewares/>
                                    <ApplicationToast
                                        containerId={UIUtils.ToastContainerIDs.default}
                                        position={'top-right'}
                                        closeOnClick
                                    />
                                </DataGridProvider>
                            </QueryBuilderProvider>
                        </DynamicColumnsProvider>
                    </LocalizationProvider>
                </CssVarsProvider>
            </HistoryRouter>
        </HelmetProvider>
    );
}

export default Application;

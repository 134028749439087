import {AppBar, Box, Breakpoint, Divider, Drawer, drawerClasses, IconButton, Theme, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import {
    createContext,
    Dispatch,
    FunctionComponent,
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {KeyboardArrowLeftRounded, KeyboardArrowRightRounded, Menu} from "@mui/icons-material";
import {darken} from "@mui/system/colorManipulator";
import classNames from "classnames";
import UIUtils from "../../../utils";


export interface PanelContextState {
    sidebarCollapsed: boolean,
    sidebarOpen: boolean,
    sidebarHovered: boolean,
    breakpoints: PanelBreakPoints,
    sidebarWidth: PanelSidebarWidth,
    setSidebarOpen: Dispatch<SetStateAction<boolean>>,
    setSidebarCollapsed: Dispatch<SetStateAction<boolean>>,
    footerCounter: number,
    setFooterCounter: Dispatch<SetStateAction<number>>,
}

/**
 * The class names used for the panel.
 */
export const panelClasses = {
    collapsed: 'collapsed',
}

/**
 * The context of the panel for handling its state by its children.
 */
const PanelContext = createContext<PanelContextState>({
    sidebarCollapsed: false,
    sidebarOpen: false,
    sidebarHovered: false,
    setSidebarOpen: () => false,
    setSidebarCollapsed: () => false,
    breakpoints: {
        mobileView: 'sm',
        tabletView: 'lg',
    },
    sidebarWidth: {
        mobileView: 220,
        tabletView: 70,
        desktopView: 220,
    },
    footerCounter: 0,
    setFooterCounter: () => 0,
});


export interface PanelBreakPoints {
    mobileView: Breakpoint,
    tabletView: Breakpoint,
}

export interface PanelSidebarWidth {
    tabletView: number,
    desktopView: number,
    mobileView: number,
}

export interface PanelProps {
    sidebarWidth?: Partial<PanelSidebarWidth>;
    breakpoints?: Partial<Pick<PanelBreakPoints, 'mobileView' | 'tabletView'>>;
    appbar?: ReactNode,
    sidebar?: ReactNode,
}

/**
 * A convenient wrapper hook to get the panel context and its state.
 */
export const usePanel: () => PanelContextState = () => {
    return useContext(PanelContext);
}

export const Panel: FunctionComponent<PropsWithChildren<PanelProps>> = (props) => {
    const theme = useTheme();
    const collapseButtonRef = useRef<HTMLButtonElement>(null);
    const sidebarRef = useRef<HTMLElement>(null);

    /**
     * As soon as the [theme] is ready or when it changes:
     * the background color of the body is updated.
     */
    useLayoutEffect(() => {
        const body = document.body;
        if (body) {
            body.style.backgroundColor = theme.palette.background.paper;
        }
    }, [theme]);

    const sidebarWidth = useMemo<PanelSidebarWidth>(() => ({
        mobileView: props.sidebarWidth?.mobileView ?? 220,
        tabletView: props.sidebarWidth?.tabletView ?? 70,
        desktopView: props.sidebarWidth?.desktopView ?? 220,
    }), [props.sidebarWidth])

    const breakpoints = useMemo<PanelBreakPoints>(() => ({
        mobileView: props.breakpoints?.mobileView ?? 'sm',
        tabletView: props.breakpoints?.tabletView ?? 'lg',
    }), [props.breakpoints])

    const inMobileView = useMediaQuery(theme.breakpoints.down(breakpoints.mobileView));
    const inTabletView = useMediaQuery(theme.breakpoints.between(breakpoints.mobileView, breakpoints.tabletView));
    const inDesktopView = useMediaQuery(theme.breakpoints.up(breakpoints.tabletView));

    const [sidebarOpen, setSidebarOpen] = useState(!inMobileView);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(inTabletView);
    const [sidebarHovered, setSidebarHovered] = useState(false);
    const [footerCounter, setFooterCounter] = useState(0);

    const panelContextValue = useMemo<PanelContextState>(() => ({
        sidebarCollapsed: sidebarCollapsed && !sidebarHovered,
        sidebarHovered: sidebarHovered,
        sidebarOpen: sidebarOpen,
        breakpoints: breakpoints,
        sidebarWidth: sidebarWidth,
        setSidebarOpen: setSidebarOpen,
        setSidebarCollapsed: setSidebarCollapsed,
        footerCounter: footerCounter,
        setFooterCounter: setFooterCounter,
    }), [sidebarCollapsed, sidebarOpen, breakpoints, sidebarHovered, sidebarWidth, footerCounter]);

    const footerExists = useMemo(() => footerCounter > 0, [footerCounter]);

    /**
     * With each change of the [inMobileView] state:
     * - the sidebar is opened if the view is not in mobile view
     */
    useEffect(() => setSidebarOpen(!inMobileView), [inMobileView, inTabletView, inDesktopView]);

    /**
     * With each change of the [inTabletView] state:
     * - the sidebar is collapsed if the view is in tablet view
     */
    useEffect(() => setSidebarCollapsed(inTabletView), [inTabletView]);


    /**
     * Sets the [sidebarHovered] state to false if the cursor leaves the collapse button and the sidebar together.
     */
    const onCollapseButtonMouseLeave = useCallback((e: MouseEvent<HTMLElement>) => {
        if (e.relatedTarget === sidebarRef.current) {
            return;
        }
        setSidebarHovered(false);
    }, []);

    /**
     * Sets the [sidebarCollapsed] state to its opposite and the [sidebarHovered] state to false.
     */
    const onCollapseButtonClicked = useCallback(() => {
        setSidebarCollapsed(prevState => !prevState);
        setSidebarHovered(false);
    }, []);

    /**
     * Sets the [sidebarHovered] state to true if the cursor enters the sidebar.
     */
    const onSidebarMouseEnter = useCallback(() => {
        setSidebarHovered(true);
    }, []);

    /**
     * Sets the [sidebarHovered] state to false if the cursor leaves the sidebar and the collapse button is not hovered.
     */
    const onSidebarMouseLeave = useCallback((e: MouseEvent<HTMLElement>) => {
        if (e.relatedTarget === collapseButtonRef.current) {
            return;
        }
        setSidebarHovered(false);
    }, []);

    /**
     * Sets the [sidebarHovered] state to true if the sidebar is touched.
     */
    const onSidebarTouchStart = useCallback(() => setSidebarHovered(true), []);


    /**
     * Creates the styles for the panel component.
     *
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the panel
     */
    const panelMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        display: 'flex',
        width: '100vw',
        minHeight: '100vh',
        overflowY: 'hidden',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            padding: theme.spacing(2),
        },
    }), [breakpoints])

    /**
     * Creates the styles for the appbar component.
     *
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the appbar
     */
    const appbarMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        marginInlineStart: theme.spacing(2),
        backgroundImage: 'none',
        boxShadow: 'none',
        transition: 'none',
        backgroundColor: 'background.paper',
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            transition: theme.transitions.create(['width', 'box-shadow', 'margin-inline-start']),
            top: theme.spacing(2),
            right: theme.spacing(2),
        },
        ...((inTabletView || sidebarCollapsed) && {
            width: `calc(100% - ${sidebarWidth.tabletView}px - ${theme.spacing(4)})`,
            marginInlineStart: `calc(${sidebarWidth.tabletView}px + ${theme.spacing(2)})`,
        }),
        ...((inDesktopView && !sidebarCollapsed) && {
            width: `calc(100% - ${sidebarWidth.desktopView}px - ${theme.spacing(4)})`,
            marginInlineStart: `calc(${sidebarWidth.desktopView}px + ${theme.spacing(2)})`,
        })
    }), [breakpoints.mobileView, inDesktopView, inTabletView, sidebarCollapsed, sidebarWidth.desktopView, sidebarWidth.tabletView])

    const ToolbarMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        ...(theme.palette.mode === 'dark' && {
            backgroundColor: darken(theme.palette.background.paper, 0.2),
        }),
        ...(theme.palette.mode === 'light' && {
            backgroundColor: darken(theme.palette.background.default, 0.02),
        }),
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            borderStartStartRadius: 20,
            borderStartEndRadius: 20,
            borderEndStartRadius: 0,
            borderEndEndRadius: 0,
        },
    }), [breakpoints.mobileView])

    const collapseButtonMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        display: "none",
        transition: theme.transitions.create(['left', 'background-color', 'color']),
        ...(inDesktopView && {
            display: "inline-flex",
            position: "fixed",
            top: `calc(${theme.spacing(4)} + ${theme.spacing(2)})`,
            transform: "translateX(-50%) translateY(-50%)",
            zIndex: theme.zIndex.drawer + 3,
            backgroundColor: `rgba(${UIUtils.themeVar(`palette-action-activeChannel`)} / ${UIUtils.themeVar(`palette-action-hoverOpacity`)})`,
            '&:hover': {
                color: theme.palette.background.paper,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        }),
        ...(inDesktopView && sidebarCollapsed && sidebarHovered && {
            border: `1px solid ${theme.palette.divider}`,
        }),
        ...((inTabletView || (sidebarCollapsed && !sidebarHovered)) && {
            left: `calc(${sidebarWidth.tabletView}px + ${theme.spacing(2)})`,
        }),
        ...((inDesktopView && (!sidebarCollapsed || sidebarHovered)) && {
            left: `calc(${sidebarWidth.desktopView}px + ${theme.spacing(2)})`,
        })
    }), [inDesktopView, inTabletView, sidebarCollapsed, sidebarWidth.desktopView, sidebarWidth.tabletView, sidebarHovered]);

    /**
     * Creates the styles for the sidebar nav component.
     *
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar nav
     */
    const sidebarNavMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        ...(inMobileView && {
            width: 0,
        }),
        ...((inTabletView || sidebarCollapsed) && {
            width: sidebarWidth.tabletView,
        }),
        ...((inDesktopView && !sidebarCollapsed) && {
            width: sidebarWidth.desktopView,
        }),
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            transition: theme.transitions.create(['width']),
        },
    }), [breakpoints.mobileView, inDesktopView, inMobileView, inTabletView, sidebarCollapsed, sidebarWidth.desktopView, sidebarWidth.tabletView]);

    /**
     * Creates the styles for the sidebar drawer component.
     *
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar drawer
     */
    const sidebarDrawerMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        transition: theme.transitions.create(['width']),
        [`& .${drawerClasses.paper}`]: {
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.paper,
        },
        ...(inMobileView && {
            [`& .${drawerClasses.paper}`]: {
                width: sidebarWidth.mobileView,
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.paper,
            },
        }),
        ...((inTabletView || (sidebarCollapsed && !sidebarHovered)) && {
            [`& .${drawerClasses.paper}`]: {
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.paper,
                width: sidebarWidth.tabletView,
            },
            [`& .${drawerClasses.paper}:hover`]: {
                // for hovering effect of the collapsed sidebar
                width: sidebarWidth.desktopView,
                borderInlineEnd: `1px solid ${theme.palette.divider}`,
            },
        }),
        ...((inDesktopView && (!sidebarCollapsed || sidebarHovered)) && {
            [`& .${drawerClasses.paper}`]: {
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.paper,
                width: sidebarWidth.desktopView,
            }
        }),
        [theme.breakpoints.down(breakpoints.mobileView)]: {
            [`& .${drawerClasses.paper}`]: {
                paddingInlineStart: theme.spacing(2),
                paddingBlockStart: theme.spacing(2),
                paddingBlockEnd: theme.spacing(2),
                backgroundImage: 'none',
            },
        },
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            [`& .${drawerClasses.paper}`]: {
                transition: theme.transitions.create(['width']),
                top: theme.spacing(2),
                left: theme.spacing(2),
                height: `calc(100% - ${theme.spacing(4)})`,
                borderInlineEnd: 'none',
            },
        },
    }), [sidebarHovered, breakpoints.mobileView, inDesktopView, inMobileView, inTabletView, sidebarCollapsed, sidebarWidth.desktopView, sidebarWidth.mobileView, sidebarWidth.tabletView]);

    /**
     * Creates the styles for the content component.
     *
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the content
     */
    const contentMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        flexGrow: 1,
        padding: 3,
        width: '100%',
        backgroundColor: theme.palette.background.default,
        height: `100vh`,
        overflowY: 'auto',
        transition: 'none',
        ...((inTabletView || sidebarCollapsed) && {
            width: `calc(100% - ${sidebarWidth.tabletView}px)`,
        }),
        ...((inDesktopView && !sidebarCollapsed) && {
            width: `calc(100% - ${sidebarWidth.desktopView}px)`,
        }),
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            transition: theme.transitions.create(['width']),
            height: `calc(100vh - ${theme.spacing(4)})`,
            borderRadius: '20px',
        },
    }), [breakpoints.mobileView, inDesktopView, inTabletView, sidebarCollapsed, sidebarWidth.desktopView, sidebarWidth.tabletView])

    /**
     * The styles for the divider 1.
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the dividers
     */
    const divider1Mixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        display: "block",
        transition: theme.transitions.create(['left', 'border-color']),
        borderColor: 'transparent',
        position: "fixed",
        top: `calc(${theme.spacing(4)} - 1px)`,
        height: `calc(${theme.spacing(4)} + 2px)`,
        transform: "translateX(-50%)",
        zIndex: theme.zIndex.drawer + 2,
        ...(inDesktopView && sidebarCollapsed && sidebarHovered && {
            borderColor: 'background.paper',
        }),
        ...((inTabletView || (sidebarCollapsed && !sidebarHovered)) && {
            left: `calc(${sidebarWidth.tabletView}px + ${theme.spacing(2)})`,
        }),
        ...((inDesktopView && (!sidebarCollapsed || sidebarHovered)) && {
            left: `calc(${sidebarWidth.desktopView}px + ${theme.spacing(2)})`,
        })
    }), [inDesktopView, sidebarCollapsed, sidebarHovered, inTabletView, sidebarWidth.tabletView, sidebarWidth.desktopView])

    /**
     * The styles for the divider 2.
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the dividers
     */
    const divider2Mixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        display: "block",
        transition: theme.transitions.create(['left', 'border-color']),
        borderColor: 'transparent',
        ...(inDesktopView && sidebarCollapsed && sidebarHovered && {
            position: "fixed",
            top: theme.spacing(2),
            bottom: theme.spacing(2),
            height: `calc(100vh - ${theme.spacing(4)})`,
            transform: "translateX(-50%)",
            zIndex: theme.zIndex.drawer + 1,
            borderColor: 'divider',
        }),
        ...((inTabletView || (sidebarCollapsed && !sidebarHovered)) && {
            left: `calc(${sidebarWidth.tabletView}px + ${theme.spacing(2)})`,
        }),
        ...((inDesktopView && (!sidebarCollapsed || sidebarHovered)) && {
            left: `calc(${sidebarWidth.desktopView}px + ${theme.spacing(2)})`,
        })
    }), [inDesktopView, sidebarCollapsed, sidebarHovered, inTabletView, sidebarWidth.tabletView, sidebarWidth.desktopView]);

    return (
        <PanelContext.Provider value={panelContextValue}>
            <Box className={classNames({[panelClasses.collapsed]: sidebarCollapsed})} sx={panelMixin}>
                <AppBar className={classNames({[panelClasses.collapsed]: sidebarCollapsed})} position="fixed" color={'primary'}
                        sx={appbarMixin}>
                    <Toolbar sx={ToolbarMixin}>
                        <IconButton
                            edge="start"
                            onClick={() => setSidebarOpen(prevState => !prevState)}
                            sx={{display: {[breakpoints.mobileView]: 'none', marginInlineEnd: '0.5rem'}}}
                        >
                            <Menu/>
                        </IconButton>
                        {
                            props.appbar
                        }
                    </Toolbar>
                </AppBar>
                <IconButton
                    onClick={onCollapseButtonClicked}
                    ref={collapseButtonRef}
                    sx={collapseButtonMixin}
                    onMouseLeave={onCollapseButtonMouseLeave}
                >
                    {
                        sidebarCollapsed
                            ? <KeyboardArrowRightRounded/>
                            : <KeyboardArrowLeftRounded/>
                    }
                </IconButton>
                <Divider sx={divider1Mixin} orientation="vertical"/>
                <Divider sx={divider2Mixin} orientation="vertical"
                />
                <Box className={classNames({[panelClasses.collapsed]: sidebarCollapsed})} component="nav" sx={sidebarNavMixin}>
                    <Drawer
                        className={classNames({[panelClasses.collapsed]: sidebarCollapsed})}
                        variant={inMobileView ? "temporary" : "permanent"}
                        open={sidebarOpen}
                        onClose={() => setSidebarOpen(false)}
                        sx={sidebarDrawerMixin}
                        PaperProps={{
                            ref: sidebarRef,
                            onMouseEnter: onSidebarMouseEnter,
                            onMouseLeave: onSidebarMouseLeave,
                            onTouchStart: onSidebarTouchStart,
                        }}
                        ModalProps={{keepMounted: true}}
                    >
                        {props.sidebar}
                    </Drawer>
                </Box>
                <Box
                    className={classNames({[panelClasses.collapsed]: sidebarCollapsed})}
                    component="main"
                    sx={contentMixin}
                >
                    <Toolbar/>
                    {props.children}
                    {
                        footerExists &&
                        <Toolbar/>
                    }
                </Box>
            </Box>
        </PanelContext.Provider>
    );
}

export default Panel;

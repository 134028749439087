import React, {FunctionComponent, useMemo} from "react";
import {useTheme} from "@mui/material";
import useBarcode, {UseBarcodeArg} from "../../hooks/use-barcode";

export interface PreviewBarcodeProps {
    downloadId?: string;
    width?: number | string;
    height?: number | string;
    value: string;
    showLabel?: boolean;
    syncPreviewWithDownloadElement?: boolean;
    downloadElementConfig?: {
        transparentBG?: boolean;
        includeMargin?: boolean;
    },
}

const PreviewBarcode: FunctionComponent<PreviewBarcodeProps> = (props) => {
    const theme = useTheme();

    const sharedOptions = useMemo<UseBarcodeArg>(() => ({
        value: props.value,
        options: {
            valid: () => void 0,
            displayValue: props.showLabel ?? false,
            text: props.showLabel ? props.value : undefined,
        },
    }), [props.value, props.showLabel])


    const displayOptions = useMemo<UseBarcodeArg>(() => ({
        ...sharedOptions,
        options: {
            ...sharedOptions.options,
            lineColor: props.syncPreviewWithDownloadElement ? '#000000' : theme.palette.text.primary,
            background: props.syncPreviewWithDownloadElement ? (props.downloadElementConfig?.transparentBG ? '#00000000' : '#ffffff') : '#00000000',
            margin: props.syncPreviewWithDownloadElement ? (props.downloadElementConfig?.includeMargin ? 10 : 0) : 0,
        },
    }), [sharedOptions, props.syncPreviewWithDownloadElement, props.downloadElementConfig?.transparentBG, theme.palette.text.primary, props.downloadElementConfig?.includeMargin])

    const downloadOptions = useMemo<UseBarcodeArg>(() => ({
        ...sharedOptions,
        options: {
            ...sharedOptions.options,
            lineColor: '#000000',
            background: props.downloadElementConfig?.transparentBG ? '#00000000' : '#ffffff',
            margin: props.downloadElementConfig?.includeMargin ? 10 : 0,
        },
    }), [sharedOptions, props.downloadElementConfig?.transparentBG, props.downloadElementConfig?.includeMargin])


    const displayBarcodeRef = useBarcode<HTMLCanvasElement>(displayOptions);
    const downloadBarcodeRef = useBarcode<HTMLCanvasElement>(downloadOptions);

    return (
        <>
            <canvas
                ref={displayBarcodeRef}
                style={{maxWidth: '100%', height: props.height, width: props.width,}}
            />
            {
                !!props.downloadId &&
                <div style={{position: 'absolute', transform: 'translate(-100%, -100%)', visibility: 'hidden'}}>
                    <canvas
                        id={props.downloadId}
                        ref={downloadBarcodeRef}
                        style={{
                            maxWidth: '100%',
                            height: props.height,
                            width: props.width,
                        }}
                    />
                </div>
            }
        </>
    );
}

export default PreviewBarcode;

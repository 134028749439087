import type {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import type {ApiRequestDs} from "./request";
import type {ApiResponseDs} from "./response";

export type * from './request';
export type  * from './response';

export type RequestInterceptor = [onFulfilled?: (value: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>, onRejected?: (error: any) => any];
export type ResponseInterceptor = [onFulfilled?: (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>, onRejected?: (error: any) => any];

export interface ApiInterceptors {
    request: Array<RequestInterceptor>,
    response: Array<ResponseInterceptor>,
}

/**
 * Different values of api call methods.
 */
export enum ApiMethods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
}

export interface ApiExecutorEffect {
    id: string,
    effect: Function,
}

export interface IApiExecutorEffect {
    run(request: ApiRequestDs, response: ApiResponseDs): void;
}


export interface IApiExecutor {
    injectInterceptors(interceptors: Partial<ApiInterceptors>): void;

    injectEffects(effects: Array<ApiExecutorEffect>): void;

    execute(request: ApiRequestDs): Promise<ApiResponseDs>;
}

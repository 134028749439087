import {v4 as UUIDv4} from 'uuid';
import type {BaseSyntheticEvent} from "react";
import {FileReadingOptions, ImageNaturalSize, ModifyEventConfig, OperatingSystems} from "./type-declerations";
import {jsonToCSV} from 'react-papaparse'
import type {UnparseConfig} from "papaparse";
import $ from 'jquery';
import dayjs, {Dayjs} from "dayjs";

/**
 * The utility methods used in the application.
 */
class Utils {

    //              ########################### COMPARATORS ###################################
    public static CurrencyFormatter = new Intl.NumberFormat('en-US', {currency: 'CAD', style: 'currency'});

    /**
     * Compares two numbers
     * @param a {number}
     * @param b {number}
     */
    static numComparator(a: number, b: number): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1
    }

    /**
     * Compares two dates by converting them to moment objects and then comparing them
     * @param a
     * @param b
     */
    static dateComparator(a: dayjs.ConfigType, b: dayjs.ConfigType): number {
        const _dayjsComparator = (a: Dayjs, b: Dayjs) => {
            if (a.isSame(b, 'ms')) return 0;
            if (a.isAfter(b, 'ms')) return 1;
            return -1;
        }
        return _dayjsComparator(dayjs(a), dayjs(b));
    }

    /**
     * Compares two strings.
     * @param a {string}
     * @param b {string}
     */
    static stringComparator(a: string, b: string): number {
        return a?.localeCompare(b);
    }

    /**
     * Compares two Booleans
     * @param a {boolean}
     * @param b {boolean}
     */
    static booleanComparator(a: boolean, b: boolean): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1;
    }

    //              ########################### UTILITIES ###################################

    /**
     * Fetches the information about the os of the user.
     */
    static getOsSpecs() {
        const os = this.getOS();
        const isMac = os === OperatingSystems.mac;
        const isIOS = os === OperatingSystems.ios;
        const isWindows = os === OperatingSystems.windows;
        const isAndroid = os === OperatingSystems.android;
        const isLinux = os === OperatingSystems.linux;
        return {
            os,
            isMac,
            isIOS,
            isWindows,
            isAndroid,
            isLinux,
        };
    }

    /**
     * Determines if two objects are equal.
     *
     * @param object1 {any}
     * @param object2 {any}
     * @return {boolean}
     */
    static deepEqual(object1: any, object2: any): boolean {
        // check if the first one is an array
        if (Array.isArray(object1)) {
            if (!Array.isArray(object2) || object1.length !== object2.length) return false;
            for (let i = 0; i < object1.length; i++) {
                if (!this.deepEqual(object1[i], object2[i])) return false;
            }
            return true;
        }
        // check if the first one is an object
        if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
            if (!(typeof object2 === 'object')) return false;
            const keys = Object.keys(object1);
            if (keys.length !== Object.keys(object2).length) return false;
            for (const key in object1) {
                if (!this.deepEqual(object1[key], object2[key])) return false;
            }
            return true;
        }
        // not array and not object, therefore must be primitive
        return object1 === object2;
    }

    /**
     * Deep copy an acyclic *basic* Javascript object.  T
     *
     * * this method only handles basic scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
     * containing these.
     * * This method does *not* handle instances of other classes.
     * @param obj {any}
     */
    static deepCopy<T = any>(obj: T): T {
        let ret: any, key;
        let marker = '__deepCopy';

        // @ts-ignore
        if (obj && obj[marker])
            throw (new Error('attempted deep copy of cyclic object'));

        if (obj && obj.constructor === Object) {
            ret = {};
            // @ts-ignore
            obj[marker] = true;

            for (key in obj) {
                if (key === marker)
                    continue;

                // @ts-ignore
                ret[key] = this.deepCopy(obj[key]);
            }

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }

        // @ts-ignore
        if (obj && obj.constructor === Array) {
            ret = [];
            // @ts-ignore
            obj[marker] = true;

            // @ts-ignore
            for (key = 0; key < obj.length; key++)
                ret.push(this.deepCopy(obj[key]));

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }
        // It must be a primitive type -- just return it.
        return (obj);
    }

    /**
     * Performs a deep merge of objects and returns new object. Does not modify
     * objects (immutable) and merges arrays via concatenation.
     *
     * @param {...object} objects Objects to merge
     * @returns {object} New object with merged key/values
     */
    static deepMerge(...objects: Record<string, any>[]): Record<string, any> {
        const isObject = (obj: any) => obj && typeof obj === 'object';

        return objects.reduce((prev: any, obj: any) => {
            for (const key of Object.keys(obj)) {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = [...new Set([...oVal, ...pVal])];
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = this.deepMerge(pVal, oVal);
                } else {
                    prev[key] = oVal;
                }
            }

            return prev;
        }, {});
    }

    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions.arrayBuffer): PromiseLike<ArrayBuffer | null>;

    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions.json): PromiseLike<Record<string, any> | null>;

    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions.text | FileReadingOptions.dataUrl | FileReadingOptions.binaryString): PromiseLike<string | null>;

    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions): PromiseLike<string | ArrayBuffer | Record<string, any> | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            switch (as) {
                case FileReadingOptions.text:
                case FileReadingOptions.json:
                    reader.readAsText(file);
                    break;
                case FileReadingOptions.dataUrl:
                    reader.readAsDataURL(file);
                    break;
                case FileReadingOptions.arrayBuffer:
                    reader.readAsArrayBuffer(file);
                    break;
                case FileReadingOptions.binaryString:
                    reader.readAsBinaryString(file);
                    break;
            }
            reader.onload = () => {
                if (as === FileReadingOptions.json) {
                    try {
                        resolve(JSON.parse(reader.result as string))
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    resolve(reader.result)
                }
            };
            reader.onerror = error => reject(error);
        });
    }

    /**
     * Fetches the natural size of the image associated with the given imageUrl when rendered in the browser.
     * @param imageUrl
     */
    static getImageSize(imageUrl: string): Promise<ImageNaturalSize | null> {
        return new Promise<ImageNaturalSize | null>((resolve) => {
            const image = new Image();
            image.src = imageUrl;
            image.onload = () => {
                resolve({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                })
            }
            image.onerror = () => {
                resolve(null)
            }
        })
    }

    /**
     * Creates a Unique Identifier in form of a string
     * @return {string}
     */
    static createUUId(): string {
        const uuid = UUIDv4();
        return `ID-${uuid}`
    }

    /**
     * Downloads the given blob with the given exported file name.
     * @param blob                  the blob to be downloaded
     * @param exportedFileName      the name of the exported file
     */
    static downloadFile(blob: Blob, exportedFileName: string): void {
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFileName);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                link.href = URL.createObjectURL(blob);
                link.download = exportedFileName;
                link.style.visibility = 'hidden';
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    /**
     * Exports a .json file from the provided [json] object and the [fileTitle].
     * @param json
     * @param fileTitle
     */
    static exportJsonFile(json: Record<string, any>, fileTitle: string): void {
        const exportedFileName = fileTitle + '.json' || 'template.json';
        const text = JSON.stringify(json);
        const blob = new Blob([text], {type: 'data:application/json;charset=utf-8;'});
        this.downloadFile(blob, exportedFileName);
    }

    /**
     * Exports a .csv file from the provided [data] and the [fileTitle].
     * @param data
     * @param fileTitle
     * @param options
     */
    static exportCsvFile(data: any, fileTitle: string, options?: UnparseConfig): void {
        const csv = jsonToCSV(data, {skipEmptyLines: true, ...(options ?? {})});
        const exportedFileName = fileTitle + '.csv' || 'export.csv';
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        this.downloadFile(blob, exportedFileName);
    }

    /**
     * Returns the given value with the maximum amount of decimals provided.
     * @param value
     * @param maxDecimals
     * @param returnType
     */
    static numberWithMaximumDecimals(
        value: string | number,
        maxDecimals: number = 2,
        returnType: "string" | "number" = 'string'
    ): number | string {
        const modulus = Number(value) % 1;
        if (modulus === 0) {
            return value;
        }
        const modulusString = value.toString();

        const numberOfDecimals = modulusString.split('.')?.length > 1
            ? modulusString.split('.')[1].length
            : 0;
        const valueWithCorrectDecimals = typeof value === 'string'
            ? parseFloat(value).toFixed(Math.min(numberOfDecimals, maxDecimals))
            : value.toFixed(Math.min(numberOfDecimals, maxDecimals));

        switch (returnType) {
            case 'string':
                return valueWithCorrectDecimals;
            case "number":
            default:
                return parseFloat(valueWithCorrectDecimals);
        }
    }

    /**
     * Awaits for the specified time in milliseconds.
     * @param {number} milliseconds
     * @return {Promise<void>}
     */
    static async wait(milliseconds: number): Promise<void> {
        await new Promise(r => setTimeout(r, milliseconds));
    }

    /**
     * Modifies the given event with the given options.
     *
     * @param event
     * @param config
     */
    static modifyEvent(
        event: Event | BaseSyntheticEvent,
        config: ModifyEventConfig = {
            preventDefault: true,
            stopPropagation: true,
            stopImmediatePropagation: false,
        }
    ): void {
        if (config.preventDefault) {
            event.preventDefault();
        }
        if (config.stopPropagation) {
            event.stopPropagation();
        }
        if (config.stopImmediatePropagation && "stopImmediatePropagation" in event) {
            event.stopImmediatePropagation();
        }
    }

    /**
     * Clamps the given value between the min and max numbers.
     *
     * @param {number} value
     * @param {number} min
     * @param {number} max
     */
    static clamp(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(value, max))
    }

    /**
     * Rounds the given number with a fraction digits between [minimumFractionDigits] and [maximumFractionDigits]
     *
     * @param value
     * @param minimumFractionDigits
     * @param maximumFractionDigits
     */
    static round(
        value: number,
        minimumFractionDigits?: number,
        maximumFractionDigits?: number
    ): number {
        const formattedValue = value.toLocaleString('en',
            {
                useGrouping: false,
                minimumFractionDigits,
                maximumFractionDigits
            }
        )
        return Number(formattedValue)
    }

    /**
     * Sets the application's title and description from given values.
     *
     * @param title
     * @param description
     */
    static setAppInfo({title, description}: { title?: string, description?: string } = {}): void {
        if (title) {
            document.title = title;
        }
        if (description) {
            $('meta[name="description"]').attr("content", description);
        }
    }

    /**
     * Deeply removes all the instances of the provided property from the given entity.
     *
     * @param property      the name of the property to be removed
     * @param entity        the entity to remove the property from
     */
    static deepRemoveProperty<T>(property: keyof T, entity: T): Omit<T, typeof property> {
        if (!entity)
            return entity;
        if (Array.isArray(entity)) {
            return entity.map(e => this.deepRemoveProperty(property, e)) as T;
        }
        if (typeof entity === 'object') {
            for (const key of Object.keys(entity)) {
                if (key === property) {
                    delete entity[key as keyof T];
                } else {
                    const value = entity[key as keyof T];
                    const innerProp = property as keyof typeof value;
                    entity[key as keyof T] = this.deepRemoveProperty(innerProp, entity[key as keyof T]) as typeof value;
                }
            }
        }
        return entity;
    }

    /**
     * Title cases a given string.
     * @param string
     */
    static stringToTitleCase(string: string): string {
        return string[0].toUpperCase().concat(
            string
                .slice(1)
                .replaceAll(/[A-Z]/g, (char) => ` ${char}`)
                .replaceAll(/[A-Za-z][0-9]/g, (char) => `${char[0]} ${char[1]}`)
        );
    }

    /**
     * Corrects the url of the video by forcing it to start with https
     * @param url      the url to be corrected
     */
    static correctUrl(url: string): string {
        if (!url?.length) return '';
        if (url.startsWith('http://') || url.startsWith('https://')) return url;
        return `https://${url}`;
    }

    /**
     * Determines if the given url is from YouTube.
     * @param url       the url to be checked
     */
    static isYoutubeUrl(url: string): boolean {
        return (url?.startsWith('https://www.youtube') || url?.startsWith('http://www.youtube')) ?? false;
    }

    /**
     * Fetches the YouTube video id from the YouTube url.
     * @param url       the YouTube url
     */
    static getYoutubeVideoId(url: string): string {
        const list = url?.split('/') ?? [];
        if (list.length < 2)
            return "";
        const id = list[list.length - 1] ?? "";
        if (id.includes('?')) {
            return id.split('?')[0];
        }
        return id;
    }

    /**
     * Fetches the OS of the system with 90% accuracy.
     *
     * @author Vladyslav Turak
     * @see https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
     */
    private static getOS() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = OperatingSystems.mac;
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = OperatingSystems.ios;
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = OperatingSystems.windows;
        } else if (/Android/.test(userAgent)) {
            os = OperatingSystems.android;
        } else if (/Linux/.test(platform)) {
            os = OperatingSystems.linux;
        }
        return os;
    }
}

export * from './type-declerations';
export default Utils;

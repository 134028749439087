import * as Bizkeytech from "../../../@bizkeytech";

/**
 * @inheritDoc
 */
class Utils extends Bizkeytech.Utils {

    /**
     * Download a URL as a file.
     * @param url           The URL to download.
     * @param filename      The filename to save the file as.
     */
    static downloadUrl(url: string, filename: string): void {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

}

export * from "./type-declarations";
export default Utils;

import React, {FunctionComponent, PropsWithChildren, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import DataGridApiService from "../../../core/services/api";
import type {DataGridSavedState} from "../../../type-declerations";
import {DataGridApplicationNameContext, DataGridSavedStateContext} from "../../../index";
import DataGridUtils from "../../../core/services/utils";
import DataGridStateSaver from "../../../core/models/in-app/state-saver";
import {useIsMounted} from "../../../../base/ui";
import DataGridCachingService, {CachingServiceEntities, DataGridCachingServiceLocalStorageKeys} from "../../../core/services/caching";


export type DataGridProviderProps = PropsWithChildren<{
    authorizationToken?: string,
    storageKey?: string | null,
    fallback?: ReactNode,
    applicationName: string,
}>

const DataGridProvider: FunctionComponent<DataGridProviderProps> = ({
                                                                        authorizationToken,
                                                                        storageKey,
                                                                        children,
                                                                        fallback,
                                                                        applicationName,
                                                                    }) => {
    const storageKeySafe = useMemo(() => storageKey || DataGridCachingService.of(CachingServiceEntities.localStorage).keys[DataGridCachingServiceLocalStorageKeys.savedState], [storageKey]);
    const [loading, setLoading] = useState(!!authorizationToken);
    const [dataGridSavedState, setDataGridSavedState] = useState<DataGridSavedState>(DataGridUtils.parseDataGridSavedStateFromStorage(storageKeySafe) ?? []);
    const isMounted = useIsMounted();

    /**
     * With each change in the [storageKey] value of the props:
     * - updates the storage key of state-saver controller.
     */
    useLayoutEffect(() => {
        DataGridStateSaver.StorageKey = storageKeySafe;
    }, [storageKeySafe])

    /**
     * With each change in the [auth-token] value of the props:
     * - creates the injectors for the api such that the auth header is set with the [token] value
     */
    useLayoutEffect(() => {
        DataGridApiService.injectInterceptors({
            request: [[
                (config) => {
                    config.headers.Authorization = config.headers.Authorization ?? authorizationToken;
                    return config;
                }
            ]],
        })
    }, [authorizationToken])

    /**
     * Fetches the saved state of all the data-grids of the application from the server.
     */
    const getSavedState = useCallback(async (abortController: AbortController) => {
        setLoading(true);
        const response = await DataGridApiService.getAllSavedStates(applicationName, abortController);
        if (!isMounted())
            return;
        if (!!response?.resultFlag && !!response?.data) {
            setDataGridSavedState(response.data);
            DataGridCachingService.of(CachingServiceEntities.localStorage).set(storageKeySafe, JSON.stringify(response.data));
        }
        setLoading(false);
    }, [applicationName, isMounted, storageKeySafe])

    /**
     * With each change in the [authorizationToken] value of the props:
     * - if the value exists, fetches the saved-state of all data-grids of the application.
     */
    useEffect(() => {
        if (!authorizationToken)
            return setLoading(false);
        const abortController = new AbortController();
        getSavedState(abortController).then();
        return () => abortController.abort();
    }, [authorizationToken, getSavedState])

    /**
     * Updates the saved state of the data-grid's context value with each update in the local-storage.
     */
    const listenToChangesInDataGridLocallySavedState = useCallback((e: StorageEvent) => {
        if (e.key !== storageKeySafe)
            return;
        setDataGridSavedState(JSON.parse(e.newValue ?? '[]'));
        DataGridCachingService.of(CachingServiceEntities.localStorage).set(storageKeySafe, e.newValue ?? '[]');
    }, [setDataGridSavedState, storageKeySafe])

    /**
     * As soon as the component mounts:
     * - attaches an event listener that updates the saved-state with each change in the local-storage.
     */
    useEffect(() => {
        window.addEventListener('storage', listenToChangesInDataGridLocallySavedState);
        return () => window.removeEventListener('storage', listenToChangesInDataGridLocallySavedState);
    }, [listenToChangesInDataGridLocallySavedState])

    return (
        <>
            <DataGridApplicationNameContext.Provider value={applicationName}>
                <DataGridSavedStateContext.Provider value={dataGridSavedState}>
                    {
                        (loading && !!fallback)
                            ? fallback
                            : children
                    }
                </DataGridSavedStateContext.Provider>
            </DataGridApplicationNameContext.Provider>
        </>
    );
}

export default DataGridProvider;

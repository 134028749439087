import EnvService from "../../../core/services/env";

/**
 * This interface houses all the available endpoints of this application specific for the 'biz-print' server.
 */
class ApiEndpoints {

    /**
     * The base url of the api endpoints of this application.
     * @private
     */
    public static baseUrl: string = EnvService.apiEndpointUrl + '/api';

    /**
     * The controllers of the api endpoints.
     * @private
     */
    public static controllers = {
        config: this.baseUrl.concat("/Config"),
        operator: this.baseUrl.concat("/Operator"),
        reportingTools: this.baseUrl.concat("/ReportingTools"),
        code: this.baseUrl.concat("/Code"),
        dashboard: this.baseUrl.concat("/Dashboard"),
    }

    /**
     * The endpoints specific for the config controller of the server api.
     */
    public static config = {
        baseUrl: this.controllers.config,
        getConfig: this.controllers.config,
    }

    /**
     * The endpoints specific for the operator controller of the server api.
     */
    public static operator = {
        baseUrl: this.controllers.operator,
        getCurrentOperatorInformation: this.controllers.operator,
    }


    /**
     * The endpoints specific for the reporting tools controller of the server api.
     */
    public static reportingTools = {
        baseUrl: this.controllers.reportingTools,
        getColumns: (reportName: string) => this.controllers.reportingTools.concat(`/GetColumns?reportName=${reportName}`),
    }


    /**
     * The endpoints specific for the code controller of the server api.
     */
    public static code = {
        baseUrl: this.controllers.code,
        get: (id: string) => this.controllers.code.concat(`/${id}`),
        create: this.controllers.code,
        update: this.controllers.code,
        delete: (id: string) => this.controllers.code.concat(`/${id}`),
        scan: (id: string) => this.controllers.code.concat(`/scan/${id}`),
        list: this.controllers.code.concat('/list'),
        getReport: this.controllers.code.concat(`/GetReport`),
    }

    /**
     * The endpoints specific for the dashboard controller of the server api.
     */
    public static dashboard = {
        baseUrl: this.controllers.dashboard,
        getDashboard: this.controllers.dashboard,
    }
}

export default ApiEndpoints;

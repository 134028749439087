import React, {FunctionComponent, useMemo, useRef} from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import classnames from "classnames";
import {AnimationItem} from "lottie-web";
import LoadingAnimationSrc from "../../../assets/animations/lottie/loading.json";


export interface LottieAnimationProps {
    className?: string,
    width?: string | number,
    height?: string | number,
    src: string | object,
}

const LottieAnimation: FunctionComponent<LottieAnimationProps> = ({
                                                                      className,
                                                                      width = '100%',
                                                                      height = 'auto',
                                                                      src
                                                                  }) => {
    const playerRef = useRef<Player>(null);
    const lottieRef = useRef<AnimationItem>();

    /**
     * Fetches the value of the given dimension.
     *
     * * if the given [d] is a number, returns the pixelated value of the [d], otherwise returns [d]
     */
    const getDimensionValue = (d: string | number): string | any => {
        const num = Number(d);
        if (Number.isNaN(num)) {
            return d;
        }
        return `${num}px`;
    }

    const dimensions = useMemo(() => ({
        width: getDimensionValue(width),
        height: getDimensionValue(height),
    }), [width, height])

    return (
        <>
            <Player
                autoplay
                loop
                src={src}
                ref={playerRef}
                style={{...dimensions}}
                lottieRef={(ref) => (lottieRef.current = ref)}
                className={classnames(className)}
            />
        </>
    )
}


export const LoadingAnimation = LoadingAnimationSrc as LottieAnimationProps['src'];
export default LottieAnimation;

import {DataGridInternalState, DataGridLayout, DataGridRow, DataGridStateActions, GetExportInfoFunc} from "../../../../type-declerations";
import DataGridController from "./index";


/**
 * The interface that manipulates the table state and layout that it belongs to.
 *
 * * This api is only used within the data grid entities (for internal manipulation)
 */
class InternalDataGridController extends DataGridController {

    /**
     * The underlying state of this data grid.
     *
     * * **NOTE**: This getter should not be used in a data grid subcomponent as its value may not be the most
     * up-to-date. Use this property only in the parent components.
     * @return {DataGridInternalState}
     */
    get state(): DataGridInternalState {
        return this._state;
    }

    /**
     * Sets the underlying state of this API.
     *
     * * **WARNING**: do not use this method to manipulate the state of the data grid. This method only exists for
     * internal usage.
     * @param {DataGridInternalState} value
     */
    set state(value: DataGridInternalState) {
        this._state = value;
    }

    /**
     * Sets the visible rows of this API.
     *
     * * **WARNING**: do not use this method to manipulate the state of the data grid. This method only exists for
     * internal usage.
     * @param {DataGridRow[]} rows
     */
    set rows(rows: DataGridRow[]) {
        this._rows = rows;
    }

    /**
     * Sets the layout of this API.
     *
     * * **WARNING**: do not use this method to manipulate the state of the data grid. This method only exists for
     * internal usage.
     * @param {DataGridLayout} layout
     */
    set layout(layout: DataGridLayout) {
        this._layout = layout;
    }


    /**
     * Sets the getExportInfo of this API.
     *
     * * **WARNING**: do not use this method to manipulate the state of the data grid. This method only exists for
     * internal usage.
     * @param {GetExportInfoFunc} value
     */
    set getExportInfo(value: GetExportInfoFunc) {
        this._getExportInfo = value;
    }

    /**
     * Sets the pagination's' length of this data grids.
     * @param {number} length
     * @param {boolean} emitEvent
     */
    setPageLength(length: number, emitEvent: boolean = false): void {
        this.dispatch({
            type: DataGridStateActions.setPageLength,
            payload: length,
            emitEvent: emitEvent,
        });
    }

    /**
     * Changes the order of the columns of this data grid based on the order of the given column names.
     *
     * * if we have disabled reordering in the data grid component, this method will have no effect.
     * * only the order of the un-pinned columns may be changed
     * @param {Array<string>} colNames
     * @param {boolean} emitEvent
     */
    setAllColumnsOrder(colNames: Array<string>, emitEvent: boolean = false) {
        if (this._layout.disableReordering)
            return;
        this.dispatch({
            type: DataGridStateActions.setAllColumnsOrder,
            payload: colNames,
            emitEvent: emitEvent,
        })
    }
}

export default InternalDataGridController;

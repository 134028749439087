import * as Bizkeytech from "../../../@bizkeytech";
import {ApiMethods, ApiResponse} from "../../../@bizkeytech";
import {
    CreateCodeRequestDs,
    CreateCodeResponse,
    FetchDynamicColumnsResponse,
    GetCodeReportRequestDs,
    GetCodeReportResponse,
    GetCodeResponse,
    GetConfigResponse,
    GetCurrentOperatorInformationResponse,
    GetDashboardResponse,
    ListCodeRequestDs,
    ListCodeResponse,
    ScanCodeResponse,
    UpdateCodeRequestDs
} from "./type-declarations";
import ApiEndpoints from "../../models/api-endpoints";

/**
 * The interface that houses all the available api calls related to this application.
 */
class ApiService extends Bizkeytech.ApiService {

    // ###########################      CONFIG        ###################################

    /**
     * Get the configuration of the site from the srver.
     *
     * @param abortController       The abort controller that will be used to cancel the request.
     */
    static async getConfig(abortController: AbortController): GetConfigResponse {
        return await this._executor.execute({
            method: ApiMethods.get,
            url: ApiEndpoints.config.getConfig,
            signal: abortController.signal,
            headers: {
                Authorization: "00000000-0000-0000-0000-000000000000",
            },
        });
    }

    // ###########################      OPERATOR        ###################################


    /**
     * Fetches the current operator information from the server.
     *
     * @param token             The token of the operator.
     * @param abortController   The abort controller that will be used to cancel the request.
     */
    static async getCurrentOperatorInformation(token: string, abortController: AbortController): GetCurrentOperatorInformationResponse {
        return await this._executor.execute({
            method: ApiMethods.get,
            url: ApiEndpoints.operator.getCurrentOperatorInformation,
            signal: abortController.signal,
            headers: {
                Authorization: token,
            },
        });
    }

    // ###########################      REPORTING TOOLS        ###################################

    /**
     * Fetches the list of task status groups from the server.
     * @param reportName        the name of the section to fetch the columns for.
     * @param abortController   the abort controller to cancel the request.
     */
    static async fetchDynamicColumns(reportName: string, abortController: AbortController): FetchDynamicColumnsResponse {
        return await this._executor.execute({
            method: ApiMethods.post,
            url: ApiEndpoints.reportingTools.getColumns(reportName),
            signal: abortController.signal,
        });
    }

    // ###########################      DASHBOARD        ###################################

    /**
     * Fetches the dashboard data from the server.
     * @param abortController   the abort controller to cancel the request.
     */
    static async getDashboard(abortController?: AbortController): GetDashboardResponse {
        return await this._executor.execute({
            method: ApiMethods.get,
            url: ApiEndpoints.dashboard.getDashboard,
            signal: abortController?.signal,
        });
    }

    // ###########################      CODE        ###################################

    /**
     * Fetches the code from the server.
     * @param id                    the id of the code to fetch.
     * @param abortController       the abort controller to cancel the request.
     */
    static async getCode(id: string, abortController?: AbortController): GetCodeResponse {
        return await this._executor.execute({
            method: ApiMethods.get,
            url: ApiEndpoints.code.get(id),
            showErrorToast: true,
            signal: abortController?.signal,
        });
    }

    /**
     * Creates a code on the server.
     * @param data                  the data to create the code with.
     * @param abortController       the abort controller to cancel the request.
     */
    static async createCode(data: CreateCodeRequestDs, abortController?: AbortController): CreateCodeResponse {
        return await this._executor.execute({
            method: ApiMethods.post,
            url: ApiEndpoints.code.create,
            signal: abortController?.signal,
            data: data,
        });
    }

    /**
     * Updates a code on the server.
     * @param data                      the data to update the code with.
     * @param abortController           the abort controller to cancel the request.
     */
    static async updateCode(data: UpdateCodeRequestDs, abortController?: AbortController): ApiResponse<void> {
        return await this._executor.execute({
            method: ApiMethods.put,
            url: ApiEndpoints.code.update,
            signal: abortController?.signal,
            data: data,
        });
    }

    /**
     * Deletes a code on the server.
     * @param id                    the id of the code to delete.
     * @param abortController       the abort controller to cancel the request.
     */
    static async deleteCode(id: string, abortController?: AbortController): ApiResponse<void> {
        return await this._executor.execute({
            method: ApiMethods.delete,
            url: ApiEndpoints.code.delete(id),
            signal: abortController?.signal,
        });
    }


    /**
     * Scans a code on the server.
     * @param id                    the id of the code to scan.
     * @param abortController       the abort controller to cancel the request.
     */
    static async scanCode(id: string, abortController?: AbortController): ScanCodeResponse {
        return await this._executor.execute({
            method: ApiMethods.get,
            url: ApiEndpoints.code.scan(id),
            signal: abortController?.signal,
            headers: {
                Authorization: "00000000-0000-0000-0000-000000000000",
            }
        });
    }

    /**
     * Fetches the list of codes from the server.
     *
     * @param data                  the data to fetch the codes with.
     * @param abortController       the abort controller to cancel the request.
     */
    static async listCodes(data: ListCodeRequestDs, abortController?: AbortController): ListCodeResponse {
        return await this._executor.execute({
            method: ApiMethods.post,
            url: ApiEndpoints.code.list,
            signal: abortController?.signal,
            data: data,
        });
    }

    /**
     * Fetches the code report from the server.
     *
     * @param data                  the data to fetch the code report with.
     * @param abortController       the abort controller to cancel the request.
     */
    static async getCodeReport(data: GetCodeReportRequestDs, abortController?: AbortController): GetCodeReportResponse {
        return await this._executor.execute({
            method: ApiMethods.post,
            url: ApiEndpoints.code.getReport,
            signal: abortController?.signal,
            data: data,
        });
    }

}


export * from './type-declarations';
export default ApiService;

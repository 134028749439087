import React, {useContext, useMemo, useState} from "react";
import {DataGridControllerContext, DataGridMiscContext, DataGridStateContext} from "../../../../../index";
import DataGridButton from "../../../../components/button";
import classnames from "classnames";
import {ReactComponent as Icon} from "../../../../../assets/images/toolbar/export.svg";
import DataGridIconButton from "../../../../components/icon-button/inedx";
import {useMediaQuery, useTheme} from "@mui/material";

const DataGridToolbarExportAction = () => {
    const {loading} = useContext(DataGridStateContext);
    const {classNames} = useContext(DataGridMiscContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const [exporting, setExporting] = useState(false);

    const theme = useTheme();
    const showIconButton = useMediaQuery(theme.breakpoints.down('lg'));

    const disabled = useMemo(() => loading.state || exporting, [loading.state, exporting])

    /**
     * Exports the data of this DataGrid.
     * @return {Promise<void>}
     */
    const exportData = async () => {
        setExporting(true);
        await dataGridApi.export();
        setExporting(false);
    }

    return (
        <>
            {
                showIconButton
                    ? <DataGridIconButton
                        disabled={!!disabled}
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarExportAction)}
                        onClick={exportData}
                    >
                        <Icon/>
                    </DataGridIconButton>
                    : <DataGridButton
                        disabled={!!disabled}
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarExportAction)}
                        onClick={exportData}
                    >
                        <Icon/>
                        <p>
                            {
                                exporting
                                    ? "Exporting..."
                                    : "Export"
                            }
                        </p>
                    </DataGridButton>
            }
        </>
    )
}

export default DataGridToolbarExportAction;

import {combineReducers} from "@reduxjs/toolkit";
import DialogsReduxSlice from "../slices/dialogs";


/**
 * The Root Reducer of the Redux Store state.
 */
const ReduxRootReducer = combineReducers({
    [DialogsReduxSlice.name]: DialogsReduxSlice.reducer,
});

export default ReduxRootReducer;

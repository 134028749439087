import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DialogsReduxSliceState,
    DialogsReduxSliceStateBaseEntry,
    DialogsReduxSliceStateExportingEntry,
    DialogsReduxSliceStatePreviewImagesEntry,
} from "./type-declerations";


const baseInitialState: DialogsReduxSliceStateBaseEntry = {
    open: false,
    disableAutoFocus: false,
    disableEnforceFocus: false,
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    hideCloseButton: false,
    disablePortal: false,
    disableRestoreFocus: false,
    disableScrollLock: false,
    hideBackdrop: false,
    keepMounted: false,
    fullWidth: true,
    onClose: (): void => void 0,
}

const initialState: DialogsReduxSliceState = {
    exporting: {
        ...baseInitialState,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        maxWidth: "xs",
    },
    previewImages: {
        ...baseInitialState,
        data: [],
    },
}


const sliceName = 'dialogs';

/**
 * Merges the given state object with the provided action.
 *
 * * if the value of [open] property of [action.payload] is set to false, resets the [type] property of the state.
 * @param state
 * @param action
 */
const mergePayloadWithState = (
    state: any,
    action: PayloadAction<Partial<DialogsReduxSliceStateBaseEntry>>,
): DialogsReduxSliceState => {
    const type = action.type.replace(`${sliceName}/`, '') as keyof DialogsReduxSliceState;
    if (action?.payload?.open === false) {
        return {
            ...state,
            [type]: {
                ...state[type],
                ...initialState[type],
            },
        };
    }
    let data;
    switch (typeof action?.payload?.data) {
        case "object":
        case "undefined": {
            if (Array.isArray(action?.payload?.data)) {
                data = [
                    ...((initialState[type]?.data as Array<any>) ?? []),
                    ...(action?.payload?.data ?? []),
                ];
                break;
            }
            data = {
                ...(initialState[type]?.data ?? {}),
                ...(action?.payload?.data ?? {}),
            };
            break;
        }
        default:
            data = action?.payload?.data ?? initialState[type].data;
    }
    return {
        ...state,
        [type]: {
            ...state[type],
            ...action?.payload,
            data: data,
        },
    };
}

/**
 * The Redux state, action creators, types and reducers of the Dialogs slice.
 */
export const DialogsReduxSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        exporting: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStateExportingEntry>>
        ) => mergePayloadWithState(state, action),
        previewImages: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStatePreviewImagesEntry>>
        ) => mergePayloadWithState(state, action),
    },
})

export * from './type-declerations';
export default DialogsReduxSlice;

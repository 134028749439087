import {CanvasHTMLAttributes, DetailedHTMLProps, ReactNode} from "react";


// ############################# ENUMS #############################

export enum DataGridStateActions {
    columnsPropsChanged = 'columns-props-changed',
    preliminaryColumnsChanged = 'preliminary-columns-changed',
    preliminaryDensityChanged = 'preliminary-density-changed',
    statePropsChanged = 'state-props-changed',
    setPageSize = 'set-page-size',
    setPageLength = 'set-page-length',
    setCurrentPage = 'set-current-page',
    setSortBy = 'set-sort-by',
    setDensity = 'set-density',
    toggleColumnsVisibility = 'toggle-columns-visibility',
    togglePinnedColumns = 'toggle-pinned-columns',
    refreshLayout = 'refresh-layout',
    reorderColumns = 'reorder-columns',
    setAllColumnsOrder = 'set-all-columns-order',

    toggleSelectedRows = 'toggle-selected-rows',
    toggleAllRowsSelection = 'toggle-all-rows-selection',
    resetSelection = 'reset-selection',

    resizeColumns = 'resize-columns',
    resizeColumnsBy = 'resize-columns-by',
    loadConfiguration = 'load-configuration',
}

export enum DataGridColumnAlignments {
    left = 'left',
    right = 'right',
    center = 'center',
}

export enum DataGridColumnTypes {
    date = 'date',
    dateTime = 'date-time',
    string = 'string',
    money = 'money',
    element = 'element',
    number = 'number'
}

export enum DataGridExoticColumnFields {
    detailedPanel = '__exotic_grid_detailed_panel_field',
    detailedPanelToggler = '__exotic_grid_detailed_panel_toggler_field',
    selection = '__exotic_grid_selection_field',
    spacer = '__exotic_grid_spacer_field',
}

export enum DataGridColumnWidthTypes {
    flex = 'flex',
    default = 'default',
}

export enum DataGridDensities {
    compact = 'compact',
    standard = 'standard',
    relaxed = 'relaxed',
}

export enum DataGridActionTypes {
    default = "default",
    delete = "delete",
}

export enum InternalDataGridEvents {
    onColumnsToolbarOpened = 'onColumnsToolbarOpened',
}

export enum DataGridColumnPinnedTypes {
    left = 'left-pinned',
    right = 'right-pinned',
}


// ############################# INTERFACES #############################


export interface DataGridColumnWidth {
    size: number,
    minWidth: number,
    type: DataGridColumnWidthTypes,
}

export interface DataGridColumnPinnedToggleable {
    left: boolean,
    right: boolean,
}


export interface DataGridColumn {
    title?: string | (() => ReactNode),
    name: string,
    alignment?: DataGridColumnAlignments,
    type: DataGridColumnTypes,
    format?: Function,
    sortable?: boolean,

    order: number,
    reorderable?: boolean;

    width: number | DataGridColumnWidth,
    resizable?: boolean,

    visible: boolean,
    visibilityToggleable?: boolean,

    pinned: boolean,
    pinnedType: DataGridColumnPinnedTypes,
    pinnedToggleable?: boolean | DataGridColumnPinnedToggleable,
    pinnedOrder?: number,

    savable: boolean;
}

export interface DataGridInternalColumn extends DataGridColumn {
    width: DataGridColumnWidth,
    pinnedOrder: number,

    leftPinOffset?: number,
    rightPinOffset?: number,
    pinnedToggleable?: DataGridColumnPinnedToggleable,
}

export type DataGridCellContentCallbackArg = {
    format: (value: any) => any,
    detailedPanelOpen: boolean,
    toggleDetailedPanelOpen: () => void,
    groupedRowsOpen: boolean,
    toggleGroupedRowsOpen: () => void,
    rowSelected: boolean,
    toggleRowSelection: () => void,
    openEditPopover: () => void,
}

export interface DataGridInitialLayoutState {
    columns: DataGridColumn[],
    density?: DataGridDensities,
}

export type DataGridCellContentCallback = (arg: DataGridCellContentCallbackArg) => ReactNode

export type DataGridCellContent = string | number | ReactNode | undefined | null | DataGridCellContentCallback;

export interface DataGridCell {
    editable?: boolean,
    value: DataGridCellContent,
    editableValue?: string | number | boolean | undefined | Object | null,
    EditableContentComponent?: ReactNode,
    groupValue?: string | number | boolean,
}


export interface DataGridSelectionEntry {
    key: number | string,
    data: any,
}

export interface DataGridRowGroup {
    columnName: string,
    showOnColumn?: string,
}


export interface DataGridRow extends DataGridSelectionEntry,
    Omit<DetailedHTMLProps<CanvasHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>, 'key'> {
    cells: Record<string, DataGridCell | DataGridCellContent>,
}

export interface DataGridInternalCell {
    column: DataGridInternalColumn,
    content: DataGridCell,
    firstRightPinned: boolean,
    lastLeftPinned: boolean,
    showGroupToggler: boolean,
    groupedRowsLength?: number,
}

// @ts-ignore
export interface DataGridInternalRow extends DataGridRow {
    selected: boolean,
    cells: Array<DataGridInternalCell>,
    detailedPanel: DataGridCellContent,
    colCount: number,
    groupedRows?: Array<DataGridInternalRow>,
}

export interface DataGridState {
    density: DataGridDensities,
    sortBy?: DataGridSortBy,
    pagination?: Partial<DataGridPagination>,
    loading?: Partial<DataGridLoading>,
    group?: DataGridRowGroup,
}

export interface DataGridInternalState extends DataGridState {
    initialState: DataGridState,

    preliminaryDensity: DataGridDensities,

    initialColumns: DataGridColumn[],
    preliminaryColumns: DataGridColumn[],
    columns: DataGridInternalColumn[],

    pagination: DataGridPagination,

    selectedRows: DataGridSelectionEntry[],
    excludedRows: DataGridSelectionEntry[],
    allRowsSelected: boolean,
}

export interface DataGridPagination {
    currentPage: number,
    pageSize: number,
    length: number,
    sizes?: number[],
}


export interface DataGridSortBy {
    field: string,
    descending: boolean,
}

export interface DataGridClassnames {
    container: string,
    layout: string,
    table: string,

    toolbar: string,
    toolbarAction: string,
    toolbarActionPopover: string,
    toolbarActionPopoverPaper: string,

    toolbarColumnsFilterAction: string,
    toolbarColumnsFilterActionPopover: string,
    toolbarColumnsFilterActionPopoverPaper: string,

    toolbarDensityAction: string,
    toolbarDensityActionPopover: string,
    toolbarDensityActionPopoverPaper: string,

    toolbarRefreshLayoutAction: string,
    toolbarExportAction: string,

    header: string,
    headerCell: string,
    headerCellPinned: string,

    headerInnerCell: string,
    headerInnerCellContent: string,

    headerSortIconButton: string,

    headerActionsIconButton: string,
    headerActionsPopover: string,
    headerActionsPopoverPaper: string,

    pinnedColumn: string,
    currentSortedByColumn: string,

    columnResizer: string,
    columnResizerDragged: string,

    body: string,
    row: string,
    selectedRow: string,

    detailedPanelContainer: string,
    detailedPanel: string,
    detailedPanelVisible: string,

    cell: string,
    innerCell: string,

    cellEditorPopover: string,
    cellEditorPopoverPaper: string,
    cellEditor: string,
    cellEditable: string,

    footer: string,

    footerActions: string,
    footerActionsButton: string,
    footerActionsSelectionText: string,
    footerActionsPopover: string,
    footerActionsPopoverPaper: string,

    footerPagination: string,
    footerPaginationText: string,
    footerPaginationPageSizesAction: string,
    footerPaginationPageSizesActionPopover: string,
    footerPaginationPageSizesActionPopoverPaper: string,
    footerPaginationNextIcon: string,
    footerPaginationPrevIcon: string,
}

export interface DataGridLoading {
    state: boolean,
    count: number,
    animationName: string | any,
    animationTimeout: number | any,
}

export interface DataGridLayout {
    disableReordering: boolean,
    disableResizing: boolean,
    disableTogglingPins: boolean,
    disableTogglingVisibility: boolean,

    hideFooter: boolean,
    hidePagination: boolean,
    hidePageSize: boolean,
    hideFooterActions: boolean,

    hideToolbar: boolean,
    hideColumnFiltering: boolean,
    hideLayoutRefreshing: boolean,
    hideDensityChanging: boolean,
    hideExporting: boolean,

    hideSelectAll: boolean,
}

export type GetExportInfoFunc = () => Promise<DataGridExportInfo | undefined>;

export type GetSingleSelectionFooterTextFunc = (entry: DataGridSelectionEntry) => string;

export interface DataGridMisc {
    classNames: Partial<DataGridClassnames>,
    EmptyContentComponent?: ReactNode,
    LoadingComponent?: ReactNode,
    getExportInfo?: GetExportInfoFunc,
    resetSelectionOnPageSizeChange?: boolean,
    resetSelectionOnCurrentPageChange?: boolean,
    resetSelectionOnSortByChange?: boolean,
    singleSelectionOnly?: boolean,
    getSingleSelectionFooterText?: GetSingleSelectionFooterTextFunc,
    visibleRows?: number,
}

export interface DataGridFooterActionVisibilityConstraints {
    minSelectedRows?: number,
    maxSelectedRows?: number,
}


export interface DataGridSavedStateEntryData {
    pageSize?: number,
    sortBy?: DataGridSortBy,
    columns: Array<Pick<DataGridInternalColumn, 'width' | 'visible' | 'order' | 'pinned' | 'pinnedType' | 'pinnedOrder' | 'name' | 'type'>>,
    density: DataGridDensities,
}

export interface DataGridSavedStateEntry<T = DataGridSavedStateEntryData> {
    name: string,
    version: number,
    data: T;
}

export interface SaveDataGridSavedStateEntry<T = DataGridSavedStateEntryData> {
    name: string,
    version: string,
    data: T;
}

export type DataGridSavedState = Array<DataGridSavedStateEntry>;

export type DataGridFooterActionVisibilityConstraintCallback = (
    selectedRows: DataGridSelectionEntry[],
    excludedRows: DataGridSelectionEntry[],
    allRowsSelected: boolean,
    pagination: DataGridPagination
) => boolean;

export type DataGridActionInvocationCallback = (
    action: DataGridFooterAction,
    selectedRows: DataGridSelectionEntry[],
    excludedRows: DataGridSelectionEntry[],
    allRowsSelected: boolean,
    pagination: DataGridPagination
) => PromiseLike<void>;

export interface DataGridFooterAction {
    name: string,
    title: string | ((selectedRows: DataGridSelectionEntry[]) => ReactNode),
    visibilityConstraints: DataGridFooterActionVisibilityConstraints | DataGridFooterActionVisibilityConstraintCallback,
    type: DataGridActionTypes,
}


export interface DataGridDispatcherAction {
    type: DataGridStateActions,
    payload: any,
    emitEvent?: boolean,
    saveState?: boolean;
}

type DataGridEvent<P, C = P> = (prev?: P, current?: C) => void;
type DataGridEventWithChange<P, C = P, CH = C> = (prev?: P, current?: C, changes?: CH) => void;

export interface DataGridReorderingEventArg {
    pinned: Record<string, number>,
    normal: Record<string, number>,
}

export interface DataGridEvents {
    onSortByChanged?: DataGridEvent<DataGridSortBy | undefined, DataGridSortBy>,
    onPageSizeChanged?: DataGridEventWithChange<Partial<DataGridPagination>, Partial<DataGridPagination>, number>,
    onCurrentPageChanged?: DataGridEventWithChange<Partial<DataGridPagination>, Partial<DataGridPagination>, number>,
    onColumnVisibilitiesToggled?: DataGridEventWithChange<Record<string, boolean>>,
    onColumnsPinnedToggled?: DataGridEventWithChange<Record<string, DataGridColumnPinnedTypes>>,
    onColumnsResized?: DataGridEventWithChange<Record<string, DataGridColumnWidth>>,
    onColumnsReordered?: DataGridEvent<DataGridReorderingEventArg>,
    onRowSelectionInfoChanged?: DataGridEvent<DataGridRowSelectionInfo>,
    onLayoutRefreshed?: DataGridEvent<void>,
    onDensityChanged?: DataGridEvent<DataGridDensities>,
    onActionInvoked?: DataGridActionInvocationCallback,
    onCellEdited?: (prevValue: any, newValue: any, rowKey: string | number, colName: string) => PromiseLike<boolean>,
}


export interface DataGridRowSelectionInfo {
    selectedRows: DataGridSelectionEntry[],
    excludedRows: DataGridSelectionEntry[],
    allRowsSelected: boolean,
}

export interface DataGridStateReducerInitializer {
    initialState: DataGridState,
    preliminaryColumns: DataGridColumn[],
    preliminaryDensity?: DataGridDensities,
    columns: DataGridColumn[],
}


export interface DataGridExportInfo {
    title: string,
    body: Array<Record<string, string>>,
}

export type DataGridConfiguration = Exclude<DataGridInternalState, DataGridRowSelectionInfo>;

export type DataGridPaginationState = Exclude<DataGridPagination, {
    sizes?: number[]
}>


// ############################# COMPONENT PROPS #############################

export interface DataGridProps extends DataGridEvents {
    id?: string,
    rows: DataGridRow[],
    footerActions?: DataGridFooterAction[],
    columns: DataGridColumn[],
    state?: Partial<DataGridState>,
    initialLayoutState?: DataGridInitialLayoutState,

    disableReordering?: boolean,
    disableResizing?: boolean,
    disableTogglingPins?: boolean,
    disableTogglingVisibility?: boolean,

    hideFooter?: boolean,
    hidePageSize?: boolean,
    hidePagination?: boolean,
    hideFooterActions?: boolean,
    hideToolbar?: boolean,
    hideColumnFiltering?: boolean,
    hideLayoutRefreshing?: boolean,
    hideDensityChanging?: boolean,
    hideExporting?: boolean,
    hideSelectAll?: boolean,

    resetSelectionOnPageSizeChange?: boolean,
    resetSelectionOnCurrentPageChange?: boolean,
    resetSelectionOnSortByChange?: boolean,
    singleSelectionOnly?: boolean,
    classNames?: Partial<DataGridClassnames>,
    EmptyContentComponent?: ReactNode,
    LoadingComponent?: ReactNode,
    getExportInfo?: GetExportInfoFunc,
    getSingleSelectionFooterText?: GetSingleSelectionFooterTextFunc,
    visibleRows?: number,

    name?: string,
    version?: number,
}

// ############################# DEFAULT VALUES #############################


export const DefaultDataGridPageSizes = [
    10,
    20,
    50,
    100,
]

export const DefaultDataGridPageSize = DefaultDataGridPageSizes[0];

export const InitialDataGridCurrentPage = 1;

export const DefaultDataGridPagination: DataGridPagination = {
    currentPage: InitialDataGridCurrentPage,
    pageSize: DefaultDataGridPageSize,
    sizes: DefaultDataGridPageSizes,
    length: 0,
}

export const DefaultDataGridDensity = DataGridDensities.standard;

export const DefaultDataGridColumnType = DataGridColumnTypes.string;

export const DefaultDataGridColumnAlignment = DataGridColumnAlignments.left;

export const DefaultDataGridLayout: DataGridLayout = {
    disableReordering: false,
    disableResizing: false,
    disableTogglingPins: false,
    disableTogglingVisibility: false,
    hidePagination: false,
    hideFooterActions: false,
    hideToolbar: false,
    hideColumnFiltering: false,
    hideLayoutRefreshing: false,
    hideDensityChanging: false,
    hideExporting: false,
    hideSelectAll: false,
    hideFooter: false,
    hidePageSize: false,
}

export const DefaultDataGridEvents: DataGridEvents = {
    onSortByChanged: undefined,
    onPageSizeChanged: undefined,
    onCurrentPageChanged: undefined,
    onColumnVisibilitiesToggled: undefined,
    onColumnsPinnedToggled: undefined,
    onColumnsResized: undefined,
    onColumnsReordered: undefined,
    onRowSelectionInfoChanged: undefined,
    onLayoutRefreshed: undefined,
    onDensityChanged: undefined,
    onActionInvoked: undefined,
    onCellEdited: undefined,
}

export const DefaultDataGridWidth: DataGridColumnWidth = {
    size: 100,
    minWidth: 100,
    type: DataGridColumnWidthTypes.default,
}

export const DefaultDataGridFlexedWidth: DataGridColumnWidth = {
    size: 1,
    minWidth: 100,
    type: DataGridColumnWidthTypes.flex,
}

export const DefaultDataGridLoading: DataGridLoading = {
    state: false,
    animationName: 'item',
    animationTimeout: 500,
    count: DefaultDataGridPageSize,
}

export const DefaultDataGridMisc: DataGridMisc = {
    classNames: {},
    EmptyContentComponent: undefined,
    LoadingComponent: undefined,
    resetSelectionOnPageSizeChange: false,
    resetSelectionOnCurrentPageChange: false,
    resetSelectionOnSortByChange: false,
    singleSelectionOnly: false,
    getExportInfo: undefined,
    getSingleSelectionFooterText: undefined,
    visibleRows: DefaultDataGridPageSize,
}

export const DefaultDataGridState: DataGridInternalState = {
    allRowsSelected: false,
    columns: [],
    excludedRows: [],
    initialColumns: [],
    groups: [],
    // @ts-ignore
    initialState: undefined,
    selectedRows: [],
    sortBy: undefined,
    pagination: DefaultDataGridPagination,
    loading: DefaultDataGridLoading,
    density: DefaultDataGridDensity
}

export const DefaultDataGridPropState: DataGridState = {
    sortBy: undefined,
    pagination: DefaultDataGridPagination,
    loading: DefaultDataGridLoading,
    density: DefaultDataGridDensity,
    group: undefined,
}

export const DefaultDataGridCell: DataGridCell = {
    value: undefined,
    editableValue: undefined,
    editable: false,
    EditableContentComponent: undefined,
}

import CachingService, {CachingServiceEntities, CachingServiceLocalStorageKeys, CachingServiceLocalStorageKeysType} from "../caching";
import type CachingServiceLocalStorageEntity from "../caching/entity/local-storage";
import type {AuthenticatedUser, LoginInfo} from "./type-declerations";
import dayjs from "dayjs";


type StorageListener = (this: Window, ev: WindowEventMap['storage']) => any;

/**
 * This class houses the logic for authenticating a user.
 */
class AuthenticationService {
    /**
     * The caching service instance used for authentication.
     * @private
     */
    private static cachingInstance: CachingServiceLocalStorageEntity<CachingServiceLocalStorageKeysType> = CachingService.of(CachingServiceEntities.localStorage);
    /**
     * The caching instance keys used for authentication.
     * @private
     */
    private static cachingInstanceKeys = CachingServiceLocalStorageKeys;


    /**
     * Fetches the logged-in user information.
     */
    public static get authenticatedUser(): AuthenticatedUser | null {
        const raw = this.cachingInstance.get(this.cachingInstanceKeys.loggedInUser);
        if (!raw)
            return null;
        return JSON.parse(raw);
    }

    /**
     * Fetches the number of milliseconds the user should stay logged.
     * * if the user is not logged in or their logged-in duration has expired, returns undefined.
     */
    public static get authenticationExpiryInMs(): number | null {
        const loggedInUser = this.authenticatedUser;
        if (!loggedInUser)
            return loggedInUser;
        const res = dayjs(loggedInUser.expiryTimestamp).valueOf() - dayjs().valueOf();
        if (res <= 0) {
            this.cachingInstance.remove(this.cachingInstanceKeys.loggedInUser);
            return null;
        }
        return res;
    }

    /**
     * Logs the user into the system by creating a 24h expiry timer in their storage.
     */
    public static login(loginInfo: LoginInfo): void {
        const oldValue = this.cachingInstance.get(this.cachingInstanceKeys.loggedInUser);
        this.cachingInstance.set(this.cachingInstanceKeys.loggedInUser, JSON.stringify({
            id: loginInfo.id,
            token: loginInfo.token,
            expiryTimestamp: new Date().setMinutes(new Date().getMinutes() + loginInfo.tokenLifeSpanMinutes),
            firstName: loginInfo.firstName,
            lastName: loginInfo.lastName,
            fullName: `${loginInfo.firstName} ${loginInfo.lastName}`,
            companyName: loginInfo.companyName,
            profileImageUrl: loginInfo.profileImageUrl,
            companyImageUrl: loginInfo.companyImageUrl,
        } as AuthenticatedUser));
        window.dispatchEvent(new StorageEvent('storage', {
            key: this.cachingInstanceKeys.loggedInUser,
            oldValue: oldValue,
            newValue: this.cachingInstance.get(this.cachingInstanceKeys.loggedInUser),
        }))
    }

    /**
     * Logs the user out the system by removing the logged-in duration in their storage.
     */
    public static logout(): void {
        this.cachingInstance.remove(this.cachingInstanceKeys.loggedInUser);
        // event is dispatched so the auth-middleware can navigate the user.
        window.dispatchEvent(new StorageEvent('storage', {
            key: this.cachingInstanceKeys.loggedInUser,
            oldValue: this.cachingInstance.get(this.cachingInstanceKeys.loggedInUser),
            newValue: null,
        }))
    }

    /**
     * An event listener that listens for when the value of the logged-in user in the cache has been removed and only then calls the
     * provided [callback].
     * @param callback
     */
    public static onAuthenticatedUserRemoved(callback: (ev: StorageEvent) => void): () => void {
        const listener: StorageListener = (ev) => {
            if (ev.key === null && !ev.storageArea?.length) {
                // all the entries were removed
                callback(ev);
                return;
            }
            switch (ev.key) {
                case this.cachingInstanceKeys.loggedInUser:
                    if (!ev.newValue) {
                        callback(ev);
                    } else if (!this.authenticatedUser?.id || !this.authenticationExpiryInMs) {
                        callback(ev);
                    }
                    break;
                default:
                    return;
            }
        }
        window.addEventListener('storage', listener);
        return () => window.removeEventListener('storage', listener);
    }

    /**
     * An event listener that listens for when the value of the token in the cache has been changed and only then calls the provided
     * @param callback
     */
    public static onAuthenticationTokenChanged(callback: (token: AuthenticatedUser['token'] | undefined) => void): () => void {
        const listener: StorageListener = (ev) => {
            if (ev.key === null && !ev.storageArea?.length) {
                // all the entries were removed
                callback(undefined);
                return;
            }
            switch (ev.key) {
                case this.cachingInstanceKeys.loggedInUser:
                    return callback(this.authenticatedUser?.token);
                default:
                    return;
            }
        }
        window.addEventListener('storage', listener);
        return () => window.removeEventListener('storage', listener);
    }

}


export * from "./type-declerations";
export default AuthenticationService;

import {useCallback} from "react";
import * as ReactRouter from "react-router-dom";
import type {Query} from '../use-query';
import {createSearchParams} from "../use-query";

export interface Path<Q extends Record<string, any>> {
    pathname: string;
    hash: string;
    query: Q;
    schema: Query.SchemaCreator<Q>;
}

export type To<Query extends Record<string, any>> = string | Partial<Path<Query>>;

export interface NavigateFunction {
    <Query extends Record<string, any> = any>(to: To<Query>, options?: ReactRouter.NavigateOptions): void;

    (delta: number): void;

    (path: string): void;
}

/**
 * Returns an imperative method for changing the location.
 *
 * * Used by <Link>s, but may also be used by other elements to change the location.
 * * This hook uses the [useNavigate] hook of react-router internally to achieve its functionality.
 */
const useNavigate = (): NavigateFunction => {
    const navigateRaw = ReactRouter.useNavigate();

    return useCallback<NavigateFunction>((...args: any[]) => {
        const [to, options] = args;
        if (typeof to === "number") {
            return navigateRaw(to);
        }
        if (typeof to === "string") {
            return navigateRaw(to, options as ReactRouter.NavigateOptions);
        }
        const path = to as Partial<Path<any>>;
        if (!path.query) {
            return navigateRaw(to, options as ReactRouter.NavigateOptions);
        }
        const urlSearchParams = createSearchParams(path.query);
        return navigateRaw({
            pathname: path.pathname,
            hash: path.hash,
            search: !Object.keys(urlSearchParams).length
                ? undefined
                : '?'.concat(ReactRouter.createSearchParams(urlSearchParams).toString())
        }, options as ReactRouter.NavigateOptions);
    }, [navigateRaw]);
}

export default useNavigate;

import React, {createContext, FunctionComponent, PropsWithChildren, ReactNode, Suspense, useEffect, useLayoutEffect} from "react";


export interface LoadingBarTriggerContextValue {
    onStart: VoidCallback,
    onFinish: VoidCallback,
}

/**
 * The LoadingBarTriggerContext is used to trigger the loading bar's animations.
 */
const LoadingBarTriggerContext = createContext<LoadingBarTriggerContextValue>({
    onStart: () => void 0,
    onFinish: () => void 0,
});

/**
 * A convenience provider to access the LoadingBarTriggerContext.
 */
export const LoadingBarTriggerProvider = LoadingBarTriggerContext.Provider;

/**
 * A convenience hook to access the LoadingBarTriggerContext.
 */
export const useLoadingBarTrigger = () => React.useContext(LoadingBarTriggerContext);


const LoadingBarTriggerFallback: FunctionComponent<PropsWithChildren<{}>> = (props) => {
    const {onStart, onFinish} = useLoadingBarTrigger();

    /**
     * As soon as the component mounts:
     * - starts the loading bar's animations.
     */
    useLayoutEffect(() => {
        onStart();
    }, [onStart])

    /**
     * As soon as the component un-mounts:
     * - completes the loading bar's animations.
     */
    useEffect(() => () => {
        onFinish();
    }, [onFinish])

    return (
        <>
            {props.children}
        </>
    );
}

export interface LoadingBarTriggerProps {
    fallback?: ReactNode,
}

export const LoadingBarTrigger: FunctionComponent<PropsWithChildren<LoadingBarTriggerProps>> = (props) => {

    return (
        <>
            <Suspense fallback={<LoadingBarTriggerFallback>{props.fallback}</LoadingBarTriggerFallback>}>
                {props.children}
            </Suspense>
        </>
    );
}


export default LoadingBarTrigger;

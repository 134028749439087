import {matchPath, matchRoutes, useLocation} from "react-router-dom";
import {FunctionComponent, useCallback, useEffect} from "react";
import AppRoutes from "../../../core/models/routes";
import {AuthenticationService, BroadcastService, CachingServiceEntities, useNavigate} from "../../../@bizkeytech";
import CachingService, {CachingServiceLocalStorageKeys} from "../../../core/services/caching";

interface AuthenticationMiddlewareBroadcastListener {
    (arg: AuthenticationMiddlewareBroadcastArg): void;
}

export interface AuthenticationMiddlewareBroadcastArg {
    type: 'login-successful',
    payload?: any,
}

const AuthenticationMiddleware: FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Caches the current route in the local storage.
     * - If the current route is not cacheable, it does nothing.
     * - If the current route is cacheable, it caches the current route in the local storage.
     */
    const cacheRoute = useCallback<VoidCallback>(() => {
        const unCachebleRoutes: string[] = [
            AppRoutes.public.error._base,
            AppRoutes.public.authentication._base,
            AppRoutes.public.test,
            AppRoutes.public.redirectLink,
        ];
        if (!!matchRoutes(unCachebleRoutes.map(e => ({path: e, end: false})), location.pathname)) {
            return;
        }
        CachingService.of(CachingServiceEntities.localStorage).set(CachingServiceLocalStorageKeys.cachedUrl, JSON.stringify(location));
    }, [location])

    /**
     * Navigates the user to the authentication view.
     * - Caches the current route as well.
     */
    const navigateToAuthView = useCallback<VoidCallback>(() => {
        cacheRoute();
        navigate(AppRoutes.public.authentication.redirect, {replace: true});
    }, [cacheRoute, navigate])

    /**
     * Navigates the user to the panel view.
     * - If the cached route is available, navigates the user to the cached route.
     * - Otherwise, navigates the user to the panel view.
     */
    const navigateToPanelView = useCallback<VoidCallback>(() => {
        try {
            const cachedUrlString = CachingService.of(CachingServiceEntities.localStorage).get(CachingServiceLocalStorageKeys.cachedUrl);
            if (!cachedUrlString) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('cachedUrlString is not available');
            }
            const cachedUrl = JSON.parse(cachedUrlString);
            if (!cachedUrl) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('cachedUrlString is not available');
            }
            CachingService.of(CachingServiceEntities.localStorage).remove(CachingServiceLocalStorageKeys.cachedUrl);
            return navigate(cachedUrl, {replace: true});
        } catch (e) {
            navigate(AppRoutes.panel.base, {replace: true});
        }
    }, [navigate])

    /**
     * With each change in the [pathname] property of the current location:
     *
     * - checks if the user is logged in and in auth view, in that case, navigates them to panel view.
     * - checks if the current pathname is a public route, in that case, does nothing
     * - checks if authentication duration exists, if not, navigates the user to the authentication view
     * - sets a timer at the end of which the user will be navigated to the authentication view since the timer signifies the logged-in
     * duration of the user.
     */
    useEffect(() => {
        const InRedirectAuthView = !!matchPath({path: AppRoutes.public.authentication.redirect, end: false}, location.pathname);
        if (InRedirectAuthView && !!AuthenticationService.authenticationExpiryInMs)
            return navigateToPanelView();

        const publicRoutes = [
            AppRoutes.public.authentication.base,
            AppRoutes.public.error.base,
            AppRoutes.public.test,
            AppRoutes.public.redirectLink,
        ]
        const match = publicRoutes.find(path => matchPath({path, end: false}, location.pathname));
        if (match)
            return;

        const timerInMs = AuthenticationService.authenticationExpiryInMs;
        if (!timerInMs) {
            // user not logged in and accessed panel.
            return navigateToAuthView();
        }
        const setTimeoutTimer = setTimeout(() => {
            navigateToAuthView();
        }, timerInMs);
        return () => {
            clearTimeout(setTimeoutTimer);
        }
    }, [location.pathname, navigateToAuthView, navigateToPanelView]);

    /**
     * With each change in [navigateToAuthView] callback:
     * - attaches an event listener that when the logged-in duration of the user is removed from the local storage, navigates the user
     * to the auth view.
     */
    useEffect(() => AuthenticationService.onAuthenticatedUserRemoved(() => {
        const publicRoutes = [
            AppRoutes.public.authentication.base,
            AppRoutes.public.error.base,
            AppRoutes.public.test,
            AppRoutes.public.redirectLink,
        ]
        const match = publicRoutes.find(path => matchPath({path, end: false}, location.pathname));
        if (match)
            return;
        navigateToAuthView()
    }), [location.pathname, navigateToAuthView])

    /**
     * Listens to the broadcast channel for the authentication middleware.
     * - If [login-successful], navigates the user to the panel view.
     */
    const listenToBroadcast = useCallback<AuthenticationMiddlewareBroadcastListener>((arg) => {
        switch (arg.type) {
            case "login-successful": {
                navigateToPanelView();
                break;
            }
            default:
                break;
        }
    }, [navigateToPanelView])

    /**
     * With each change in [listenToBroadcast] callback:
     * - adds an event listener to the broadcast channel for the authentication middleware.
     * - returns the cleanup function.
     */
    useEffect(() => {
        const result = BroadcastService.addEventListener<AuthenticationMiddlewareBroadcastArg>(
            'authentication-middleware',
            "message",
            listenToBroadcast,
        );
        return result.close;
    }, [listenToBroadcast]);


    return (<></>);
}

export default AuthenticationMiddleware;

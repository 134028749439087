import React from "react";
import {
    DataGridControllerContext,
    DataGridEventHandlersContext,
    DataGridLayoutContext,
    DataGridMiscContext,
    DataGridStateContext
} from "../../../index";


const DataGridProvider = ({state, api, layout, eventHandlers, misc, children}) => {

    return (
        <>
            <DataGridStateContext.Provider value={state}>
                <DataGridControllerContext.Provider value={api}>
                    <DataGridLayoutContext.Provider value={layout}>
                        <DataGridEventHandlersContext.Provider value={eventHandlers}>
                            <DataGridMiscContext.Provider value={misc}>
                                {children}
                            </DataGridMiscContext.Provider>
                        </DataGridEventHandlersContext.Provider>
                    </DataGridLayoutContext.Provider>
                </DataGridControllerContext.Provider>
            </DataGridStateContext.Provider>
        </>
    );
}
export default DataGridProvider;

import './index.scss';
import React, {FunctionComponent, useCallback, useEffect} from "react";
import LoadingCircles from "../../../components/loading-circles";
import {ParamParseKey, useParams} from "react-router-dom";
import AppRoutes from "../../../../core/models/routes";
import {SEO, ToastTypes, useIsMounted, useNavigate} from "../../../../@bizkeytech";
import {CodeContentTypeIds} from "../../../../core/constants/enums";
import UIUtils from "../../../utils";
import EnvService from "../../../../core/services/env";
import ApiService from "../../../../core/services/api";

const RedirectLinkView: FunctionComponent = () => {
    const navigate = useNavigate();
    const params = useParams<ParamParseKey<typeof AppRoutes.public.redirectLink>>();
    const isMounted = useIsMounted();

    /**
     * Fetches the redirection link from the token from the server.
     *
     * - If the response is successful, it redirects the user to the redirection link.
     * - If the response is unsuccessful, it redirects the user to the access denied page.
     * - If the response is empty, it redirects the user to the not found page.
     */
    const getRedirectionLinkFromToken = useCallback(async (abortController: AbortController) => {
        const response = await ApiService.scanCode(params.token!, abortController);
        if (!isMounted() || response?.aborted)
            return;
        if (!response || !response.resultFlag)
            return navigate(AppRoutes.public.error.notFound, {replace: true});

        if (response.data) {
            switch (response.data.contentTypeId) {
                case CodeContentTypeIds.externalLink: {
                    if (!(response.data.link.startsWith('http://') || response.data.link.startsWith('https://'))) {
                        response.data.link = `https://${response.data.link}`;
                    }
                    window.location.href = response.data.link;
                    break;
                }
                // TODO: add other content types for redirection of code links
                default: {
                    UIUtils.toast(ToastTypes.warning, {message: "Unsupported Content Type is embedded into your code."})
                    break;
                }
            }
        } else {
            navigate(AppRoutes.public.error.notFound, {replace: true});
        }
    }, [isMounted, navigate, params.token])

    /**
     * As soon as the component mounts:
     * - Fetches the redirection link from the token when the component mounts.
     */
    useEffect(() => {
        const abortController = new AbortController();
        getRedirectionLinkFromToken(abortController).then();
        return () => {
            abortController.abort();
        }
    }, [getRedirectionLinkFromToken]);

    return (
        <>
            <SEO title={`Redirecting You - ${EnvService.websiteName}`}/>
            <div className={'redirect-link-view'}>
                <LoadingCircles text={'Redirecting You'}/>
            </div>
        </>
    );
}

export default RedirectLinkView;

import React, {ButtonHTMLAttributes, DetailedHTMLProps, forwardRef} from "react";
import classnames from "classnames";


const DataGridButton = forwardRef<HTMLButtonElement, DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>((
    {
        className,
        children,
        ...props
    }, ref) => {
    return (
        <button
            type={'button'}
            ref={ref}
            {...props}
            className={classnames('data-grid-button', className)}
        >
            {children}
        </button>
    )
})

export default DataGridButton;

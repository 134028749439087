import type {FunctionComponent} from "react";
import {Typography} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import type {ToastContentProps} from "react-toastify";

export interface IInfoToastContent {
    message: string,
}

const InfoToastContent: FunctionComponent<ToastContentProps<IInfoToastContent>> =
    ({
         data,
     }) => {

        return (
            <>
                <div className={'toast-content'}>
                    <InfoOutlined color={'info'} sx={{marginRight: 1}}/>
                    <Typography variant={'body2'}>
                        {data?.message ?? ''}
                    </Typography>
                </div>
            </>
        )
    }

export default InfoToastContent;

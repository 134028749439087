import {
    ArrayElementType,
    QueryBuilderColumn,
    QueryBuilderColumnTypeEnum,
    QueryBuilderQuery,
    QueryBuilderQueryRule,
    QueryBuilderQueryRuleListValueEntry,
    QueryBuilderStateQuery
} from "../../../index";
import {Utils} from "../../../../base/core";

export type QueryType = QueryBuilderQuery | QueryBuilderStateQuery;
export type RuleType<T extends QueryType> = ArrayElementType<ArrayElementType<T['filters']>['rules']>;
export type RuleMapperType<IN extends QueryType, OUT extends QueryType> = (value: RuleType<IN>, index: number, array: RuleType<IN>[], columns: QueryBuilderColumn[]) => RuleType<OUT>;

/**
 * @inheritDoc
 */
class QueryBuilderUtils extends Utils {

    /**
     * Sift through the state query and remove any rules that don't fit:
     *
     * * if any of the rules don't have their field set, remove the rule
     * * if any of the rules don't have their operator set, remove the rule
     * * if any of the rules don't have their value set (undefined only, and null if nullable is set to false), remove the rule
     * * if any of the filters don't have their condition set, remove the condition
     * * if any of the filters don't have any rules, remove the filters only after the rules have been sifted
     *
     * @param query                     The state query to sift
     * @param columns                   The columns to sift through
     * @param ruleMapper                The mapper to use to map the rules
     * @param allowIncompleteRules      Whether or not to allow incomplete rules
     */
    public static siftQueryWithColumns<IN extends QueryType, OUT extends QueryType>(
        query: IN,
        columns: QueryBuilderColumn[],
        ruleMapper: RuleMapperType<IN, OUT>,
        allowIncompleteRules: boolean = true,
    ): OUT {
        return {
            keyword: query.keyword ?? "",
            filters: (query.filters ?? [])
                .filter((filter) => filter.condition)
                .map((filter) => {
                    return {
                        condition: filter.condition,
                        rules: filter.rules
                            .filter((rule) => {
                                const column = columns.find((column) => column.field === rule.field);
                                return (
                                    column &&
                                    rule.field &&
                                    (
                                        (column.isNullable && ((rule.displayEmptyValuesOnly && rule.value === null) || (!rule.displayEmptyValuesOnly && (allowIncompleteRules || (rule.value !== undefined && rule.value !== null))))) ||
                                        (!column.isNullable && (rule.operator || column.type === QueryBuilderColumnTypeEnum.Bool) && (allowIncompleteRules || (rule.value !== undefined && rule.value !== null)))
                                    )
                                )
                            })
                            .map((rule, index, array) => ruleMapper(rule as any, index, array as any, columns)),
                    }
                })
                .filter((filter) => filter.rules.length > 0),
        } as unknown as OUT;
    }

    /**
     * Map a query rule to a state query rule
     * @param rule      The rule to map
     */
    public static mapQueryRuleToStateQueryRule: RuleMapperType<QueryBuilderQuery, QueryBuilderStateQuery> = (rule) => {
        return {
            field: rule.field,
            operator: rule.operator,
            value: rule.value,
            displayEmptyValuesOnly: rule.displayEmptyValuesOnly,
        } as QueryBuilderQueryRule
    }

    /**
     * Map a state query rule to a query rule
     * @param rule    The rule to map
     */
    public static mapStateQueryRuleToStateQueryRule: RuleMapperType<QueryBuilderStateQuery, QueryBuilderStateQuery> = (rule) => {
        return {
            field: rule.field,
            operator: rule.operator,
            value: rule.value,
            displayEmptyValuesOnly: rule.displayEmptyValuesOnly,
        } as QueryBuilderQueryRule
    }

    /**
     * Map a query rule to a query rule
     * @param rule    The rule to map
     */
    public static mapQueryRuleToSearchQueryRule: RuleMapperType<QueryBuilderQuery, QueryBuilderQuery> = (rule) => {
        return {
            field: rule.field,
            operator: rule.operator,
            value: rule.displayEmptyValuesOnly
                ? null
                : rule.type === QueryBuilderColumnTypeEnum.List ?
                    rule.multiSelect
                        ? (rule.value as QueryBuilderQueryRuleListValueEntry[])?.map(e => e.key)
                        : [(rule.value as QueryBuilderQueryRuleListValueEntry)?.key]
                    : rule.value,
        } as QueryBuilderQueryRule
    }

    /**
     * Map a state query rule to a query rule
     * @param rule      The rule to map
     * @param index     The index of the rule
     * @param array     The array of rules
     * @param columns   The columns to map the rule to
     */
    public static mapStateQueryRuleToQueryRule: RuleMapperType<QueryBuilderStateQuery, QueryBuilderQuery> = (rule, index, array, columns) => {
        const column = columns.find((column) => column.field === rule.field)!;
        return {
            field: rule.field,
            operator: rule.operator,
            value: rule.value,
            multiSelect: column.multiSelect,
            isNullable: column.isNullable,
            type: column.type,
            displayEmptyValuesOnly: column.isNullable && rule.displayEmptyValuesOnly,
        } as QueryBuilderQueryRule
    }

    /**
     * Get a state query from a query
     * @param query     The query to get the state query from
     * @param columns   The columns to map the query to
     */
    public static getApiQueryFromStateQuery(query: QueryBuilderStateQuery, columns: QueryBuilderColumn[]): QueryBuilderQuery {
        return this.siftQueryWithColumns(query, columns, this.mapStateQueryRuleToQueryRule, false);
    }
}

export default QueryBuilderUtils;

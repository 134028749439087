export enum CachingServiceLocalStorageKeys {
    loggedInUser = 'logged-in-user',
}

export type CachingServiceLocalStorageKeysType = ReverseMap<typeof CachingServiceLocalStorageKeys>;


export enum CachingServiceCookiesKeys {
    loggedInUser = 'logged-in-user',
}

export type CachingServiceCookiesKeysType = ReverseMap<typeof CachingServiceCookiesKeys>;


/**
 * The available entities of the caching service.
 */
export enum CachingServiceEntities {
    localStorage = 'localStorage',
    cookies = 'cookies',
}


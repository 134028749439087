import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {DataGridMiscContext, DataGridStateContext} from "../../../index";
import DataGridBody from "../body";
import DataGridHeader from "../header";
import DataGridObserversService from "../../../core/services/observers";
import {useCombinedRefs, useResizeObserver} from "../../../../base/ui";

const DataGridTable = ({rows}) => {
    const {classNames, visibleRows} = useContext(DataGridMiscContext);
    const {loading: {state: loading}, pagination} = useContext(DataGridStateContext);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();
    const {rect: layoutRect, ref: layoutResizeObserverRef} = useResizeObserver();
    const [scrolling, setScrolling] = useState(false);
    const ref = useCombinedRefs(containerRef, layoutResizeObserverRef);
    const mousePosition = useRef(null);
    const showEmptyContent = useMemo(() => !rows?.length, [rows?.length])

    /**
     * With each change in the [loading] state, or [showEmptyContent] value or the [visibleRows] value or [pagination] value:
     * - attaches an observer that would sync the height of the layout rect with the provided constraints.
     */
    useEffect(() => {
        if (!containerRef.current)
            return;

        const headerElement = containerRef.current?.querySelector('.data-grid-header')
        const bodyElement = containerRef.current?.querySelector('.data-grid-body')

        if (!headerElement || !bodyElement)
            return;

        const observer = DataGridObserversService.newResizeObserver(syncHeight);
        DataGridObserversService.observeResizeObserver(observer, headerElement);
        DataGridObserversService.observeResizeObserver(observer, bodyElement);
        syncHeight([headerElement, bodyElement])
        return () => DataGridObserversService.disconnectResizeObserver(observer);
    }, [containerRef, pagination?.currentPage, pagination?.pageSize, visibleRows, loading, showEmptyContent, rows?.length])

    /**
     * Syncs the height of the layout container with the provided constraints:
     *
     * * the height will be calculated by the following formula:
     * - max(height of the header + height of visible rows, height of the loading container, height of the empty content container)
     * @param {(ResizeObserverEntry | Element)[]} entries
     */
    const syncHeight = (entries) => {
        window.requestAnimationFrame(() => {
            if (!entries?.length || !containerRef.current)
                return;
            let loadingHeight = containerRef.current?.querySelector('.data-grid-loading-container')?.getBoundingClientRect()?.height ?? 0
            let emptyContentHeight = containerRef.current?.querySelector('.data-grid-empty-content-container')?.getBoundingClientRect()?.height ?? 0;
            let headerHeight = 0;
            let bodyHeight = 0;
            const headerElement = containerRef.current?.querySelector('.data-grid-header')
            const bodyElement = containerRef.current?.querySelector('.data-grid-body')

            for (const entry of entries) {
                switch (entry?.target ?? entry) {
                    case headerElement:
                        headerHeight = entry?.contentRect?.height ?? entry?.getBoundingClientRect()?.height ?? 0;
                        break;
                    case bodyElement:
                        bodyHeight = entry?.contentRect?.height ?? entry?.getBoundingClientRect()?.height ?? 0;
                        break;
                    default:
                        break
                }
            }

            if (headerHeight === 0)
                headerHeight = headerElement?.getBoundingClientRect()?.height ?? 0;
            if (bodyHeight === 0)
                bodyHeight = bodyElement?.getBoundingClientRect()?.height ?? 0;

            const visibleRowsHeight = Array.from(bodyElement.querySelectorAll('.data-grid-body-row') ?? [])
                .slice(0, visibleRows)
                .map(e => e.getBoundingClientRect().height ?? 0)
                .reduce((agg, c) => agg + c, 0);

            const includeMinimumHeight = loading || showEmptyContent;
            containerRef.current.style.height = Math.max(
                Math.max(
                    loadingHeight,
                    emptyContentHeight,
                    Math.min(
                        bodyHeight,
                        visibleRowsHeight
                    )
                ) + headerHeight + 8,
                includeMinimumHeight ? 400 + headerHeight + 8 : headerHeight + 8
            ) + 'px';
        })
    }

    /**
     * Starts the scrolling of the data-grid content.
     * @param {MouseEvent | TouchEvent} e
     */
    const startScrolling = (e) => {
        setScrolling(true);
        mousePosition.current = {
            pageX: e.pageX,
            pageY: e.pageY,
        }
    }

    /**
     * Scrolls the data-grid content with the given mouse movement.
     * @param {MouseEvent | TouchEvent} e
     */
    const scroll = useCallback((e) => {
        const layoutElement = containerRef.current;
        if (!layoutElement || !mousePosition.current) {
            return;
        }
        const movementX = (mousePosition.current.pageX - e.pageX);
        const movementY = (mousePosition.current.pageY - e.pageY);
        layoutElement.scrollBy({
            left: movementX,
            top: movementY,
            behavior: 'instant'
        })
        mousePosition.current = {
            pageX: e.pageX,
            pageY: e.pageY,
        }
    }, [])

    /**
     * Ends the scrolling of the data-grid content.
     */
    const endScrolling = useCallback(() => {
        setScrolling(false);
        mousePosition.current = null;
    }, [])

    /**
     * With each change in the [scrolling] state value:
     * - attaches or removes the event listeners for the scrolling feature of the data-grid
     */
    useLayoutEffect(() => {
        if (scrolling) {
            document.documentElement.addEventListener('mousemove', scroll);
            document.documentElement.addEventListener('touchmove', scroll);
            document.documentElement.addEventListener('mouseup', endScrolling);
            document.documentElement.addEventListener('touchend', endScrolling);
        } else {
            document.documentElement.removeEventListener('mouseup', endScrolling);
            document.documentElement.removeEventListener('touchend', endScrolling);
        }
    }, [scrolling, scroll, endScrolling])

    /**
     * As soon as the component un-mounts:
     * - removes the event listeners for the scrolling feature of the data-grid
     */
    useLayoutEffect(() => () => {
        document.documentElement.removeEventListener('mousemove', scroll);
        document.documentElement.removeEventListener('touchmove', scroll);
        document.documentElement.removeEventListener('mouseup', endScrolling);
        document.documentElement.removeEventListener('touchend', endScrolling);
    }, [scroll, endScrolling])


    const header = useMemo(() =>
            <DataGridHeader
                layoutRectWidth={layoutRect?.width}
            />,
        [layoutRect?.width])

    const body = useMemo(() =>
            <DataGridBody
                rows={rows}
                startScrolling={startScrolling}
                layoutRectWidth={layoutRect?.width}
            />,
        [rows, layoutRect?.width])

    return (
        <>
            <div
                className={classnames('data-grid-layout', classNames.layout)}
                ref={ref}
            >
                <table
                    className={classnames(
                        'data-grid-table',
                        classNames.table,
                        {'scrolling': scrolling},
                    )}
                >
                    {header}
                    {body}
                </table>
            </div>
        </>
    )
}

export default DataGridTable;

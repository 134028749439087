import {ApiExecutorEffect, ApiInterceptors, IApiExecutor,} from "./type-declarations";
import type {Partial} from "@react-spring/web";

/**
 * The interface that houses all the available api calls related to this application.
 */
class ApiService {

    /**
     * The executor of this api responsible for handling the api call themselves.
     * @private
     */
    protected static _executor: IApiExecutor;

    /**
     * The executor of this api responsible for handling the api call themselves.
     */
    public static get executor(): IApiExecutor {
        return this._executor;
    }

    /**
     * Sets the executor of this api responsible for handling the api call themselves.
     * @param value    The executor of this api responsible for handling the api call themselves.
     */
    public static set executor(value: IApiExecutor) {
        this._executor = value;
    }

    /**
     * Injects the given effects to the API executor.
     * @param effects   The effects to be injected.
     */
    public static injectEffects(effects: Array<ApiExecutorEffect>): void {
        return this._executor.injectEffects(effects);
    }


    /**
     * Injects api interceptors to the axios instance used in this interface.
     * @param interceptors
     */
    public static injectInterceptors(interceptors: Partial<ApiInterceptors>): void {
        return this._executor.injectInterceptors(interceptors);
    }
}


export * from './type-declarations';
export {default as ApiExecutor} from './executor';
export default ApiService;

import * as Bizkeytech from "../../../../@bizkeytech";

export enum CachingServiceLocalStorageKeys {
    dataGridSavedState = "data-grid-saved-state",
    cachedUrl = "cached-url",
}

export type CachingServiceLocalStorageKeysType = ReverseMap<typeof CachingServiceLocalStorageKeys>;


export enum CachingServiceCookiesKeys {
    dataGridSavedState = "data-grid-saved-state",
    cachedUrl = "cached-url",
}

export type CachingServiceCookiesKeysType = ReverseMap<typeof CachingServiceCookiesKeys>;

export enum AppCachingServiceEntities {

}

export type AllCachingServiceEntities = AppCachingServiceEntities | Bizkeytech.CachingServiceEntities;

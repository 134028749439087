import type {ReportCallback} from 'web-vitals';

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 * @param onPerfEntry
 */
const reportWebVitals = (onPerfEntry?: ReportCallback | undefined) => {
    if (!onPerfEntry || typeof onPerfEntry !== 'function') return;
    import('web-vitals').then(({onCLS, onFID, onFCP, onLCP, onTTFB}) => {
        onCLS(onPerfEntry);
        onFID(onPerfEntry);
        onFCP(onPerfEntry);
        onLCP(onPerfEntry);
        onTTFB(onPerfEntry);
    });
};

export default reportWebVitals;

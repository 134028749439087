import type {ButtonHTMLAttributes, DetailedHTMLProps} from 'react';
import React, {forwardRef} from "react";
import classnames from "classnames";


const DataGridIconButton = forwardRef<HTMLButtonElement, DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>((
    {
        className,
        children,
        ...props
    },
    ref) => {
    return (
        <button ref={ref} {...props} className={classnames('data-grid-icon-button', className)}>
            {children}
        </button>
    )
})

export default DataGridIconButton;

import {CssVarsTheme} from '@mui/material';
import {SystemStyleObject} from "@mui/system";
import {default as QueryBuilder} from './ui';

export enum QueryBuilderColumnTypeEnum {
    String = 'String',
    Int = 'Int',
    Decimal = 'Decimal',
    Bool = 'Bool',
    List = 'List',
    Date = 'Date',
    Time = 'Time',
    DateTime = 'DateTime',
}

export type QueryBuilderColumnType = ReverseMap<typeof QueryBuilderColumnTypeEnum>;


export interface QueryBuilderColumnBase {
    column: string;
    field: string;
    type: QueryBuilderColumnType;
    isNullable: boolean;
    sortable: boolean;
    isIncludedInKeywordSearch: boolean;
}

export type QueryBuilderColumn =
    QueryBuilderColumnString |
    QueryBuilderColumnInt |
    QueryBuilderColumnDecimal |
    QueryBuilderColumnBool |
    QueryBuilderColumnList |
    QueryBuilderColumnDate |
    QueryBuilderColumnTime |
    QueryBuilderColumnDateTime;


export interface QueryBuilderColumnString extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.String;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}

export interface QueryBuilderColumnInt extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.Int;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}

export interface QueryBuilderColumnDecimal extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.Decimal;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}

export interface QueryBuilderColumnBool extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.Bool;
    operators: null;
    trueValue: string;
    falseValue: string;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}

export interface QueryBuilderColumnList extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.List;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: boolean;
    options: Array<string | number | QueryBuilderQueryRuleListValueEntry>,
    apiUrl: null | string,
    baseUrl: string,
}

export interface QueryBuilderColumnDate extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.Date;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}

export interface QueryBuilderColumnTime extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.Time;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}

export interface QueryBuilderColumnDateTime extends QueryBuilderColumnBase {
    type: QueryBuilderColumnTypeEnum.DateTime;
    operators: Array<string>;
    trueValue: null;
    falseValue: null;
    multiSelect: null;
    options: null,
    apiUrl: null,
    baseUrl: null,
}


export interface QueryBuilderQueryRuleBase {
    field: string,
    operator: string,
    type: QueryBuilderColumnType,
    isNullable: boolean,
    multiSelect: boolean;
    displayEmptyValuesOnly: boolean;
    value: QueryBuilderQueryRule['value'] | null | undefined;
}


export interface QueryBuilderQueryRuleString extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.String;
    value: string;
}

export interface QueryBuilderQueryRuleInt extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.Int;
    value: number;
}

export interface QueryBuilderQueryRuleDecimal extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.Decimal;
    value: number;
}

export interface QueryBuilderQueryRuleBool extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.Bool;
    value: boolean;
}

export interface QueryBuilderQueryRuleListValueEntry {
    key: string | number | null;
    value: string;
}

export interface QueryBuilderQueryRuleList extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.List;
    value: Array<QueryBuilderQueryRuleListValueEntry> | QueryBuilderQueryRuleListValueEntry;
}

export interface QueryBuilderQueryRuleDate extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.Date;
    value: string;
}

export interface QueryBuilderQueryRuleTime extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.Time;
    value: string;
}

export interface QueryBuilderQueryRuleDateTime extends QueryBuilderQueryRuleBase {
    type: QueryBuilderColumnTypeEnum.DateTime;
    value: string;
}


export type QueryBuilderQueryRule =
    QueryBuilderQueryRuleString |
    QueryBuilderQueryRuleInt |
    QueryBuilderQueryRuleDecimal |
    QueryBuilderQueryRuleBool |
    QueryBuilderQueryRuleList |
    QueryBuilderQueryRuleDate |
    QueryBuilderQueryRuleTime |
    QueryBuilderQueryRuleDateTime;


export enum QueryBuilderQueryConditionTypeEnum {
    AND = 'AND',
    OR = 'OR',
}

export type QueryBuilderQueryConditionType = ReverseMap<typeof QueryBuilderQueryConditionTypeEnum>;

export interface QueryBuilderQueryFilter {
    condition: QueryBuilderQueryConditionType;
    rules: Array<QueryBuilderQueryRule>;
}

export interface QueryBuilderQuery {
    keyword?: string;
    filters: Array<QueryBuilderQueryFilter>,
}

export interface QueryBuilderStateQueryRule extends Partial<Omit<QueryBuilderQueryRuleBase, 'type' | 'isNullable' | 'multiSelect'>> {
    value?: QueryBuilderQueryRule['value'] | null | undefined;
}

export interface QueryBuilderStateQueryCondition {
    condition: QueryBuilderQueryConditionType;
    rules: Array<QueryBuilderStateQueryRule>;
}


export interface QueryBuilderStateQuery {
    keyword?: string;
    filters: Array<QueryBuilderStateQueryCondition>,
}

export interface QueryBuilderState {
    columns: Array<QueryBuilderColumn>;
    query: QueryBuilderStateQuery;
}

export interface QueryBuilderReducerInitialState {
    columns: Array<QueryBuilderColumn>;
    query?: QueryBuilderQuery;
}


export enum QueryBuilderReducerActionTypeEnum {
    SetKeyword = 'SetKeyword',
    AddCondition = 'AddCondition',
    AddRule = 'AddRule',
    ChangeCondition = 'ChangeCondition',
    ChangeField = 'ChangeField',
    ChangeOperator = 'ChangeOperator',
    ChangeValue = 'ChangeValue',
    ChangeDisplayEmptyValuesOnly = 'ChangeDisplayEmptyValuesOnly',
    DeleteCondition = 'DeleteCondition',
    DeleteRule = 'DeleteRule',
    SetQuery = 'SetQuery',
    SetColumns = 'SetColumns',
}

export type QueryBuilderReducerActionType = ReverseMap<typeof QueryBuilderReducerActionTypeEnum>;

export interface QueryBuilderReducerDispatch {
    (action: QueryBuilderReducerAction): void;
}

export interface QueryBuilderReducerActionBase {
    payload: any;
}

export interface QueryBuilderReducerActionSetKeyword extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.SetKeyword;
    payload: {
        keyword: string;
    };
}

export interface QueryBuilderReducerActionAddCondition extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.AddCondition;
    payload: {
        condition: QueryBuilderQueryConditionType;
    }
}


export interface QueryBuilderReducerActionAddRule extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.AddRule;
    payload: {
        conditionIndex: number;
    }
}

export interface QueryBuilderReducerActionChangeCondition extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.ChangeCondition;
    payload: {
        conditionIndex: number;
        condition: QueryBuilderQueryConditionType;
    }
}

export interface QueryBuilderReducerActionChangeField extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.ChangeField;
    payload: {
        conditionIndex: number;
        ruleIndex: number;
        field: string;
    }
}

export interface QueryBuilderReducerActionChangeOperator extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.ChangeOperator;
    payload: {
        conditionIndex: number;
        ruleIndex: number;
        operator: string;
    }
}

export interface QueryBuilderReducerActionChangeValue extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.ChangeValue;
    payload: {
        conditionIndex: number;
        ruleIndex: number;
        value: QueryBuilderQueryRuleBase['value'];
    }
}

export interface QueryBuilderReducerActionChangeDisplayEmptyValuesOnly extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.ChangeDisplayEmptyValuesOnly;
    payload: {
        conditionIndex: number;
        ruleIndex: number;
        displayEmptyValuesOnly: boolean;
    }
}

export interface QueryBuilderReducerActionDeleteCondition extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.DeleteCondition;
    payload: {
        conditionIndex: number;
    }
}

export interface QueryBuilderReducerActionDeleteRule extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.DeleteRule;
    payload: {
        conditionIndex: number;
        ruleIndex: number;
    }
}

export interface QueryBuilderReducerActionSetQuery extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.SetQuery;
    payload: {
        query: QueryBuilderQuery;
        columns?: Array<QueryBuilderColumn>;
    }
}

export interface QueryBuilderReducerActionSetColumns extends QueryBuilderReducerActionBase {
    type: QueryBuilderReducerActionTypeEnum.SetColumns;
    payload: {
        columns: Array<QueryBuilderColumn>;
    }
}

export type QueryBuilderReducerAction =
    QueryBuilderReducerActionSetKeyword |
    QueryBuilderReducerActionAddCondition |
    QueryBuilderReducerActionAddRule |
    QueryBuilderReducerActionChangeCondition |
    QueryBuilderReducerActionChangeField |
    QueryBuilderReducerActionChangeOperator |
    QueryBuilderReducerActionChangeValue |
    QueryBuilderReducerActionChangeDisplayEmptyValuesOnly |
    QueryBuilderReducerActionDeleteCondition |
    QueryBuilderReducerActionDeleteRule |
    QueryBuilderReducerActionSetQuery |
    QueryBuilderReducerActionSetColumns;


export interface QueryBuilderTemplate {
    id: number,
    title: string,
    data: QueryBuilderQuery,
}


export type ArrayElementType<T> = T extends readonly (infer ElementType)[] ? ElementType : never
export type ReverseMap<T> = T[keyof T];
export type ThemeMixin<Theme extends object> = (theme: Theme & Partial<{
    vars: CssVarsTheme['vars']
}>) => SystemStyleObject<Theme>;


export * from './core/services/utils';
export {default as QueryBuilderUtils} from './core/services/utils';
export {default as QueryBuilderApiService} from './core/services/api';
export {default as QueryBuilderProvider} from './ui/components/provider';
export * from './ui';
export default QueryBuilder;

import React, {FunctionComponent, useMemo} from "react";
import {useTheme} from "@mui/material";
import {QRCodeCanvas} from "qrcode.react";


export interface PreviewQrcodeProps {
    size: number;
    value: string;
    logoUrl?: string;
    downloadId?: string;
    syncPreviewWithDownloadElement?: boolean;
    downloadElementConfig?: {
        transparentBG?: boolean;
        includeMargin?: boolean;
    },
}

const PreviewQrcode: FunctionComponent<PreviewQrcodeProps> = (props) => {
    const theme = useTheme();

    const sharedProps = useMemo<Parameters<typeof QRCodeCanvas>[0]>(() => ({
        value: props.value,
        level: "H",
    }), [props.value])

    return (
        <>
            <QRCodeCanvas
                size={props.size}
                imageSettings={{src: props.logoUrl ?? '', height: props.size / 4, width: props.size / 4, excavate: true,}}
                fgColor={props.syncPreviewWithDownloadElement ? '#000000' : theme.palette.text.primary}
                bgColor={props.syncPreviewWithDownloadElement ? (props.downloadElementConfig?.transparentBG ? '#00000000' : '#ffffff') : '#00000000'}
                includeMargin={props.syncPreviewWithDownloadElement ? props.downloadElementConfig?.includeMargin : true}
                {...sharedProps}
            />
            {
                !!props.downloadId &&
                <div style={{position: 'absolute', transform: 'translate(-100%, -100%)', visibility: 'hidden'}}>
                    <QRCodeCanvas
                        id={props.downloadId}
                        size={4000}
                        imageSettings={{src: props.logoUrl ?? '', height: 1000, width: 1000, excavate: true}}
                        fgColor={'#000000'}
                        bgColor={props.downloadElementConfig?.transparentBG ? '#00000000' : '#ffffff'}
                        includeMargin={props.downloadElementConfig?.includeMargin}
                        {...sharedProps}
                    />
                </div>
            }

        </>
    );
}

export default PreviewQrcode;

import CachingServiceLocalStorageEntity from "./entity/local-storage";
import {
    CachingServiceCookiesKeys,
    CachingServiceCookiesKeysType,
    CachingServiceEntities,
    CachingServiceLocalStorageKeys,
    CachingServiceLocalStorageKeysType
} from "./type-declarations";
import CachingServiceCookiesEntity from "./entity/cookies";


/**
 * Houses the logic for caching information in the user browser.
 * This wrapper provides the requested storage entity through its .of() method.
 */
class CachingService {

    /**
     * The local-storage entity of this Caching service.
     * @private
     */
    protected static LocalStorageEntity = new CachingServiceLocalStorageEntity<CachingServiceLocalStorageKeysType>({
        [CachingServiceLocalStorageKeys.loggedInUser]: 'liu',
    });

    /**
     * The cookies entity of this Caching service.
     * @private
     */
    protected static CookiesEntity = new CachingServiceCookiesEntity<CachingServiceCookiesKeysType>({
        [CachingServiceCookiesKeys.loggedInUser]: 'liu',
    });


    /**
     * Fetches the cookies entity of this Caching service.
     * @param entityType
     */
    public static of(entityType: CachingServiceEntities.cookies): CachingServiceCookiesEntity<CachingServiceCookiesKeys>;
    /**
     * Fetches the local-storage entity of this Caching service.
     * @param entityType
     */
    public static of(entityType: CachingServiceEntities.localStorage): CachingServiceLocalStorageEntity<CachingServiceLocalStorageKeys>;
    /**
     * Fetches the requested entity of this Caching service based on the provided entityType.
     * @param entityType
     */
    public static of(entityType: CachingServiceEntities) {
        switch (entityType) {
            case CachingServiceEntities.cookies:
                return this.CookiesEntity;
            case CachingServiceEntities.localStorage:
                return this.LocalStorageEntity;
            default:
                throw new Error("Wrong Caching Service Entity is requested.");
        }
    }
}

export * from "./entity/local-storage";
export * from "./entity/cookies";
export * from "./type-declarations";
export default CachingService;

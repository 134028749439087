import type BaseCachingServiceEntity from "./base";
import Cookies from "universal-cookie";


/**
 * The cookies Entity of the CachingService.
 */
export class CachingServiceCookiesEntity<T extends string> implements BaseCachingServiceEntity<T> {
    /**@inheritDoc */
    keys: Record<T | string, string>;

    /**@inheritDoc */
    get(key: T | string): string | null {
        return this.cookies.get(key);
    }

    /**@inheritDoc */
    getAll(): Record<T | string, string | null> {
        const res: Record<string, string | null> = {};
        for (const [resKey, key] of Object.entries<string>(this.keys)) {
            res[resKey] = this.cookies.get(key);
        }
        return res;
    }

    /**@inheritDoc */
    set(key: string, value: string): void {
        this.cookies.set(key, value);
    }

    /**@inheritDoc */
    remove(key: string): void {
        this.cookies.remove(key);
    }

    /**@inheritDoc */
    clear(): void {
        for (const key of Object.values<string>(this.keys)) {
            this.cookies.remove(key);
        }
    }

    /**
     * The cookies instance of this entity.
     */
    cookies = new Cookies();

    /**
     * Constructs a new LocalStorageEntity for the caching service based on the provided keys.
     * @param keys    The keys of this entity.
     */
    constructor(keys: Record<T | string, string>) {
        this.keys = keys;
    }
}

export default CachingServiceCookiesEntity;

import React, {createRef, FunctionComponent, MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {Card, Fade, IconButton, Modal, Typography} from "@mui/material";
import {ArrowBack, ArrowForward, Close, ZoomIn, ZoomOut} from "@mui/icons-material";
import PrismaZoom from 'react-prismazoom';
import {BizkeytechReduxActions, useBizkeytechDispatch, useBizkeytechSelector} from "../../redux";
import {Ref} from "react-prismazoom/dist/esm/types";
import {Utils} from "../../../core";
import './index.scss';

const PreviewImagesDialog: FunctionComponent = () => {
    const dispatch = useBizkeytechDispatch();
    const state = useBizkeytechSelector(state => state.dialogs?.previewImages);
    const [refs, setRefs] = useState<MutableRefObject<Ref | null>[]>([]);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const {hideCloseButton, disableBackdropClick, fullWidth, ...dialogProps} = state ?? {};

    /**
     * With each change of the open state and the data:
     * - sets the refs to the length of the data.
     */
    useEffect(() => {
        if (!state.open)
            return;
        setRefs(prevState => state.data?.map((e, i) => prevState[i] ?? createRef()) ?? []);
    }, [state.open, state.data])

    /**
     * With each change of the open state:
     * - if open then resets the refs when the dialog is closed.
     */
    useEffect(() => {
        if (state.open) {
            return () => {
                for (const ref of refs) {
                    ref.current = null;
                }
            };
        }
    }, [state.open, refs]);

    /**
     * Scrolls to the next appropriate image based on the value of next.
     *
     * - resets the current images' zoom scale back to 1
     * - if the forceIndex is available, then scrolls to that index
     * @param {boolean} next whether to scroll to next or prev
     * @param {boolean} forceIndex the index that we are forced to scroll to.
     */
    const scrollToAnotherImage = useCallback((next: boolean = true, forceIndex?: number) => {
        const current = (containerRef.current?.scrollLeft ?? 0) / window.innerWidth
        const index = forceIndex ?? Math.floor(current);
        refs.at(index)?.current?.reset();
        if (forceIndex !== undefined) {
            return containerRef.current?.scrollTo(window.innerWidth * index, 0);
        }
        if (!next) {
            containerRef.current?.scrollTo(window.innerWidth * Math.max(Math.floor(current) - 1, 0), 0);
        } else {
            containerRef.current?.scrollTo(window.innerWidth * Math.min(Math.ceil(current) + 1, (state.data?.length ?? 1) - 1), 0);
        }
    }, [refs, containerRef, state.data])

    /**
     * Determines whether the modal can show the prev or next buttons.
     * - resets the current images' zoom scale back to 1
     */
    const onScroll = useCallback(() => {
        const scrollLeft = containerRef.current?.scrollLeft ?? 0;
        const current = scrollLeft / window.innerWidth
        const index = Math.floor(current);
        const prev = Math.max(index - 1, 0);
        const next = Math.min(index + 1, 8);
        refs.at(index)?.current?.reset();
        refs.at(prev)?.current?.reset();
        refs.at(next)?.current?.reset();
        setHasNext((scrollLeft + window.innerWidth) < (containerRef.current?.scrollWidth ?? 0));
        setHasPrev(scrollLeft > 0)
    }, [refs, containerRef])

    /**
     * As soon as the container is rendered and visible:
     *
     * - determines the visibility of the buttons.
     * - if there is a startingImage, then scrolls to that image
     */
    useEffect(() => {
        if (!state.open)
            return;
        const _interval: Function = () => {
            if (!containerRef.current) {
                return timer = setInterval(_interval, 100);
            }
            clearInterval(timer);
            onScroll()
            scrollToAnotherImage(false, 0);
        }
        let timer = setInterval(_interval, 100)
        return () => clearInterval(timer);
    }, [onScroll, scrollToAnotherImage, state.open])

    /**
     * Closes the dialog by dispatching its relevant action to the redux store.
     */
    const closeDialog = useCallback((successful = false) => {
        if (state?.onClose) {
            state.onClose(successful ? "successful" : "leave");
        }
        dispatch(BizkeytechReduxActions.dialogs.previewImages({open: false}));
    }, [state, dispatch])

    /**
     * Closes the dialog if the values from the state do not prevent the closing of the dialog.
     */
    const onDialogClosed = useCallback((event: Object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        switch (reason) {
            case "backdropClick":
                if (!state.disableBackdropClick)
                    closeDialog();
                break;
            case "closeClick":
                if (!state.hideCloseButton)
                    closeDialog();
                break;
            case "escapeKeyDown":
                if (!state.disableEscapeKeyDown)
                    closeDialog();
                break;
            default:
                break;
        }
    }, [closeDialog, state.disableBackdropClick, state.disableEscapeKeyDown, state.hideCloseButton])

    return (
        <>
            <Modal
                {...dialogProps}
                onClose={onDialogClosed}
                className={'preview-images-dialog'}
            >
                <Fade in={state.open} unmountOnExit mountOnEnter>
                    <div>
                        <div className={'images-preview'} onScroll={onScroll} ref={containerRef}>
                            <IconButton
                                className={'close-button'}
                                color={"paper.error"}
                                onClick={() => closeDialog(false)}
                            >
                                <Close/>
                            </IconButton>
                            <Fade in={hasPrev}>
                                <IconButton
                                    className={'prev-button'}
                                    onClick={() => scrollToAnotherImage(false)}
                                >
                                    <ArrowBack/>
                                </IconButton>
                            </Fade>
                            <div
                                className={'image-container'}
                                onClick={() => closeDialog(false)}
                                style={{width: `${(state.data?.length ?? 1) * 100}vw`}}>
                                {
                                    state.data?.map((image, i) => (
                                        <Image
                                            key={`${image}-$Pi}`}
                                            image={image}
                                            imageRef={refs[i]}
                                        />
                                    ))
                                }
                            </div>
                            <Fade in={hasNext}>
                                <IconButton
                                    className={'next-button'}
                                    onClick={() => scrollToAnotherImage(true)}
                                >
                                    <ArrowForward/>
                                </IconButton>
                            </Fade>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}


interface ImageProps {
    image: string,
    imageRef: MutableRefObject<Ref | null>,
}

const Image: FunctionComponent<ImageProps> = (props) => {
    const [zoom, setZoom] = useState(1);

    /**
     * Changes the zoom scale of this image.
     * @param {Event} e
     * @param {number} modifier the modifier to specify the direction and how much to change the zoom
     */
    const changeZoom = useCallback((e: React.MouseEvent<HTMLButtonElement>, modifier: number) => {
        Utils.modifyEvent(e);
        if (modifier > 0) {
            props.imageRef.current?.zoomIn(modifier);
        } else {
            props.imageRef.current?.zoomOut(Math.abs(modifier));
        }
    }, [props.imageRef])

    return (
        <div className={'image'}>
            <PrismaZoom
                className={'image-prisma'}
                ref={props.imageRef}
                onZoomChange={setZoom}
                minZoom={1}
                maxZoom={8}
            >
                <img
                    src={props.image}
                    alt={"Previewed"}
                    onClick={Utils.modifyEvent}
                />
            </PrismaZoom>
            <Card variant={'outlined'} className={'controller'} sx={{padding: 1, gap: 1}}>
                <IconButton color={'primary'} onClick={(e) => changeZoom(e, -0.1)}>
                    <ZoomOut/>
                </IconButton>
                <Typography variant={'body2'} fontWeight={600}>
                    {`${Math.round(zoom * 100)}%`}
                </Typography>
                <IconButton color={'primary'} onClick={(e) => changeZoom(e, 0.1)}>
                    <ZoomIn/>
                </IconButton>
            </Card>
        </div>
    );
}

export default PreviewImagesDialog;

import {FunctionComponent, PropsWithChildren, useCallback, useLayoutEffect} from "react";
import {AppBar, Theme, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import {darken} from "@mui/system/colorManipulator";
import classNames from "classnames";
import {panelClasses, usePanel} from "../panel";

export const PanelFooter: FunctionComponent<PropsWithChildren> = (props) => {
    const {sidebarCollapsed, breakpoints, sidebarWidth, setFooterCounter} = usePanel();

    /**
     * Updates the footer counter when the component is mounted and unmounted.
     */
    useLayoutEffect(() => {
        setFooterCounter(prevState => prevState + 1);
        return () => {
            setFooterCounter(prevState => prevState - 1);
        }
    }, [setFooterCounter]);

    const theme = useTheme();
    const inTabletView = useMediaQuery(theme.breakpoints.between(breakpoints.mobileView, breakpoints.tabletView));
    const inDesktopView = useMediaQuery(theme.breakpoints.up(breakpoints.tabletView));

    /**
     * Creates the styles for the appbar component.
     *
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the appbar
     */
    const appbarMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        marginInlineStart: theme.spacing(2),
        backgroundImage: 'none',
        boxShadow: 'none',
        transition: 'none',
        backgroundColor: 'background.paper',
        top: 'auto',
        bottom: 0,
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            transition: theme.transitions.create(['width', 'box-shadow', 'margin-inline-start']),
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        ...((inTabletView || sidebarCollapsed) && {
            width: `calc(100% - ${sidebarWidth.tabletView}px - ${theme.spacing(4)})`,
            marginInlineStart: `calc(${sidebarWidth.tabletView}px + ${theme.spacing(2)})`,
        }),
        ...((inDesktopView && !sidebarCollapsed) && {
            width: `calc(100% - ${sidebarWidth.desktopView}px - ${theme.spacing(4)})`,
            marginInlineStart: `calc(${sidebarWidth.desktopView}px + ${theme.spacing(2)})`,
        })
    }), [breakpoints.mobileView, inDesktopView, inTabletView, sidebarCollapsed, sidebarWidth.desktopView, sidebarWidth.tabletView])

    const ToolbarMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        ...(theme.palette.mode === 'dark' && {
            backgroundColor: darken(theme.palette.background.paper, 0.2),
        }),
        ...(theme.palette.mode === 'light' && {
            backgroundColor: darken(theme.palette.background.default, 0.02),
        }),
        [theme.breakpoints.up(breakpoints.mobileView)]: {
            borderEndStartRadius: 20,
            borderEndEndRadius: 20,
            borderStartStartRadius: 0,
            borderStartEndRadius: 0,
        },
    }), [breakpoints.mobileView])

    return (
        <>
            <AppBar
                component={'footer'}
                className={classNames({[panelClasses.collapsed]: sidebarCollapsed})} position="fixed" color={'primary'}
                sx={appbarMixin}
            >
                <Toolbar sx={ToolbarMixin}>
                    {props.children}
                </Toolbar>
            </AppBar>
        </>
    );
}

export default PanelFooter;

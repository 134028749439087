import {
    QueryBuilderQuery,
    QueryBuilderReducerAction,
    QueryBuilderReducerActionAddCondition,
    QueryBuilderReducerActionAddRule,
    QueryBuilderReducerActionChangeCondition,
    QueryBuilderReducerActionChangeDisplayEmptyValuesOnly,
    QueryBuilderReducerActionChangeField,
    QueryBuilderReducerActionChangeOperator,
    QueryBuilderReducerActionChangeValue,
    QueryBuilderReducerActionDeleteCondition,
    QueryBuilderReducerActionDeleteRule,
    QueryBuilderReducerActionSetColumns,
    QueryBuilderReducerActionSetKeyword,
    QueryBuilderReducerActionSetQuery,
    QueryBuilderReducerActionType,
    QueryBuilderReducerActionTypeEnum,
    QueryBuilderReducerInitialState,
    QueryBuilderState
} from "../../../../index";
import {Reducer, ReducerState} from "react";
import QueryBuilderUtils from "../../../services/utils";

/**
 * The reducer for the query builder.
 */
class QueryBuilderReducer {

    public static placeholderQuery: QueryBuilderQuery = {
        keyword: "",
        filters: [],
    };

    /**
     * Initialize the state of the reducer
     * @param arg   The initial state
     */
    public static init(arg: QueryBuilderReducerInitialState): ReducerState<Reducer<QueryBuilderState, QueryBuilderReducerActionType>> {
        return {
            columns: arg.columns,
            query: QueryBuilderUtils.siftQueryWithColumns(arg.query ?? QueryBuilderReducer.placeholderQuery, arg.columns, QueryBuilderUtils.mapQueryRuleToStateQueryRule),
        };
    }

    /**
     * The reducer for the query builder.
     *
     * @param prevState     The previous state
     * @param _action        The action to perform
     */
    public static reducer(prevState: QueryBuilderState, _action: QueryBuilderReducerAction): QueryBuilderState {
        switch (_action.type) {
            case QueryBuilderReducerActionTypeEnum.SetKeyword: {
                const action = _action as QueryBuilderReducerActionSetKeyword;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        keyword: action.payload.keyword,
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.AddCondition: {
                const action = _action as QueryBuilderReducerActionAddCondition;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: [
                            ...prevState.query.filters,
                            {
                                condition: action.payload.condition,
                                rules: [{
                                    field: undefined,
                                    operator: undefined,
                                    value: undefined,
                                    displayEmptyValuesOnly: false,
                                }],
                            }
                        ],
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.AddRule: {
                const action = _action as QueryBuilderReducerActionAddRule;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, index) => {
                            if (index === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    rules: [
                                        ...condition.rules,
                                        {
                                            field: undefined,
                                            operator: undefined,
                                            value: undefined,
                                            displayEmptyValuesOnly: false,
                                        },
                                    ],
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.ChangeCondition: {
                const action = _action as QueryBuilderReducerActionChangeCondition;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, index) => {
                            if (index === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    condition: action.payload.condition,
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.ChangeField: {
                const action = _action as QueryBuilderReducerActionChangeField;
                const operators = prevState.columns.find((column) => column.field === action.payload.field)?.operators;
                const firstOperator = operators?.[0];
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, conditionIndex) => {
                            if (conditionIndex === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    rules: condition.rules.map((rule, ruleIndex) => {
                                        if (ruleIndex === action.payload.ruleIndex) {
                                            return {
                                                ...rule,
                                                field: action.payload.field,
                                                operator: firstOperator,
                                                value: undefined,
                                                displayEmptyValuesOnly: false,
                                            }
                                        }
                                        return rule;
                                    }),
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.ChangeOperator: {
                const action = _action as QueryBuilderReducerActionChangeOperator;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, conditionIndex) => {
                            if (conditionIndex === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    rules: condition.rules.map((rule, ruleIndex) => {
                                        if (ruleIndex === action.payload.ruleIndex) {
                                            return {
                                                ...rule,
                                                operator: action.payload.operator,
                                            }
                                        }
                                        return rule;
                                    }),
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.ChangeValue: {
                const action = _action as QueryBuilderReducerActionChangeValue;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, conditionIndex) => {
                            if (conditionIndex === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    rules: condition.rules.map((rule, ruleIndex) => {
                                        if (ruleIndex === action.payload.ruleIndex) {
                                            return {
                                                ...rule,
                                                value: action.payload.value,
                                                displayEmptyValuesOnly: false,
                                            }
                                        }
                                        return rule;
                                    }),
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.ChangeDisplayEmptyValuesOnly: {
                const action = _action as QueryBuilderReducerActionChangeDisplayEmptyValuesOnly;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, conditionIndex) => {
                            if (conditionIndex === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    rules: condition.rules.map((rule, ruleIndex) => {
                                        if (ruleIndex === action.payload.ruleIndex) {
                                            return {
                                                ...rule,
                                                value: action.payload.displayEmptyValuesOnly ? null : undefined,
                                                displayEmptyValuesOnly: action.payload.displayEmptyValuesOnly,
                                            }
                                        }
                                        return rule;
                                    }),
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.DeleteCondition: {
                const action = _action as QueryBuilderReducerActionDeleteCondition;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.filter((condition, index) => index !== action.payload.conditionIndex),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.DeleteRule: {
                const action = _action as QueryBuilderReducerActionDeleteRule;
                return {
                    ...prevState,
                    query: {
                        ...prevState.query,
                        filters: prevState.query.filters.map((condition, conditionIndex) => {
                            if (conditionIndex === action.payload.conditionIndex) {
                                return {
                                    ...condition,
                                    rules: condition.rules.filter((rule, ruleIndex) => ruleIndex !== action.payload.ruleIndex),
                                }
                            }
                            return condition;
                        }),
                    }
                }
            }
            case QueryBuilderReducerActionTypeEnum.SetQuery: {
                const action = _action as QueryBuilderReducerActionSetQuery;
                return {
                    ...prevState,
                    query: QueryBuilderUtils.siftQueryWithColumns(action.payload.query, action.payload.columns ?? prevState.columns, QueryBuilderUtils.mapQueryRuleToStateQueryRule),
                }
            }
            case QueryBuilderReducerActionTypeEnum.SetColumns: {
                const action = _action as QueryBuilderReducerActionSetColumns;
                return {
                    ...prevState,
                    columns: action.payload.columns,
                    query: QueryBuilderUtils.siftQueryWithColumns(prevState.query, action.payload.columns, QueryBuilderUtils.mapStateQueryRuleToStateQueryRule),
                }
            }
            default: {
                return prevState;
            }
        }
    }
}

export default QueryBuilderReducer;

import type {FunctionComponent} from "react";
import {Typography} from "@mui/material";
import {CheckCircleOutlined} from "@mui/icons-material";
import type {ToastContentProps} from "react-toastify";

export interface ISuccessToastContent {
    message: string,
}

const SuccessToastContent: FunctionComponent<ToastContentProps<ISuccessToastContent>> =
    ({
         data,
     }) => {

        return (
            <>
                <div className={'toast-content'}>
                    <CheckCircleOutlined color={'success'} sx={{marginRight: 1}}/>
                    <Typography variant={'body2'}>
                        {data?.message ?? ''}
                    </Typography>
                </div>
            </>
        )
    }

export default SuccessToastContent;

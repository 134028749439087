import {combineReducers} from "@reduxjs/toolkit";
import BizkeytechDialogsReduxSlice from "../slices/dialogs";


/**
 * The Root Reducer of the Redux Store state.
 */
const BizkeytechReduxRootReducer = combineReducers({
    [BizkeytechDialogsReduxSlice.name]: BizkeytechDialogsReduxSlice.reducer,
});

export default BizkeytechReduxRootReducer;

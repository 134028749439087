import {Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";


/**
 * A Convenient hook to debounce the value.
 * @param {T} value                                                  The value to be debounced.
 * @param {number} debounceTimeout                                   The debounce timeout.
 * @return {[T, Dispatch<SetStateAction<T>>, T, VoidCallback] }      The debounced value and the callback to change the value.
 */
const useDebounced = <T, >(value: T, debounceTimeout: number = 300): readonly [T, Dispatch<SetStateAction<T>>, T, VoidCallback] => {
    const [rawValue, setRawValue] = useState<T>(value);
    const [debouncedValue, setDebouncedValue] = useState<T>(rawValue);
    const timerRef = useRef<number>();

    /**
     * With each change in the [value]:
     * - updates the [debouncedValue].
     */
    useLayoutEffect(() => {
        setRawValue(value);
    }, [value]);

    const clearDebounceTimeout = useCallback<VoidCallback>(() => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
    }, []);

    /**
     * With each change in the [debouncedValue]:
     * - updates the debounced vaplue.
     */
    useEffect(() => {
        clearDebounceTimeout();
        timerRef.current = window.setTimeout(() => {
            setDebouncedValue(rawValue);
        }, debounceTimeout);
    }, [debounceTimeout, rawValue, clearDebounceTimeout]);

    return [rawValue, setRawValue, debouncedValue, clearDebounceTimeout] as const;
}

export default useDebounced;

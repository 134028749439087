import './index.scss';
import React, {lazy, useCallback, useMemo, useRef} from "react";
import ElementIds from "../../core/models/element-ids";
import LoadingBar, {LoadingBarRef} from "react-top-loading-bar";
import {FadeAnimationLayout, LoadingBarTrigger, LoadingBarTriggerContextValue, LoadingBarTriggerProvider} from '../../@bizkeytech';
import AppRoutes from "../../core/models/routes";
import {matchRoutes, Navigate, Route, Routes, useLocation} from "react-router-dom";
import EnvService from "../../core/services/env";
import RedirectLinkView from "./public/redirect-link";


const AuthenticationViews = lazy(() => import("./public/authentication"));
const LoginAuthenticationView = lazy(() => import("./public/authentication/login"));
const LogoutAuthenticationView = lazy(() => import("./public/authentication/logout"));
const RedirectAuthenticationView = lazy(() => import("./public/authentication/redirect"));

const ErrorViews = lazy(() => import("./public/error"));
const NotFoundErrorView = lazy(() => import("./public/error/not-found"));
const AccessDeniedErrorView = lazy(() => import("./public/error/access-denied"));
const ServerErrorErrorView = lazy(() => import("./public/error/server-error"));

const TestView = lazy(() => import("./public/test"));

const VersionHistoryView = lazy(() => import("./private/version-history"));


const PanelViews = lazy(() => import("./panel"));
const PanelDashboardView = lazy(() => import('./panel/dashboard'));
const PanelGenerateStaticCodeView = lazy(() => import('./panel/generate-static-code'));
const PanelGenerateCodeView = lazy(() => import('./panel/generate-code'));
const PanelCodeHistoryView = lazy(() => import('./panel/code-history'));
const PanelCodeDetailsView = lazy(() => import('./panel/code-details'));

const ApplicationViews = () => {
    const location = useLocation();
    const loadingBarRef = useRef<LoadingBarRef | null>(null);

    /**
     * Starts the animation of the LoadingBar component.
     */
    const onRoutesLoadingStarted = useCallback<LoadingBarTriggerContextValue['onStart']>(() => {
        loadingBarRef.current?.continuousStart?.(0, 300);
    }, [loadingBarRef])

    /**
     * Completes the animation of the LoadingBar component.
     */
    const onRoutesLoadingFinished = useCallback<LoadingBarTriggerContextValue['onFinish']>(() => {
        loadingBarRef.current?.complete?.();
    }, [loadingBarRef])

    const motionKey = useMemo<string>(() => {
        const nonPanelRoutes: string[] = [
            AppRoutes.public.error._base,
            AppRoutes.public.authentication._base,
            AppRoutes.public.test,
            AppRoutes.public.redirectLink,
        ];
        if (!!matchRoutes(
            nonPanelRoutes.map(e => ({path: e, end: false})),
            location.pathname
        )) {
            return location.pathname
        }
        return "panel";

    }, [location.pathname])

    return (
        <LoadingBarTriggerProvider value={{onStart: onRoutesLoadingStarted, onFinish: onRoutesLoadingFinished}}>
            <div className={'application-view'}>
                <FadeAnimationLayout motionKey={motionKey} id={ElementIds.appId} className={'app'}>
                    <LoadingBar
                        ref={loadingBarRef}
                        containerClassName={'loading-bar-container'}
                        className={'loading-bar'}
                        color={'transparent'}
                    />
                    <LoadingBarTrigger fallback={<div style={{minHeight: '100vh', width: '100%'}}/>}>
                        <Routes>
                            {/* --- Authentication Views ---*/}
                            <Route path={AppRoutes.public.authentication.base} element={<AuthenticationViews/>}>
                                <Route path={AppRoutes.public.authentication._relative.login} element={<LoginAuthenticationView/>}/>
                                <Route path={AppRoutes.public.authentication._relative.logout} element={<LogoutAuthenticationView/>}/>
                                <Route path={AppRoutes.public.authentication._relative.redirect} element={<RedirectAuthenticationView/>}/>
                                <Route index element={<Navigate to={AppRoutes.public.authentication.redirect} replace/>}/>
                                <Route path="*" element={<Navigate to={AppRoutes.public.error.base} replace/>}/>
                            </Route>

                            {/*This view should be rendered as fast as possible since all it does is redirection*/}
                            <Route path={AppRoutes.public.redirectLink} element={<RedirectLinkView/>}/>

                            {/* --- Private Views ---*/}
                            <Route path={AppRoutes.private.versionHistory} element={<VersionHistoryView/>}/>

                            {/* --- Panel Views ---*/}
                            <Route path={AppRoutes.panel.base} element={<PanelViews/>}>
                                <Route path={AppRoutes.panel._relative.dashboard} element={<PanelDashboardView/>}/>
                                <Route path={AppRoutes.panel._relative.generateCode} element={<PanelGenerateCodeView/>}/>
                                <Route path={AppRoutes.panel._relative.generateStaticCode} element={<PanelGenerateStaticCodeView/>}/>
                                <Route path={AppRoutes.panel._relative.codeList} element={<PanelCodeHistoryView/>}/>
                                <Route path={AppRoutes.panel._relative.codeDetails} element={<PanelCodeDetailsView/>}/>
                                <Route index element={<Navigate to={AppRoutes.panel.dashboard} replace/>}/>
                            </Route>

                            {/* --- Other Routes ---*/}
                            <Route path={AppRoutes.public.landing} element={<Navigate to={AppRoutes.panel.base} replace/>}/>
                            {
                                !EnvService.isProduction &&
                                <Route path={AppRoutes.public.test} element={<TestView/>}/>
                            }

                            {/* --- Error Views ---*/}
                            <Route path={AppRoutes.public.error.base} element={<ErrorViews/>}>
                                <Route path={AppRoutes.public.error._relative.notFound} element={<NotFoundErrorView/>}/>
                                <Route path={AppRoutes.public.error._relative.accessDenied} element={<AccessDeniedErrorView/>}/>
                                <Route path={AppRoutes.public.error._relative.serverError} element={<ServerErrorErrorView/>}/>
                                <Route index element={<Navigate to={AppRoutes.public.error.notFound} replace/>}/>
                                <Route path="*" element={<Navigate to={AppRoutes.public.error.notFound} replace/>}/>
                            </Route>

                            {/* --- Fallback Route ---*/}
                            <Route path="*" element={<Navigate to={AppRoutes.public.error.base} replace/>}/>
                        </Routes>
                    </LoadingBarTrigger>
                </FadeAnimationLayout>
            </div>
        </LoadingBarTriggerProvider>
    );
}

export default ApplicationViews;

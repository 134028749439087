import './index.scss';
import {useColorScheme} from "@mui/material/styles";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {Id, Slide, toast, ToastContainer, ToastContainerProps} from "react-toastify";
import {useUpdateEffect} from "react-use";
import {Utils} from '../../../core';

const ApplicationToast: FunctionComponent<ToastContainerProps> = (props) => {
    const {mode, systemMode} = useColorScheme();
    const [, setToastIds] = useState<Array<Id>>([]);

    const containerId = useMemo(() => props.containerId ?? Utils.createUUId(), [props.containerId]);

    const toastTheme: 'light' | 'dark' = useMemo(() => {
        switch (mode) {
            case "dark":
            case "light":
                return mode;
            default:
                switch (systemMode) {
                    case "dark":
                    case "light":
                        return systemMode;
                    default:
                        return 'light';
                }
        }
    }, [mode, systemMode])

    /**
     * As soon as the component mounts:
     * - attaches an event listener on the toasts that would store the ids of the added toasts and remove the ids of the removed toasts.
     */
    useEffect(() => toast.onChange(e => {
        if (e.containerId !== containerId)
            return;
        switch (e.status) {
            case "added":
                setToastIds(prevState => [...prevState, e.id]);
                break;
            case "removed":
                setToastIds(prevState => prevState.filter(id => id !== e.id))
                break;
            default:
                break;
        }
    }), [containerId])

    /**
     * With each change in the [toastTheme] memo value:
     * - updates the theme of the shown toasts to be in sync the current [toastTheme].
     */
    useUpdateEffect(() => {
        setToastIds(prevState => {
            for (let toastId of prevState) {
                if (toast.isActive(toastId))
                    toast.update(toastId, {
                        theme: toastTheme,
                    });
            }
            return prevState;
        })
    }, [toastTheme])

    return (
        <>
            <ToastContainer
                containerId={containerId}
                theme={toastTheme}
                hideProgressBar
                transition={Slide}
                closeButton={false}
                position={'bottom-center'}
                newestOnTop={true}
                draggableDirection={'y'}
                draggablePercent={10}
                autoClose={5000}
                stacked={true}
                {...props}
            />
        </>
    );
}

export default ApplicationToast;

import * as ReactIs from 'react-is';
import {Id as ToastId, toast, ToastOptions} from "react-toastify";
import {ToastTypes,} from "./type-declerations";
import {type ApiRequestDs, ApiResponseCodes, type ApiResponseDs, Texts} from '../../core';
import {
    ErrorToastContent,
    IApiResponseErrorToastContent,
    IApiResponseSuccessToastContent,
    IErrorToastContent,
    IInfoToastContent,
    InfoToastContent,
    ISuccessToastContent,
    IWarningToastContent,
    SuccessToastContent,
    WarningToastContent
} from '../components/application-toast/toast-content';
import {ApiResponseErrorToastContent, ApiResponseSuccessToastContent} from "../index";
import type {SxProps, SystemStyleObject} from '@mui/system';

/**
 * Utilities specific for the UI of the project.
 */
class UIUtils {

    /**
     * The prefix of the theme options.
     */
    public static readonly ThemePrefix = 'bizkey';

    /**
     * The prefix of the theme options with the --. Specific for the variable names.
     */
    public static readonly withThemePrefix = '--'.concat(!this.ThemePrefix.length ? '' : this.ThemePrefix.concat('-'));

    /**
     * The ids of the toast containers.
     */
    public static readonly ToastContainerIDs = {
        default: 'default',
    }

    /**
     * Creates a css variable with the theme-prefix.
     * @param name
     */
    public static themeVar(name: string) {
        return `var(${this.withThemePrefix}${name})`;
    };

    /**
     * Shows toasts associated with the status of the api response if the permission was granted in the request.
     *
     * @param request       The request that was executed.
     * @param response      The response that was received.
     */
    public static showToastApiCallEffect(request: ApiRequestDs, response: ApiResponseDs): void {
        if (response?.resultFlag) {
            const message = response?.message ?? '';
            if (!!message?.length && request.showSuccessToast) {
                this.toast(ToastTypes.apiResponseSuccess, {message: message}, {toastId: request.url});
            }
            return;
        }
        if (!response?.aborted && request.showErrorToast) {
            // only show the toast for non-aborted responses.
            let message = response?.message ?? ApiResponseCodes.getMessageFromCode(response?.code) ?? Texts.serverError;
            this.toast(ToastTypes.apiResponseError, {message: message}, {toastId: request.url as ToastId});
        }
    }

    /**
     * Invokes the given sx function with the given theme.
     * @param sx        the sx function to be invoked
     * @param theme     the theme to be passed to the sx function
     */
    static invokeSx<Theme extends object>(sx?: SxProps<Theme>, theme?: Theme): SystemStyleObject<Theme> | ReadonlyArray<boolean | SystemStyleObject<Theme>> {
        if (!sx) {
            return {};
        }
        if (typeof sx === 'function') {
            if (!theme) {
                throw new Error('Theme must be provided when invoking a sx function.');
            }
            return sx(theme);
        }
        if (Array.isArray(sx)) {
            return sx.map(s => this.invokeSx(s, theme)) as ReadonlyArray<boolean | SystemStyleObject<Theme>>;
        }
        return sx as SystemStyleObject<Theme>;
    }

    /**
     * Determines if the given object is a valid element for the React to render.
     * @param obj
     */
    static isObjectValidElementType(obj: object): boolean {
        if (obj === null)
            return false;
        return ReactIs.isValidElementType(obj);
    }


    /**
     * Shows the toast for duplicateCode toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes.apiResponseError, data: IApiResponseErrorToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for apiResponseSuccess toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes.apiResponseSuccess, data: IApiResponseSuccessToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for error toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes.error, data: IErrorToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for info toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes.info, data: IInfoToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for success toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes.success, data: ISuccessToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows the toast for warning toast-type.
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes.warning, data: IWarningToastContent, options?: ToastOptions): ToastId;
    /**
     * Shows a toast specific for the given [type].
     * @param type      the type of the toast that determines the content to be shown.
     * @param data      the data specific for the toast content.
     * @param options   the options related to the toast itself.
     */
    static toast(type: ToastTypes, data?: any, options?: ToastOptions): ToastId {
        options = options ?? {};
        let content;
        switch (type) {
            case ToastTypes.apiResponseError:
                content = ApiResponseErrorToastContent;
                break;
            case ToastTypes.apiResponseSuccess:
                content = ApiResponseSuccessToastContent;
                break;
            case ToastTypes.error:
                content = ErrorToastContent;
                break
            case ToastTypes.info:
                content = InfoToastContent;
                break
            case ToastTypes.success:
                content = SuccessToastContent;
                break
            case ToastTypes.warning:
                content = WarningToastContent;
                break
        }
        return toast<any>(content, {
            ...options,
            containerId: options?.containerId ?? this.ToastContainerIDs.default,
            data: data,
        });
    }
}

export * from './type-declerations';
export default UIUtils;

import React, {useContext, useMemo, useRef, useState} from "react";
import {DataGridControllerContext, DataGridLayoutContext, DataGridMiscContext, DataGridStateContext} from "../../../../index";
import {ReactComponent as PageSizeTogglerIcon} from '../../../../assets/images/pagination/page-size-toggler.svg';
import {ReactComponent as PrevPageIcon} from '../../../../assets/images/pagination/pagination-prev.svg';
import {ReactComponent as NextPageIcon} from '../../../../assets/images/pagination/pagination-next.svg';
import classnames from "classnames";
import DataGridIconButton from "../../../components/icon-button/inedx";
import {Popover} from "@mui/material";
import DataGridUtils from "../../../../core/services/utils";

const DataGridFooterPagination = () => {
    const {
        pagination,
        loading: {state: loading},
        initialState: {pagination: initialPagination}
    } = useContext(DataGridStateContext);
    const dataGridApi = useContext(DataGridControllerContext)
    const dataGridLayout = useContext(DataGridLayoutContext)
    const {
        classNames,
        resetSelectionOnPageSizeChange,
        resetSelectionOnCurrentPageChange
    } = useContext(DataGridMiscContext);
    const [popover, setPopover] = useState(null);
    const id = useRef(DataGridUtils.createUUId(true));

    const paginationInformation = useMemo(() => {
        const totalCount = pagination.length ?? 0;
        const start = (pagination.currentPage - 1) * pagination.pageSize + 1;
        const end = Math.min(pagination.currentPage * pagination.pageSize, totalCount);

        return `${start}-${end} of ${totalCount}`
    }, [pagination.pageSize, pagination.length, pagination.currentPage])

    const pageSizeDropdownDisabled = useMemo(() =>
            loading || pagination.sizes.length <= 1,
        [loading, pagination.sizes]);

    const prevButtonDisabled = useMemo(() =>
            loading || pagination.currentPage === 1,
        [loading, pagination.currentPage]);

    const nextButtonDisabled = useMemo(() =>
            loading || (pagination.currentPage * pagination.pageSize >= pagination.length),
        [loading, pagination]);

    /**
     * Closes the popover
     */
    const closePageSizePopover = () => setPopover(null);

    /**
     * Opens the popover
     * @param {Event} e
     */
    const openPageSizePopover = (e) => setPopover(e.currentTarget);

    /**
     * Sets the current-page of the data-grid
     * @param {-1 | 1} offset the offset of the current page, could be 1 or -1 to indicate the previous page or the
     * next page.
     */
    const setCurrentPage = (offset) => {
        if (loading)
            return;
        if (resetSelectionOnCurrentPageChange) {
            dataGridApi.resetSelection();
        }
        dataGridApi.setCurrentPage(pagination?.currentPage + offset, true);
    }

    /**
     * Sets the page-size of the data-grid.
     * @param {number} pageSize
     */
    const setPageSize = (pageSize) => {
        if (loading)
            return;
        dataGridApi.setPageSize(pageSize, true);
        if (resetSelectionOnPageSizeChange) {
            dataGridApi.resetSelection();
        }
        closePageSizePopover();
    }

    return (
        <>
            <div className={classnames(
                'data-grid-footer-pagination',
                classNames.footerPagination
            )}>
                {
                    !dataGridLayout.hidePageSize &&
                    <>
                        <p className={classnames(
                            'data-grid-footer-pagination-text',
                            classNames.footerPaginationText,
                        )}>
                            Rows per page:
                        </p>
                        <div
                            onClick={openPageSizePopover}
                            className={classnames(
                                'data-grid-footer-pagination-page-size-dropdown',
                                {'disabled': pageSizeDropdownDisabled},
                            )}
                        >
                            <p className={classnames(
                                'data-grid-footer-pagination-text',
                                classNames.footerPaginationText,
                            )}>
                                {pagination.pageSize ?? 0}
                            </p>
                            <PageSizeTogglerIcon/>
                        </div>
                    </>
                }
                {
                    !!initialPagination && !dataGridLayout.hidePagination &&
                    <>
                        <p className={classnames(
                            'data-grid-footer-pagination-text',
                            classNames.footerPaginationText,
                        )}>
                            {paginationInformation}
                        </p>
                        <DataGridIconButton
                            disabled={prevButtonDisabled}
                            onClick={() => setCurrentPage(-1)}
                            className={classnames(
                                'data-grid-footer-pagination-icon-button prev'
                            )}>
                            <PrevPageIcon/>
                        </DataGridIconButton>
                        <DataGridIconButton
                            disabled={nextButtonDisabled}
                            onClick={() => setCurrentPage(1)}
                            className={classnames(
                                'data-grid-footer-pagination-icon-button next'
                            )}>
                            <NextPageIcon/>
                        </DataGridIconButton>
                    </>
                }
            </div>
            <Popover
                id={!!popover ? id.current : undefined}
                elevation={2}
                open={!!popover}
                onClose={closePageSizePopover}
                anchorReference={'anchorEl'}
                className={classnames(
                    "data-grid-footer-pagination-page-sizes-action-popover",
                    "data-grid-popover",
                    classNames.footerPaginationPageSizesActionPopover,
                )}
                classes={{
                    paper: classnames(
                        'data-grid-popover-paper',
                        classNames.footerPaginationPageSizesActionPopoverPaper,
                    )
                }}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                {
                    pagination?.sizes?.map(pageSize => (
                        <div key={pageSize}
                             onClick={() => setPageSize(pageSize)}
                             className={classnames(
                                 'popover-item default',
                                 {'active': pageSize === pagination.pageSize},
                             )}>
                            <p>
                                {pageSize ?? ''}
                            </p>
                        </div>
                    ))
                }
            </Popover>
        </>
    )
}

export default DataGridFooterPagination;

import {generatePath} from "react-router";

/**
 * The map of all the available AppRoutes in the system
 */
class AppRoutes {
    /**
     * Public Views
     */
    static public = {
        landing: "/",
        test: "/test",
        redirectLink: '/link/:token',
        error: {
            _base: "/error/*",
            base: "/error",
            _relative: {
                notFound: "not-found",
                accessDenied: "access-denied",
                serverError: "server-error",
            },
            notFound: "/error/not-found",
            accessDenied: "/error/access-denied",
            serverError: "/error/server-error",
        },
        authentication: {
            _base: "/authentication/*",
            base: "/authentication",
            _relative: {
                login: "login/:token/:time",
                logout: "logout",
                redirect: "redirect",
            },
            login: "/authentication/login/:token/:time",
            logout: "/authentication/logout",
            redirect: "/authentication/redirect",
        },
    };

    /**
     * Private Views
     */
    static private = {
        versionHistory: '/version-history',
    };

    /**
     * Panel Views
     */
    static panel = {
        base: "/panel",
        dashboard: "/panel/dashboard",
        generateStaticCode: "/panel/generate-static-code",
        generateCode: "/panel/generate-code",
        codeList: "/panel/code-list",
        codeDetails: "/panel/code-details/:contentType/:codeId/:contentId?",
        _relative: {
            dashboard: "dashboard",
            generateStaticCode: "generate-static-code",
            generateCode: "generate-code",
            codeList: "code-list",
            codeDetails: "code-details/:contentType/:codeId/:contentId?",
        }
    };

    /**
     * Generates the path of the given AppRoutes in the system that include route parameters.
     */
    static generateRoute: typeof generatePath = (route, params) => {
        return generatePath(route, params);
    }
}

export default AppRoutes;

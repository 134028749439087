import QueryBuilderApiEndpoints from "../../models/static/api-endpoints";
import {
    CreateTemplateRequestDS,
    CreateTemplateResponse,
    GetFilterOptionsResponse,
    GetTemplatesApiResponseDS,
    GetTemplatesResponse,
    RemoveTemplateRequestDS,
    RemoveTemplateResponse,
    ReplaceTemplateRequestDS,
    ReplaceTemplateResponse
} from "./type-declarations";
import {QueryBuilderTemplate} from "../../../index";
import QueryBuilderUtils from "../utils";
import {ApiMethods, ApiService} from "../../../../base/core";

/**
 * @inheritDoc
 */
class QueryBuilderApiService extends ApiService {
    /**
     * The version of the query builder.
     */
    private static version = '1';

    // ###################################       LIST OPTIONS         ###################################

    /**
     * Fetches the filter options from the server.
     * @param fetchOptionsApiUrl    the url to fetch the filter options from.
     * @param apiKey                the apiKey to fetch the filter options for.
     * @param keyword               the keyword to filter the filter options with.
     * @param abortController   the abort controller to abort the request
     */
    static async getFilterOptions(fetchOptionsApiUrl: string, apiKey: string, keyword: string | undefined, abortController: AbortController): GetFilterOptionsResponse {
        return await this.executor.execute({
            method: ApiMethods.get,
            url: fetchOptionsApiUrl,
            params: {
                apiKey: apiKey,
                ...(!!keyword?.trim() && {
                    keyword: keyword.trim(),
                })
            },
            signal: abortController.signal,
        });
    }

    // ###################################       QUERY BUILDER         ###################################


    /**
     * Fetches the templates from the server.
     * @param applicationName           the name application to fetch the templates from.
     * @param sectionName               the name of the section to fetch the templates from.
     * @param abortController           the cancel token to cancel the request.
     */
    static async getTemplates(applicationName: string, sectionName: string, abortController: AbortController): GetTemplatesResponse {
        const response = await this.executor.execute({
            method: ApiMethods.get,
            url: QueryBuilderApiEndpoints.queryBuilder.getData,
            signal: abortController.signal,
            params: {
                applicationName: applicationName,
            }
        });
        if (response?.resultFlag) {
            response.data = (response.data as Array<GetTemplatesApiResponseDS> ?? [])
                .filter(e => e.name === sectionName && e.version === QueryBuilderApiService.version)
                .map<QueryBuilderTemplate[]>(e => {
                    let res: Array<QueryBuilderTemplate>;
                    try {
                        res = JSON.parse(e.data as unknown as string ?? '{}');
                    } catch {
                        res = [];
                    }
                    return res;
                })
                .flat()
                .filter(e => !!e.data)
        }
        return response;
    }

    /**
     * Creates a new template in the server.
     * @param data      The data to create the template with.
     */
    static async createTemplate(data: CreateTemplateRequestDS): CreateTemplateResponse {
        const newTemplate = {...data.newTemplate, id: QueryBuilderUtils.createUUId()};
        const response = await this.executor.execute({
            method: ApiMethods.post,
            url: QueryBuilderApiEndpoints.queryBuilder.saveData,
            data: {
                applicationName: data.applicationName,
                name: data.name,
                version: QueryBuilderApiService.version,
                data: JSON.stringify([
                    ...data.templates,
                    newTemplate
                ])
            },
        });
        if (response?.resultFlag) {
            response.data = newTemplate;
        }
        return response;
    }


    /**
     * Replaces the template in the server.
     * @param data    the data to replace the template with.
     */
    static async replaceTemplate(data: ReplaceTemplateRequestDS): ReplaceTemplateResponse {
        return await this.executor.execute({
            method: ApiMethods.post,
            url: QueryBuilderApiEndpoints.queryBuilder.saveData,
            data: {
                applicationName: data.applicationName,
                name: data.name,
                version: QueryBuilderApiService.version,
                data: JSON.stringify(data.templates.map(e => e.id === data.templateId ? {...e, data: data.data} : e))
            },
        });
    }

    /**
     * Deletes the template from the server.
     * @param data    the request data to delete the template with.
     */
    static async removeTemplate(data: RemoveTemplateRequestDS): RemoveTemplateResponse {
        return await this.executor.execute({
            method: ApiMethods.post,
            url: QueryBuilderApiEndpoints.queryBuilder.saveData,
            data: {
                applicationName: data.applicationName,
                name: data.name,
                version: QueryBuilderApiService.version,
                data: JSON.stringify(data.templates.filter(e => e.id !== data.templateId))
            },
        });
    }
}


export * from './type-declarations';
export default QueryBuilderApiService;

import type {FunctionComponent, ReactEventHandler} from "react";
import {useCallback, useEffect, useState} from "react";
import type {LazyLoadImageProps} from 'react-lazy-load-image-component';
import {LazyLoadImage as RLazyLoadImage} from 'react-lazy-load-image-component';
import placeholderSrc from '../../../assets/images/blurred-bg-480.jpeg';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {EnvService} from "../../../core";


const LazyLoadImage: FunctionComponent<LazyLoadImageProps> = (props) => {
    const [src, setSrc] = useState<string | undefined>(props.src);

    /**
     * With each change in the [src] property of props:
     * - sets the src state value.
     */
    useEffect(() => {
        setSrc(props.src);
    }, [props.src]);

    /**
     * Changes the src of the image to noImage if there is noImage available and the src is not already noImage, and it's not a valid React element
     * If the noImage is a valid component, sets the [src] in the state to null
     *
     * @param {Event} e
     */
    const onImageError = useCallback<ReactEventHandler<HTMLImageElement>>((e) => {
        if (EnvService.isDevelopment)
            console.log("Image loading failed", src)

        if (props.onError)
            props.onError(e);

        setSrc(undefined)
    }, [props, src]);

    return (
        <>
            {
                !!src &&
                <RLazyLoadImage
                    effect={'blur'}
                    placeholderSrc={placeholderSrc}
                    onError={onImageError}
                    {...props}
                    placeholder={undefined}
                    src={src}
                />
            }
            {
                !src &&
                props.placeholder
            }
        </>
    )
}

export default LazyLoadImage;

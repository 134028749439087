import React, {FunctionComponent, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import type {DraggableEventHandler} from "react-draggable";
import Draggable from "react-draggable";
import $ from 'jquery';
import classnames from "classnames";
import DataGridUtils from "../../../core/services/utils";

// the default size of the switch
const DEFAULT_SIZE = 6;


declare module 'csstype' {
    // noinspection JSUnusedGlobalSymbols
    interface Properties {
        '--switch_size'?: number;
    }
}

export type DataGridSwitchProps = {
    onChange: (checked: boolean, callback: () => void) => void,
    size?: number,
    className?: string,
    disabled?: boolean,
    checked?: boolean,
    id?: string,
    activeTitle?: string,
    inActiveTitle?: string,
}

export type SwitchOnClickCallback = (e: React.MouseEvent<HTMLElement, MouseEvent>, force?: boolean) => void;
export type MoveSwitchCallback = (checked: boolean) => void;

const DataGridSwitch: FunctionComponent<DataGridSwitchProps> = ({
                                                                    onChange,
                                                                    size = DEFAULT_SIZE,
                                                                    className = '',
                                                                    disabled = false,
                                                                    checked: checkedProp = false,
                                                                    id: idProp,
                                                                    activeTitle = 'ON',
                                                                    inActiveTitle = 'OFF'
                                                                }) => {
    const id = useRef<string>(idProp || DataGridUtils.createUUId(true));
    const containerId = useRef<string>(DataGridUtils.createUUId(true));
    const [checked, setChecked] = useState<boolean>(checkedProp ?? false);
    const [switchSize, setSwitchSize] = useState<number>(size);
    const [timer, setTimer] = useState<number>(0);
    const draggableRef = useRef<HTMLElement>(null);

    /**
     * As soon as the component mounts:
     * sets the switch's size if it is not in a valid range
     */
    useLayoutEffect(() => {
        if (!(size >= 1 && size <= 10) || size === undefined)
            setSwitchSize(DEFAULT_SIZE);
    }, [size]);

    /**
     * Moves the switch thumb component in the container.
     * @param checked         whether to move to switch to on or off state.
     */
    const moveSwitch = useCallback<MoveSwitchCallback>((checked) => {
        const switchElm = $(`#${id.current}`);
        if (!checked) {
            switchElm.css({
                transform: `translateX(-2px)`
            });
        } else {
            const containerWidth = $(`#${containerId.current}`).outerWidth() ?? 0;
            switchElm.css({
                transform: `translateX(${containerWidth / 2}px)`
            })
        }
    }, []);

    /**
     * Listens to the checked state
     * Call the onChange method provided from props
     */
    useEffect(() => {
        if (!id.current)
            return;
        moveSwitch(checked);
        if (checked === checkedProp)
            return;
        const prev = !checked;
        if (onChange)
            onChange(checked, () => {
                setChecked(prev);
            });
    }, [checked, moveSwitch, onChange]);

    /**
     * Listens to the checked state
     * Call the onChange method provided from props
     */
    useEffect(() => {
        setChecked(prevState => {
            if (prevState === checkedProp)
                return prevState;
            return checkedProp ?? false;
        });
    }, [checkedProp]);

    /**
     * Handles the onClick event for the switch
     * @param e     the mouse event
     * @param force whether to force the onClick event or not
     */
    const onClick = useCallback<SwitchOnClickCallback>((e, force = false) => {
        if (disabled && !force)
            return;
        setChecked(prevState => !prevState);
        DataGridUtils.modifyEvent(e);
    }, [disabled])

    /**
     * Sets the timer when the drag functionality starts
     */
    const onDragStart = useCallback<VoidCallback>(() => {
        setTimer(new Date().getTime());
    }, [])

    /**
     * Determines whether the user has dragged to switch or clicked on it.
     * @return {boolean}    whether the user dragged the switch or not.
     */
    const determineIfUserDraggedTheSwitch = useCallback<() => boolean>(() => {
        const startTime = timer;
        const endTime = new Date().getTime();
        // Checking if the user clicked on the switch handle or dragged it
        return endTime - startTime <= 300;
    }, [timer]);

    /**
     * Handles the drag stop event for the switch by determining whether the user dragged the switch or clicked on it.
     * @param e     the drag event
     */
    const onDragStop = useCallback<DraggableEventHandler>((e) => {
        if (determineIfUserDraggedTheSwitch()) {
            draggableRef.current?.classList?.remove('no-transition');
            requestAnimationFrame(() => onClick(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>, true))
            return;
        }
        const switchEle = $(`#${id.current}`);
        const {left: switchLeft} = switchEle.position();
        const switchWidth = switchEle.outerWidth() ?? 0;
        const containerWidth = $(`#${containerId.current}`).outerWidth() ?? 0;
        const switchInOffArea = (switchLeft + (switchWidth / 2)) < (containerWidth / 2);
        const switchInOnArea = (switchLeft + (switchWidth / 2)) > (containerWidth / 2);
        let _checked = checked;
        if (switchInOffArea && checked) {
            _checked = false;
        }
        if (switchInOnArea && !checked) {
            _checked = true;
        }
        if (checked !== _checked) {
            setChecked(_checked);
        } else {
            moveSwitch(_checked);
        }
    }, [checked, determineIfUserDraggedTheSwitch, moveSwitch, onClick]);

    return (
        <div
            className={classnames(`data-grid-switch-wrapper`, className, {disabled, checked})}
            id={containerId.current}
            style={{
                '--switch_size': switchSize,
            }}
            onClick={(e) => DataGridUtils.modifyEvent(e)}
            onMouseDown={e => DataGridUtils.modifyEvent(e)}
            onMouseUp={e => DataGridUtils.modifyEvent(e)}
            onTouchStart={e => DataGridUtils.modifyEvent(e)}
            onTouchEnd={e => DataGridUtils.modifyEvent(e)}
        >
            <div
                className={classnames(`w-100 switch-text switch-text-start`, {'show': checked,})}
                onClick={onClick}>
                {activeTitle}
            </div>
            <div
                className={classnames(`w-100 switch-text switch-text-end`, {'show': !checked,})}
                onClick={onClick}>
                {inActiveTitle}
            </div>
            <Draggable
                axis='x'
                bounds='parent'
                scale={1}
                disabled={disabled}
                allowAnyClick={false}
                onStart={onDragStart}
                onStop={onDragStop}
                ref={draggableRef as unknown as RefObject<Draggable>}
                defaultPosition={{x: -2, y: 0}}
                position={{
                    x: checked
                        ? (($(`#${containerId.current}`).outerWidth() ?? 0) / 2)
                        : -2,
                    y: 0,
                }}
                defaultClassNameDragging={'no-transition'}
            >
                <div
                    className={classnames(`switch`)}
                    id={id.current}
                />
            </Draggable>
        </div>
    )
};

export default DataGridSwitch;

import React, {useContext, useImperativeHandle, useLayoutEffect, useMemo, useReducer, useRef} from "react";
import {
    DataGridStateActions,
    DefaultDataGridDensity,
    DefaultDataGridEvents,
    DefaultDataGridLayout,
    DefaultDataGridMisc,
    DefaultDataGridPropState
} from "../type-declerations";
import classnames from "classnames";
import DataGridToolbar from "./sections/toolbar";
import DataGridFooter from "./sections/footer";
import DataGridEventEmitter from "../core/models/in-app/event-emitter";
import DataGridReducer from "../core/models/in-app/reducer";
import InternalDataGridController from "../core/models/in-app/controller/internal";
import DataGridTable from "./sections/table";
import DataGridProvider from "./sections/provider";
import DataGridLoading from "./sections/loading";
import DataGridEmptyContent from "./sections/empty-content";
import DataGridStateSaver from "../core/models/in-app/state-saver";
import {DataGridApplicationNameContext} from "../index";

/**
 * @param id                                    The id of the data-grid.
 * @param {DataGridRow[]}                       rows
 * @param {DataGridColumn[]}                    columns
 * @param {DataGridInitialLayoutState?}         initialLayoutState
 * @param {DataGridState}                       stateProp
 *
 * @param {string}                              name
 * @param {number}                              version
 *
 * @param {boolean}                             disableReordering
 * @param {boolean}                             disableResizing
 * @param {boolean}                             disableTogglingPins
 * @param {boolean}                             disableTogglingVisibility
 *
 * @param {boolean}                             hideFooter
 * @param {boolean}                             hidePagination
 * @param {boolean}                             hidePageSize
 * @param {boolean}                             hideFooterActions
 * @param {boolean}                             hideToolbar
 * @param {boolean}                             hideColumnFiltering
 * @param {boolean}                             hideLayoutRefreshing
 * @param {boolean}                             hideDensityChanging
 * @param {boolean}                             hideExporting
 * @param {boolean}                             hideSelectAll
 *
 * @param {boolean}                             resetSelectionOnPageSizeChange
 * @param {boolean}                             resetSelectionOnCurrentPageChange
 * @param {boolean}                             resetSelectionOnSortByChange
 * @param {boolean}                             singleSelectionOnly
 * @param {Partial<DataGridClassnames>}         classNames
 * @param {JSX.Element | undefined}             EmptyContentComponent
 * @param {JSX.Element | undefined}             LoadingComponent
 * @param {GetExportInfoFunc}                   getExportInfo
 * @param {GetSingleSelectionFooterTextFunc}    getSingleSelectionFooterText
 * @param {DataGridFooterAction[]}              footerActions
 * @param {number}                              visibleRows
 *
 * @param {DataGridEvent<DataGridSortBy | undefined, DataGridSortBy>}  onSortByChanged
 * @param {DataGridEventWithChange<DataGridPagination, DataGridPagination, number>}  onPageSizeChanged
 * @param {DataGridEventWithChange<DataGridPagination, DataGridPagination, number>}  onCurrentPageChanged
 * @param {DataGridEventWithChange<Record<string, boolean>>}  onColumnVisibilitiesToggled
 * @param {DataGridEventWithChange<Record<string, DataGridColumnPinnedTypes>>}  onColumnsPinnedToggled
 * @param {DataGridEventWithChange<Record<string, DataGridColumnWidth>>}  onColumnsResized
 * @param {DataGridEvent<DataGridReorderingEventArg>}  onColumnsReordered
 * @param {DataGridEvent<DataGridRowSelectionInfo>}  onRowSelectionInfoChanged
 * @param {DataGridEvent<void>}  onLayoutRefreshed
 * @param {DataGridEvent<DataGridDensities>}  onDensityChanged
 * @param {DataGridActionInvocationCallback}  onActionInvoked
 * @param {(prevValue: any, newValue: any, rowKey: any, colName: string) => PromiseLike<boolean>}  onCellEdited
 *
 * @param {MutableRefObject<DataGridController | null>} ref
 * @constructor
 * @return {JSX.Element}
 */
const _DataGrid = ({
                       id,
                       rows = [],
                       footerActions = [],
                       columns = [],
                       initialLayoutState,
                       state: stateProp = DefaultDataGridPropState,

                       name = undefined,
                       version = undefined,

                       disableReordering = DefaultDataGridLayout.disableReordering,
                       disableResizing = DefaultDataGridLayout.disableResizing,
                       disableTogglingPins = DefaultDataGridLayout.disableTogglingPins,
                       disableTogglingVisibility = DefaultDataGridLayout.disableTogglingVisibility,

                       hideFooter = DefaultDataGridLayout.hideFooter,
                       hidePagination = DefaultDataGridLayout.hidePagination,
                       hidePageSize = DefaultDataGridLayout.hidePageSize,
                       hideFooterActions = DefaultDataGridLayout.hideFooterActions,
                       hideToolbar = DefaultDataGridLayout.hideToolbar,
                       hideColumnFiltering = DefaultDataGridLayout.hideColumnFiltering,
                       hideLayoutRefreshing = DefaultDataGridLayout.hideLayoutRefreshing,
                       hideDensityChanging = DefaultDataGridLayout.hideDensityChanging,
                       hideExporting = DefaultDataGridLayout.hideExporting,
                       hideSelectAll = DefaultDataGridLayout.hideSelectAll,

                       resetSelectionOnPageSizeChange = DefaultDataGridMisc.resetSelectionOnPageSizeChange,
                       resetSelectionOnCurrentPageChange = DefaultDataGridMisc.resetSelectionOnCurrentPageChange,
                       resetSelectionOnSortByChange = DefaultDataGridMisc.resetSelectionOnSortByChange,
                       singleSelectionOnly = DefaultDataGridMisc.singleSelectionOnly,
                       classNames = DefaultDataGridMisc.classNames,
                       EmptyContentComponent = DefaultDataGridMisc.EmptyContentComponent,
                       LoadingComponent = DefaultDataGridMisc.LoadingComponent,
                       getExportInfo = DefaultDataGridMisc.getExportInfo,
                       getSingleSelectionFooterText = DefaultDataGridMisc.getSingleSelectionFooterText,
                       visibleRows = DefaultDataGridMisc.visibleRows,

                       onSortByChanged = DefaultDataGridEvents.onSortByChanged,
                       onPageSizeChanged = DefaultDataGridEvents.onPageSizeChanged,
                       onCurrentPageChanged = DefaultDataGridEvents.onCurrentPageChanged,
                       onColumnVisibilitiesToggled = DefaultDataGridEvents.onColumnVisibilitiesToggled,
                       onColumnsPinnedToggled = DefaultDataGridEvents.onColumnsPinnedToggled,
                       onColumnsResized = DefaultDataGridEvents.onColumnsResized,
                       onColumnsReordered = DefaultDataGridEvents.onColumnsReordered,
                       onRowSelectionInfoChanged = DefaultDataGridEvents.onRowSelectionInfoChanged,
                       onLayoutRefreshed = DefaultDataGridEvents.onLayoutRefreshed,
                       onDensityChanged = DefaultDataGridEvents.onDensityChanged,
                       onActionInvoked = DefaultDataGridEvents.onActionInvoked,
                       onCellEdited = DefaultDataGridEvents.onCellEdited,
                   }, ref) => {
    /**@type {DataGridEvents}*/
    const events = useMemo(() => ({
        onSortByChanged,
        onPageSizeChanged,
        onCurrentPageChanged,
        onColumnVisibilitiesToggled,
        onColumnsPinnedToggled,
        onColumnsResized,
        onColumnsReordered,
        onRowSelectionInfoChanged,
        onLayoutRefreshed,
        onDensityChanged,
        onActionInvoked,
        onCellEdited,
    }), [
        onSortByChanged,
        onPageSizeChanged,
        onCurrentPageChanged,
        onColumnVisibilitiesToggled,
        onColumnsPinnedToggled,
        onColumnsResized,
        onColumnsReordered,
        onRowSelectionInfoChanged,
        onLayoutRefreshed,
        onDensityChanged,
        onActionInvoked,
        onCellEdited,
    ]);
    const appName = useContext(DataGridApplicationNameContext);
    const reducer = useMemo(() => new DataGridReducer(new DataGridEventEmitter(events), new DataGridStateSaver(appName, name, version)), []);
    const reducerFunc = useMemo(() => reducer.reducer, [])
    const initializerArg = useMemo(() =>
        /**@type {DataGridStateReducerInitializer}*/
        ({
            initialState: {
                ...(stateProp ?? {}),
            },
            columns: columns,
            preliminaryColumns: initialLayoutState?.columns ?? columns ?? [],
            preliminaryDensity: initialLayoutState?.density ?? stateProp?.density ?? DefaultDataGridDensity,
        }), [])
    const [state, dispatch] = useReducer(
        reducerFunc,
        initializerArg,
        reducer.initializer
    );
    /**@type {DataGridLayout}*/
    const layout = useMemo(() => ({
        disableReordering,
        disableResizing,
        disableTogglingPins,
        disableTogglingVisibility,
        hidePagination,
        hidePageSize,
        hideFooterActions,
        hideToolbar,
        hideColumnFiltering,
        hideLayoutRefreshing,
        hideDensityChanging,
        hideExporting,
        hideSelectAll,
        hideFooter,
    }), [
        disableReordering,
        disableResizing,
        disableTogglingPins,
        disableTogglingVisibility,
        hidePagination,
        hideFooterActions,
        hideToolbar,
        hideSelectAll,
        hideColumnFiltering,
        hideLayoutRefreshing,
        hideDensityChanging,
        hideExporting,
        hideFooter,
        hidePageSize,
    ]);
    const api = useMemo(() => new InternalDataGridController(state, dispatch, rows, layout, getExportInfo), []);
    /**@type {DataGridEvents}*/
    const eventHandlers = useMemo(() => events, [events])
    /** @type {DataGridMisc} */
    const misc = useMemo(() => ({
        classNames,
        EmptyContentComponent,
        LoadingComponent,
        getExportInfo,
        resetSelectionOnPageSizeChange,
        resetSelectionOnCurrentPageChange,
        resetSelectionOnSortByChange,
        singleSelectionOnly,
        getSingleSelectionFooterText,
        visibleRows,
    }), [
        classNames,
        EmptyContentComponent,
        LoadingComponent,
        getExportInfo,
        resetSelectionOnPageSizeChange,
        resetSelectionOnCurrentPageChange,
        resetSelectionOnSortByChange,
        singleSelectionOnly,
        getSingleSelectionFooterText,
        visibleRows,
    ])
    const initialRender = useRef({
        // api
        state: true,
        rows: true,
        layout: true,
        getExportInfo: true,
        // reducer
        events: true,
        columns: true,
        preliminaryColumns: true,
        preliminaryDensity: true,
        stateProps: true,
        stateSaverInfo: true,
    });
    const prevRowsLengthRef = useRef(undefined);

    const isLoading = useMemo(() => state.loading?.state, [state?.loading?.state]);

    /**
     * Binds the passed ref from the parent with the api of this grid data.
     */
    useImperativeHandle(ref, () => api, [api])

    /**
     * With each change in columns:
     * * dispatches the action that indicates a columnPropsChange so that the reducer can change the state accordingly.
     *
     * NOTE: the dispatch is used directly without the api since changing the columns is one directional (only
     * though props)
     */
    useLayoutEffect(() => {
        if (initialRender.current.columns) {
            return (initialRender.current.columns = false) || undefined;
        }
        dispatch({
            type: DataGridStateActions.columnsPropsChanged,
            payload: columns
        })
    }, [dispatch, columns])
    /**
     * With each change in preliminaryColumns:
     * * dispatches the action that indicates a preliminaryColumnPropsChange so that the reducer can change the state accordingly.
     *
     * NOTE: the dispatch is used directly without the api since changing the columns is one directional (only
     * though props)
     */
    useLayoutEffect(() => {
        if (initialRender.current.preliminaryColumns) {
            return (initialRender.current.preliminaryColumns = false) || undefined;
        }
        dispatch({
            type: DataGridStateActions.preliminaryColumnsChanged,
            payload: initialLayoutState?.columns ?? [],
        })
    }, [dispatch, initialLayoutState?.columns])
    /**
     * With each change in preliminaryDensity:
     * * dispatches the action that indicates a preliminaryDensityChanged so that the reducer can change the state accordingly.
     *
     * NOTE: the dispatch is used directly without the api since changing the columns is one directional (only
     * though props)
     */
    useLayoutEffect(() => {
        if (initialRender.current.preliminaryDensity) {
            return (initialRender.current.preliminaryDensity = false) || undefined;
        }
        dispatch({
            type: DataGridStateActions.preliminaryDensityChanged,
            payload: initialLayoutState?.density ?? [],
        })
    }, [dispatch, initialLayoutState?.density])
    /**
     * With each change in stateProp:
     * * dispatches the action that indicates a initialStateChange so that the reducer can change the state accordingly.
     *
     * NOTE: the dispatch is used directly without the api since changing the stateProps is one directional (only
     * though props)
     */
    useLayoutEffect(() => {
        if (initialRender.current.stateProps) {
            return (initialRender.current.stateProps = false) || undefined;
        }
        dispatch({
            type: DataGridStateActions.statePropsChanged,
            payload: stateProp
        })
    }, [dispatch, stateProp])
    /**
     * With each change in the [name] or [version] prop values:
     * - updates the state saver's information of this data-grid.
     */
    useLayoutEffect(() => {
        if (initialRender.current.stateSaverInfo) {
            return (initialRender.current.stateSaverInfo = false) || undefined;
        }
        reducer.stateSaverInfo = {name, version, appName};
    }, [name, reducer, version, appName])
    /**
     * With each change in state:
     * - sets the apis' state property to keep it updated
     */
    useLayoutEffect(() => {
        if (initialRender.current.state) {
            return (initialRender.current.state = false) || undefined;
        }
        api.state = state
    }, [api, state])
    /**
     * With each change in rows:
     * - sets the apis' rows property to keep it updated
     */
    useLayoutEffect(() => {
        api.rows = rows
        if (stateProp?.pagination?.length)
            return;
        if (!prevRowsLengthRef) {
            prevRowsLengthRef.current = rows?.length;
            return
        }
        if (rows?.length === prevRowsLengthRef.current)
            return;
        prevRowsLengthRef.current = rows?.length;
        api.setPageLength(prevRowsLengthRef.current ?? 0);
    }, [api, rows])
    /**
     * With each change in layout:
     * - sets the APIs layout property to keep it updated
     */
    useLayoutEffect(() => {
        if (initialRender.current.layout) {
            return (initialRender.current.layout = false) || undefined;
        }
        api.layout = layout
    }, [api, layout])
    /**
     * With each change in getExportedInfo:
     * - sets the apis' getExportedInfo property to keep it updated
     */
    useLayoutEffect(() => {
        if (initialRender.current.getExportInfo) {
            return (initialRender.current.getExportInfo = false) || undefined;
        }
        api.getExportInfo = getExportInfo;
    }, [api, getExportInfo])
    /**
     * with each change in events:
     * * updates the events in the event emitter of the reducer.
     */
    useLayoutEffect(() => {
        if (initialRender.current.events) {
            return (initialRender.current.events = false) || undefined;
        }
        reducer.events = events
    }, [reducer, events])

    const toolbar = useMemo(() =>
            <DataGridToolbar/>,
        [])

    const footer = useMemo(() =>
            <DataGridFooter
                footerActions={footerActions}
                rows={rows}
            />,
        [footerActions, rows])

    const content = useMemo(() =>
            <DataGridTable
                rows={rows}
            />,
        [rows])

    return (
        <>
            <div id={id} className={classnames(
                'data-grid-container',
                classNames.container,
                {'no-toolbar': hideToolbar},
                {'data-grid-loading': isLoading},
            )}>
                <DataGridProvider
                    state={state}
                    api={api}
                    layout={layout}
                    eventHandlers={eventHandlers}
                    misc={misc}
                >
                    {toolbar}
                    {content}
                    {footer}
                    {
                        isLoading &&
                        <DataGridLoading
                            rowsLength={rows?.length ?? 0}
                        />
                    }
                    {
                        (!rows?.length) &&
                        <DataGridEmptyContent loading={isLoading}/>
                    }
                </DataGridProvider>
            </div>
        </>
    )
}

export default _DataGrid;

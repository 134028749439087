import {FunctionComponent} from "react";
import ExportingDialog from "./exporting";
import PreviewImagesDialog from "./preview-images";


const BizkeytechDialogs: FunctionComponent = () => {


    return (
        <>
            <ExportingDialog/>
            <PreviewImagesDialog/>
        </>
    );
}


export default BizkeytechDialogs;

import {BroadcastChannel, BroadcastChannelOptions, EventContext, OnMessageHandler} from 'broadcast-channel';

export interface BroadcastServiceAddEventListenerResult {
    resultFlag: boolean,
    close: VoidFunction,
}

/**
 * The service that handles all the static broadcast channel functionalities.
 */
class BroadcastService {

    /**
     * Creates a new broadcast channel with the provided name and options.
     * @param channelName       The name of the channel to create.
     * @param options           The options to pass to the broadcast channel.
     */
    public static newBroadcastChannel<T = any>(channelName: string, options?: BroadcastChannelOptions): BroadcastChannel<T> {
        return new BroadcastChannel<T>(channelName, options);
    }

    /**
     * Posts a singular message to the given channel name with the given args.
     *
     * @param channelName       The name of the channel to post the message to.
     * @param args              The arguments to pass to the channel.
     */
    public static postMessage<T = any>(channelName: string, args: T): boolean {
        try {
            const bc = new BroadcastChannel<T>(channelName);
            bc.postMessage(args).then(() => {
                bc.close().then();
            });
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Adds an event listener for the given channel name and returns its cleanup function.
     *
     * @param channelName   The name of the channel to listen to.
     * @param type          The type of the event to listen to.
     * @param listener      The listener to call when the event is triggered.
     */
    public static addEventListener<T = any>(channelName: string, type: EventContext, listener: OnMessageHandler<T>): BroadcastServiceAddEventListenerResult {
        try {
            const bc = new BroadcastChannel<T>(channelName);
            bc.addEventListener(type, listener);
            return {
                resultFlag: true,
                close: () => bc.close(),
            };
        } catch (e) {
            return {
                resultFlag: false,
                close: () => (void 0),
            };
        }
    }
}

export * from 'broadcast-channel';
export default BroadcastService;


import React, {FunctionComponent, useCallback, useLayoutEffect, useMemo, useState} from "react";
import {ReduxActions, useAppDispatch, useAppSelector} from "../../redux";
import {
    DialogsReduxSliceStateRemoveConfirmationData,
    DialogsReduxSliceStateRemoveConfirmationEntryDataRecord
} from "../../redux/slices/dialogs";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
    Unstable_Grid2 as Grid
} from "@mui/material";
import {useIsMounted} from "../../../@bizkeytech";


const RemoveConfirmationDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.dialogs?.removeConfirmation);
    const [removing, setRemoving] = useState(false);
    const [replacement, setReplacement] = useState<DialogsReduxSliceStateRemoveConfirmationEntryDataRecord | null>(null);
    const isMounted = useIsMounted();

    const {hideCloseButton, disableBackdropClick, ...dialogProps} = state;

    const needsToReplace = useMemo(() => !!state?.data?.data?.length, [state?.data?.data?.length]);

    /**
     * As soon as the dialog opens, resets the state values.
     */
    useLayoutEffect(() => {
        if (state?.open) {
            setRemoving(false);
            setReplacement(null);
        }
    }, [state?.open])

    /**
     * Closes the dialog by dispatching its relevant action to the redux store.
     */
    const closeDialog = useCallback(async (successful = false, data: DialogsReduxSliceStateRemoveConfirmationData | null = null) => {
        if (state?.onClose) {
            setRemoving(true);
            const resultFlag = await state.onClose(successful ? "successful" : "leave", data);
            if (!isMounted())
                return;
            setRemoving(false);
            if (successful && !resultFlag)
                return;
        }
        dispatch(ReduxActions.dialogs.removeConfirmation({open: false}));
    }, [state, dispatch, isMounted])

    /**
     * Closes the dialog if the values from the state do not prevent the closing of the dialog.
     */
    const onDialogClosed = useCallback((event: Object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        switch (reason) {
            case "backdropClick":
                if (!state.disableBackdropClick && !removing)
                    closeDialog().then();
                break;
            case "closeClick":
                if (!state.hideCloseButton && !removing)
                    closeDialog().then();
                break;
            case "escapeKeyDown":
                if (!state.disableEscapeKeyDown && !removing)
                    closeDialog().then();
                break;
            default:
                break;
        }
    }, [removing, closeDialog, state.disableBackdropClick, state.disableEscapeKeyDown, state.hideCloseButton])

    return (
        <>
            <Dialog
                {...dialogProps}
                onClose={onDialogClosed}
            >
                <DialogTitle fontWeight={600}>
                    Remove Confirmation
                </DialogTitle>
                <DialogContent>
                    {
                        !needsToReplace &&
                        <DialogContentText>
                            Are you sure you want to remove {state?.data?.removedEntryTitle ?? "the selected record"}?
                        </DialogContentText>
                    }
                    {
                        needsToReplace &&
                        <>
                            <DialogContentText variant={'body1'}>
                                Please select the replacement record before deleting the requested record:
                            </DialogContentText>
                            <Grid container xs={12} gap={2} spacing={2} marginInline={0} marginBlock={2}>
                                <Grid xs={'auto'} padding={0}>
                                    <Typography variant={'body1'}>
                                        To be removed:
                                    </Typography>
                                </Grid>
                                <Grid xs padding={0}>
                                    <Typography variant={'body1'}>
                                        {state?.data?.removedEntryTitle ?? "the selected record"}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} padding={0}/>
                                <Grid xs={'auto'} padding={0} sx={{display: 'flex', alignItems: 'center'}}>
                                    <Typography variant={'body1'}>
                                        To be replaced with:
                                    </Typography>
                                </Grid>
                                <Grid xs padding={0}>
                                    <TextField
                                        select
                                        fullWidth
                                        label={"Replacement"}
                                        value={replacement?.id ?? ""}
                                        onChange={(e) => setReplacement(state?.data?.data?.find(a => a.id === e.target.value) ?? null)}
                                        SelectProps={{
                                            renderValue: (value: any) => state?.data?.data?.find(e => e.id === value)?.title ?? "",
                                        }}
                                    >
                                        {
                                            state?.data?.data?.map(e => (
                                                <MenuItem key={e.id} value={e.id}>
                                                    {e.title}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        variant={'text'}
                        type={'button'}
                        color={'primary'}
                        disabled={removing}
                        onClick={() => onDialogClosed({}, 'closeClick')}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        disabled={(needsToReplace && !replacement) || removing}
                        onClick={() => closeDialog(true, replacement)}
                    >
                        {
                            removing
                                ? "Removing..."
                                : "Remove".concat(needsToReplace ? " And Replace" : "")
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RemoveConfirmationDialog;

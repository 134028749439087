import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import MapboxGlGeocoder from '@mapbox/mapbox-gl-geocoder';
import useIsMounted from "../../base/ui/hooks/use-is-mounted";
import MapboxEnvService from "../core/services/env";
import MapboxApiService, {GetMapboxMarkerAddressResponseDS} from "../core/services/api";
import classnames from "classnames";
import {ErrorBoundary} from "react-error-boundary";
import debounce from "lodash.debounce";
import './index.scss';
import {Box, BoxProps} from "@mui/material";
import {Room} from "@mui/icons-material";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapBoxGl, {MapboxOptions} from '!mapbox-gl';
import {useUpdateEffect} from "react-use";


// set the access token of mapboxGL
mapBoxGl.accessToken = MapboxEnvService.accessToken;

export interface MapboxAddress {
    country: string,
    address: string,
    province: string,
    postalCode: string,
}

export interface MapboxStartingAddress {
    address: string,
    country?: string,
}

export interface MapboxProps {
    /**
     * Callback function that is called when a marker is clicked on the mapbox
     */
    onAddressSelect?: (address: MapboxAddress) => void;
    /**
     * Callback function that is called when the component mounts
     */
    startingAddress?: MapboxAddress;
    /**
     * The class name of the map container
     */
    mapContainerClassName?: string;
    /**
     * The style of the map container
     */
    sx?: BoxProps['sx'],
    /**
     * Options of the mapbox
     */
    options?: Partial<Pick<Required<MapboxOptions>,
        'bearing' |
        'center' |
        'maxBounds' |
        'maxPitch' |
        'maxZoom' |
        'minPitch' |
        'minZoom' |
        'pitch' |
        'projection' |
        'renderWorldCopies' |
        'style' |
        'zoom'
    >>;

}

export interface MapboxRef {
    /**
     * Removes all the markers from the mapbox
     */
    removeMarkers: () => void;
    /**
     * Sets the address of the mapbox to the given address
     * @param address       The address to be searched
     * @param instance      The instance of the mapbox, if not given, the component's instance will be used
     */
    setAddress: (address: MapboxAddress, instance?: mapBoxGl.Map) => Promise<void>;
}

const Mapbox = forwardRef<MapboxRef, MapboxProps>((props, ref) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [markers, setMarkers] = useState<Array<mapBoxGl.Marker>>([]);
    const map = useRef<mapBoxGl.Map>();
    const isMounted = useIsMounted();

    /**
     * Fetches the coordinates of the given address
     * @param address    The address to be searched
     */
    const getCoordinatesOfAddress = useCallback(async (mapboxAddress: MapboxAddress) => {
        const address: MapboxStartingAddress = {
            address: [mapboxAddress.address ?? "", mapboxAddress?.province ?? ""].filter(e => !!e).join(', ').concat(mapboxAddress.postalCode ?? ""),
            country: mapboxAddress.country ?? "",
        }
        if (!address?.address?.length)
            return;
        const response = await MapboxApiService.getMapboxCoordinates(
            address?.address,
            mapBoxGl.accessToken,
            address.country,
        );
        if (!isMounted())
            return;
        if (!response?.resultFlag || !response?.data?.features?.length) {
            return;
        }
        return response.data.features[0].center;
    }, [isMounted])

    /**
     * Creates a marker with a specific center location in mapbox
     * @param {[number, number]} center
     * @param {mapBoxGl.Map} map
     */
    const createMarker = useCallback((center: [number, number], map: mapBoxGl.Map) => {
        const marker = new mapBoxGl.Marker()
            .setLngLat(center)
            .addTo(map);
        marker.getElement().classList.add('mapbox-marker')
        marker.getElement()?.addEventListener('click', () => {
            map.flyTo({
                center,
                zoom: 15,
                bearing: 0,
                essential: true,
            });
        })
        setMarkers(prevState => {
            prevState.forEach(e => e.remove());
            return [marker];
        });
    }, [])

    /**
     * Sets the address of the mapbox to the given address
     */
    const setAddress = useCallback<MapboxRef['setAddress']>(async (address, instance) => {
        const center = await getCoordinatesOfAddress(address);
        if (!center)
            return;
        instance = instance ?? map.current;
        if (!instance)
            return
        if (instance.loaded()) {
            instance.flyTo({
                center,
                zoom: 15,
                bearing: 0,
                essential: true,
            });
            createMarker(center, instance);
        } else {
            instance.on('load', () => {
                instance?.flyTo({
                    center,
                    zoom: 15,
                    bearing: 0,
                    essential: true,
                });
                createMarker(center, instance);
            })
        }
    }, [createMarker, getCoordinatesOfAddress]);

    /**
     * Removes all the markers from the mapbox
     */
    const removeMarkers = useCallback<MapboxRef['removeMarkers']>(() => {
        setMarkers(prevState => {
            prevState.forEach(e => e.remove());
            return [];
        });
    }, []);

    /**
     * Attaches functions to the ref of the component to be used outside the component.
     */
    useImperativeHandle(ref, () => ({
        removeMarkers: removeMarkers,
        setAddress: setAddress,
    }), [removeMarkers, setAddress])

    /**
     * Creates a new MapboxGL and adds controls to it.
     */
    const createMapAndMapControls = useCallback((): mapBoxGl.Map => {
        const newMap = new mapBoxGl.Map({
            container: mapContainer.current as HTMLDivElement,
            style: 'mapbox://styles/mapbox/light-v11',
            center: [45, 45],
            zoom: 1,
            ...props.options,
        });
        newMap.addControl(
            new mapBoxGl.NavigationControl(),
            'top-left'
        );
        newMap.addControl(
            new mapBoxGl.GeolocateControl({positionOptions: {enableHighAccuracy: true}, trackUserLocation: true}),
            'top-left'
        );
        newMap.addControl(
            new MapboxGlGeocoder({
                accessToken: mapBoxGl.accessToken,
                marker: false,
                mapboxgl: mapBoxGl
            })
        );
        return newMap;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * As soon as the component mounts:
     * Creates the map and adds the search, zoomIn, zoomOut, and current functionalities
     */
    useEffect(() => {
        if (!map.current) {
            map.current = createMapAndMapControls();
        }
        if (props.startingAddress)
            setAddress(props.startingAddress, map.current).then();
    }, [createMapAndMapControls, setAddress, props.startingAddress])

    /**
     * With each change in the [options] of props:
     * - updates the map box's options
     */
    useUpdateEffect(() => {
        if (map.current?.setStyle && props.options?.style) {
            map.current?.setStyle?.(props.options?.style)
        }
    }, [props.options]);


    /**
     * Fetches the address based on the given coordinates
     * @param {number} lng
     * @param {number} lat
     */
    const fetchAddressData = (lng: number, lat: number) => {
        MapboxApiService.getMapboxMarkerAddress(lng, lat, mapBoxGl.accessToken).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                const foundAddress = parseMapboxAddress(response.data!);
                props.onAddressSelect?.(foundAddress);
            }
        })
    }

    /**
     * Parses the returned data of mapbox address api
     * @param data      The data returned from mapbox
     */
    const parseMapboxAddress = (data: GetMapboxMarkerAddressResponseDS) => {
        const foundAddress = {
            address: '',
            province: '',
            country: '',
            postalCode: ''
        };
        data.features.forEach((item) => {
            switch (item.place_type[0]) {
                case 'address':
                    let streetName = item.text;
                    const lengthOfStreetNumber = item.place_name.indexOf(item.text);
                    if (lengthOfStreetNumber !== -1) {
                        streetName = item.place_name.substring(0, lengthOfStreetNumber) + streetName;
                    }
                    foundAddress.address = streetName;
                    break;
                case 'postcode':
                    foundAddress.postalCode = item.text;
                    break;
                case 'region':
                    foundAddress.province = item.text;
                    break;
                case 'country':
                    foundAddress.country = item.text;
                    break;
                case 'poi':
                    foundAddress.address = item.properties.address;
                    break;
                default:
                    break;
            }
        });
        return foundAddress;
    }

    /**
     * Retrieves the coordinates based on the location of the marker on the map and adds a new marker to the markers
     * of the mapbox
     *
     * If the onAddressSelect callback exists, fetches the address of the clicked marker and sends it to the callback
     */
    const onMarkerClick = () => {
        if (!map.current)
            return;
        const {lng, lat} = map.current?.getCenter();
        if (props.onAddressSelect) {
            if (map.current?.loaded()) {
                createMarker([lng, lat], map.current);
            } else {
                map.current?.on('load', () => {
                    createMarker([lng, lat], map.current!);
                })
            }
            fetchAddressData(lng, lat);
        }
    };

    /**
     * Resizes the map each time the window is resized.
     */
    useEffect(() => {
        let timer: number | undefined;
        const listener = debounce(() => {
            if (timer) {
                window.clearTimeout(timer);
                timer = undefined;
            }
            map.current?.resize();

            if (!markers.length)
                return;

            map.current?.flyTo({
                center: [45, 45],
                zoom: 1,
                maxDuration: 500,
            })
            timer = window.setTimeout(() => {
                map.current?.flyTo({
                    center: markers[0].getLngLat(),
                    zoom: 15,
                    bearing: 0,
                    essential: true,
                });
            }, 1000)
        }, 100);

        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [markers]);

    return (
        <ErrorBoundary fallback={<></>}>
            <Box className='mapbox'>
                <Box
                    ref={mapContainer}
                    sx={props.sx}
                    className={classnames('map-container', props.mapContainerClassName)}
                />
                {
                    props.onAddressSelect &&
                    <Room color={'primary'} className="marker" onClick={onMarkerClick}/>
                }
            </Box>
        </ErrorBoundary>
    )
});

export default Mapbox;

import {
    DataGridCachingServiceCookiesKeys,
    DataGridCachingServiceCookiesKeysType,
    DataGridCachingServiceLocalStorageKeys,
    DataGridCachingServiceLocalStorageKeysType
} from "./type-declarations";
import {CachingService, CachingServiceCookiesEntity, CachingServiceEntities, CachingServiceLocalStorageEntity} from "../../../../base/core";

/**
 * @inheritDoc
 */
class DataGridCachingService extends CachingService {

    /**
     * @inheritDoc
     */
    protected static LocalStorageEntity = new CachingServiceLocalStorageEntity<DataGridCachingServiceLocalStorageKeysType>({});

    /**
     * @inheritDoc
     */
    protected static CookiesEntity = new CachingServiceCookiesEntity<DataGridCachingServiceCookiesKeysType>({});

    /**
     * Fetches the cookies entity of this Caching service.
     * @param entityType
     */
    public static of(entityType: CachingServiceEntities.cookies): CachingServiceCookiesEntity<DataGridCachingServiceCookiesKeys>;
    /**
     * Fetches the local-storage entity of this Caching service.
     * @param entityType
     */
    public static of(entityType: CachingServiceEntities.localStorage): CachingServiceLocalStorageEntity<DataGridCachingServiceLocalStorageKeys>;
    /**
     * Fetches the requested entity of this Caching service based on the provided entityType.
     * @param entityType
     */
    public static of(entityType: CachingServiceEntities) {
        switch (entityType) {
            default:
                // @ts-ignore
                return super.of(entityType);
        }
    }

}

export * from "./type-declarations";
export {CachingServiceEntities} from "../../../../base/core";
export default DataGridCachingService;

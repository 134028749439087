import React, {useContext} from "react";
import classnames from "classnames";
import DataGridToolbarFilterColumnsAction from "./actions/columns";
import {DataGridLayoutContext, DataGridMiscContext} from "../../../index";
import DataGridToolbarDensityAction from "./actions/density";
import DataGridToolbarExportAction from "./actions/export";
import DataGridToolbarRefreshLayoutAction from "./actions/refresh-layout";

const DataGridToolbar = () => {
    const layout = useContext(DataGridLayoutContext)
    const {classNames} = useContext(DataGridMiscContext);

    return (
        <>
            {
                !layout.hideToolbar &&
                <div className={classnames('data-grid-toolbar', classNames.toolbar)}>
                    {
                        !layout.hideExporting &&
                        <DataGridToolbarExportAction/>
                    }
                    {
                        !layout.hideDensityChanging &&
                        <DataGridToolbarDensityAction/>
                    }
                    {
                        !layout.hideColumnFiltering &&
                        <DataGridToolbarFilterColumnsAction/>
                    }
                    {
                        !layout.hideLayoutRefreshing &&
                        <DataGridToolbarRefreshLayoutAction/>
                    }
                </div>
            }
        </>
    )
}

export default DataGridToolbar;

import React, {useContext, useMemo, useRef, useState} from "react";
import {Popover, useMediaQuery, useTheme} from "@mui/material";
import DataGridUtils from "../../../../../core/services/utils";
import {DataGridControllerContext, DataGridMiscContext, DataGridStateContext} from "../../../../../index";
import DataGridButton from "../../../../components/button";
import classnames from "classnames";
import {ReactComponent as StandardIcon} from "../../../../../assets/images/toolbar/density/density-standard.svg";
import {ReactComponent as CompactIcon} from "../../../../../assets/images/toolbar/density/density-compact.svg";
import {ReactComponent as ComfortableIcon} from "../../../../../assets/images/toolbar/density/density-comfortable.svg";
import {DataGridDensities} from "../../../../../type-declerations";
import DataGridIconButton from "../../../../components/icon-button/inedx";


const DataGridToolbarDensityAction = () => {
    const {density} = useContext(DataGridStateContext);
    const {classNames} = useContext(DataGridMiscContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const [popover, setPopover] = useState(null);
    const id = useRef(DataGridUtils.createUUId(true));

    const theme = useTheme();
    const showIconButton = useMediaQuery(theme.breakpoints.down('lg'));

    /**
     * Closes the popover
     */
    const closePopover = () => setPopover(null);

    /**
     * Opens the popover
     * @param {Event} e
     */
    const openPopover = (e) => setPopover(e.currentTarget);

    /**
     * Toggles the specified column's visibility.
     * @param {DataGridDensities} density
     */
    const setDensity = (density) => {
        dataGridApi.setDensity(density, true)
        closePopover();
    }

    /**
     * Fetches the icon based on the value of the given density.
     * @param {DataGridDensities} density
     * @return {JSX.Element}
     */
    const getDensityIcon = (density) => {
        switch (density) {
            case DataGridDensities.compact:
                return <CompactIcon/>
            case DataGridDensities.relaxed:
                return <ComfortableIcon/>
            case DataGridDensities.standard:
            default:
                return <StandardIcon/>
        }
    }

    const densities = useMemo(() =>
        [
            {title: 'Compact', value: DataGridDensities.compact},
            {title: 'Standard', value: DataGridDensities.standard},
            {title: 'Relaxed', value: DataGridDensities.relaxed},
        ].map(e => ({
            ...e,
            active: e.value === density,
            icon: getDensityIcon(e.value),
        })), [density])

    const densityIcon = useMemo(() => getDensityIcon(density), [density])

    return (
        <>
            {
                showIconButton
                    ? <DataGridIconButton
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarDensityAction)}
                        onClick={openPopover}
                    >
                        {densityIcon}
                    </DataGridIconButton>
                    : <DataGridButton
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarDensityAction)}
                        onClick={openPopover}
                    >
                        {densityIcon}
                        <p>Density</p>
                    </DataGridButton>
            }
            <Popover
                id={!!popover ? id.current : undefined}
                elevation={2}
                open={!!popover}
                onClose={closePopover}
                anchorReference={'anchorEl'}
                className={classnames(
                    "data-grid-toolbar-density-action-popover",
                    "data-grid-popover data-grid-toolbar-action-popover",
                    classNames.toolbarActionPopover,
                    classNames.toolbarDensityActionPopover,
                )}
                classes={{
                    paper: classnames(
                        'data-grid-popover-paper data-grid-toolbar-action-popover-paper',
                        classNames.toolbarActionPopoverPaper,
                        classNames.toolbarDensityActionPopoverPaper,
                    )
                }}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                {
                    densities.map(density => (
                        <div key={density.value}
                             onClick={() => setDensity(density.value)}
                             className={classnames('popover-item default', {'active': density.active})}>
                            {density.icon}
                            <p>
                                {density.title ?? ''}
                            </p>
                        </div>
                    ))
                }
            </Popover>
        </>
    )
}

export default DataGridToolbarDensityAction;

import React, {createContext, FunctionComponent, PropsWithChildren, useLayoutEffect} from "react";
import QueryBuilderApiService from "../../../../query-builder/core/services/api";

export interface QueryBuilderContextType {
    applicationName: string;
    authorizationToken?: string,
}


/**
 * The query builder context.
 */
const QueryBuilderContext = createContext<QueryBuilderContextType>({
    applicationName: "",
});

/**
 * The provider for the query builder context.
 * @param props The props.
 */
const QueryBuilderProvider: FunctionComponent<PropsWithChildren<QueryBuilderContextType>> = (props) => {

    /**
     * With each change in the [auth-token] value of the props:
     * - creates the injectors for the api such that the auth header is set with the [token] value
     */
    useLayoutEffect(() => {
        QueryBuilderApiService.injectInterceptors({
            request: [[
                (config) => {
                    config.headers.Authorization = config.headers.Authorization ?? props.authorizationToken;
                    return config;
                }
            ]],
        })
    }, [props.authorizationToken])

    return (
        <QueryBuilderContext.Provider value={{applicationName: props.applicationName}}>
            {props.children}
        </QueryBuilderContext.Provider>

    );
}

/**
 * A hook to get the query builder context.
 */
export const useQueryBuilderContext = (): QueryBuilderContextType => {
    return React.useContext(QueryBuilderContext);
}

export default QueryBuilderProvider;

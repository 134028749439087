import {FunctionComponent} from "react";
import RemoveConfirmationDialog from "./remove-confirmation";
import ConfirmationDialog from "./confirmation";
import DownloadCodeDialog from "./download-code";


const ApplicationDialogs: FunctionComponent = () => {


    return (
        <>
            <ConfirmationDialog/>
            <DownloadCodeDialog/>
            <RemoveConfirmationDialog/>
        </>
    );
}


export default ApplicationDialogs;

import {
    DataGridDispatcherAction,
    DataGridInternalState,
    DataGridReorderingEventArg,
    DataGridSavedStateEntry,
    DataGridSavedStateEntryData,
    DataGridStateActions
} from "../../../../type-declerations";
import DataGridUtils from "../../../services/utils";
import {debounce} from "lodash";
import DataGridApiService from "../../../services/api";

/**
 * This controller is responsible for saving the state of the data-grid
 */
class DataGridStateSaver {
    public static StorageKey: string;
    private static readonly debounceTimerInMs = 1500;
    private static readonly localDebounceTimerInMs = 300;
    private _applicationName?: string;
    private abortController?: AbortController;

    /**
     * Constructs a new StateSaver controller for the data-grid with the provided name and version.
     * @param name      the name of the data-grid
     * @param appName   the name of the application
     * @param version   the current version of the data-grid with the name.
     */
    public constructor(appName: string, name: string, version: number) {
        this._name = name;
        this._applicationName = appName;
        this._version = version;
    }

    private _name?: string;

    /**
     * Sets the name of the data-grid in this controller.
     * @param value
     */
    public set name(value: string | undefined) {
        this._name = value;
    }

    private _version?: number;

    /**
     * Sets the version of the data-grid in this controller.
     * @param value
     */
    public set version(value: number | undefined) {
        this._version = value;
    }

    /**
     * Saves the state of the data-grid locally in the browser storage.
     *
     * @param state                 the state that is going to be saved in the server
     * @param properties            the array of properties that need to be saved from the new-state
     */
    private readonly saveLocalState = debounce((state: DataGridInternalState, properties: string[]) => {
        if (!this.canSave)
            return;

        const savedState = DataGridUtils
            .parseDataGridSavedStateFromStorage(DataGridStateSaver.StorageKey)
            ?.find(e => this._name === e.name && this._version === e.version)

        const forApi: DataGridSavedStateEntry<Partial<DataGridSavedStateEntryData>> = {
            name: this._name as string,
            version: this._version as number,
            data: savedState?.data ?? {},
        }
        for (const property of properties) {
            switch (property) {
                case 'columns':
                    forApi.data.columns = DataGridUtils.createSavedStateColumns(state.columns);
                    break;
                case 'sortBy':
                    forApi.data.sortBy = state.sortBy;
                    break;
                case 'pagination.pageSize':
                    forApi.data.pageSize = state.pagination.pageSize;
                    break;
                case 'density':
                    forApi.data.density = state.density;
                    break;
                default:
                    break;
            }
        }
        DataGridUtils.saveDataGridSavedStateEntry(DataGridStateSaver.StorageKey, forApi as DataGridSavedStateEntry);
    }, DataGridStateSaver.localDebounceTimerInMs);
    /**
     * Saves the state of the data-grid in the server by calling its appropriate api.
     * @param abortController       the abortController used to abort the execution of the api
     */
    private readonly saveState = debounce(async (abortController: AbortController) => {
        if (!this.canSave || abortController?.signal?.aborted)
            return;

        const state = DataGridUtils
            .parseDataGridSavedStateFromStorage(DataGridStateSaver.StorageKey)
            ?.find(e => this._name === e.name && this._version === e.version)?.data

        if (!state)
            return;

        const forApi = {
            applicationName: this._applicationName as string,
            name: this._name as string,
            version: (this._version as number).toString(),
            data: state,
        }
        await DataGridApiService.saveDataGridState(forApi, abortController);
    }, DataGridStateSaver.debounceTimerInMs)

    /**
     * Sets the app-name of the data-grid in this controller.
     * @param value
     */
    public set appName(value: string | undefined) {
        this._applicationName = value;
    }

    /**
     * Determines whether the provided name and version of the data-grid are sufficient for saving its inforamtion in the server.
     */
    private get canSave(): boolean {
        return this._name !== undefined && !!this._name.length && this._version !== undefined;
    }

    /**
     * Saves the current state of the data-grid in the server by checking the conditions for the change in the savable state.
     *
     * @param action        the reducer action that initiated the change in the state
     * @param prevState     the state of the data-grid prior to the action
     * @param newState      the state of the data-grid after the reducer has changed the state based on the action.
     */
    public async onStateChanged(action: DataGridDispatcherAction, prevState: DataGridInternalState, newState: DataGridInternalState): Promise<void> {
        if (!this.canSave || !action.saveState)
            return;

        let properties = [];
        switch (action.type) {
            case DataGridStateActions.columnsPropsChanged: {
                if (DataGridUtils.deepEqual(prevState.columns, newState.columns))
                    return;
                const savedState = DataGridUtils
                    .parseDataGridSavedStateFromStorage(DataGridStateSaver.StorageKey)
                    ?.find(e => this._name === e.name && this._version === e.version)
                const newStateColumns = DataGridUtils.createSavedStateColumns(newState.columns);
                if (DataGridUtils.deepEqual(newStateColumns, savedState?.data?.columns ?? []))
                    return;
                properties.push('columns');
                break;
            }
            case DataGridStateActions.setPageSize: {
                if (prevState.pagination.pageSize === newState.pagination.pageSize)
                    return;
                properties.push('pagination.pageSize');
                break;
            }
            case DataGridStateActions.setSortBy: {
                if (DataGridUtils.deepEqual(prevState.sortBy, newState.sortBy))
                    return;
                properties.push('sortBy')
                break;
            }
            case DataGridStateActions.setDensity: {
                if (DataGridUtils.deepEqual(prevState.density, newState.density))
                    return;
                properties.push('density')
                break;
            }
            case DataGridStateActions.toggleColumnsVisibility: {
                const prev = Object.fromEntries(prevState.columns
                    .filter(e => e.savable)
                    .map(e => [e.name, e.visible]))
                const curr = Object.fromEntries(newState.columns
                    .filter(e => e.savable)
                    .map(e => [e.name, e.visible]))
                const changes = Object.fromEntries(Object.entries(curr).filter(([n, v]) => prev[n] !== v))
                if (!Object.keys(changes).length)
                    return;
                properties.push('columns');
                break;
            }
            case DataGridStateActions.togglePinnedColumns: {
                const prev = Object.fromEntries(prevState.columns
                    .filter(e => e.savable)
                    .map(e => [e.name, e.pinnedType]))
                const curr = Object.fromEntries(newState.columns
                    .filter(e => e.savable)
                    .map(e => [e.name, e.pinnedType]))
                const changes = Object.fromEntries(Object.entries(curr).filter(([n, v]) => prev[n] !== v))
                if (!Object.keys(changes).length)
                    return;
                properties.push('columns');
                break;
            }
            case DataGridStateActions.reorderColumns:
            case DataGridStateActions.setAllColumnsOrder: {
                const prev: DataGridReorderingEventArg = {
                    pinned: Object.fromEntries(prevState.columns
                        .filter(e => e.pinned)
                        .filter(e => e.savable)
                        .map(e => ([e.name, e.pinnedOrder]))),
                    normal: Object.fromEntries(prevState.columns
                        .map(e => ([e.name, e.order]))),
                }
                const curr: DataGridReorderingEventArg = {
                    pinned: Object.fromEntries(newState.columns
                        .filter(e => e.pinned)
                        .filter(e => e.savable)
                        .map(e => ([e.name, e.pinnedOrder]))),
                    normal: Object.fromEntries(newState.columns
                        .map(e => ([e.name, e.order]))),
                }
                if (DataGridUtils.deepEqual(prev, curr))
                    return;
                properties.push('columns');
                break;
            }
            case DataGridStateActions.resizeColumnsBy:
            case DataGridStateActions.resizeColumns: {
                const prev = Object.fromEntries(prevState.columns
                    .filter(e => e.savable)
                    .map(e => [e.name, e.width]));
                const curr = Object.fromEntries(newState.columns
                    .filter(e => e.savable)
                    .map(e => [e.name, e.width]));
                const changes = Object.fromEntries(Object.entries(curr).filter(([n, v]) => !DataGridUtils.deepEqual(prev[n], v)))
                if (!Object.keys(changes).length)
                    return;
                properties.push('columns');
                break;
            }
            case DataGridStateActions.refreshLayout:
                properties.push('columns');
                properties.push('density');
                break;
            default:
                return;
        }

        // the change list does not exist.
        if (!properties.length)
            return;

        // create a new abortController and abort the previous one
        if (!this.abortController?.signal?.aborted)
            this.abortController?.abort();
        this.abortController = new AbortController();

        this.saveLocalState(newState, properties);
        this.saveState(this.abortController);
    }

}

export default DataGridStateSaver;

/**
 * Types of the application toasts.
 */
export enum ToastTypes {
    apiResponseError = 'apiResponseError',
    apiResponseSuccess = 'apiResponseSuccess',
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

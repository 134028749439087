import React, {useContext, useMemo, useRef, useState} from "react";
import DataGridIconButton from "../../../components/icon-button/inedx";
import classnames from "classnames";
import {ReactComponent as ActionsIcon} from "../../../../assets/images/header/header-actions.svg";
import {DataGridControllerContext, DataGridLayoutContext, DataGridMiscContext, DataGridStateContext} from "../../../../index";
import DataGridUtils from "../../../../core/services/utils";
import {Popover} from "@mui/material";
import {DataGridColumnAlignments, DataGridColumnPinnedTypes, InternalDataGridEvents} from "../../../../type-declerations";

/**
 * @param {DataGridInternalColumn} column
 * @return {JSX.Element}
 * @constructor
 */
const DataGridAHeaderActionsPopover = ({column}) => {
    const {classNames} = useContext(DataGridMiscContext);
    const {sortBy} = useContext(DataGridStateContext);
    const {hideToolbar, hideColumnFiltering} = useContext(DataGridLayoutContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const [popover, setPopover] = useState(null);
    const id = useRef(DataGridUtils.createUUId(true));

    const sortedByThisColumn = useMemo(() => sortBy?.field === column.name, [sortBy, column.name])

    const popoverProps = useMemo(() => {
        switch (column.alignment) {
            case DataGridColumnAlignments.center:
            case DataGridColumnAlignments.left:
                return {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }
            case DataGridColumnAlignments.right:
                return {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }
            default:
                return {};
        }
    }, [column.alignment])

    /**
     * Closes the popover
     */
    const closePopover = () => setPopover(null);

    /**
     * Opens the popover
     * @param {Event} e
     */
    const openPopover = (e) => setPopover(e.currentTarget);

    /**
     * Performs the given action then closes the popover.
     * @param wrapper
     * @return {(function(): void)|*}
     */
    const performAction = (wrapper) => () => {
        wrapper();
        closePopover();
    }

    const allActions = useMemo(() => ({
        hide: {
            title: "Hide",
            action: performAction(() => {
                dataGridApi.toggleColumnsVisibility({
                    [column.name]: false,
                }, true);
            }),
        },
        unsort: {
            title: "Unsort",
            action: performAction(() => {
                dataGridApi.setSortBy(undefined, true);
            }),
        },
        sortAsc: {
            title: "Sort ASC",
            action: performAction(() => {
                dataGridApi.setSortBy({
                    field: column.name,
                    descending: false,
                }, true);
            }),
        },
        sortDesc: {
            title: "Sort DESC",
            action: performAction(() => {
                dataGridApi.setSortBy({
                    field: column.name,
                    descending: true,
                }, true);
            }),
        },
        showColumns: {
            title: "Show Columns",
            action: performAction(() => {
                dataGridApi.emit(InternalDataGridEvents.onColumnsToolbarOpened);
            }),
        },
        pinToLeft: {
            title: "Pin to Left",
            action: performAction(() => {
                dataGridApi.togglePinnedColumns({
                    [column.name]: DataGridColumnPinnedTypes.left,
                }, true);
            })
        },
        pinToRight: {
            title: "Pin to Right",
            action: performAction(() => {
                dataGridApi.togglePinnedColumns({
                    [column.name]: DataGridColumnPinnedTypes.right,
                }, true);
            })
        },
        unpin: {
            title: "Unpin",
            action: performAction(() => {
                dataGridApi.togglePinnedColumns({
                    [column.name]: undefined,
                }, true);
            })
        },
    }), [dataGridApi, column.name])

    const availableActions = useMemo(() => {
        const availableActions = [];

        // sorting actions
        if (column.sortable) {
            if (!sortedByThisColumn) {
                availableActions.push(
                    allActions.sortAsc,
                    allActions.sortDesc,
                )
            } else {
                if (sortBy?.descending) {
                    availableActions.push(
                        allActions.unsort,
                        allActions.sortAsc,
                    );
                } else {
                    availableActions.push(
                        allActions.unsort,
                        allActions.sortDesc,
                    );
                }
            }
        }

        // visibility action
        if (!hideToolbar && !hideColumnFiltering && column.visibilityToggleable) {
            availableActions.push(allActions.hide);
        }

        // visibility toolbar popover action
        if (!hideToolbar && !hideColumnFiltering) {
            availableActions.push(allActions.showColumns);
        }

        const pinnedToggleable = column.pinnedToggleable.left || column.pinnedToggleable.right;
        // pinned state action
        if (pinnedToggleable) {
            if (!column.pinned) {
                if (column.pinnedToggleable.left)
                    availableActions.push(allActions.pinToLeft);
                if (column.pinnedToggleable.right)
                    availableActions.push(allActions.pinToRight);
            } else {
                availableActions.push(allActions.unpin);
                if (column.pinnedType === DataGridColumnPinnedTypes.left &&
                    column.pinnedToggleable.right) {
                    availableActions.push(allActions.pinToRight);
                }
                if (column.pinnedType === DataGridColumnPinnedTypes.right &&
                    column.pinnedToggleable.left) {
                    availableActions.push(allActions.pinToLeft);
                }
            }
        }

        return availableActions;

    }, [allActions, column, sortBy, sortedByThisColumn, hideToolbar, hideColumnFiltering])

    return (
        <>
            <div className={'data-grid-header-actions-icon-button-container'}>
                {
                    (availableActions?.length ?? 0) > 0 &&
                    <DataGridIconButton
                        className={classnames(
                            'data-grid-header-actions-icon-button',
                            {'active': !!popover},
                            classNames.headerActionsIconButton,
                        )}
                        onClick={openPopover}
                    >
                        <ActionsIcon/>
                    </DataGridIconButton>
                }
            </div>
            <Popover
                id={!!popover ? id.current : undefined}
                elevation={2}
                open={!!popover}
                onClose={closePopover}
                anchorReference={'anchorEl'}
                className={classnames(
                    "data-grid-popover data-grid-header-actions-popover",
                    classNames.headerActionsPopover,
                )}
                classes={{
                    paper: classnames(
                        'data-grid-popover-paper data-grid-header-actions-popover-paper',
                        classNames.headerActionsPopoverPaper,
                    )
                }}
                anchorEl={popover}
                {...popoverProps}
            >
                {
                    availableActions?.map(action => (
                        <div
                            key={action.title}
                            className={'popover-item default'}
                            onClick={() => action.action()}
                        >
                            <p>
                                {action.title ?? ''}
                            </p>
                        </div>
                    ))
                }
            </Popover>
        </>
    )
}

export default DataGridAHeaderActionsPopover;

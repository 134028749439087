import React, {FunctionComponent, useCallback, useLayoutEffect, useState} from "react";
import {ReduxActions, useAppDispatch, useAppSelector} from "../../redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useIsMounted} from "../../../@bizkeytech";


const ConfirmationDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.dialogs?.confirmation);
    const [processing, setProcessing] = useState(false);
    const isMounted = useIsMounted();

    const {hideCloseButton, disableBackdropClick, ...dialogProps} = state;

    /**
     * As soon as the dialog opens, resets the state values.
     */
    useLayoutEffect(() => {
        if (state?.open) {
            setProcessing(false);
        }
    }, [state?.open])

    /**
     * Closes the dialog by dispatching its relevant action to the redux store.
     */
    const closeDialog = useCallback(async (successful = false) => {
        if (state?.onClose) {
            setProcessing(true);
            const resultFlag = await state.onClose(successful ? "successful" : "leave");
            if (!isMounted())
                return;
            setProcessing(false);
            if (successful && !resultFlag)
                return;
        }
        dispatch(ReduxActions.dialogs.confirmation({open: false}));
    }, [state, dispatch, isMounted])

    /**
     * Closes the dialog if the values from the state do not prevent the closing of the dialog.
     */
    const onDialogClosed = useCallback((event: Object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        switch (reason) {
            case "backdropClick":
                if (!state.disableBackdropClick && !processing)
                    closeDialog().then();
                break;
            case "closeClick":
                if (!state.hideCloseButton && !processing)
                    closeDialog().then();
                break;
            case "escapeKeyDown":
                if (!state.disableEscapeKeyDown && !processing)
                    closeDialog().then();
                break;
            default:
                break;
        }
    }, [processing, closeDialog, state.disableBackdropClick, state.disableEscapeKeyDown, state.hideCloseButton])

    return (
        <>
            <Dialog
                {...dialogProps}
                onClose={onDialogClosed}
            >
                <DialogTitle fontWeight={600}>
                    {state.data?.title ?? "Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant={'body1'}>
                        {
                            state.data?.description ?? "Would you like to proceed with the requested action?"
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        variant={'text'}
                        type={'button'}
                        color={'primary'}
                        disabled={processing}
                        onClick={() => onDialogClosed({}, 'closeClick')}
                    >
                        {state.data?.cancelButtonText ?? "Cancel"}
                    </Button>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        disabled={processing}
                        onClick={() => closeDialog(true)}
                    >
                        {
                            processing
                                ? "Processing..."
                                : state.data?.confirmButtonText ?? "Confirm"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmationDialog;

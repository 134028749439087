import BizkeytechDialogsReduxSlice from "../slices/dialogs";

/**
 * The Actions of the Redux Store state.
 */
const BizkeytechReduxActions = {
    [BizkeytechDialogsReduxSlice.name]: BizkeytechDialogsReduxSlice.actions,
}

export default BizkeytechReduxActions;

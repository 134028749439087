import type {FunctionComponent} from "react";
import AuthenticationMiddleware from "./authentication";

/**
 * The container of all the application level middlewares.
 *
 * The middlewares responsibility is to inject behaviour
 * into the views or the entire application without any visible ui components.
 */
const ApplicationMiddlewares: FunctionComponent = () => {

    return (
        <>
            <AuthenticationMiddleware/>
        </>
    )
}

export default ApplicationMiddlewares;

import {FunctionComponent, useCallback} from "react";
import {BizkeytechReduxActions, useBizkeytechDispatch, useBizkeytechSelector} from "../../redux";
import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import LottieAnimation, {LoadingAnimation} from "../../components/lottie-animation";

const ExportingDialog: FunctionComponent = () => {
    const dispatch = useBizkeytechDispatch();
    const state = useBizkeytechSelector(state => state.dialogs?.exporting);

    const {hideCloseButton, disableBackdropClick, ...dialogProps} = state;


    /**
     * Closes the dialog by dispatching its relevant action to the redux store.
     */
    const closeDialog = useCallback((successful = false) => {
        if (state?.onClose) {
            state.onClose(successful ? "successful" : "leave");
        }
        dispatch(BizkeytechReduxActions.dialogs.exporting({open: false}));
    }, [state, dispatch])

    /**
     * Closes the dialog if the values from the state do not prevent the closing of the dialog.
     */
    const onDialogClosed = useCallback((event: Object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        switch (reason) {
            case "backdropClick":
                if (!state.disableBackdropClick)
                    closeDialog();
                break;
            case "closeClick":
                if (!state.hideCloseButton)
                    closeDialog();
                break;
            case "escapeKeyDown":
                if (!state.disableEscapeKeyDown)
                    closeDialog();
                break;
            default:
                break;
        }
    }, [closeDialog, state.disableBackdropClick, state.disableEscapeKeyDown, state.hideCloseButton])


    return (
        <>
            <Dialog
                {...dialogProps}
                onClose={onDialogClosed}
            >
                <DialogTitle fontWeight={600} textAlign={'center'}>
                    Exporting Entries
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} textAlign={'center'}>
                        Please be patient while we prepare your records for exporting purposes.
                    </Typography>
                    <LottieAnimation width={'100%'} src={LoadingAnimation}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ExportingDialog;

import {
    DataGridColumn,
    DataGridColumnAlignments,
    DataGridColumnPinnedToggleable,
    DataGridColumnPinnedTypes,
    DataGridColumnTypes,
    DataGridColumnWidth,
    DataGridColumnWidthTypes,
    DataGridExoticColumnFields,
    DataGridInternalColumn,
    DataGridLoading,
    DataGridPagination,
    DataGridRowGroup,
    DataGridSavedState,
    DataGridSavedStateEntry,
    DataGridSavedStateEntryData,
    DataGridSortBy,
    DefaultDataGridColumnAlignment,
    DefaultDataGridColumnType,
    DefaultDataGridFlexedWidth,
    DefaultDataGridLoading,
    DefaultDataGridPageSize,
    DefaultDataGridPageSizes,
    DefaultDataGridWidth
} from "../../../type-declerations";
import {v4 as UUIDv4} from "uuid";
import {CachingServiceEntities, Utils} from "../../../../base/core";
import dayjs from "dayjs";
import DataGridCachingService from "../caching";


/**
 * The utility functions in the data-grid component.
 */
class DataGridUtils extends Utils {

    static staticIconsWidth = 4 + 24 + 28;

    /**
     * Creates the default formatter of the columns based on its type.
     *
     * @param {DataGridColumnTypes | undefined}  type
     * @return {function|null}
     */
    static getDefaultFormat(type?: DataGridColumnTypes): Function | undefined {
        switch (type) {
            case DataGridColumnTypes.money:
                return (cell: any) => this.CurrencyFormatter.format(cell) ?? '';
            case DataGridColumnTypes.date:
                return (cell: any) => !!cell ? dayjs(cell).format('YYYY-MM-DD') : null
            case DataGridColumnTypes.dateTime:
                return (cell: any) => !!cell ? dayjs(cell).format(`YYYY-MM-DD [    ] HH:mm:ss`) : null
            case DataGridColumnTypes.element:
            case DataGridColumnTypes.string:
            default:
                return undefined;
        }
    }

    /**
     * Fills the width with the column object based on its value.
     *
     * @param {Partial<DataGridColumn> | undefined} column
     * @param {DataGridColumnWidth | number | undefined} width
     * @param {boolean} addStaticWidths
     * @return {DataGridColumnWidth}
     */
    static fillColumnWidth(
        column: Partial<DataGridColumn> | undefined,
        width: Partial<DataGridColumnWidth> | number | undefined,
        addStaticWidths = false,
    ): DataGridColumnWidth {
        const staticIconsWidth = addStaticWidths ? this.staticIconsWidth : 0;
        switch (column?.name) {
            case DataGridExoticColumnFields.detailedPanel:
                return {
                    minWidth: 0,
                    size: 0,
                    type: DataGridColumnWidthTypes.default,
                }
            case DataGridExoticColumnFields.detailedPanelToggler:
                return {
                    minWidth: 60,
                    size: 60,
                    type: DataGridColumnWidthTypes.default,
                }
            case DataGridExoticColumnFields.selection:
                return {
                    minWidth: 60,
                    size: 60,
                    type: DataGridColumnWidthTypes.default,
                }
            default:
                if (typeof width === "number")
                    return {
                        minWidth: width + staticIconsWidth,
                        size: width + staticIconsWidth,
                        type: DataGridColumnWidthTypes.default,
                    }
                if (width?.type === DataGridColumnWidthTypes.flex) {
                    return {
                        ...DefaultDataGridFlexedWidth,
                        ...(width ?? {}),
                    }
                }
                const minWidth = (width?.minWidth ?? DefaultDataGridWidth.minWidth ?? 0) + staticIconsWidth;
                const size = Math.max((width?.size ?? DefaultDataGridWidth.size ?? 0) + staticIconsWidth, minWidth);
                return {
                    ...DefaultDataGridWidth,
                    ...(width ?? {}),
                    size: size,
                    minWidth: minWidth,
                }
        }
    }

    /**
     * Creates the columns list of the data-grid specific for caching purposes.
     * @param columns
     */
    static createSavedStateColumns(columns: DataGridInternalColumn[]): DataGridSavedStateEntryData['columns'] {
        const staticIconsWidth = this.staticIconsWidth;
        return columns
            .filter(e => e.savable)
            .map(_column => {
                const column = this.deepCopy(_column);
                if (column.width.type === DataGridColumnWidthTypes.default) {
                    let removeStaticWidth: boolean = true;
                    switch (column.name) {
                        case DataGridExoticColumnFields.detailedPanel:
                        case DataGridExoticColumnFields.detailedPanelToggler:
                        case DataGridExoticColumnFields.selection:
                            removeStaticWidth = false;
                            break;
                        default:
                            break;
                    }
                    if (removeStaticWidth) {
                        column.width.minWidth = Math.max(column.width.minWidth - staticIconsWidth, 0);
                        column.width.size = Math.max(column.width.size - staticIconsWidth, column.width.minWidth);
                    }
                }
                return {
                    width: column.width,
                    visible: column.visible,
                    order: column.order,
                    pinned: column.pinned,
                    pinnedType: column.pinnedType,
                    pinnedOrder: column.pinnedOrder,
                    name: column.name,
                    type: column.type,
                };
            })
    }

    /**
     * Fills the column objects of the data grid.
     *
     * * replaces the properties with their default values if they do not exist.
     * * for the boolean properties, only sets them to false if these properties are set to false explicitly since their
     *   default values are true.
     * @param {Partial<Partial<DataGridColumn>>[]} columns
     * @param {boolean} addStaticWidths
     * @return {DataGridInternalColumn[]}
     */
    static fillColumns(
        columns: Partial<DataGridColumn & { addStaticWidths?: boolean }>[],
        addStaticWidths = false
    ): DataGridInternalColumn[] {
        return columns?.map<DataGridInternalColumn>((column) => ({
            ...column,
            title: column.title ?? '',
            alignment: column.alignment ?? DefaultDataGridColumnAlignment,
            type: column.type ?? DefaultDataGridColumnType,
            format: column.format ?? this.getDefaultFormat(column.type),

            sortable: column?.sortable !== false,

            width: this.fillColumnWidth(column, column?.width, column.addStaticWidths ?? addStaticWidths),
            resizable: column?.resizable !== false,

            visible: column?.visible !== false,
            visibilityToggleable: column?.visibilityToggleable !== false,

            pinned: !!column.pinned,
            pinnedToggleable: this.fillColumnPinnedToggleable(column.pinnedToggleable),
            pinnedType: !column.pinned
                ? undefined
                : column?.pinnedType ?? DataGridColumnPinnedTypes.left,

            reorderable:
                !!column.pinned
                    ? false
                    : column?.reorderable !== false,

            savable: column?.savable !== false,
        } as DataGridInternalColumn)) ?? [];
    }

    /**
     * The comparator the order or pinnedOrder of the data grid columns.
     * @param {'order' | 'pinnedOrder'} name
     * @return {function(a: DataGridInternalColumn, b: DataGridInternalColumn): number}
     */
    static columnOrderComparator(name: 'order' | 'pinnedOrder')
        : (a: DataGridInternalColumn, b: DataGridInternalColumn) => number {
        const sortingPinnedColumns = name === 'pinnedOrder';

        const orderComparator = sortingPinnedColumns
            ? this.columnOrderComparator('order')
            : null;

        return ((a: DataGridInternalColumn, b: DataGridInternalColumn): number => {
            // @ts-ignore
            if (isNaN(a[name]) && isNaN(b[name])) {
                if (sortingPinnedColumns) {
                    // if we are comparing based on "pinnedOrder", and they do not exist, then compare based on "order".
                    return orderComparator?.(a, b) ?? 0;
                }
                // if both do not exist, then return the same order (a then b)
                return 0
            }


            if (sortingPinnedColumns && a.pinnedType !== b.pinnedType) {
                // if sorting based on pinnedOrder and the pinned types are not the same we sort based on the types only
                // (putting left pins then right pins)
                if (a.pinnedType === DataGridColumnPinnedTypes.left &&
                    b.pinnedType === DataGridColumnPinnedTypes.right) {
                    // if a is left pinned and b is right pinned, return the same order (a then b)
                    return -1;
                }
                if (a.pinnedType === DataGridColumnPinnedTypes.right
                    && b.pinnedType === DataGridColumnPinnedTypes.left) {
                    // if a is right pinned and b is left pinned, return the reverse order (b then a)
                    return 1;
                }
            }

            // @ts-ignore
            if (isNaN(a[name])) {
                if (sortingPinnedColumns) {
                    if (b.pinnedType === DataGridColumnPinnedTypes.left) {
                        // since [a] is not pinned and [b] is left pinned (b then a)
                        return 1
                    }
                    if (b.pinnedType === DataGridColumnPinnedTypes.right) {
                        // since [a] is not pinned and [b] is right pinned (a then b)
                        return -1
                    }
                }
                // if a does not exist, then return reverse order (b then a)
                return 1
            }

            // @ts-ignore
            if (isNaN(b[name])) {
                if (sortingPinnedColumns) {
                    if (a.pinnedType === DataGridColumnPinnedTypes.left) {
                        // since [b] is not pinned and [a] is left pinned (a then b)
                        return -1
                    }
                    if (a.pinnedType === DataGridColumnPinnedTypes.right) {
                        // since [b] is not pinned and [a] is right pinned (b then a)
                        return 1
                    }
                }
                // if b does not exist, then return the same order (a then b)
                return 0
            }

            if (sortingPinnedColumns &&
                a.pinnedType === DataGridColumnPinnedTypes.right &&
                a.pinnedType === b.pinnedType) {
                return this.numComparator(b[name], a[name])
            }

            // return the order specified in the columnOrders
            return this.numComparator(a[name], b[name])
        })
    }

    /**
     * Fills data grid columns' order and pinnedOrder properties then sorts them based on the given orders ascending.
     *
     * * imputes any of the non-existing orders of the columns
     * * imputes any of the non-existing pinnedOrders of the pinned columns
     * * removes the pinnedOrders of the un-pinned columns
     * @param { Partial<DataGridColumn>[]} columns
     * @return {Partial<DataGridColumn>[]}
     */
    static sortColumnsByOrder<T = DataGridInternalColumn, >(columns: Partial<DataGridColumn>[]): Array<T> {
        let result = (Array.from(columns) as DataGridInternalColumn[]) ?? [];

        // only if any columns have orders exist sort based on pinnedOrder property
        if (columns.some(e => Number.isInteger(e.order)))
            result.sort(this.columnOrderComparator('order'));

        result = result.map((column, index) => ({
            ...column,
            order: index + 1,
        }));

        // only if any pinned columns exist sort based on pinnedOrder property
        if (columns.some(e => e.pinned && Number.isInteger(e.pinnedOrder)))
            result.sort(this.columnOrderComparator('pinnedOrder'));

        // add the order for the pinned columns (taken from their normal order)
        let leftPinI = 0;
        let rightPinI = result.filter(e => e.pinnedType === DataGridColumnPinnedTypes.right).length;
        // @ts-ignore
        result = result.map(column => ({
                ...column,
                pinnedOrder: column.pinned
                    ? column.pinnedType === DataGridColumnPinnedTypes.left
                        ? ++leftPinI
                        : rightPinI--
                    : undefined,
            })
        );

        result.sort(this.columnOrderComparator('pinnedOrder'));

        //@ts-ignore
        return result
    }

    /**
     * Ensures at least one of the provided columns has a flex based width.
     *
     * * in case there is no flexed based column existing in the provided list, the last entry will be flexed base.
     * @param {DataGridInternalColumn[]} _columns
     * @return {DataGridInternalColumn[]}
     */
    static ensureOneColumnHasFlexedWidth(_columns: DataGridInternalColumn[]): DataGridInternalColumn[] {
        const columns = Array.from(_columns);
        const flexedWidthExists = columns.some(e => {
            if (typeof e.width === "number")
                return false;
            return e.width?.type === DataGridColumnWidthTypes.flex;
        });
        if (!flexedWidthExists) {
            const highestOrder = Math.max(...(columns?.map(e => e.order) ?? []), 0) + 1;

            let firstRightPinnedIndex = columns.findIndex(e => e.pinnedType === DataGridColumnPinnedTypes.right)
            if (firstRightPinnedIndex === -1) {
                firstRightPinnedIndex = columns.length;
            }

            columns.splice(
                firstRightPinnedIndex,
                0,
                ...this.fillColumns([{
                    name: DataGridExoticColumnFields.spacer,
                    width: {
                        size: 1,
                        type: DataGridColumnWidthTypes.flex,
                        minWidth: 1,
                    },
                    sortable: false,
                    visibilityToggleable: false,
                    pinnedToggleable: false,
                    savable: false,
                    resizable: false,
                    reorderable: false,
                    pinned: false,
                    order: highestOrder,
                }])
            );
        }
        return columns;
    }

    /**
     * Fills the pagination object of the data grid.
     *
     * * replaces the properties with their default values if they do not exist.
     * @param {Partial<DataGridPagination>} pagination
     * @return {DataGridPagination}
     */
    static fillPagination(pagination?: Partial<DataGridPagination>): DataGridPagination {
        return {
            ...(pagination ?? {}),
            sizes: !pagination?.sizes?.length ? DefaultDataGridPageSizes : pagination?.sizes,
            currentPage: ((pagination?.currentPage ?? 0) > 0 ? pagination?.currentPage : 1) as number,
            pageSize: pagination?.pageSize ?? DefaultDataGridPageSize,
            length: pagination?.length ?? 0,
        };
    }

    /**
     * Fills the sort by object of the data grid.
     *
     * * replaces the properties with their default values if they do not exist.
     * @param {Partial<DataGridSortBy>} sortBy
     * @return {DataGridSortBy | undefined}
     */
    static fillSortBy(sortBy?: Partial<DataGridSortBy>): DataGridSortBy | undefined {
        // only set sortBy if the field of the sortBy is a truthy
        if (!sortBy?.field) {
            return undefined;
        }
        return {
            descending: sortBy?.descending ?? false,
            field: sortBy?.field,
        }
    }

    /**
     * Fills the loading object of the data grid.
     *
     * * replaces the properties with their default values if they do not exist.
     * @param {Partial<DataGridLoading>} loading
     * @return {DataGridLoading}
     */
    static fillLoading(loading?: Partial<DataGridLoading>): DataGridLoading {
        return {
            animationName: loading?.animationName ?? DefaultDataGridLoading.animationName,
            animationTimeout: loading?.animationTimeout ?? DefaultDataGridLoading.animationTimeout,
            count: Math.max(loading?.count ?? DefaultDataGridLoading.count, 0),
            state: loading?.state ?? false,
        }
    }

    /**
     * Creates a Unique Identifier in form of a string
     */
    static createUUId(reactKey = false) {
        const uuid = UUIDv4();
        if (!reactKey) return uuid;
        return `_${uuid}`
    }

    /**
     * Determines whether the selection content of the data grid column should be checked or not.
     * @param {boolean} allRowsSelected
     * @param {number} selectedRowsLength
     * @param {number} excludedRowsLength
     * @param {number} paginationLength
     */
    static areAllRowsSelected(
        allRowsSelected: boolean,
        selectedRowsLength: number,
        excludedRowsLength: number,
        paginationLength: number,
    ): boolean {
        let checked = allRowsSelected;
        if (selectedRowsLength === paginationLength && selectedRowsLength !== 0)
            checked = true;
        if (excludedRowsLength > 0)
            checked = false;
        return checked;
    }

    /**
     * Fetches the padding applied to a column based on its value of the alignment.
     * @param {DataGridColumnAlignments} alignment the alignment of the column
     * @return {number}
     */
    static getColumnAlignmentPadding(alignment?: DataGridColumnAlignments): number {
        const basePadding = 10;
        switch (alignment) {
            case DataGridColumnAlignments.center:
                return basePadding * 2;
            case DataGridColumnAlignments.left:
            case DataGridColumnAlignments.right:
                return basePadding;
            default:
                return 0
        }
    }

    /**
     * Fetches the visible columns of the data-grid with their calculated widths in pixels.
     * @param {DataGridInternalColumn[]} _visibleColumns the available width of the grid-layout for the flexed based columns.
     * @param {number} layoutRectWidth the width of the layout container.
     */
    static getVisibleColumnsCalculatedWidths(
        _visibleColumns: DataGridInternalColumn[],
        layoutRectWidth: number
    ): DataGridInternalColumn[] {
        const visibleColumns = this.ensureOneColumnHasFlexedWidth(_visibleColumns);

        const fixedWidths = visibleColumns
                ?.filter(e => e.width.type !== DataGridColumnWidthTypes.flex)
                ?.reduce((p, c) => p + Math.max(c.width.size, c.width.minWidth), 0)
            ?? 0;

        let availableWidthForFlex = Math.max((layoutRectWidth ?? 0) - fixedWidths, 0);

        const flexCount = visibleColumns?.reduce((p, c) =>
                p + (
                    c.width.type === DataGridColumnWidthTypes.flex
                        ? c.width.size
                        : 0
                ), 0)
            ?? 0;

        const flexColumnsWithStaticWidthSize = visibleColumns
            .filter(e => e.width.type === DataGridColumnWidthTypes.flex && Math.max((availableWidthForFlex / Math.max(flexCount, 1)) * e.width.size, e.width.minWidth) === e.width.minWidth)
            .reduce((size, column) => {
                return size + column.width.minWidth;
            }, 0)

        const actualAvailableWidthForFlex = availableWidthForFlex - flexColumnsWithStaticWidthSize;

        const res = visibleColumns.map(e => {
            // calc size
            let size;
            if (e.width.type === DataGridColumnWidthTypes.flex) {
                size = Math.max((actualAvailableWidthForFlex / Math.max(flexCount, 1)) * e.width.size, e.width.minWidth);
            } else {
                size = Math.max(e.width.size, e.width.minWidth);
            }
            return {...e, width: {...e.width, size: size},}
        });

        let leftPinOffset = 0;
        let rightPinOffset = 0;

        const firstRightPinnedIndex = res.findIndex(e => e.pinnedType === DataGridColumnPinnedTypes.right)

        if (firstRightPinnedIndex !== -1 && res.slice(firstRightPinnedIndex).length > 1) {
            const rightSlice = res.slice(firstRightPinnedIndex);
            rightPinOffset = rightSlice.reduce((p, c, index, array) => p + c.width.size, 0);
            const firstRightPinnedColumn = res[firstRightPinnedIndex];
            rightPinOffset -= firstRightPinnedColumn.width.size;
        }

        return res.map((e, index, array) => {
            const hasPrevColumn = index !== 0;
            const hasNextColumn = index + 1 < array.length;

            // left pin offset calculation
            if (e.pinnedType === DataGridColumnPinnedTypes.left && hasPrevColumn) {
                const prevColumn = array[index - 1];
                leftPinOffset += (prevColumn.width.size);
            }

            let _rightPinOffset = rightPinOffset;
            if (e.pinnedType === DataGridColumnPinnedTypes.right && hasNextColumn) {
                const nextColumn = array[index + 1];
                rightPinOffset -= (nextColumn.width.size);
            }

            return {
                ...e,
                leftPinOffset: e.pinnedType !== DataGridColumnPinnedTypes.left
                    ? undefined
                    : leftPinOffset,
                rightPinOffset: e.pinnedType !== DataGridColumnPinnedTypes.right
                    ? undefined
                    : _rightPinOffset,
            }
        })
    }

    /**
     * Fills the group property of the data-grid state.
     *
     * * if the group does not exist, returns undefined
     * * if either of group's columnName or group's showOnColumn do not exist, returns undefiend
     * @param columns   the columns of the data grid. Used to determine whether the given group properties are valid
     * @param group     the group to be validated and filled.
     */
    static fillGroup(columns: Array<DataGridInternalColumn>, group?: DataGridRowGroup): DataGridRowGroup | undefined {
        if (!group)
            return undefined;
        const colNames = columns.map(e => e.name);
        if (colNames.includes(group.columnName)) {
            if (!group.showOnColumn) {
                group = {
                    ...group,
                    showOnColumn: group.columnName,
                }
            }
            if (!group.showOnColumn || !colNames.includes(group.showOnColumn))
                return undefined;
            return group;
        }
        return undefined;
    }

    /**
     * Parses the saved state of the data-grids from the local-storage.
     * @param storageKey
     */
    public static parseDataGridSavedStateFromStorage(storageKey: string): DataGridSavedState | undefined {
        try {
            const raw = DataGridCachingService.of(CachingServiceEntities.localStorage).get(storageKey);
            if (!raw)
                return undefined;
            return JSON.parse(raw);
        } catch {
            return undefined;
        }
    }

    /**
     * Saves the given entry in the local-storage among the other entries of the data-grid saved state.
     *
     * * if the saved state already has that entry, updates the data of the entry
     * * else, the entry is appended in the saved state list.
     * @param storageKey
     * @param entry
     */
    public static saveDataGridSavedStateEntry(storageKey: string, entry: DataGridSavedStateEntry) {
        if (!storageKey)
            return;
        const state = this.parseDataGridSavedStateFromStorage(storageKey);
        const oldState = this.deepCopy(state);
        let newValue;
        if (!state) {
            newValue = [entry];
        } else {
            const found = state.find(e => e.name === entry.name && e.version === entry.version);
            if (found) {
                found.data = entry.data;
                newValue = state;
            } else {
                state.push(entry);
                newValue = state;
            }
        }
        DataGridCachingService.of(CachingServiceEntities.localStorage).set(storageKey, JSON.stringify(newValue));
        window.dispatchEvent(new StorageEvent('storage', {
            key: storageKey,
            newValue: JSON.stringify(newValue),
            oldValue: JSON.stringify(oldState ?? '[]'),
        }))
    }

    //              ########################### SAVE STATE SPECIFIC ###################################
    /**
     * Fills the pinnedToggleable property of a column.
     *
     * * if the given input is undefined, returns pinnedToggleable for both directions
     * * if the given input is boolean, makes both directions the value of the input
     * * if the given input is of type object, then uses the input with imputation of false.
     * @param pinnedToggleable
     */
    private static fillColumnPinnedToggleable(pinnedToggleable: DataGridColumnPinnedToggleable | boolean | undefined)
        : DataGridColumnPinnedToggleable {
        if (typeof pinnedToggleable === 'undefined') {
            return {
                left: true,
                right: true,
            }
        }
        if (typeof pinnedToggleable === 'boolean') {
            return {
                left: pinnedToggleable,
                right: pinnedToggleable,
            }
        }
        return {
            left: pinnedToggleable.left ?? false,
            right: pinnedToggleable.right ?? false,
        }
    }
}

export default DataGridUtils

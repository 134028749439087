import type {FunctionComponent, MouseEventHandler, PropsWithChildren, ReactNode} from "react";
import {cloneElement, createContext, useCallback, useContext, useLayoutEffect, useMemo, useState} from "react";
import {
    Box,
    BoxProps,
    Collapse,
    Divider,
    List,
    ListItemButton,
    listItemButtonClasses,
    ListItemButtonProps,
    ListItemIcon,
    listItemIconClasses,
    ListItemIconProps,
    ListItemText,
    ListItemTextProps,
    ListProps,
    Theme,
    useMediaQuery,
    useTheme
} from "@mui/material";
import type {LinkProps} from "react-router-dom";
import {Link, useLocation} from "react-router-dom";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {matchRoutes} from "react-router";
import {usePanel} from "../panel";
import UIUtils from "../../../utils";
import type {SvgIconOwnProps} from "@mui/material/SvgIcon/SvgIcon";
import './index.scss';
import classNames from "classnames";


interface SidebarContextState {
    color: 'primary' | 'secondary';
}

const SidebarContext = createContext<SidebarContextState>({
    color: 'primary'
});

/**
 * A convenience hook to get the current configuration of the sidebar.
 */
const useSidebar: () => SidebarContextState = () => {
    return useContext(SidebarContext);
}

export type SidebarProps = Omit<BoxProps, 'sx' | 'color'> & {
    color?: SidebarContextState['color'];
};
export const PanelSidebar: FunctionComponent<SidebarProps> = (props) => {
    const sidebarContextValue = useMemo<SidebarContextState>(() => ({
        color: props.color ?? 'primary',
    }), [props.color]);

    /**
     * Creates the styles for the sidebar
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar
     */
    const mixin = useCallback<ThemeMixin<Theme>>(() => ({
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    }), []);

    return (
        <SidebarContext.Provider value={sidebarContextValue}>
            <Box {...props} sx={mixin}>
                {props.children}
            </Box>
        </SidebarContext.Provider>
    );
}

export default PanelSidebar;

export type SidebarHeaderProps = BoxProps;

export const SidebarHeader: FunctionComponent<SidebarHeaderProps> = (props) => {

    /**
     * Creates the styles for the sidebar header
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar header
     */
    const mixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        width: '100%',
        height: 'fit-content',
        minHeight: theme.spacing(8),
        paddingBlockEnd: theme.spacing(1),
        ...UIUtils.invokeSx(props.sx, theme) as any,
    }), [props.sx]);

    return (
        <Box {...props} sx={mixin}>
            {props.children}
        </Box>
    );
}

const SidebarNestedLevelContext = createContext<number>(0);

/**
 * A convenience hook to get the current nested level of the sidebar
 */
export const useSidebarNestedLevel: () => number = () => {
    return useContext(SidebarNestedLevelContext);
}


export type SidebarContentProps = ListProps;
export const SidebarContent: FunctionComponent<SidebarContentProps> = (props) => {
    const sidebar = useSidebar();

    /**
     * Creates the styles for the sidebar content
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar content
     */
    const mixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        width: '100%',
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: '100%',
        paddingBlock: theme.spacing(1),
        ...UIUtils.invokeSx(props.sx, theme) as any,
    }), [props.sx]);

    return (
        <SidebarNestedLevelContext.Provider value={0}>
            <List color={sidebar.color} {...props} sx={mixin} disablePadding>
                {props.children}
            </List>
        </SidebarNestedLevelContext.Provider>
    );
}


export type SidebarFooterProps = BoxProps;
export const SidebarFooter: FunctionComponent<SidebarFooterProps> = (props) => {

    /**
     * Creates the styles for the sidebar footer
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar footer
     */
    const mixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        width: '100%',
        height: 'fit-content',
        flexGrow: 0,
        paddingBlockStart: theme.spacing(1),
        ...UIUtils.invokeSx(props.sx, theme) as any,
    }), [props.sx]);

    return (
        <Box {...props} sx={mixin}>
            {props.children}
        </Box>
    );
}


export type SidebarMenuItemProps = {
    ListItemButtonProps?: ListItemButtonProps;
    ListItemIconProps?: ListItemIconProps;
    PrimaryTextProps?: ListItemTextProps['primaryTypographyProps'];
    SecondaryTextProps?: ListItemTextProps['secondaryTypographyProps'];
    primaryText: string;
    secondaryText?: string,
    icon?: ReactNode;
    badge?: ReactNode;
    action?: ReactNode;
    startAction?: ReactNode;
    isActive?: boolean;
    LinkProps?: Omit<LinkProps, 'children' | 'to'> & {
        to?: LinkProps['to'];
        path?: string | string[];
        exact?: boolean,
        caseSensitive?: boolean,
    }
};
export const SidebarMenuItem: FunctionComponent<SidebarMenuItemProps> = (props) => {
    const location = useLocation();
    const panel = usePanel();
    const sidebar = useSidebar();
    const currentNestedLevel = useSidebarNestedLevel();
    const theme = useTheme();
    const inMobileView = useMediaQuery(theme.breakpoints.down(panel.breakpoints.mobileView));

    const isActive = useMemo(() => {
        const paths: Array<string> = typeof props.LinkProps?.path === 'string'
            ? [props.LinkProps?.path]
            : Array.isArray(props.LinkProps?.path)
                ? props.LinkProps?.path as Array<string>
                : typeof props.LinkProps?.to === 'string'
                    ? [props.LinkProps?.to]
                    : [];
        return props.isActive
            || !!matchRoutes(paths.map(path => ({
                path: path,
                exact: props.LinkProps?.exact ?? false,
                caseSensitive: props.LinkProps?.caseSensitive ?? false,
            })), location)
    }, [props.isActive, props.LinkProps?.path, props.LinkProps?.to, location, props.LinkProps?.exact, props.LinkProps?.caseSensitive]);

    /**
     * Closes the sidebar if the menu item is a link and the sidebar is in mobile view
     *
     * @param {React.MouseEvent<HTMLDivElement>} e  The click event
     * @returns {void}
     */
    const closeSidebarOnClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
        if (props.ListItemButtonProps?.onClick) {
            props.ListItemButtonProps.onClick(e);
        }
        if (!props.LinkProps?.to) {
            return; // Only close sidebar if the menu item is a link
        }
        if (inMobileView) {
            panel.setSidebarOpen(false);
        }
    }, [props.ListItemButtonProps, props.LinkProps?.to, inMobileView, panel])

    /**
     * Creates the styles for the Sidebar list item
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the Sidebar list item
     */
    const listItemButtonMixin = useCallback<ThemeMixin<Theme>>((theme) => {
        const sx = UIUtils.invokeSx(props.ListItemButtonProps?.sx, theme) as any;
        return ({
            paddingInline: theme.spacing(1 + currentNestedLevel),
            minHeight: 44,
            padding: theme.spacing(1),
            marginInlineEnd: theme.spacing(2),
            borderRadius: theme.vars?.shape.borderRadius,
            ...sx,
            [`&.${listItemButtonClasses.selected}`]: {
                backgroundColor: `rgba(${UIUtils.themeVar(`palette-${sidebar.color}-mainChannel`)} / ${UIUtils.themeVar(`palette-action-selectedOpacity`)})`,
                ...(currentNestedLevel > 0 && {
                    backgroundColor: 'transparent',
                }),
                ...(sx?.[`&.${listItemButtonClasses.selected}`] ?? {}),
            },
            [`&.${listItemButtonClasses.selected}:hover`]: {
                backgroundColor: `rgba(${UIUtils.themeVar(`palette-${sidebar.color}-mainChannel`)} / ${UIUtils.themeVar(`palette-action-selectedOpacity`)})`,
                ...(currentNestedLevel > 0 && {
                    backgroundColor: 'transparent',
                }),
                ...(sx?.[`&.${listItemButtonClasses.selected}:hover`] ?? {}),
            },
            '&:hover': {
                backgroundColor: 'inherit',
                ...(sx?.['&:hover'] ?? {}),
                [`& > .${listItemIconClasses.root} > .sidebar-icon`]: {
                    animation: 'sidebar-menu-icon-swing ease-in-out 0.5s 1 alternate',
                    ...(sx?.['&:hover']?.[`& > .${listItemIconClasses.root} > .sidebar-icon`] ?? {}),
                },
            },
        })
    }, [currentNestedLevel, sidebar.color, props.ListItemButtonProps]);

    /**
     * Creates the styles for the Sidebar list item icon
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the Sidebar list item icon
     */
    const listItemIconMixin = useCallback<ThemeMixin<Theme>>((theme) => {
        const sx = UIUtils.invokeSx(props.ListItemIconProps?.sx, theme) as any;
        return ({
            ...sx,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 30,
            ...(panel.sidebarCollapsed && {
                minWidth: 40,
            }),
            ...(!panel.sidebarCollapsed && {
                marginInlineEnd: theme.spacing(1),
            }),
            ...(isActive && {
                '& .sidebar-icon': {
                    color: theme.palette[sidebar.color].main,
                    '.fill': {
                        fill: 'currentColor',
                    },
                    '.stroke': {
                        stroke: 'currentColor',
                    },
                    ...(sx?.[`& .sidebar-icon`] ?? {}),
                },
            }),
        })
    }, [props.ListItemIconProps?.sx, panel.sidebarCollapsed, isActive, sidebar.color]);

    /**
     * Creates the styles for the icon placeholder
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the icon placeholder
     */
    const iconPlaceholderMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        width: '0.4rem',
        height: '0.4rem',
        borderRadius: '50%',
        backgroundColor: theme.palette[sidebar.color].main,
    }), [sidebar.color])

    const primaryTextMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        color: theme.palette.text.secondary,
        ...(isActive && {
            color: theme.palette[sidebar.color].main,
            fontWeight: theme.typography.fontWeightMedium,
        }),
        ...(UIUtils.invokeSx(props.PrimaryTextProps?.sx, theme) as any),
    }), [isActive, props.PrimaryTextProps, sidebar.color])

    return (
        <>
            <ListItemButton
                disableRipple
                selected={isActive}
                {...props.ListItemButtonProps}
                sx={listItemButtonMixin}
                onClick={closeSidebarOnClick}
                {...(!!props.LinkProps?.to && {
                    component: Link as any,
                    ...(props.LinkProps ?? {}) as any,
                })}
            >
                {props.startAction}
                <ListItemIcon {...props.ListItemIconProps} sx={listItemIconMixin}>
                    {
                        props.icon
                            ? cloneElement(props.icon as any, {
                                color: isActive ? sidebar.color : 'action',
                                className: classNames('sidebar-icon', cloneElement(props.icon as any)?.props?.className ?? ""),
                            } as SvgIconOwnProps)
                            : isActive
                                ? <Box sx={iconPlaceholderMixin}/>
                                : null
                    }
                </ListItemIcon>
                {
                    !panel.sidebarCollapsed &&
                    <>
                        <ListItemText
                            primary={props.primaryText}
                            primaryTypographyProps={{
                                ...props.PrimaryTextProps,
                                noWrap: true,
                                variant: 'body2',
                                sx: primaryTextMixin,
                            }}
                            secondary={props.secondaryText}
                            secondaryTypographyProps={props.SecondaryTextProps}
                        />
                        {props.badge}
                        {props.action}
                    </>
                }
            </ListItemButton>
        </>
    )
}


export type SidebarMenuProps = PropsWithChildren<Omit<SidebarMenuItemProps, 'action' | 'onClick' | 'isActive'> & {
    ListProps?: Omit<ListProps, 'sx' | 'children'>;
    defaultOpen?: boolean
}>;

export const SidebarMenu: FunctionComponent<SidebarMenuProps> = (props) => {
    const [open, setOpen] = useState(props.defaultOpen ?? false);
    const currentNestedLevel = useSidebarNestedLevel();
    const panel = usePanel();
    const sidebar = useSidebar();

    /**
     * With each change in the [panel.collapsed] and [currentNestedLevel]:
     * - updates the [open] state if the menu is collapsed and the current nested level is greater than 0.
     */
    useLayoutEffect(() => {
        if (panel.sidebarCollapsed && currentNestedLevel > 0) {
            setOpen(false);
        }
    }, [panel.sidebarCollapsed, currentNestedLevel]);

    /**
     * Toggles the opened state of the menu
     */
    const toggleOpened = useCallback<VoidCallback>(() => setOpen(prevState => !prevState), []);

    /**
     * Creates the styles for the sidebar menu collapse
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar menu collapse
     */
    const collapseMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        paddingInline: theme.spacing(1),
        paddingBlockEnd: theme.spacing(1),
        transition: theme.transitions.create(['margin', 'padding', 'height']),
        borderEndStartRadius: theme.shape.borderRadius,
        borderEndEndRadius: theme.shape.borderRadius,
        ...(currentNestedLevel === 0 && {
            marginInlineEnd: theme.spacing(2),
        }),
        ...(open && {
            marginBlockEnd: theme.spacing(1),
            backgroundColor: (currentNestedLevel % 2 !== 0) ? theme.palette.background.paper : `rgba(${UIUtils.themeVar(`palette-${sidebar.color}-mainChannel`)} / ${UIUtils.themeVar(`palette-action-selectedOpacity`)})`,
        }),
        [`a`]: {
            paddingInline: 0,
        }
    }), [currentNestedLevel, open, sidebar.color]);

    /**
     * Creates the styles for the sidebar menu list item button.
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the menu list item button
     */
    const ListItemButtonMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        transition: theme.transitions.create(['padding']),
        ...(currentNestedLevel > 0 && {
            marginInlineEnd: 0,
        }),
        ...(open && {
            ...(currentNestedLevel % 2 !== 0 && {
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                    backgroundColor: theme.palette.background.paper,
                },
                [`&.${listItemButtonClasses.selected}`]: {
                    backgroundColor: theme.palette.background.paper,
                },
                [`&.${listItemButtonClasses.selected}:hover`]: {
                    backgroundColor: theme.palette.background.paper,
                },
            }),
            ...(!panel.sidebarCollapsed && {
                borderEndStartRadius: 0,
                borderEndEndRadius: 0,
            })
        }),
    }), [currentNestedLevel, open, panel.sidebarCollapsed])

    /**
     * Creates the styles for the sidebar menu item primary text.
     * @param {Theme} theme                      The theme to use
     * @returns {ReturnType<ThemeMixin<Theme>>}  The styles for the sidebar menu item primary text.
     */
    const PrimaryTextMixin = useCallback<ThemeMixin<Theme>>((theme) => ({
        ...(open && {
            fontWeight: theme.typography.fontWeightRegular,
        })
    }), [open])

    return (
        <>
            <SidebarMenuItem
                isActive={open}
                {...props}
                {...props.children && !panel.sidebarCollapsed && {
                    action: open ? <ExpandLess sx={{opacity: 0.6}}/> : <ExpandMore sx={{opacity: 0.6}}/>,
                }}
                ListItemButtonProps={{
                    sx: ListItemButtonMixin,
                    ...(props.children && !panel.sidebarCollapsed && {
                        onClick: toggleOpened,
                    }),
                }}
                PrimaryTextProps={{
                    sx: PrimaryTextMixin,
                }}
            />
            {
                props.children &&
                <Collapse
                    in={open && !panel.sidebarCollapsed}
                    sx={collapseMixin}
                >
                    <SidebarNestedLevelContext.Provider value={currentNestedLevel + 1}>
                        {
                            !panel.sidebarCollapsed &&
                            <Divider/>
                        }
                        <List
                            color={sidebar.color}
                            {...props.ListProps}
                            disablePadding
                        >
                            {props.children}
                        </List>
                    </SidebarNestedLevelContext.Provider>
                </Collapse>
            }
        </>
    );
}

import DataGridApiEndpoints from "../../models/static/api-endpoints";
import type {GetAllSavedStatesResponse, SaveDataGridStateRequestDs, SaveDataGridStateResponse} from "./type-declarations";
import type {DataGridSavedStateEntry, DataGridSavedStateEntryData, SaveDataGridSavedStateEntry} from "../../../type-declerations";
import {ApiMethods, ApiService} from "../../../../base/core";

/**
 * @inheritDoc
 */
class DataGridApiService extends ApiService {

    /**
     * Loads the saved data of the data-grid from the server.
     */
    static async getAllSavedStates(applicationName: string, abortController: AbortController): GetAllSavedStatesResponse {
        const response = await this.executor.execute({
            method: ApiMethods.get,
            url: DataGridApiEndpoints.dataGrid.getData,
            params: {
                applicationName: applicationName,
            },
            signal: abortController.signal,
        });
        if (response?.resultFlag) {
            response.data = (response.data as Array<DataGridSavedStateEntry<string>>)
                    ?.map<DataGridSavedStateEntry<DataGridSavedStateEntryData | undefined>>(e => {
                        const res = e as unknown as DataGridSavedStateEntry<DataGridSavedStateEntryData | undefined>;
                        res.version = Number(res.version);
                        try {
                            res.data = JSON.parse(e.data ?? '{}');
                        } catch {
                            res.data = undefined;
                        }
                        return res;
                    })
                    ?.filter(e => !!e.data)
                ?? [];
        }
        return response;
    }

    /**
     * Saves the data-grid the newest state in the server.
     *
     * @param state             the state that is to be saved in the server.
     * @param abortController   the controller that would abort the execution of the api
     */
    static async saveDataGridState(state: SaveDataGridSavedStateEntry, abortController?: AbortController): SaveDataGridStateResponse {
        return await this.executor.execute({
            method: ApiMethods.post,
            url: DataGridApiEndpoints.dataGrid.saveData,
            data: {
                ...state,
                data: JSON.stringify(state.data),
            },
            signal: abortController?.signal,
        } as SaveDataGridStateRequestDs);
    }
}


export * from './type-declarations';
export default DataGridApiService;

import {configureStore as toolkitConfigureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import BizkeytechReduxActionsReduxRootReducer from "./root-reducer";
import DialogsReduxSlice from "./slices/dialogs";

/**
 * Configures the Redux store of the Bizkey tech tools and returns the configured store.
 * * enables hot module reloading (HMR) for the redux reducers as well.
 */
const configureStore = () => {
    const store = toolkitConfigureStore({
        reducer: BizkeytechReduxActionsReduxRootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    ...Object.values(DialogsReduxSlice.actions).map(e => e.type),
                ],
                ignoredPaths: [
                    DialogsReduxSlice.name,
                ],
            }
        }),
    })

    if (process.env.NODE_ENV === 'development') {
        module.hot?.accept('./root-reducer', () => store.replaceReducer(BizkeytechReduxActionsReduxRootReducer))
    }
    return store;
}

const BizkeytechReduxStore = configureStore();

export type RootReduxState = ReturnType<typeof BizkeytechReduxStore.getState>;
export type AppDispatch = typeof BizkeytechReduxStore.dispatch;
export const useBizkeytechDispatch: () => AppDispatch = useDispatch;
export const useBizkeytechSelector: TypedUseSelectorHook<RootReduxState> = useSelector;


export {default as BizkeytechReduxActions} from './actions';
export default BizkeytechReduxStore
